import { Box, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledImg = styled('img')(({ theme }) => ({
  margin: 'auto',
  display: 'block',
  maxHeight: '100%',
  maxWidth: '100%',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 5),
  },
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '4px',
    maxWidth: '1000px',
    maxHeight: '650px',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '750px',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '550px',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '100%',
    },
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.main}`,
  padding: theme.spacing(2.5),
  maxWidth: '1000px',
  height: 'calc(100% - 56px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '750px',
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '550px',
  },
}));

export const StyledPrevArrow = styled(Box)(() => ({
  height: '60px',
  position: 'absolute',
  left: '20px',
  top: '300px',
  cursor: 'pointer',
}));

export const StyledNextArrow = styled(Box)(() => ({
  height: '60px',
  position: 'absolute',
  right: '20px',
  top: '300px',
  cursor: 'pointer',
}));
