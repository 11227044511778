import {
  createContext, FC, ReactNode, useState,
} from 'react';

import { PaymentSteps } from 'constants/enums';
import { PaymentDetailsOfPlacedOrder } from 'types/order.interface';

const PaymentContext = createContext({
  paymentDetails: {} as PaymentDetailsOfPlacedOrder,
  paymentFlow: {
    paymentSteps: PaymentSteps.HowToPay,
    showKioskPaymentMethods: false,
    hasPaymentFailed: false,
  },
});

const PaymentDispatchContext = createContext({
  savePayment: (payment: PaymentDetailsOfPlacedOrder) => {}, // eslint-disable-line
  removePayment: () => {}, // eslint-disable-line
  resetPaymentFlow: () => {}, // eslint-disable-line
  updatePaymentFlow: (info: Record<string, PaymentSteps | boolean>) => {}, // eslint-disable-line
});

interface PaymentProviderProps {
  children?: ReactNode|null;
}

const PaymentProvider:FC<PaymentProviderProps> = ({ children }) => {
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetailsOfPlacedOrder>({} as PaymentDetailsOfPlacedOrder);
  const [paymentFlow, setPaymentFlow] = useState({
    paymentSteps: PaymentSteps.HowToPay,
    showKioskPaymentMethods: false,
    hasPaymentFailed: false,
  });

  const updatePaymentFlow = (data: any) => {
    setPaymentFlow((prevState) => ({ ...prevState, ...data }));
  };

  const resetPaymentFlow = () => {
    setPaymentFlow({
      paymentSteps: PaymentSteps.HowToPay,
      showKioskPaymentMethods: false,
      hasPaymentFailed: false,
    });
  };

  const savePayment = (payment: PaymentDetailsOfPlacedOrder): void => {
    setPaymentDetails(payment);
  };

  const removePayment = (): void => {
    setPaymentDetails({} as PaymentDetailsOfPlacedOrder);
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <PaymentContext.Provider value={{ paymentDetails, paymentFlow }}>
      {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
      <PaymentDispatchContext.Provider value={{
        savePayment,
        removePayment,
        updatePaymentFlow,
        resetPaymentFlow,
      }}
      >
        {children}
      </PaymentDispatchContext.Provider>
    </PaymentContext.Provider>
  );
};

export {
  PaymentProvider,
  PaymentContext,
  PaymentDispatchContext,
};
