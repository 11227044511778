import {
  Grid2 as Grid, Box, Dialog,
} from '@mui/material';
import { isNil } from 'lodash';
import {
  Suspense, lazy, FC, useContext, useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import KioskFooter from 'components/_Footer/index.kiosk';
import CustomContent from 'components/CustomContent';
import EmptyState from 'components/EmptyState';
import ProductCardSkeleton from 'components/ProductCard/index.skeleton';
import Categories from 'containers/Categories';
import KioskCategoriesSkeleton from 'containers/Categories/KioskCategories/index.skeleton';
import HighlightsBanner from 'containers/HighlightsBanner';
import ProductDetailsPage from 'containers/ProductDetailsPage/index.kiosk';
import ProductFilterFlyout from 'containers/ProductFilterFlyout';
import ProductSortFlyout from 'containers/ProductSortFlyout';
import { ActionsWrapper, Content } from 'containers/ProductsProviderPage/KioskProducts/index.styled';
import RecommendationsBanner from 'containers/ProductsProviderPage/RecommendationsBanner';
import PromotionBanner from 'containers/PromotionBanner';
import { CategoryContext } from 'context/CategoryContext';
import { LocationContext } from 'context/LocationContext';
import { SystemContext } from 'context/SystemContext';

import { BannerType, ProductSortOptions, ViewMode } from 'constants/enums';
import { KIOSK_HEADER_HEIGHT } from 'constants/general';
import { Bounty } from 'types/bounty.interface';
import { Promotion } from 'types/promotion.interface';

const ProductCard = lazy(() => import('components/ProductCard'));

interface KioskProductsProps {
  promotions?: Record<BannerType, Promotion[]>;
  onLoadProducts: () => void;
  skeletonProducts: number[];
  products: Bounty[] | null;
  hasMore: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  onSort: (newSort: ProductSortOptions) => void;
  sortOption: ProductSortOptions;
}

const KioskProducts: FC<KioskProductsProps> = ({
  promotions,
  onLoadProducts,
  skeletonProducts,
  products,
  hasMore,
  isLoadingMore,
  isLoading,
  onSort,
  sortOption,
}) => {
  const [selectedBounty, setSelectedBounty] = useState<Bounty|null>(null);
  const { categories, isLoading: isCategoriesLoading } = useContext(CategoryContext);
  const { shopSettings } = useContext(SystemContext);
  const { onboardingInfo } = useContext(LocationContext);
  const hasCategories = categories?.length > 0;
  const isDescriptionVisible = shopSettings?.viewConfigs?.PRODUCT_DESCRIPTION?.viewMode === ViewMode.Full;
  const {
    [BannerType.Hero]: heroes = [],
    [BannerType.Recommendations]: recommendations = [],
    [BannerType.Highlights]: highlights = [],
  } = promotions || {};
  const isListVisible = !(highlights?.length > 0 && recommendations?.length > 0);

  const openBountyDetails = (bounty: Bounty) => {
    setSelectedBounty(bounty);
  };

  const closeBountyDetails = () => {
    setSelectedBounty(null);
  };

  return (
    <Box display="flex" minHeight={`calc(100vh - ${KIOSK_HEADER_HEIGHT}px)`}>
      {isCategoriesLoading
        ? <KioskCategoriesSkeleton />
        : hasCategories && <Categories />}
      <Content>
        <CustomContent kioskMode sx={{ flexGrow: 1 }}>
          <Box maxHeight="calc(100vh - 288px)" className="hidden-scroll">
            {heroes?.length > 0 && (
              <PromotionBanner
                sx={{ px: heroes?.length > 1 ? 6 : 0, mt: 0 }}
                promotions={heroes}
              />
            )}
            {highlights?.length > 0 && <HighlightsBanner highlights={highlights} sx={{ mb: 6 }} /> }
            {recommendations?.length > 0 && (
              <RecommendationsBanner
                recommendations={recommendations}
                openDetails={openBountyDetails}
              />
            )}
          </Box>

          {isListVisible && (
            <div>
              {!isLoading && isNil(products)
                ? <EmptyState message="No products found!" />
                : (
                  <>
                    <ActionsWrapper>
                      <ProductSortFlyout sortOption={sortOption} onChange={onSort} />
                      <ProductFilterFlyout />
                    </ActionsWrapper>

                    <InfiniteScroll
                      pageStart={0}
                      loadMore={onLoadProducts}
                      hasMore={hasMore}
                    >
                      <Grid container spacing={2} maxHeight="calc(100vh - 390px)" className="hidden-scroll">
                        {!isLoading && products && products.map((bounty, index) => (
                          <Grid key={`${index}-${bounty.id}`} size={[12, 12, 4, 3]}>
                            <Suspense fallback={<ProductCardSkeleton />}>
                              <Box
                                className="pointer"
                                onClick={() => openBountyDetails(bounty)}
                                height="100%"
                                width="100%"
                              >
                                <ProductCard
                                  bounty={bounty}
                                  isDescriptionVisible={isDescriptionVisible}
                                  useType={onboardingInfo?.useType}
                                />
                              </Box>
                            </Suspense>
                          </Grid>
                        ))}

                        {(isLoading || isLoadingMore) && skeletonProducts.map((loader) => (
                          <Grid key={`productSkeleton-${loader}`} size={[12, 12, 4, 3]}>
                            <ProductCardSkeleton />
                          </Grid>
                        ))}
                      </Grid>
                    </InfiniteScroll>
                  </>
                )}
            </div>
          )}
        </CustomContent>

        <KioskFooter />
      </Content>

      {!!selectedBounty && (
        <Dialog
          open
          fullWidth
          onClose={closeBountyDetails}
          aria-describedby="select-tier-price"
          PaperProps={{ sx: { maxWidth: 800, borderRadius: '16px', p: '44px' } }}
        >
          <ProductDetailsPage
            bountyDetails={selectedBounty}
            onCancel={closeBountyDetails}
          />
        </Dialog>
      )}
    </Box>
  );
};

export default KioskProducts;
