import { Box, Typography } from '@mui/material';
import { FC } from 'react';

import SectionTitle from 'components/SectionTitle';

import { ShopSpecialHoursOfOperation } from 'types/shopOperation.interface';
import { formatSpecialDateRange } from 'utils/shopOperation';

interface ScheduleProps {
  scheduleInfo: {
    pickupSchedule: string | null;
    deliverySchedule: string | null;
    specialHoursOfOperation?: ShopSpecialHoursOfOperation;
  };
}

const Schedule: FC<ScheduleProps> = ({ scheduleInfo }) => {
  const { pickupSchedule, deliverySchedule, specialHoursOfOperation } = scheduleInfo;

  return (
    <div>
      <SectionTitle title="Hours" sx={{ mb: 2, mt: 4 }} />
      {pickupSchedule && (
        <Box mb={1}>
          <Typography color="textPrimary">
            Pickup
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {pickupSchedule}
          </Typography>
        </Box>
      )}
      {deliverySchedule && (
        <Box mb={1}>
          <Typography color="textPrimary">
            Delivery
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {deliverySchedule}
          </Typography>
        </Box>
      )}
      {specialHoursOfOperation && specialHoursOfOperation?.pickup?.specialDateRanges?.length > 0 && (
        <>
          <SectionTitle title="Pickup Special Hours" sx={{ mb: 2, mt: 4 }} />
          {specialHoursOfOperation?.pickup?.specialDateRanges?.map((specialDate, key) => (
            <Box mb={1} key={key}>
              {specialDate.label && (
              <Typography color="textPrimary">
                {specialDate.label}
              </Typography>
              )}
              <Typography variant="body2" color="textSecondary">
                {formatSpecialDateRange(specialDate)}
              </Typography>
            </Box>
          ))}
        </>
      )}
      {specialHoursOfOperation && specialHoursOfOperation?.delivery?.specialDateRanges?.length > 0 && (
        <>
          <SectionTitle title="Delivery Special Hours" sx={{ mb: 2, mt: 4 }} />
          {specialHoursOfOperation?.delivery?.specialDateRanges?.map((specialDate, key) => (
            <Box mb={1} key={key}>
              {specialDate.label && (
                <Typography color="textPrimary">
                  {specialDate.label}
                </Typography>
              )}
              <Typography variant="body2" color="textSecondary">
                {formatSpecialDateRange(specialDate)}
              </Typography>
            </Box>
          ))}
        </>
      )}
    </div>
  );
};

export default Schedule;
