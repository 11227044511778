import { ButtonBase } from '@mui/material';
import MuiDialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';

export const StyledButton = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.general.lightGrey2,
  height: '100%',
}));

export const StyledShoppingCart = styled('div')<{ kioskMode : boolean }>(({ kioskMode, theme }) => ({
  padding: !kioskMode ? '2.5px' : theme.spacing(0.5),
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '20px',
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  '&:hover': {
    backgroundColor: theme.palette.background.secondary,
  },
  backgroundColor: theme.palette.common.white,

  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
}));

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  minWidth: '500px',
  mb: theme.spacing(2),
  pt: theme.spacing(2),

  [theme.breakpoints.down('sm')]: {
    minWidth: '100%',
  },
}));
