import { FC, ReactElement } from 'react';
import { useDropzone } from 'react-dropzone';

import { ACCEPTED_IMAGE_TYPES } from 'constants/general';

interface DropzoneWrapperProps {
  onDrop: (acceptedFiles: File[]) => void;
  children: ReactElement;
  supportedFileTypes?: { [key: string]: string[] };
  multiple?: boolean;
}

const DropzoneWrapper: FC<DropzoneWrapperProps> = ({
  onDrop,
  children,
  supportedFileTypes = ACCEPTED_IMAGE_TYPES,
  multiple = false,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: supportedFileTypes,
    multiple,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

export default DropzoneWrapper;
