import { Box, SelectChangeEvent, Grid2 as Grid } from '@mui/material';
import {
  get, isEmpty, omit, set,
} from 'lodash';
import {
  ChangeEvent, useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ValidationError } from 'yup';

import CheckoutFooter from 'components/_CheckoutFooter';
import BackButton from 'components/BackButton';
import CustomContent from 'components/CustomContent';
import EmptyPreviewCart from 'components/EmptyPreviewCart';
import SectionTitle from 'components/SectionTitle';
import SubheaderWrapper from 'components/SubheaderWrapper';
import CheckoutForm from 'containers/CheckoutPage/CheckoutForm';
import CheckoutSummary from 'containers/CheckoutPage/CheckoutSummary';
import { FormWrapper, Root } from 'containers/CheckoutPage/index.styled';
import KioskPaymentMethods from 'containers/KioskPaymentMethods';
import { CartContext, CartDispatchContext } from 'context/CartContext';
import { LocationContext } from 'context/LocationContext';
import { PaymentContext, PaymentDispatchContext } from 'context/PaymentContext';
import { SystemContext } from 'context/SystemContext';
import { UserContext } from 'context/UserContext';

import useCheckoutState from 'hooks/useCheckoutState';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { getCartRoute, getCheckoutCompleteRoute, getPaymentWidgetRoute } from 'hooks/useRouting';
import useSummary from 'hooks/useSummary';
import { placeOrder } from 'services/Order';

import { PaymentMethod } from 'constants/enums';
import {
  DELIVER_PHONE_NUMBER,
  MEDICAL_ID,
  MEDICAL_ID_EXP,
  PHONE_NUMBER,
  PICK_UP_DATE,
  PICK_UP_TIME,
} from 'constants/fields';
import { PlaceOrderInfo } from 'types/checkout.interface';
import { getPaymentMethodBasedOnEnv } from 'utils/bmbUtils';
import { generateUID } from 'utils/cartUtils';
import {
  formatCheckoutValues,
  getDayLimitBasedOnDelivery,
  isIntegratedPayment,
  isPickupMethod,
} from 'utils/checkoutUtils';
import { handleApiErrors } from 'utils/errorUtils';
import { isValidHourForPlacingOrder } from 'utils/shopOperation';
import {
  getLocalUsStates, getSavedUid, saveCheckoutDetailsToStorage, saveUIDToStorage,
} from 'utils/storageUtils';
import { reportAlert } from 'utils/utils';
import { getCheckoutFormSchema } from 'validation/checkoutFormSchema';

const CheckoutPage = () => {
  const navigate = useNavigate();
  const cart = useContext(CartContext);
  const { removeItems } = useContext(CartDispatchContext);
  const { user } = useContext(UserContext);
  const { paymentFlow } = useContext(PaymentContext);
  const { savePayment, updatePaymentFlow, resetPaymentFlow } = useContext(PaymentDispatchContext);
  const { onboardingInfo } = useContext(LocationContext);
  const { shopSettings, detailedClientData } = useContext(SystemContext);
  const pageTitle = user.isAnonymous ? 'Guest Checkout' : 'Checkout';
  const kioskSettings = detailedClientData?.kioskId
    ? shopSettings?.kioskSettings?.[detailedClientData?.kioskId]
    : null;

  const usStates = getLocalUsStates();

  const {
    checkSummary,
    isLoading: isSummaryLoading,
    cartPriceDetails,
    formattedCartDetails,
  } = useSummary();
  const { handleTrackPurchase, handleTrackBeginCheckout } = useGoogleAnalytics();

  const products = useMemo(() => Object.values(cart?.products) || [], [cart?.products]);
  const { useType } = onboardingInfo;

  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSummaryFailed, setHasSummaryFailed] = useState<boolean>(false);

  const {
    checkoutForm,
    setCheckoutForm,
    orderDateRestrictions,
    isLoadingDateRestrictions,
  } = useCheckoutState();

  useDocumentTitle({ title: 'Checkout' });

  useEffect(() => {
    if (!isEmpty(cart?.products)) {
      handleCreateSummary();
    }
  }, [cart?.products]);

  useEffect(() => {
    if (products?.length > 0 && !isEmpty(cartPriceDetails)) {
      handleTrackBeginCheckout(cartPriceDetails, products);
    }
  }, [cartPriceDetails, products]);

  useEffect(() => () => {
    resetPaymentFlow();
  }, []);

  const saveCheckoutDetails = (data: PlaceOrderInfo) => {
    const omitFields = [
      MEDICAL_ID.name, MEDICAL_ID_EXP.name, PICK_UP_DATE.name, PICK_UP_TIME.name, DELIVER_PHONE_NUMBER.name,
    ];
    saveCheckoutDetailsToStorage(omit(data, omitFields) as PlaceOrderInfo);
  };

  const handleCreateSummary = async () => {
    try {
      const { products } = await checkSummary();

      if (!isEmpty(products)) {
        toast.error('Some of the products in your cart are no longer available. Please review your cart.');
        return { hasProductsChanged: true };
      }

      setHasSummaryFailed(false);
      return { hasProductsChanged: false };
    } catch (e) {
      setHasSummaryFailed(true);
      return { hasProductsChanged: false };
    }
  };

  const clearErrors = (name: string) => {
    setFieldErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  const handleChangeValue = (value: any, name: string) => {
    setCheckoutForm((prev) => {
      const newCheckoutForm = { ...prev };

      if ([PHONE_NUMBER.name, DELIVER_PHONE_NUMBER.name].includes(name)) {
        const isCustomerPhone = name === PHONE_NUMBER.name;
        const fieldPath = isCustomerPhone ? 'customer.address' : 'address';
        set(newCheckoutForm, fieldPath, { ...get(prev, fieldPath), ...value });

        if (isCustomerPhone) {
          clearErrors(DELIVER_PHONE_NUMBER.name);
        }
      } else {
        set(newCheckoutForm, name, value);
      }

      if (!detailedClientData?.kioskMode) {
        saveCheckoutDetails(newCheckoutForm);
      }

      return newCheckoutForm;
    });

    clearErrors(name);
  };

  const handleInputChange = ({
    target: { value, name },
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    handleChangeValue(value, name);
  };

  const handlePlaceOrder = async ({ shouldRedirect } = { shouldRedirect: false }) => {
    const { hasProductsChanged } = await handleCreateSummary();

    if (!hasProductsChanged) {
      const formattedCheckoutForm = formatCheckoutValues(
        checkoutForm,
        onboardingInfo,
        shopSettings?.checkoutFields,
        detailedClientData?.kioskMode,
      );
      const currentPaymentMethod = formattedCheckoutForm?.payment?.paymentMethod as PaymentMethod;
      let uid = getSavedUid();

      if (!uid) {
        uid = generateUID();
        saveUIDToStorage(uid);
      }

      const order = {
        products: cart?.products,
        ...formattedCheckoutForm,
        uid,
        payment: {
          ...formattedCheckoutForm?.payment,
          paymentMethod: getPaymentMethodBasedOnEnv(currentPaymentMethod),
        },
      };

      const { data } = await placeOrder(order);

      setFieldErrors({});
      handleTrackPurchase(cartPriceDetails, order, products, data?.id);
      removeItems();

      savePayment({
        orderAmount: formattedCartDetails?.total || '0',
        orderId: data?.bountyId,
        alleavesOrderId: data?.alleavesOrderId,
        orderShortId: data?.bountyShortId,
        bmbAuthToken: data?.bmbAuthToken,
        bmbEnv: data?.bmbEnv,
        paymentMethod: currentPaymentMethod,
        deliveryMethod: order?.delivery?.method,
        receiptUrl: data?.receiptUrl,
      });

      if (!data?.bountyId) {
        toast.error('Missing information about the order!');
        return '';
      }

      if (shouldRedirect) {
        if (isIntegratedPayment(currentPaymentMethod)) {
          navigate(getPaymentWidgetRoute(data.bountyId, `alleavesOrderId=${data?.alleavesOrderId || ''}`));
        } else {
          navigate(getCheckoutCompleteRoute(data.bountyId, `alleavesOrderId=${data?.alleavesOrderId || ''}`));
        }
      }

      return data?.bountyId;
    }

    return '';
  };

  const handleOrder = async ({ ignoreDialog, shouldRedirect } = { ignoreDialog: false, shouldRedirect: false }) => {
    if (isEmpty(cart?.products)) {
      toast.error('Your cart is empty!');
    } else {
      try {
        setIsSubmitting(true);
        const hoursOfOperation = shopSettings.effectiveShopOperation?.hoursOfOperation;
        const dayCap = hoursOfOperation
          ? getDayLimitBasedOnDelivery(hoursOfOperation, checkoutForm.delivery.method)
          : 0;
        const checkoutFormSchema = getCheckoutFormSchema(
          dayCap,
          checkoutForm?.delivery?.method,
          shopSettings?.checkoutFields,
          detailedClientData?.kioskMode,
        );
        await checkoutFormSchema.validate(checkoutForm, { abortEarly: false });

        const { pickupTime, method } = checkoutForm.delivery;
        const isPickup = isPickupMethod(method);

        if (!detailedClientData?.kioskMode && pickupTime && isPickup
          && !isValidHourForPlacingOrder(pickupTime, orderDateRestrictions)
        ) {
          setFieldErrors({ ...fieldErrors, [PICK_UP_TIME.name]: 'We have too many pickups for this hour.' });
          return '';
        }

        if (detailedClientData?.kioskId && !ignoreDialog) {
          updatePaymentFlow({ showKioskPaymentMethods: true });
          return '';
        }

        const id = await handlePlaceOrder({ shouldRedirect });
        return id;
      } catch (err: any) {
        if (!(err instanceof ValidationError) || !ValidationError.isError(err)) {
          reportAlert(`place order failed: ${JSON.stringify(err)}`);
        }

        handleApiErrors(err, setFieldErrors);
      } finally {
        setIsSubmitting(false);
        saveUIDToStorage();
      }
    }

    return '';
  };

  useEffect(() => {
    if (paymentFlow.showKioskPaymentMethods && !kioskSettings) {
      toast.error('Missing kiosk settings!');
    }
  }, [paymentFlow.showKioskPaymentMethods, kioskSettings]);

  if (paymentFlow.showKioskPaymentMethods && kioskSettings) {
    return (
      <Root>
        <KioskPaymentMethods
          isSubmitting={isSubmitting}
          onPlaceOrder={handleOrder}
          kioskSettings={kioskSettings}
          onChangeCheckout={handleChangeValue}
        />
      </Root>
    );
  }

  if (detailedClientData?.kioskMode) {
    return (
      <Root>
        {products?.length > 0 ? (
          <>
            <CustomContent kioskMode sx={{ flexGrow: 1 }} className="hidden-scroll">
              <FormWrapper>
                <CheckoutForm
                  onChange={handleInputChange}
                  onValueChange={handleChangeValue}
                  checkoutForm={checkoutForm}
                  fieldErrors={fieldErrors}
                  usStates={usStates || []}
                  useType={useType}
                  orderDateRestrictions={orderDateRestrictions}
                  checkoutFields={shopSettings?.checkoutFields}
                  isLoadingDateRestrictions={isLoadingDateRestrictions}
                  kioskMode={detailedClientData?.kioskMode}
                />
              </FormWrapper>
            </CustomContent>
            <CheckoutFooter
              onSubmit={() => handleOrder({ ignoreDialog: false, shouldRedirect: true })}
              isSummaryLoading={isSummaryLoading}
              isSubmitting={isSubmitting}
              disabled={hasSummaryFailed}
              hasSummaryFailed={hasSummaryFailed}
              cartPriceDetails={formattedCartDetails}
              onChangeSummary={checkSummary}
              continueLabel="Place Order"
            />
          </>
        ) : (
          <Box mt={12}>
            <EmptyPreviewCart hasButton fontSize="large" variant="h6" shouldResetPayment />
          </Box>
        )}
      </Root>
    );
  }

  return (
    <>
      <SubheaderWrapper>
        <Box display="flex" alignItems="center">
          <BackButton href={getCartRoute('checkout')} label="Back" />
          <SectionTitle variant="subheader" title={pageTitle} sx={{ ml: 2.5 }} />
        </Box>
      </SubheaderWrapper>

      <CustomContent>
        {products?.length > 0 ? (
          <Grid container spacing={5} alignItems="flex-start">
            <Grid size={{ xs: 12, md: 7.5, lg: 8 }}>
              <CheckoutForm
                onChange={handleInputChange}
                onValueChange={handleChangeValue}
                checkoutForm={checkoutForm}
                fieldErrors={fieldErrors}
                usStates={usStates || []}
                useType={useType}
                orderDateRestrictions={orderDateRestrictions}
                checkoutFields={shopSettings?.checkoutFields}
                isLoadingDateRestrictions={isLoadingDateRestrictions}
                kioskMode={detailedClientData?.kioskMode}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 4.5, lg: 4 }}>
              <CheckoutSummary
                products={products}
                isSummaryLoading={isSummaryLoading}
                hasSummaryFailed={hasSummaryFailed}
                handlePlaceOrder={() => handleOrder({ ignoreDialog: false, shouldRedirect: true })}
                cartPriceDetails={formattedCartDetails}
                isSubmitting={isSubmitting}
                consentMessage={shopSettings?.consentMessage}
                kioskMode={detailedClientData?.kioskMode}
                onChangeSummary={checkSummary}
                useType={onboardingInfo?.useType}
              />
            </Grid>
          </Grid>
        ) : (
          <Box mt={12}>
            <EmptyPreviewCart hasButton fontSize="large" variant="h6" />
          </Box>
        )}
      </CustomContent>
    </>
  );
};

export default CheckoutPage;
