import { union } from 'lodash';

import { Point } from 'types/money.interface';

export function addPoints(existingPoints?: Record<string, Point>, pointsToAdd?: Record<string, Point>) {
  if (!pointsToAdd || !Object.keys(pointsToAdd).length) {
    return existingPoints;
  }

  if (!existingPoints || !Object.keys(existingPoints).length) {
    return pointsToAdd;
  }

  const allCurrencies = union(Object.keys(existingPoints), Object.keys(pointsToAdd));

  let points = {};

  allCurrencies.forEach((currency) => {
    if (!existingPoints[currency]) {
      points = {
        ...points,
        [currency]: pointsToAdd[currency],
      };
    } else if (!pointsToAdd[currency]) {
      points = {
        ...points,
        [currency]: existingPoints[currency],
      };
    } else {
      points = {
        ...points,
        [currency]: {
          currency,
          amount: +existingPoints[currency].amount + +pointsToAdd[currency].amount,
        },
      };
    }
  });

  return points;
}

export function sumOfAbs(points: Record<string, Point>) {
  if (!points || (points && !Object.keys(points).length)) {
    return 0;
  }

  let sum = 0;

  Object.values(points).forEach(({ amount }) => {
    sum += Math.abs(amount);
  });

  return sum;
}

export function isPointZero(point: Point) {
  const { amount } = point;

  return amount === 0;
}
