import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Box, Typography } from '@mui/material';
import {
  ChangeEvent, FC, useContext, useEffect, useState,
} from 'react';

import {
  InfoBox,
  StoresContainer,
  InputField,
  Wrapper,
} from 'containers/Onboarding/DeliveryRequiredInfo/index.styled';
import { SystemContext } from 'context/SystemContext';

import { useDebounce } from 'hooks/useDebounce';

import { ZIP_CODE } from 'constants/fields';
import { formatAddress } from 'utils/formatters';
import { getStoresByZipcode } from 'utils/storeUtils';

interface DeliveryRequiredInfoProps {
  setup: any;
  updateSettings: (data: any) => void;
}

const DeliveryRequiredInfo:FC<DeliveryRequiredInfoProps> = ({ setup, updateSettings }: any) => {
  const { zipcode, storeId, searchResults } = setup;
  const [showSupport, setShowSupport] = useState(false);
  const { shopSettings } = useContext(SystemContext);
  const debounceValue = useDebounce([zipcode], 1000);

  useEffect(() => {
    searchStores(zipcode);
  }, [debounceValue]);

  const handleZipcodeChanges = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    updateSettings({ zipcode: value });
  };

  const searchStores = (value: string) => {
    if (!value) {
      updateSettings({ searchResults: [] });
      setShowSupport(false);
    } else {
      const foundStores = getStoresByZipcode(value);

      if (foundStores?.length === 1) {
        updateSettings({
          storeId: foundStores[0].id,
          state: foundStores[0]?.address?.state,
        });
      }

      updateSettings({ searchResults: foundStores });
      setShowSupport(!foundStores?.length);
    }
  };

  const handleSelectStore = (selectedStore: any) => {
    updateSettings({ storeId: selectedStore.id });
  };

  return (
    <Wrapper>
      <div>
        <InputField
          {...ZIP_CODE}
          value={zipcode}
          onChange={handleZipcodeChanges}
        />
      </div>

      {!zipcode && !showSupport && (
        <InfoBox>
          Type a zip code to list available stores...
        </InfoBox>
      )}

      {showSupport && (
        <InfoBox>
          {shopSettings?.supportPhoneNumber
            ? `Please reach out to Delivery Dispatch at ${shopSettings.supportPhoneNumber} for assistance.`
            : 'There are no locations currently serving your area'}
        </InfoBox>
      )}

      {searchResults?.length > 0 && (
        <StoresContainer>
          {searchResults.map((result: any) => (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              key={result.id}
              onClick={() => handleSelectStore(result)}
            >
              <div>
                <Typography fontWeight={700}>
                  {result.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {formatAddress(result.address)}
                </Typography>
              </div>
              {result.id === storeId && (
                <CheckCircleOutlineOutlinedIcon fontSize="small" />
              )}
            </Box>
          ))}
        </StoresContainer>
      )}
    </Wrapper>
  );
};

export default DeliveryRequiredInfo;
