import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, SyntheticEvent } from 'react';

import Filters from 'components/Filters';
import FiltersMobile from 'components/FiltersMobile';
import ProductFiltersSkeleton from 'containers/ProductFilters/index.skeleton';

import { FilterType } from 'constants/enums';
import { Filter } from 'types/filter.interface';

interface ProductFiltersProps {
  filters: Filter[];
  isLoading: boolean;
  activeFilters: { [key: string]: string[] };
  selectFilter: (filterId: string, filterCode: string, filterType: FilterType) => void;
  removeFilter: (filterId: string, filterCode: string) => void;
}

const ProductFilters:FC<ProductFiltersProps> = (props) => {
  const theme = useTheme();
  const matchesScreenSizeMd = useMediaQuery(theme.breakpoints.down('md'));
  const {
    filters, isLoading, activeFilters, selectFilter, removeFilter,
  } = props;

  const handleFilter = (filterId: string, filterType: FilterType) => (e: SyntheticEvent, checked: boolean): void => {
    const filterCode = (e.target as HTMLInputElement).value;

    if (checked) {
      selectFilter(filterId, filterCode, filterType);
    } else {
      removeFilter(filterId, filterCode);
    }
  };

  if (isLoading && !filters?.length) {
    return <ProductFiltersSkeleton />;
  }

  return (
    matchesScreenSizeMd
      ? (
        <FiltersMobile
          isLoading={isLoading}
          filters={filters}
          activeFilters={activeFilters}
          onSelectFilter={handleFilter}
        />
      ) : (
        <Filters
          isLoading={isLoading}
          filters={filters}
          activeFilters={activeFilters}
          onSelectFilter={handleFilter}
        />
      )
  );
};

export default ProductFilters;
