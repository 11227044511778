import { Box, SxProps, Typography } from '@mui/material';
import { FC, useMemo, useState } from 'react';

import Brand from 'components/Brand';

import { CART_PRODUCT_PREVIEW_COVER_SIZE } from 'constants/cover';
import { Bounty } from 'types/bounty.interface';
import { getProductCover } from 'utils/attachmentsUtils';
import { textTruncate } from 'utils/formatters';
import { getManufacturer } from 'utils/productUtils';
import { getPublicUrlForImage } from 'utils/publicUrl';

import { MoreSpan, ProductWrapper, StyledImage } from './index.styled';

interface ProductShortPreviewProps {
  bounty: Bounty;
  sx?: SxProps;
}

const ProductShortPreview: FC<ProductShortPreviewProps> = ({ bounty, sx = {} }) => {
  const manufacturer = getManufacturer(bounty?.product);
  const [showMore, setShowMore] = useState(false);

  const coverUrl = useMemo(() => {
    const cover = getProductCover(
      bounty?.product.imageUrl,
      CART_PRODUCT_PREVIEW_COVER_SIZE.w,
      CART_PRODUCT_PREVIEW_COVER_SIZE.h,
      bounty?.product.imageCdn,
    );
    return cover.cdnUrl || cover.url;
  }, [bounty.id]);

  const handleShowMore = () => setShowMore(!showMore);

  return (
    <ProductWrapper display="flex" sx={sx}>
      <StyledImage
        src={coverUrl || getPublicUrlForImage('images/placeholder_image.jpg')}
        alt={bounty?.product?.name}
      />
      <Box mx={1} overflow="hidden">
        <Typography variant="body1" fontWeight={700} noWrap>
          {bounty?.product?.name}
        </Typography>
        {!!manufacturer && <Brand brand={manufacturer} />}
        <Typography variant="body1" color="textSecondary">
          {showMore
            ? bounty?.product?.description
            : textTruncate(bounty?.product?.description, 100)}
          {bounty?.product?.description?.length > 100 && (
            <MoreSpan role="presentation" onClick={handleShowMore}>
              &nbsp;
              {showMore ? 'less' : 'more'}
            </MoreSpan>
          )}
        </Typography>
      </Box>
    </ProductWrapper>
  );
};

export default ProductShortPreview;
