import { Box, Dialog, Typography } from '@mui/material';
import {
  FC, useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CardMedia, ActionWrapper, KioskHeader } from 'components/_Navbar/index.styled';
import CustomButton from 'components/CustomButton';
import ResetKiosk from 'components/ResetKiosk';
import SearchProducts from 'containers/SearchProducts';
import User from 'containers/User';
import { CategoryContext } from 'context/CategoryContext';

import { HOME_ROUTE } from 'constants/clientRoutes';
import { KIOSK_SIDEBAR_WEIGHT } from 'constants/general';
import { ClientInfoInterface } from 'types/clientInfo.interface';
import { getCategoryLeaf, getCategoryName } from 'utils/categoriesUtils';
import { getPublicUrlForImage } from 'utils/publicUrl';

interface KioskNavbarProps {
  clientInfo?: ClientInfoInterface | null;
}

const KioskNavbar:FC<KioskNavbarProps> = ({ clientInfo = null }) => {
  const navigate = useNavigate();
  const { name, branding } = clientInfo || {};
  const [searchParams] = useSearchParams();
  const [clickCount, setClickCount] = useState(0);
  const [showAppVersion, setShowAppVersion] = useState(false);
  const { categories } = useContext(CategoryContext);
  const categoryCode = getCategoryLeaf(searchParams?.get('category'));
  const categoryName = useMemo(() => (
    categoryCode ? getCategoryName(categoryCode, categories) : ''
  ), [categoryCode]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (clickCount === 1 && !showAppVersion) {
        navigate(HOME_ROUTE);
      }
      setClickCount(0); // Reset count after timeout
    }, 300);

    return () => clearTimeout(timeout);
  }, [clickCount]);

  const handleLogoClick = () => {
    setClickCount((prevCount) => prevCount + 1);

    if (clickCount === 4) {
      setShowAppVersion(true);
      setClickCount(0);
    }
  };

  const closeAppVersionDialog = () => setShowAppVersion(false);

  return (
    <KioskHeader>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Box display="flex" alignItems="center" gap={3}>
          <Box onClick={handleLogoClick} className="pointer">
            <Box width={`${KIOSK_SIDEBAR_WEIGHT}px`} display="flex" alignItems="center" justifyContent="center">
              <CardMedia
                component="img"
                src={branding?.logoUrl || getPublicUrlForImage('images/appLogoText.png')}
                alt={name}
              />
            </Box>
          </Box>
          {categoryName && (
            <Typography variant="h5" fontWeight={700} className="headerBackground">
              {categoryName}
            </Typography>
          )}
        </Box>
        <ActionWrapper>
          <SearchProducts />
          <User />
          <ResetKiosk />
        </ActionWrapper>
      </Box>

      {showAppVersion && (
        <Dialog
          open
          onClose={closeAppVersionDialog}
          PaperProps={{ sx: { width: 300, borderRadius: '16px', p: '44px' } }}
        >
          <Box>
            <Typography variant="body2" textAlign="center" mb={4}>
              App version
              {' '}
              {process.env.REACT_APP_VERSION}
            </Typography>
            <CustomButton
              id="button-closeAppVersionDialog"
              fullWidth
              variant="contained"
              onClick={closeAppVersionDialog}
            >
              Close
            </CustomButton>
          </Box>
        </Dialog>
      )}
    </KioskHeader>
  );
};

export default KioskNavbar;
