import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';
import { FC } from 'react';

import CarouselNextArrow from 'components/CarouselNextArrow';
import CarouselPrevArrow from 'components/CarouselPrevArrow';
import {
  StyledBox,
  StyledDialog,
  StyledImg,
  StyledNextArrow,
  StyledPrevArrow,
} from 'components/PicturePreview/index.styled';

interface PicturesCarouselProps {
  open: boolean;
  onClose(): void;
  pictureUrl: string;
  onNext(): void;
  onPrev(): void;
  isFirstPicture: boolean;
  isLastPicture: boolean;
}

const PicturesCarousel: FC<PicturesCarouselProps> = (props) => {
  const {
    open, onClose, pictureUrl, isFirstPicture, isLastPicture,
    onNext, onPrev,
  } = props;

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={onClose} sx={{ m: 1, p: 1 }} id="iconButton-closePicturePreview">
          <CloseIcon />
        </IconButton>
      </Box>

      <StyledBox>
        <StyledImg src={pictureUrl} alt="Product Picture" />
      </StyledBox>

      {!isFirstPicture && (
        <StyledPrevArrow>
          <CarouselPrevArrow onClick={onPrev} />
        </StyledPrevArrow>
      )}

      {!isLastPicture && (
        <StyledNextArrow>
          <CarouselNextArrow onClick={onNext} />
        </StyledNextArrow>
      )}

    </StyledDialog>
  );
};

export default PicturesCarousel;
