import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { StyledChip } from 'components/BackButton/index.styled';

import { useKiosk } from 'hooks/useKiosk';

interface BackButtonProps {
  href?: string;
  label: string;
}

const BackButton:FC<BackButtonProps> = ({ href, label }) => {
  const navigate = useNavigate();
  const { kioskMode } = useKiosk();

  const goBack = () => {
    if (href) {
      navigate(href);
    } else {
      navigate(-1);
    }
  };

  return (
    <StyledChip
      icon={<ArrowBackIcon fontSize="small" />}
      kioskMode={kioskMode}
      label={label}
      onClick={goBack}
      variant="outlined"
    />
  );
};

export default BackButton;
