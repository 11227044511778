import { styled } from '@mui/material/styles';

interface StyledWrapperProps {
  hasError?: boolean;
  kioskMode: boolean;
}
export const StyledWrapper = styled('div')<StyledWrapperProps>(({ theme, hasError, kioskMode }) => {
  const borderColor = hasError ? theme.palette.error.main : theme.palette.border.main;
  const focusBorderColor = hasError ? theme.palette.error.main : theme.palette.primary.main;
  const errorCss = hasError ? {
    '& .MuiFormHelperText-root': {
      color: theme.palette.error.main,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
    },
  } : {};

  return {
    width: '100%',
    '& .react-tel-input .form-control': {
      height: kioskMode ? '44.6px' : '40px', // this is the default height for all the fields in our app
      width: '100%',
      fontSize: kioskMode ? '1.2rem' : '1rem',
      fontFamily: 'Roboto, sans-serif',
      color: theme.palette.text.primary,
      borderColor,
      '&:hover': {
        borderColor: hasError ? theme.palette.error.main : theme.palette.common.black,
      },
      '&:focus': {
        border: `2px solid ${focusBorderColor}`,
      },
    },
    ...errorCss,
  };
});
