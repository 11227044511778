import { Box, Container, Typography } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { SystemContext } from 'context/SystemContext';

import { getHomeRoute } from 'hooks/useRouting';

import { getPublicUrlForImage } from 'utils/publicUrl';

const ShopClosed = () => {
  const { isClosed } = useContext(SystemContext);

  if (!isClosed) {
    return <Navigate to={getHomeRoute()} replace />;
  }

  return (
    <Container>
      <Box display="flex" flexDirection="column" alignItems="center" mt={20}>
        <CardMedia
          component="img"
          sx={{
            objectFit: 'contain',
            width: '100%',
            height: '200px',
          }}
          src={getPublicUrlForImage('images/emptyBox.gif')}
          alt="Closed"
        />
        <Typography variant="h6" fontWeight={600} mt={3} mb={2}>
          Shop Closed
        </Typography>
      </Box>
    </Container>
  );
};
export default ShopClosed;
