import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const UserFormRoot = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  width: '500px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
