import { SxProps, Typography } from '@mui/material';
import { FC } from 'react';

import { FailedSummaryWrapper } from './index.styled';

interface FailedSummaryMessageProps {
  sx?: SxProps;
}

const FailedSummaryMessage: FC<FailedSummaryMessageProps> = ({ sx }) => (
  <FailedSummaryWrapper sx={sx}>
    <Typography fontWeight={700} mb={2}>
      Total Calculation Issue
    </Typography>
    <Typography variant="body2">
      Please note that certain items in your cart are currently unavailable and cannot be processed for order.
      We suggest removing these items to proceed with your purchase.
    </Typography>
  </FailedSummaryWrapper>
);

export default FailedSummaryMessage;
