import {
  FormControl, FormLabel, TextField, TextFieldProps, Typography,
} from '@mui/material';
import { FC } from 'react';

const CustomInput:FC<TextFieldProps> = ({
  name, required, fullWidth, label, slotProps = {}, ...remainingProps
}) => (
  <FormControl component="div" fullWidth={fullWidth}>
    {label && (
    <FormLabel required={required}>
      <Typography component="span" color="textPrimary">
        {label}
      </Typography>
    </FormLabel>
    )}
    <TextField
      {...remainingProps}
      name={name}
      slotProps={{
        input: { autoComplete: 'new-password' },
        ...slotProps,
      }}
    />
  </FormControl>
);

export default CustomInput;
