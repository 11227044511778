import CardMedia from '@mui/material/CardMedia';
import { alpha, styled } from '@mui/material/styles';
import { FC } from 'react';

import { getContrastColorForHeaderBackground } from 'utils/clientUtils';

export const Card = styled('div')<{ isActive?: boolean }>(({ theme, isActive }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  padding: theme.spacing(2, 0.5),
  cursor: 'pointer',
  marginBottom: theme.spacing(1),
  borderRadius: '4px',
  textAlign: 'center',

  '& .MuiTypography-root': {
    color: isActive
      ? theme.palette.getContrastText(theme.palette.headerBackground.main)
      : alpha(theme.palette.getContrastText(theme.palette.headerBackground.main), 0.8),
  },
}));

export const CategoryIconWrapper = styled('div')<{ isActive?: boolean }>(({ theme, isActive }) => {
  const { contrastColor } = getContrastColorForHeaderBackground(theme);
  return ({
    cursor: 'pointer',
    borderRadius: '50%',
    height: '80px',
    width: '80px',
    backgroundColor: isActive
      ? contrastColor
      : alpha(contrastColor, 0.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  });
});

//  default image is black
export const CategoryImage = styled(CardMedia)<{ isActive: boolean }>(({ theme, isActive }) => {
  const { isWhite } = getContrastColorForHeaderBackground(theme);
  return ({
    height: '40px',
    width: '40px',
    filter: isWhite ? `invert(${isActive ? '0%' : '100%'})` : `invert(${isActive ? '100%' : '0%'})`,
  });
}) as typeof CardMedia | FC<{ isActive: boolean }>;
