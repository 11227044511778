import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BalanceWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  width: '100%',
  backgroundColor: theme.palette.background.secondary,
  borderRadius: '4px',
  position: 'relative',
}));
