import { PhoneNumberUtil } from 'google-libphonenumber';

export const checkValidPhone = (value?: string): boolean => {
  const phoneUtil = PhoneNumberUtil.getInstance();

  if (!value) {
    return true;
  }

  try {
    const phoneNumber = phoneUtil.parse(value, '');
    const countryCode = phoneUtil.getRegionCodeForNumber(phoneNumber);

    if (phoneUtil.isValidNumberForRegion(phoneNumber, countryCode)) {
      return true;
    }
  } catch (error) {
    return false;
  }

  return false;
};
