import CloseIcon from '@mui/icons-material/Close';
import {
  Box, Dialog, Divider, IconButton, Typography,
} from '@mui/material';
import {
  FC, MouseEvent, useContext,
} from 'react';

import CustomButton from 'components/CustomButton';
import ProductShortPreview from 'components/ProductShortPreview';
import PriceVariantsSelector from 'containers/PriceVariantsSelector';
import { LocationContext } from 'context/LocationContext';

import { Bounty } from 'types/bounty.interface';

interface ProductFlwrVariantsDialogProps {
  bounty: Bounty;
  isOpen: boolean;
  onClose: (e: MouseEvent<HTMLElement>) => void;
}

const ProductFlwrVariantsDialog: FC<ProductFlwrVariantsDialogProps> = ({
  bounty,
  isOpen,
  onClose,
}) => {
  const { onboardingInfo } = useContext(LocationContext);

  const handleClick = (e: MouseEvent<HTMLElement>) => e.preventDefault();

  return (
    <Dialog
      open={isOpen}
      fullWidth
      onClose={onClose}
      aria-describedby="select-tier-price"
      PaperProps={{ sx: { maxWidth: 560, borderRadius: '16px', p: 2.5 } }}
    >
      <Box onClick={handleClick}>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="h5" fontWeight={700}>
            Select weight
          </Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <ProductShortPreview bounty={bounty} sx={{ mb: 2 }} />

        <Box mt={2}>
          <PriceVariantsSelector bounty={bounty} useType={onboardingInfo?.useType}>
            {({ onAddToCart, isDisabled, label }) => (
              <>
                <Divider sx={{ my: 2 }} />

                <Box display="flex" gap={2} justifyContent="flex-end">
                  <CustomButton
                    id="btn-closeFlwrVariants"
                    onClick={onClose}
                    variant="outlined"
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    id="btn-addToCard-flwrVariantsDialog"
                    onClick={(e) => {
                      onAddToCart();
                      onClose(e);
                    }}
                    disabled={isDisabled}
                  >
                    {label}
                  </CustomButton>
                </Box>
              </>
            )}
          </PriceVariantsSelector>
        </Box>

      </Box>
    </Dialog>
  );
};

export default ProductFlwrVariantsDialog;
