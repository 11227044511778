
export const FIRST_NAME = {
  name: 'customer.firstName',
  required: true,
  fullWidth: true,
  label: 'First Name',
};

export const LAST_NAME = {
  name: 'customer.lastName',
  required: true,
  fullWidth: true,
  label: 'Last Name',
};

export const EMAIL = {
  name: 'customer.email',
  required: true,
  fullWidth: true,
  label: 'Email',
};

export const STATE = {
  name: 'customer.address.state',
  // required: true,
  fullWidth: true,
  label: 'What state do you live in',
};

export const STATE_WITH_PLACEHOLDER = {
  name: 'state',
  placeholder: 'Select State',
  fullWidth: true,
  displayEmpty: true,
};

export const STORE_WITH_PLACEHOLDER = {
  name: 'storeId',
  placeholder: 'Select Store',
  fullWidth: true,
  displayEmpty: true,
};

export const BIRTHDAY = {
  name: 'customer.dob',
  required: true,
  fullWidth: true,
  label: 'Birthday',
  minDate: new Date('1900-01-01'),
  maxDate: new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 18)).setHours(0, 0, 0, 0)),
};

export const PHONE_NUMBER = {
  name: 'customer.address.phoneNumber',
  required: true,
  fullWidth: true,
  label: 'Phone number',
};

export const DELIVERY_METHOD = {
  name: 'delivery.method',
  required: true,
  sx: { '& .MuiFormControlLabel-label': { fontSize: '14px' } },
  fontWeight: 600,
};

export const DELIVER_PHONE_NUMBER = {
  name: 'address.phoneNumber',
  fullWidth: true,
  label: 'Phone number',
};

export const PICK_UP_DATE = {
  name: 'delivery.pickupDate',
  required: true,
  fullWidth: true,
  label: 'Pickup Date',
  disablePast: true,
};

export const DELIVER_NOTE = {
  name: 'delivery.note',
  fullWidth: true,
  multiline: true,
  rows: 3,
};

export const PICK_UP_TIME = {
  name: 'delivery.pickupTime',
  fullWidth: true,
  label: 'Pickup Time',
};

export const PAYMENT_METHOD = {
  name: 'payment.paymentMethod',
  required: true,
  fullWidth: true,
  label: 'Payment Method',
  sx: { '& .MuiFormControlLabel-label': { fontSize: '14px' } },
  fontWeight: 600,
};

export const MEDICAL_ID = {
  name: 'medical.medicalId',
  fullWidth: true,
  required: true,
  label: 'Medical ID',
  placeholder: 'Enter your medical ID',
};

export const MEDICAL_ID_EXP = {
  name: 'medical.medicalIdExp',
  fullWidth: true,
  required: true,
  label: 'Medical ID Exp.',
  minDate: new Date(new Date().setHours(0, 0, 0, 0)),
};

export const CITY = {
  name: 'address.city',
  required: true,
  fullWidth: true,
  placeholder: 'Enter City',
};

export const ZIP_CODE = {
  name: 'address.postalCode',
  required: true,
  fullWidth: true,
  placeholder: 'Enter Zipcode',
};

export const ADDRESS_STATE = {
  name: 'address.state',
  fullWidth: true,
  label: 'State',
};

export const TEMP_STATE = {
  name: 'address.tempState',
};

export const ADDRESS_LINE1 = {
  name: 'address.line1',
  fullWidth: true,
  required: true,
  label: 'Address',
  placeholder: 'Enter your Address',
};

export const CUSTOMER_ADDRESS_LINE1 = {
  ...ADDRESS_LINE1,
  name: 'customer.address.line1',
};

export const CUSTOMER_CITY = {
  ...CITY,
  name: 'customer.address.city',
};

export const CUSTOMER_ZIP_CODE = {
  ...ZIP_CODE,
  name: 'customer.address.postalCode',
};

export const CUSTOMER_STATE = {
  ...ADDRESS_STATE,
  name: 'customer.address.state',
};

export const DRIVER_LICENSE_NUMBER = {
  name: 'driverLicense.number',
  fullWidth: true,
  // required: true,
  label: 'Driver\'s License Number',
  placeholder: 'Enter your Driver License Number',
};

export const DRIVER_LICENSE_STATE = {
  name: 'driverLicense.state',
  fullWidth: true,
  // required: true,
  label: 'Driver\'s License State',
};

export const DRIVER_LICENSE_EXP_DATE = {
  name: 'driverLicense.expDate',
  fullWidth: true,
  // required: true,
  label: 'Driver\'s License Date of Expiration',
  minDate: new Date(new Date().setHours(0, 0, 0, 0)),
};

export const DRIVER_LICENSE_OR_TRIBAL_NUMBER = {
  name: 'driverLicense.number',
  fullWidth: true,
  // required: true,
  label: 'Driver\'s License Number/Tribal ID',
  placeholder: 'Enter your Driver License Number or Tribal ID',
};

export const DRIVER_LICENSE_OR_TRIBAL_STATE = {
  name: 'driverLicense.state',
  fullWidth: true,
  // required: true,
  label: 'Driver\'s License/Tribal ID State',
};

export const REVIEW = {
  name: 'review',
  fullWidth: true,
  required: true,
  multiline: true,
  label: 'Review',
  rows: 4,
};
