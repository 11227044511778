import { Theme } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import { ChemicalKind } from 'constants/enums';

const getChemicalColor = (theme: Theme) => ({
  [ChemicalKind.Cannabinoid]: theme.palette.general.lightGreen1,
  [ChemicalKind.Terpene]: theme.palette.general.lightYellow2,
  [ChemicalKind.Other]: theme.palette.background.secondary,
});

export const ChemicalElement = styled('div')<{ kind: ChemicalKind }>(({ theme, kind }) => ({
  backgroundColor: getChemicalColor(theme)?.[kind] || theme.palette.background.secondary,
  padding: theme.spacing(1),
  borderRadius: '10px',
}));

export const ChemicalPercent = styled(LinearProgress)(({ theme, value }) => ({
  height: '13px',
  borderRadius: '5px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.common.white,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '5px',
    height: '9px',
    backgroundColor: theme.palette.primary.main,
    margin: '2px',
    width: `${value}%`,
    transform: 'none !important',
  },
}));
