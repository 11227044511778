import { Select } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '4px',
  padding: theme.spacing(1.5, 2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
  height: '60px',

  [theme.breakpoints.only('xs')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',

    '& .MuiTypography-root': {
      display: 'none',
    },
  },

  [theme.breakpoints.down(370)]: {
    padding: theme.spacing(1.5, 0.75),
  },
}));

export const Dropdown = styled(Select)(({ theme }) => ({
  borderRadius: '22px',
  backgroundColor: theme.palette.general.lightGrey5,
  height: '34px',

  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.border.main}`,
    },
  },
  '&:hover:not(&.Mui-focused, &.Mui-disabled)': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.border.main}`,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.border.main}`,
  },

  [theme.breakpoints.down(370)]: {
    width: '-webkit-fill-available',

    '& .MuiSelect-select': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      display: 'initial',
      paddingTop: theme.spacing(1),
    },
  },
}));
