import { Box, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { FC, useMemo } from 'react';

import { FreeText, StyledImage } from 'components/PreviewCartProduct/index.styled';
import ProductWeight from 'components/ProductWeight';

import { CART_PRODUCT_PREVIEW_COVER_SIZE } from 'constants/cover';
import { OnboardingUseType, WeightUnit } from 'constants/enums';
import { WEIGHT_UNIT_LABELS } from 'constants/labels';
import { ProductInfo } from 'types/cart.interface';
import { getProductCover } from 'utils/attachmentsUtils';
import { formatProductQuantity, textTruncate } from 'utils/formatters';
import { formatPrice, getPrice, isFree } from 'utils/priceUtils';
import { shouldUseProductWeight } from 'utils/productUtils';
import { getPublicUrlForImage } from 'utils/publicUrl';

interface PreviewCartProductProps {
  product: ProductInfo;
  descriptionLength?: number;
  useType?: OnboardingUseType;
}

const PreviewCartProduct :FC<PreviewCartProductProps> = ({
  product,
  descriptionLength = 150,
  useType,
}) => {
  const {
    weight = '', sortWeight = 0, quantity = 0, sortUnit,
  } = product;
  const productWeight = weight ? +weight : null;
  const { price } = getPrice({
    useType,
    altPrices: product?.altPrices,
    productPrice: { mainPrice: product?.price || {} },
    msrp: product?.msrp,
    weight: shouldUseProductWeight(product)
      ? productWeight
      : sortWeight * quantity,
    sortUnit,
    quantity,
  });
  const coverUrl = useMemo(() => {
    const cover = getProductCover(
      product.imageUrl,
      CART_PRODUCT_PREVIEW_COVER_SIZE.w,
      CART_PRODUCT_PREVIEW_COVER_SIZE.h,
      product.imageCdn,
    );
    return cover.cdnUrl || cover.url;
  }, [product.id]);

  return (
    <Box mb={2}>
      <Box display="flex" mb={1}>
        <Box width="100%" flexBasis="60px" textAlign="center">
          <StyledImage
            src={coverUrl || getPublicUrlForImage('images/placeholder_image.jpg')}
            alt={product?.name}
          />
        </Box>
        <Box mx={1} overflow="hidden">
          <Typography variant="body2" fontWeight={700} noWrap>
            {product?.name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {textTruncate(product?.description, descriptionLength)}
          </Typography>
        </Box>
      </Box>

      {isNil(product?.weight)
        ? (
          <Typography variant="body2" flexGrow={1} textAlign="right">
            {isFree(price) ? <FreeText>Free</FreeText> : formatPrice(price)}
            {' X '}
            {formatProductQuantity(quantity, product?.sortUnit)}
          </Typography>
        ) : (
          <Box display="flex" alignItems="center" gap={0.5}>
            <ProductWeight
              fontWeight={400}
              variant="body2"
              flexGrow={1}
              textAlign="right"
              weight={product?.weight}
              weightUnit={product?.weightUnit}
            />
            <Typography variant="body2">
              {`(${formatPrice(price)} / 1 ${WEIGHT_UNIT_LABELS[product?.weightUnit || WeightUnit.G]})`}
            </Typography>
          </Box>
        )}
    </Box>
  );
};

export default PreviewCartProduct;
