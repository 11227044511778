import { Box, Typography, Rating } from '@mui/material';
import { FC } from 'react';

import { StyledAvatar } from 'components/ReviewDetails/index.styled';

import { Comment } from 'types/comment.interface';
import { formatUserInitials } from 'utils/formatters';
import { getRatingAmount } from 'utils/ratingUtils';

interface ReviewDetailsProps {
 review: Comment;
}

const ReviewDetails:FC<ReviewDetailsProps> = ({ review }) => {
  const {
    creator, sentAt, text, reward,
  } = review;

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <StyledAvatar>{formatUserInitials(creator.name)}</StyledAvatar>
        <Box ml={2}>
          <Typography>{creator.name}</Typography>
          <Typography variant="body2" color="textSecondary">{sentAt}</Typography>
        </Box>
      </Box>

      <Box ml={7}>
        <Rating value={getRatingAmount(reward)} readOnly />
        <Typography my={2}>{text}</Typography>
      </Box>
    </Box>
  );
};

export default ReviewDetails;
