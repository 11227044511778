import MuiIconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  borderBottom: `1px solid ${theme.palette.border.main}`,
}));

export const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const CloseButton = styled(MuiIconButton)(() => ({
  backgroundColor: 'rgba(0, 0, 0, 0.06)',
}));

export const UploadButton = styled(MuiIconButton)(() => ({
  transform: 'rotate(320deg)',
}));
