import { BoxProps } from '@mui/material/Box/Box';
import { FC } from 'react';

import { DotBox } from 'components/Dot/index.styled';

const Dot:FC<BoxProps> = (props) => (
  <DotBox {...props} />
);

export default Dot;
