import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent } from '@mui/material';
import { FC, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Login from 'containers/Login';
import { CloseDialog } from 'containers/User/index.styled';
import LoginButton from 'containers/User/LoginButton';
import Username from 'containers/User/Username';
import { UserContext, UserDispatchContext } from 'context/UserContext';

import { getHomeRoute } from 'hooks/useRouting';

import { auth } from 'config/firebase';
import { DASHBOARD_ROUTE, HOME_ROUTE, PRODUCTS_ROUTE } from 'constants/clientRoutes';
import { saveCheckoutDetailsToStorage } from 'utils/storageUtils';

const User:FC = () => {
  const { user, showLoginFlow } = useContext(UserContext);
  const { setShowLoginFlow, setPaymentAccount } = useContext(UserDispatchContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleClose = () => setShowLoginFlow(false);
  const handleOpen = () => setShowLoginFlow(true);

  const logout = async () => {
    await auth.signOut();
    saveCheckoutDetailsToStorage();
    setPaymentAccount(null);

    if (![DASHBOARD_ROUTE, HOME_ROUTE, PRODUCTS_ROUTE].includes(pathname)) {
      navigate(getHomeRoute());
    }
  };

  return (
    <div>
      {user.isAnonymous
        ? <LoginButton onClick={handleOpen} />
        : <Username onLogout={logout} user={user} />}
      {showLoginFlow && (
        <Dialog
          open
          onClose={handleClose}
          aria-describedby="signin"
        >
          <CloseDialog
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </CloseDialog>
          <DialogContent>
            <Login
              onSuccess={handleClose}
              onFail={handleClose}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default User;
