import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  cursor: 'initial',
  backgroundColor: theme.palette.background.secondary,
  zIndex: 1,
  height: '100%',
  width: '100%',
  border: `1px solid ${theme.palette.border.main}`,
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));
