import { Money } from 'types/money.interface';

export function isZero(money: Money) {
  return !money.amount || money.amount === '0' || money.amount === '0.0' || money.amount === '0.00';
}

export function addMoney(existingMoney: Money | undefined, moneyToAdd: Money | undefined) {
  if (!existingMoney) {
    return moneyToAdd;
  }

  if (!moneyToAdd || !moneyToAdd.amount) {
    return existingMoney;
  }

  if (existingMoney.currency !== moneyToAdd.currency) {
    //  TODO: needs to be implemented
    return 'error';
    // amt = amt.convertTo(getCurrencyAsEnum());
    // if (amt == null) {
    //   return null;
    // }
  }

  if (!existingMoney.amount) {
    return moneyToAdd;
  }

  return {
    amount: +existingMoney.amount + +moneyToAdd.amount,
    currency: existingMoney.currency,
  };
}

export function getAmountAsBigDecimal(amount: string, enforceDecimal?: boolean) {
  if (!amount || amount === '0' || amount === '0.0') {
    return 0;
  }

  try {
    const floatAmount = Number.parseFloat(amount);

    if (enforceDecimal) {
      return floatAmount.toFixed(2);
    }

    return (
      Number.isInteger(floatAmount)
        ? floatAmount.toString()
        : floatAmount.toFixed(2)
    );
  } catch (ex) {
    return 0;
  }
}
