import { darken, styled } from '@mui/material/styles';

export const StyledSection = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '4px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'all .15s ease-in-out',
  backgroundColor: theme.palette.background.secondary,

  '&:hover': {
    transform: 'scale(1.015)',
    backgroundColor: darken(theme.palette.background.secondary, 0.05),
  },
}));
