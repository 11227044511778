import { styled } from '@mui/material/styles';

export const StyledBox = styled('div')(({ theme }) => ({
  '& .firebaseui-container': {
    minWidth: '350px',
    boxShadow: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    '& .firebaseui-container': {
      minWidth: '100%',
    },
  },
  '& .firebaseui-id-secondary-link': {
    color: theme.palette.primary.main,
    fontSize: '1rem',
  },
  '& .mdl-button--raised.mdl-button--colored, .mdl-button--raised.mdl-button--colored:hover, .firebaseui-label:after': {
    backgroundColor: theme.palette.primary.main,
    textTransform: 'none',
  },
  '& .firebaseui-textfield.mdl-textfield .firebaseui-label::after': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .mdl-button--primary.mdl-button--primary': {
    color: theme.palette.primary.main,
    textTransform: 'none',
  },
  '& .firebaseui-id-submit': {
    backgroundColor: theme.palette.primary.main,
    fontSize: '1rem',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main,
    },
  },
  '& .firebaseui-link, .firebaseui-tos-link': {
    color: theme.palette.primary.main,
  },
  '& .firebaseui-tos': {
    color: theme.palette.text.secondary,
  },
}));
