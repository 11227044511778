import { styled } from '@mui/material/styles';
import MuiSwipeableDrawer from '@mui/material/SwipeableDrawer';

export const SwipeableDrawer = styled(MuiSwipeableDrawer)(() => ({
  '& > .MuiPaper-root': {
    width: '280px',
  },
}));

export const DrawerTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2, 2, 1, 2),
  marginBottom: theme.spacing(2),
}));

export const FiltersLabel = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '4px',
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
  cursor: 'pointer',
  height: '60px',

  [theme.breakpoints.down(370)]: {
    padding: theme.spacing(1.5, 0.75),
  },
}));
