import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Divider, Box } from '@mui/material';
import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Content, Root } from 'components/_CheckoutFooter/index.styled';
import CartTotal from 'components/CartTotal';
import Coupon from 'components/Coupon';
import CustomButton from 'components/CustomButton';
import OrderDisclaimerMessage from 'components/OrderDisclaimerMessage';
import { SystemContext } from 'context/SystemContext';

import { getHomeRoute } from 'hooks/useRouting';

import { CartPriceDetails } from 'types/cart.interface';

interface CheckoutFooterProps {
  onSubmit: () => void;
  isSummaryLoading: boolean;
  hasSummaryFailed: boolean;
  isSubmitting?: boolean;
  disabled?: boolean;
  hasBack?: boolean;
  cartPriceDetails: CartPriceDetails;
  onChangeSummary: (coupon: string) => void;
  continueLabel: string;
}

const CheckoutFooter:FC<CheckoutFooterProps> = ({
  onSubmit,
  isSummaryLoading,
  isSubmitting,
  hasSummaryFailed,
  cartPriceDetails,
  disabled,
  hasBack,
  onChangeSummary,
  continueLabel,
}) => {
  const navigate = useNavigate();
  const { shopSettings } = useContext(SystemContext);

  const handleContinueShopping = () => {
    navigate(getHomeRoute());
  };

  return (
    <Root>
      <Content>
        <Coupon kioskMode onChangeSummary={onChangeSummary} />
        <Divider sx={{ borderBottomWidth: '2px', mx: 3 }} />
        <Box p={3}>
          <CartTotal
            cartPriceDetails={cartPriceDetails}
            isLoading={isSummaryLoading}
            hasFailed={hasSummaryFailed}
            kioskMode
          />

          <CustomButton
            id="button-goToCheckout"
            variant="contained"
            fullWidth
            sx={{ mt: 3 }}
            onClick={onSubmit}
            isLoading={isSummaryLoading || isSubmitting}
            disabled={disabled}
          >
            {continueLabel}
          </CustomButton>

          {hasBack && (
            <CustomButton
              id="button-continueShopping"
              variant="outlined"
              sx={{ mt: 3, position: 'absolute', left: -250 }}
              onClick={handleContinueShopping}
              icon={<ArrowBackIcon />}
              className="continueShopping"
            >
              Continue Shopping
            </CustomButton>
          )}

          {shopSettings?.orderDisclaimerMessage && (
            <OrderDisclaimerMessage message={shopSettings?.orderDisclaimerMessage} />
          )}
        </Box>
      </Content>
    </Root>
  );
};

export default CheckoutFooter;
