import { styled } from '@mui/material/styles';

import { getShopConfiguration } from 'utils/storeUtils';

const { asFullPage } = getShopConfiguration();

export const StyledSubHeader = styled(
  'div',
  { shouldForwardProp: (props) => props !== 'kioskMode' },
)<{ kioskMode?: boolean }>(({ theme, kioskMode }) => ({
  minHeight: kioskMode ? '50px' : '40px',
  top: asFullPage ? '64px' : 0,
  marginBottom: asFullPage ? 0 : theme.spacing(3),
  backgroundColor: kioskMode ? theme.palette.background.main : theme.palette.subheaderBackground.main,
  position: asFullPage ? 'fixed' : 'relative',
  zIndex: 998,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  borderBottom: kioskMode ? 'none' : `1px solid ${theme.palette.general.lightGrey}`,
}));

export const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    display: 'block',
    padding: theme.spacing(1, 0),
  },
}));

export const HeaderContent = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),

  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(0.5),
  },
}));
