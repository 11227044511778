import { Grid2 as Grid, SelectChangeEvent } from '@mui/material';
import { get } from 'lodash';
import { ChangeEvent, FC } from 'react';

import CustomInput from 'components/CustomInput';
import PlacesAutocomplete from 'components/PlacesAutocomplete';

import { ORDER_ADDRESS } from 'constants/address';
import {
  ADDRESS_LINE1, ADDRESS_STATE, CITY, ZIP_CODE,
} from 'constants/fields';
import { GoogleFormattedAddress } from 'types/address.interface';
import { isAddressAutocomplete } from 'utils/checkoutUtils';

interface DeliveryAddressFormProps {
  onChange: (e: ChangeEvent<HTMLInputElement> | SelectChangeEvent) => void;
  onValueChange: (value: any, name: string) => void;
  address: GoogleFormattedAddress;
  fieldErrors: Record<string, string>;
  parentName?: string;
}

const DeliveryAddressForm :FC<DeliveryAddressFormProps> = ({
  onChange,
  onValueChange,
  address,
  fieldErrors,
}) => (
  <Grid container spacing={4}>
    <Grid size={12}>
      {isAddressAutocomplete() ? (
        <PlacesAutocomplete
          {...ADDRESS_LINE1}
          onValueChange={onValueChange}
          defaultValue={address?.line1 || ''}
          error={!!get(fieldErrors, ADDRESS_LINE1.name)}
          helperText={get(fieldErrors, ADDRESS_LINE1.name)}
          fieldsConfig={ORDER_ADDRESS}
        />
      ) : (
        <CustomInput
          {...ADDRESS_LINE1}
          label="Address"
          size="small"
          onChange={(e) => onChange(e as ChangeEvent<HTMLInputElement>)}
          value={address?.line1 || ''}
          error={!!get(fieldErrors, ADDRESS_LINE1.name)}
          helperText={get(fieldErrors, ADDRESS_LINE1.name)}
        />
      )}
    </Grid>
    <Grid size={{ xs: 12, sm: 6 }}>
      <CustomInput
        {...CITY}
        label="City"
        size="small"
        onChange={(e) => onChange(e as ChangeEvent<HTMLInputElement>)}
        value={address?.city || ''}
        error={!!get(fieldErrors, CITY.name)}
        helperText={get(fieldErrors, CITY.name)}
      />
    </Grid>
    <Grid size={{ xs: 12, sm: 3 }}>
      <CustomInput
        {...ZIP_CODE}
        label="Zipcode"
        size="small"
        onChange={(e) => onChange(e as ChangeEvent<HTMLInputElement>)}
        value={address?.postalCode || ''}
        error={!!get(fieldErrors, ZIP_CODE.name)}
        helperText={get(fieldErrors, ZIP_CODE.name)}
      />
    </Grid>
    <Grid size={{ xs: 12, sm: 3 }}>
      <CustomInput
        {...ADDRESS_STATE}
        disabled
        size="small"
        onChange={(e) => onChange(e as ChangeEvent<HTMLInputElement>)}
        value={address?.state || ''}
        error={!!get(fieldErrors, ADDRESS_STATE.name)}
        helperText={get(fieldErrors, ADDRESS_STATE.name)}
      />
    </Grid>
  </Grid>
);

export default DeliveryAddressForm;
