import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FC } from 'react';

import { StyledButton } from 'components/CarouselNextArrow/index.styled';

interface CarouselNextArrowProps {
  className?: string;
  onClick?: () => unknown;
  sx?: object;
}

const CarouselNextArrow:FC<CarouselNextArrowProps> = ({ className, onClick, sx }) => (
  <div>
    <StyledButton
      className={className}
      onClick={onClick}
      sx={sx}
    >
      <ArrowForwardIosIcon />
    </StyledButton>
  </div>
);

export default CarouselNextArrow;
