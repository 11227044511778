import { StartButtonPosition } from 'constants/enums';

export const startButtonProps: { [key in StartButtonPosition]?: { justifyContent: string; alignItems: string } } = {
  [StartButtonPosition.Center]: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  [StartButtonPosition.Up]: {
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  [StartButtonPosition.UpRight]: {
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  [StartButtonPosition.Down]: {
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  [StartButtonPosition.DownRight]: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
};

export const getStartButtonProps = (startBtnPosition?: Partial<StartButtonPosition>) => {
  if (!startBtnPosition || !startButtonProps[startBtnPosition]) {
    return startButtonProps[StartButtonPosition.Center];
  }

  return startButtonProps[startBtnPosition];
};
