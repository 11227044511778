import { Typography, Box, Grid } from '@mui/material';
import { FC, useContext } from 'react';

import EmptyReviews from 'components/EmptyReviews';
import ReviewDetails from 'components/ReviewDetails';
import ReviewOverview from 'components/ReviewOverview';
import AddProductReview from 'containers/AddProductReview';
import { StyledReviewSection } from 'containers/ProductReviews/index.styled';
import { UserContext } from 'context/UserContext';

import { MOCK_REVIEW_STATS } from 'constants/mockData';
import { Bounty } from 'types/bounty.interface';
import { Comment } from 'types/comment.interface';

interface ProductReviewsProps {
  reviews: Comment[];
  bounty: Bounty;
}

const ProductReviews:FC<ProductReviewsProps> = ({ reviews, bounty }) => {
  const { user } = useContext(UserContext);

  return (
    <Box mt={5}>
      {reviews.length > 0
        ? (
          <Grid container spacing={10}>
            <Grid item xs={12} md={4}>
              <StyledReviewSection>
                <Typography variant="h6" fontWeight="500" mb={2}>
                  Customer Reviews
                </Typography>
                <ReviewOverview reviewStats={MOCK_REVIEW_STATS} />
                {!user?.isAnonymous && <AddProductReview fullWidth bounty={bounty} />}
              </StyledReviewSection>
            </Grid>
            <Grid item xs={12} md={8}>
              {Object.values(reviews).map((review) => (
                <Box key={review.id} mb={2}>
                  <ReviewDetails review={review} />
                </Box>
              ))}
            </Grid>
          </Grid>
        )
        : <EmptyReviews bounty={bounty} canAddReview={!user?.isAnonymous} />}
    </Box>
  );
};

export default ProductReviews;
