import { Filter } from 'types/filter.interface';

export const getActiveFilterNames = (activeFilters: {[key: string]: string[]}, filters: Filter[]) => {
  const filterOptions = [] as string[];

  Object.keys(activeFilters)?.forEach((filterId) => {
    const activeFilter = filters?.find((filter) => filter?.id === filterId);
    const activeFilterOptions = activeFilter?.options || [];

    activeFilters[filterId]?.forEach((optionCode) => {
      const filterOption = activeFilterOptions.find((option) => option?.code === optionCode);

      if (filterOption) {
        filterOptions.push(filterOption?.name);
      }
    });
  });

  return filterOptions.join(', ');
};
