const config = {
  env: 'prod',
  firebaseConfig: {
    apiKey: 'AIzaSyDSu7JyHCE9SFhI_kJzVbZqhaIVB246Gx0',
    authDomain: 'alleaves-prod.firebaseapp.com',
    databaseURL: 'https://alleaves-prod-default-rtdb.firebaseio.com',
    projectId: 'alleaves-prod',
    storageBucket: 'alleaves-prod.appspot.com',
    messagingSenderId: '548556636801',
    appId: '1:548556636801:web:6d1ab413e3d578b643c380',
    measurementId: 'G-SGHJNP1D4E',
  },
  siteId: 'alleaves-shop',
  stripeKey: '',
  API_BASE_URL: 'https://api.alleaves.shop',
  UI_BASE_URL: 'https://shop.alleaves.shop',
  SENTRY_CONFIG: {
    dsn: 'https://decf7b354e534d03a77b8bd8b5cd2b99@o116454.ingest.sentry.io/4504725751595008',
    tracesSampleRate: 1.0,
  },
};

export default config;
