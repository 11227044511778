import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(
  Box,
  { shouldForwardProp: (props) => !['isActive', 'isDisabled'].includes(props as string) },
)<{ isActive: boolean; isDisabled: boolean }>(({ theme, isActive, isDisabled }) => ({
  borderRadius: '8px',
  cursor: isDisabled ? 'not-allowed' : 'pointer',
  opacity: isDisabled ? '0.5' : 1,
  padding: theme.spacing(1.5),
  border: `1px solid ${isActive ? theme.palette.primary.main : theme.palette.border.main}`,
  color: theme.palette.getContrastText(
    isActive ? theme.palette.primary.light : theme.palette.common.white,
  ),
  backgroundColor: isActive ? theme.palette.primary.light : theme.palette.common.white,
  textAlign: 'center',

  '&:hover': !isDisabled ? {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.getContrastText(theme.palette.primary.light),
    backgroundColor: theme.palette.primary.main,
  } : {},
}));
