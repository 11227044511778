import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { useContext } from 'react';
import { RouterProvider } from 'react-router-dom';

import { SystemContext } from 'context/SystemContext';
import getRouter from 'routes/main';

import { getShadowDomRoot } from 'utils/utils';

const node = getShadowDomRoot();

const cache = createCache({
  key: 'shop-app',
  prepend: true,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  container: node,
});

const AppRouter = () => {
  const {
    theme,
    clientError,
    isClosed,
    detailedClientData,
  } = useContext(SystemContext);

  const router = getRouter(clientError, isClosed, detailedClientData);

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </CacheProvider>
  );
};

export default AppRouter;
