import { Box, Typography } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { FC } from 'react';

import { getPublicUrlForImage } from 'utils/publicUrl';

interface EmptyStateProps {
  message: string;
}

const EmptyState:FC<EmptyStateProps> = ({ message }) => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <CardMedia
      sx={{
        objectFit: 'contain',
        width: '100%',
        height: '200px',
        mb: 2,
      }}
      component="img"
      src={getPublicUrlForImage('images/no_products.png')}
      alt="Not found"
    />
    <Typography fontWeight={600} color="textSecondary">
      {message}
    </Typography>
  </Box>
);

export default EmptyState;
