const { merge } = require("lodash"); // eslint-disable-line

const flavour = process.env.FLAVOUR || process.env.REACT_APP_FLAVOUR;
const env = process.env.TARGET_ENV || process.env.REACT_APP_TARGET_ENV;

if (!flavour) {
  // eslint-disable-next-line no-console
  console.error('Missing FLAVOUR!');
  process.exit(1);
}

if (!env) {
  // eslint-disable-next-line no-console
  console.error('Missing TARGET_ENV!');
  process.exit(1);
}

// eslint-disable-next-line
console.log(`loading ${flavour}/${env} in ${process.env.NODE_ENV} mode`);

/* eslint @typescript-eslint/no-var-requires: "off" */
const {
  flavourConfig,
} = require(`../../flavours/${flavour}/config`); // eslint-disable-line
const envConfig = require(`../../flavours/${flavour}/config/config-${env}`); // eslint-disable-line

if (envConfig.default.env !== env) {
  // eslint-disable-next-line no-console
  console.error(`Missing or incorrect 'env' in ${flavour}/${env}!`);
  process.exit(1);
}

module.exports = merge(flavourConfig, envConfig);
