import { Icon, Typography } from '@mui/material';
import { FC } from 'react';

import { Root } from 'components/OrderWarningMessage/index.styled';

import { WarningIcon } from 'assets/icons/general';

const DEFAULT_MESSAGE = 'Cancer and Reproductive Harm - www.P65Warnings.ca.gov.';

interface OrderWarningMessageProps {
  message?: string;
}

const OrderWarningMessage:FC<OrderWarningMessageProps> = ({ message = DEFAULT_MESSAGE }) => (
  <Root>
    <Icon sx={{ height: '25px' }}>
      <WarningIcon />
    </Icon>

    <Typography variant="body2" ml={4} mt={0.5} color="general.lightGrey4">
      <b>WARNING: </b>
      {message}
    </Typography>
  </Root>
);

export default OrderWarningMessage;
