import { Typography } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { FC } from 'react';
// import ReactPlayer from 'react-player/lazy';

import { AttachmentType } from 'constants/enums';
import { Attachment as AttachmentInterface } from 'types/attachment.interface';
import { getPublicUrlForImage } from 'utils/publicUrl';

interface AttachmentProps {
  attachment: AttachmentInterface;
  height?: string;
  width?: string;
}

//  TODO: enable audio and hideo handler after fixing the issue with baseUrl for embedded
const Attachment:FC<AttachmentProps> = ({ attachment, height, width }) => {
  const { type, url, ...remainingProps } = attachment;

  switch (type) {
    case AttachmentType.OTHER:
    case AttachmentType.FILE:
      return (
        <CardMedia
          sx={{
            borderRadius: '4px',
            width: width || '100%',
            height: height || '100%',
          }}
          component="img"
          src={getPublicUrlForImage('images/doc-file.svg')}
          alt={remainingProps?.filename}
        />
      );
    case AttachmentType.PDF:
      return (
        <embed
          src={`${url}#pagemode=zoom&scrollbar=0&toolbar=0&statusbar=0&messages=0&navpanes=0`}
          width="100%"
          height="130%"
          className="embed-pdf"
        />
      );
    case AttachmentType.IMAGE:
      return (
        <CardMedia
          sx={{
            borderRadius: '4px',
            width: width || '100%',
            height: height || '100%',
          }}
          component="img"
          src={url}
          alt={remainingProps?.filename}
        />
      );
    // case AttachmentType.VIDEO:
    // case AttachmentType.AUDIO:
      // return (
      //   <ReactPlayer
      //     url={url}
      //     width={width || '100%'}
      //     height={height || '100%'}
      //     controls
      //   />
      // );
    default:
      return (
        <Typography variant="body2">
          {attachment?.filename}
        </Typography>
      );
  }
};

export default Attachment;
