import { Box } from '@mui/material';
import CardMediaMui from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

export const CardMedia = styled(CardMediaMui)<{ kioskMode?: boolean }>(({ kioskMode }) => ({
  objectFit: 'contain',
  width: '100%',
  height: kioskMode ? '500px' : '210px',
})) as typeof CardMediaMui | FC<{ kioskMode?: boolean }>;

export const ContentCard = styled(Box)(({ theme }) => ({
  border: `4px solid ${theme.palette.primary.main}`,
  borderRadius: '40px',
  padding: theme.spacing(5),
  display: 'flex',
  textAlign: 'center',
  flexDirection: 'column',
  gap: theme.spacing(3),
  backgroundColor: theme.palette.background.main,
  maxWidth: '600px',
}));
