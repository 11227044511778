import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { getShopConfiguration } from 'utils/storeUtils';

const { hideHeader } = getShopConfiguration();

export const UserInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '& .MuiTypography-root': {
    marginRight: theme.spacing(1),
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiTypography-root': {
      display: 'none',
    },
  },
}));

export const NameTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.getContrastText(
    hideHeader
      ? theme.palette.subheaderBackground.main
      : theme.palette.headerBackground.main,
  ),
}));
