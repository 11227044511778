import MuiSelect from '@mui/material/Select';
import { styled } from '@mui/material/styles';

export const StyledWrapper = styled('div')<{ hasError?: boolean }>(({ theme, hasError }) => (hasError ? {
  '& .MuiFormHelperText-root': {
    color: theme.palette.error.main,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.error.main,
  },
} : {}));

export const Select = styled(MuiSelect)(() => ({
  '& .MuiSelect-select': {
    display: 'block',
  },
}));
