import { Checkbox, FormGroup } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  FC, SyntheticEvent, useEffect, useMemo, useState,
} from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
} from 'components/Filters/FilterGroup/index.styled';
import ShowMore from 'components/ShowMore';

import { useKiosk } from 'hooks/useKiosk';

import { FilterType } from 'constants/enums';
import { Filter, FilterOption } from 'types/filter.interface';

interface FilterGroupProps {
  activeFilters: string[];
  filter: Filter;
  onSelectFilter?: (filterId: string, filterType: FilterType) => (e: SyntheticEvent, checked: boolean) => void;
  isLoading: boolean;
}

export const MAX_SHOWN_FILTER_OPTIONS = 5;

const FilterGroup:FC<FilterGroupProps> = ({
  filter,
  onSelectFilter,
  activeFilters = [],
  isLoading,
}) => {
  const {
    id, name, options = [], type,
  } = filter;
  const { kioskMode } = useKiosk();
  const [showMore, toggleShow] = useState(true);
  const [optionsToShow, setOptionsToShow] = useState<FilterOption[]>([]);
  const hasMoreItems = useMemo(() => options?.length > 5, [options?.length]);

  useEffect(() => {
    if (options?.length) {
      setOptionsToShow(options?.length > MAX_SHOWN_FILTER_OPTIONS ? options.slice(0, 4) : options);
    }
  }, [options]);

  useEffect(() => {
    if (activeFilters.length > 0) {
      let lastActiveOptionIndex = -1;

      options?.forEach((option, index) => {
        if (activeFilters?.includes(option.code)) {
          lastActiveOptionIndex = index;
        }
      });

      if (hasMoreItems && lastActiveOptionIndex + 1 > MAX_SHOWN_FILTER_OPTIONS) {
        setOptionsToShow(options);
        toggleShow(false);
      }
    }
  }, [options, activeFilters]);

  const toggleShowMore = () => {
    if (showMore) {
      setOptionsToShow(options);
    } else {
      setOptionsToShow(options.slice(0, 4));
    }

    toggleShow((prevState) => !prevState);
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography fontWeight={600} color="textSecondary">{name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormGroup>
          {optionsToShow?.map((filter) => (
            <FormControlLabel
              kioskMode={kioskMode}
              key={filter.code}
              label={filter.name}
              value={filter.code}
              disabled={!onSelectFilter || isLoading}
              onChange={onSelectFilter && onSelectFilter(id, type)}
              checked={!!activeFilters?.includes(filter.code)}
              control={<Checkbox />}
            />
          ))}
        </FormGroup>
      </AccordionDetails>
      {hasMoreItems && (
        <ShowMore
          showMore={showMore}
          toggleShowMore={toggleShowMore}
        />
      )}
    </Accordion>
  );
};

export default FilterGroup;
