import {
  Container, Box, Grid, useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useContext, FC, useMemo } from 'react';
import LazyLoad from 'react-lazyload';

import EmptyState from 'components/EmptyState';
import SubheaderWrapper from 'components/SubheaderWrapper';
import Categories from 'containers/Categories';
import CategoryCarousel from 'containers/CategoryCarousel';
import CategoryCarouselSkeleton from 'containers/CategoryCarousel/index.skeleton';
import DashboardSkeleton from 'containers/Dashboard/index.skeleton';
import ProductFilters from 'containers/ProductFilters';
import PromotionBanner from 'containers/PromotionBanner';
import SearchProducts from 'containers/SearchProducts';
import { CategoryContext } from 'context/CategoryContext';

import { useDocumentTitle } from 'hooks/useDocumentTitle';
import useFilters from 'hooks/useFilters';
import { useKiosk } from 'hooks/useKiosk';

import { Promotion } from 'types/promotion.interface';

interface DashboardProps {
  promotions: Promotion[];
}

const Dashboard: FC<DashboardProps> = ({ promotions }) => {
  const theme = useTheme();
  const matchesScreenSizeMd = useMediaQuery(theme.breakpoints.down('md'));

  const { kioskMode } = useKiosk();
  const { categories, isLoading } = useContext(CategoryContext);
  const {
    isLoading: areFiltersLoading, activeFilters, filters, ...remainingFilterProps
  } = useFilters({ shouldRedirect: true });
  const hasFilters = useMemo(() => areFiltersLoading || filters?.length > 0, [areFiltersLoading, filters]);

  useDocumentTitle({ title: 'Dashboard' });

  if (isLoading) {
    return <DashboardSkeleton />;
  }

  return (
    <Box>
      <SubheaderWrapper>
        <Box display="flex" alignItems="center" gap={2}>
          {categories?.length > 0 && <Categories />}
          {!kioskMode && <SearchProducts />}
        </Box>
      </SubheaderWrapper>
      <Container>
        {promotions?.length > 0 && (
          <PromotionBanner promotions={promotions} />
        )}

        <Grid container spacing={matchesScreenSizeMd ? 2 : 7}>
          {(hasFilters || areFiltersLoading) && (
            <Grid item xs={12} md={3} mt={1} pr={matchesScreenSizeMd ? 1.25 : 0}>
              <ProductFilters
                {...remainingFilterProps}
                isLoading={areFiltersLoading}
                filters={filters}
                activeFilters={activeFilters}
              />
            </Grid>
          )}

          <Grid item xs={12} md={hasFilters ? 9 : 12}>
            {!categories?.length ? (
              <EmptyState message="No products found!" />
            ) : (
              categories.map((category) => (
                <LazyLoad
                  key={category.code}
                  once
                  offset={50}
                  placeholder={<CategoryCarouselSkeleton />}
                >
                  <CategoryCarousel category={category} />
                </LazyLoad>
              ))
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Dashboard;
