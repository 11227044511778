import { Box, Container, Grid2 as Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import { useSearchParams } from 'react-router-dom';

import BackButton from 'components/BackButton';
import CartTotal from 'components/CartTotal';
// import Coupon from 'components/Coupon';
import CustomButton from 'components/CustomButton';
import OrderDisclaimerMessage from 'components/OrderDisclaimerMessage';
import OrderWarningMessage from 'components/OrderWarningMessage';
import ProductMedicalBalanceInfo from 'components/ProductMedicalBalanceInfo';
import SectionTitle from 'components/SectionTitle';
import SubheaderWrapper from 'components/SubheaderWrapper';
import { StyledSummary, StyledSummaryPrice } from 'containers/CartListingPage/Summary/index.styled';
import CartProduct from 'containers/CartProduct';
import MedicalBalance from 'containers/MedicalBalance';
import PreviewLayout from 'layouts/PreviewLayout';

import { PREVIEW_CART } from 'constants/clientRoutes';
import { MOCK_CART_DETAILS, MOCK_PRODUCTS } from 'constants/mockData';
import { ProductInfo } from 'types/cart.interface';

const PreviewCartPage = () => {
  const [searchParams] = useSearchParams();
  const adjustedProducts:string[] = ['id 3'];
  const updatedProducts:{ [key: string]: ProductInfo } = { 'id 3': MOCK_PRODUCTS[2] };
  const orderDisclaimerMessage = searchParams.get('orderDisclaimerMessage');
  const orderWarningMessage = searchParams.get('orderWarningMessage');

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleEmptyFunction = () => {};

  return (
    <PreviewLayout hideFooter hideNavbarActions>
      <div>
        <SubheaderWrapper>
          <Box display="flex" alignItems="center">
            <BackButton href={PREVIEW_CART} label="Back" />
            <SectionTitle variant="subheader" title="Your cart" sx={{ ml: 2.5 }} />
          </Box>
        </SubheaderWrapper>

        <Container>
          <Grid container spacing={5} alignItems="flex-start">
            <Grid size={{ xs: 12, md: 7.5, lg: 8 }}>
              {MOCK_PRODUCTS?.map((product, index) => {
                const wasAdjusted = adjustedProducts?.includes(product.id);
                const { balances = {} } = product;
                const isProductUpdated = !isEmpty(updatedProducts?.[product.id] || {});
                const updatedQuantity = 0;

                return (
                  <Box mb={index + 1 !== MOCK_PRODUCTS?.length ? 1.5 : 0} key={product?.id}>
                    <CartProduct
                      product={product}
                      isProductUpdated={isProductUpdated}
                      updatedQuantity={updatedQuantity}
                      handleChange={handleEmptyFunction}
                      wasAdjusted={wasAdjusted}
                      isDisabled
                    />

                    {balances && Object.values(balances).map((balance) => (
                      <ProductMedicalBalanceInfo balance={balance} key={balance?.key} />
                    ))}
                  </Box>
                );
              })}

              {orderWarningMessage && (
                <Box mt={2.5}>
                  <OrderWarningMessage message={orderWarningMessage} />
                </Box>
              )}
            </Grid>
            <Grid size={{ xs: 12, md: 4.5, lg: 4 }}>
              <StyledSummary>
                <StyledSummaryPrice>
                  <CartTotal cartPriceDetails={MOCK_CART_DETAILS} />
                  <CustomButton
                    id="button-goToCheckout"
                    variant="contained"
                    fullWidth
                    sx={{ mt: 3 }}
                    onClick={handleEmptyFunction}
                  >
                    Go to checkout
                  </CustomButton>

                  {orderDisclaimerMessage && (
                    <OrderDisclaimerMessage message={orderDisclaimerMessage} />
                  )}
                </StyledSummaryPrice>

                {/* <Box mt={5}> */}
                {/*  <Coupon onChangeSummary={handleEmptyFunction} /> */}
                {/* </Box> */}

                {MOCK_CART_DETAILS?.balances && (
                  <Box mt={5}>
                    <MedicalBalance balances={MOCK_CART_DETAILS.balances} checkSummary={() => {}} />
                  </Box>
                )}
              </StyledSummary>
            </Grid>
          </Grid>
        </Container>
      </div>
    </PreviewLayout>
  );
};

export default PreviewCartPage;
