import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Box, Badge, Typography } from '@mui/material';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import EmptyPreviewCart from 'components/EmptyPreviewCart';
import PreviewCartContent from 'components/PreviewCartContent';
import {
  StyledButton,
  StyledCollapse,
  StyledShoppingCart,
} from 'components/ShoppingCart/index.styled';
import { CartContext } from 'context/CartContext';

import { useKiosk } from 'hooks/useKiosk';
import { getCartRoute } from 'hooks/useRouting';
import useSummary from 'hooks/useSummary';

import { calculateTotalQuantity } from 'utils/cartUtils';

const ShoppingCart = () => {
  const navigate = useNavigate();
  const cart = useContext(CartContext);
  const { kioskMode } = useKiosk();
  const {
    checkSummary,
    formattedCartDetails,
  } = useSummary();

  const [open, setOpen] = useState(false);
  const totalProductsQuantity = useMemo(() => calculateTotalQuantity(cart?.products), [cart?.products]);
  const products = useMemo(() => Object.values(cart?.products) || [], [cart?.products]);

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        await checkSummary();
      } catch (e) {
        console.log('e', e); // eslint-disable-line
      }
    };

    if (kioskMode) {
      fetchSummary();
    }
  }, [products]);

  const toggle = () => {
    setOpen(!open);
  };

  const handleRedirectCart = () => {
    navigate(getCartRoute());
    setOpen(false);
  };

  return (
    <Box
      onMouseEnter={() => setOpen(!kioskMode)}
      onMouseLeave={() => setOpen(false)}
      position="relative"
      zIndex={1}
    >
      <StyledButton
        disableRipple
        aria-label="cart"
      >
        <StyledShoppingCart kioskMode={kioskMode} onClick={handleRedirectCart}>
          <div>
            <Badge badgeContent={totalProductsQuantity} color="primary">
              <ShoppingCartOutlinedIcon />
            </Badge>
          </div>
          {!!formattedCartDetails.total && (
            <Typography variant="body1" fontWeight={500}>
              {formattedCartDetails.formattedTotal}
            </Typography>
          )}
        </StyledShoppingCart>
      </StyledButton>
      {open && (
        <Box position="absolute" top="40" right={0} pt={1} zIndex={1}>
          <StyledCollapse>
            <div>
              {totalProductsQuantity > 0
                ? <PreviewCartContent products={products} closeCart={toggle} />
                : <EmptyPreviewCart hasButton={false} fontSize="medium" variant="body2" />}
            </div>
          </StyledCollapse>
        </Box>
      )}
    </Box>
  );
};

export default ShoppingCart;
