import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import CustomToastContainer from 'components/_CustomToastContainer';
import { SystemProvider } from 'context/SystemContext';
import { UserProvider } from 'context/UserContext';
import AppRouter from 'routes/AppRouter';

import 'config/firebase';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'assets/scss/modules/category-carousel.scss';

const App = () => (
  <UserProvider>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SystemProvider>
        <AppRouter />
        <CustomToastContainer />
      </SystemProvider>
    </LocalizationProvider>
  </UserProvider>
);
export default App;
