import {
  createContext, FC, ReactNode, useContext, useEffect, useState,
} from 'react';

import { LocationContext } from 'context/LocationContext';

import { getCategories } from 'services/Product';

import { Category } from 'types/category.interface';
import { getOnboardingInfo } from 'utils/storageUtils';

const CategoryContext = createContext({
  isLoading: false,
  categories: [] as Category[],
});

const CategoryDispatchContext = createContext({
  removeCategory: (categoryCode: string) => {},// eslint-disable-line
});

interface CategoryProviderProps {
  children: ReactNode;
}

const CategoryProvider:FC<CategoryProviderProps> = ({ children }) => {
  const [rawCategories, setRawCategories] = useState<Category[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { onboardingInfo } = useContext(LocationContext);
  const { storeId, useType } = onboardingInfo;

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (rawCategories?.length) {
      setCategories(rawCategories);
    }
  }, [storeId, useType]);

  const fetchCategories = async () => {
    try {
      setIsLoading(true);
      const { useType } = getOnboardingInfo() || {};
      const params = new URLSearchParams();

      if (useType) {
        params.append('tag', useType);
        params.append('tag', 'is_non_cannabis');
      }

      const { data } = await getCategories(params);
      const categories = data?.children || [];

      setCategories(categories);
      setRawCategories(categories);
    } catch (e) {
      setCategories([]);
    } finally {
      setIsLoading(false);
    }
  };

  const removeCategory = (categoryCode: string) => {
    setCategories((prevState) => {
      const availableCategories = prevState.filter(({ code }) => code !== categoryCode);
      return [...availableCategories];
    });
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CategoryContext.Provider value={{ categories, isLoading }}>
      {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
      <CategoryDispatchContext.Provider value={{ removeCategory }}>
        {children}
      </CategoryDispatchContext.Provider>
    </CategoryContext.Provider>
  );
};

export {
  CategoryProvider,
  CategoryContext,
  CategoryDispatchContext,
};
