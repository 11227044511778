import { Box } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';

export const Root = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(5),

  [theme.breakpoints.down('md')]: {
    marginTop: 0,
    marginBottom: theme.spacing(4),
  },
}));

export const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  borderRadius: '8px',
  height: '350px',
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    height: '200px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '150px',
  },
  objectFit: 'contain',
})) as typeof CardMedia;
