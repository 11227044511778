import { format } from 'date-fns';

import { ORDER_DATE_FORMAT } from 'constants/general';
import { AlleavesUser, AlleavesUserForm } from 'types/alleavesCustomer.interface';

export const formatUserInfoValues = (values: AlleavesUserForm): AlleavesUser => {
  const { customer, driverLicense } = values || {};

  return {
    customer: {
      ...customer,
      dob: format(new Date(customer?.dob || ''), ORDER_DATE_FORMAT),
    },
    driverLicense: {
      ...driverLicense,
      expDate: driverLicense?.expDate ? format(new Date(driverLicense.expDate), ORDER_DATE_FORMAT) : null,
    },
  };
};
