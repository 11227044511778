import { styled } from '@mui/material/styles';

import CustomButton from 'components/CustomButton';

export const PayButton = styled(CustomButton)(() => ({
  width: '500px',
  height: '100px',
  fontSize: '3rem',
  borderWidth: '2px',
  borderRadius: '10px',

  '&:disabled': {
    opacity: 0.8,
    height: '100px',
  },
})) as typeof CustomButton;
