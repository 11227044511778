export const formatProductItem = (link: string) => ({
  label: 'Products',
  name: 'products',
  link,
});

export const formatProductNameItem = (productName: string) => ({
  label: productName,
  name: productName,
});

export const formatCategoryItem = (categoryName: string) => ({
  label: categoryName,
  name: 'category',
});

export const formatFiltersItem = (filterNames: string) => ({
  label: filterNames,
  name: 'filters',
});

export const formatNavigableCategoryItem = (categoryName: string, link: string) => ({
  label: categoryName,
  name: 'category',
  link,
});
