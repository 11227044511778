import * as Yup from 'yup';

import { hexColorRegex, urlRegex } from 'validation/regex';

export const getValidationErrorsHash = (yupErr: Yup.ValidationError) => {
  let errorsHash: Record<string, string> = {};

  yupErr?.inner?.forEach((err: Yup.ValidationError) => {
    const path = err.path || '';

    if (!errorsHash[path]) {
      errorsHash = {
        ...errorsHash,
        [path]: err.message,
      };
    }
  });
  return errorsHash;
};

export const checkHexColor = (hexColor: string, defaultColor: string) => {
  if (hexColorRegex.test(hexColor)) {
    return hexColor;
  }

  return defaultColor;
};

export const checkUrl = (url: string, defaultUrl: string) => {
  if (urlRegex.test(url)) {
    return url;
  }

  return defaultUrl;
};
