import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledViewAll = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
  '&:hover': {
    textDecoration: 'underline',
  },
}));
