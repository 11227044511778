import { ref, push } from 'firebase/database';

import { runGetApiRequest, runPostApiRequest } from 'services/Api';

import { db } from 'config/firebase';
import {
  CHAT_CMD_ROUTE, MARK_MSG_AS_READ_ROUTE, PSG_CHAT_ROUTE, WRITE_MESSAGE_ROUTE,
} from 'constants/apiRoutes';
import { Message } from 'types/chat.interface';
import { formatRoute } from 'utils/formatters';

export const writeMessage = async (roomId: string, msg: Message) => (
  runPostApiRequest(formatRoute(WRITE_MESSAGE_ROUTE, { roomId }), msg)
);

export const writeChatCmd = async (roomId: string, cmd: string) => (
  runPostApiRequest(formatRoute(CHAT_CMD_ROUTE, { roomId }), { cmd })
);

export const createPsgChat = async (params: { orderId?: string; existingOnly: boolean }) => (
  runGetApiRequest(PSG_CHAT_ROUTE, params)
);

export const getChatMessagesRef = (roomId: string) => (
  ref(db, `chatMessages/${roomId}`)
);

export const getUserChatDetailsRef = (userId: string, roomId: string) => (
  ref(db, `userChats/${userId}/${roomId}`)
);

export const getNewChatMessageRef = (roomId: string) => {
  const messageRef = ref(db, `chatMessages/${roomId}`);
  return push(messageRef);
};

export const markUserChatAsRead = async (roomId: string, readAt: number) => (
  runPostApiRequest(formatRoute(MARK_MSG_AS_READ_ROUTE, { roomId }), { readAt })
);
