import MuiIconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: '78px',
  width: '290px',
  padding: theme.spacing(2),
  borderRadius: '8px',
  backgroundColor: 'rgb(244 246 250 / 80%)',
  display: 'flex',
  gap: theme.spacing(1),
  overflow: 'auto',
}));

export const ImageBox = styled('div')(({ theme }) => ({
  padding: theme.spacing(0.25),
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '8px',
  position: 'relative',
  '&:hover': {
    '& > .MuiIconButton-root': {
      display: 'flex',
    },
  },
}));

export const RemoveAttachment = styled(MuiIconButton)(({ theme }) => ({
  position: 'absolute',
  top: '-12px',
  right: '-8px',
  color: theme.palette.common.black,
  display: 'none',
}));
