import { Reward } from 'types/money.interface';
import { Rating, RatingStats } from 'types/rating.interface';

export const getPercentage = (totalReviews = 0, reviews = 0): number => {
  if (!reviews) {
    return 0;
  }

  const percentage = (reviews * 100) / totalReviews;
  return Math.round(percentage);
};

export const getRatingScore = (rating: Rating): RatingStats => {
  const { ratingCount, points } = rating;

  if (!ratingCount || !points) {
    return {
      averageRating: 0,
      ratingCount: 0,
    };
  }

  const amount = points?.RATING?.amount || 0;

  return {
    averageRating: amount / ratingCount,
    ratingCount,
  };
};

export const getRatingAmount = (reward: Reward): number => reward?.points?.RATING?.amount || 0;
