/* eslint max-len: 0 */

import { ReactElement } from 'react';

const SupportChat = ({ size = 28 }: { size?: number }): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 30 30"
    width={size}
    height={size}
  >
    <path d="M15 26v2c2.342 0 4.202-.853 5.623-2H15z" />
    <path
      d="M5 16v2c0 2.5 2 3 2 3s2 6 8 6"
      style={{
        fill: "none",
        stroke: "#000",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path d="M26 17h-3c0-6.24-3-9-3-9-3 7-13 2-13 9H4v-4C4 6 10 2 15 2c4 0 5 2 5 2 4 0 6 5 6 9v4zm-10.5 4h-1a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 0 0-3z" />
    <path
      d="M16 23h5a4 4 0 0 0 4-4v-3"
      style={{
        fill: "none",
        stroke: "#000",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path d="M25 20h-2v-5h2a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2z" />
  </svg>
);

export default SupportChat;
