import { Select } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '24px',
  minWidth: '135px',
  '& .MuiSelect-select': {
    padding: '4px 14px',
  },
  '&:hover': {
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none',

      [theme.breakpoints.down('sm')]: {
        border: `1px solid ${theme.palette.border.main}`,
      },
    },
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',

    [theme.breakpoints.down('sm')]: {
      border: `1px solid ${theme.palette.border.main}`,
    },
  },
}));
