import MuiDialog from '@mui/material/Dialog';
import MuiPaper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import { getStartButtonProps } from 'containers/KioskInactivityChecker/utils';

import { StartButtonPosition } from 'constants/enums';

export const Paper = styled(MuiPaper)(({ theme }) => ({
  borderRadius: '60px',
  width: '575px',
  height: '120px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  color: theme.palette.primary.main,
}));

export const WarningPaper = styled(MuiPaper)(({ theme }) => ({
  borderRadius: '30px',
  width: '600px',
  padding: theme.spacing(5),
}));

export const PlayerWrapper = styled('div')(() => ({
  '& iframe': {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1200,
  },
}));

export const StartOrderDialog = styled(MuiDialog)<{ startBtnPosition?: StartButtonPosition }>(({
  startBtnPosition,
}) => ({
  background: '#00000042 0% 0% no-repeat padding-box;',

  '& .MuiDialog-container': getStartButtonProps(startBtnPosition),
}));
