import { Box } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

export const StyledBox = styled(
  Box,
  { shouldForwardProp: (props) => props !== 'hasBackground' },
)<{ hasBackground?: boolean }>(({ theme, hasBackground = false }) => ({
  border: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
  borderRadius: '4px',
  padding: theme.spacing(1.5),
  height: '100%',
  backgroundColor: hasBackground
    ? theme.palette.general.lightGrey6
    : theme.palette.common.white,
}));

export const StyledStatusWrapper = styled(Box)(({ theme }) => ({
  border: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.general.lightGrey6,
  display: 'flex',
  alignItems: 'center',
  borderRadius: '4px',
  color: theme.palette.primary.main,
  marginTop: theme.spacing(2),
}));

export const Root = styled(Box)(({ theme }) => ({
  '& .MuiTypography-root': {
    fontSize: '18px',
  },
  padding: theme.spacing(1),
}));
