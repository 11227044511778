import { SelectProps, MenuItem } from '@mui/material';
import { FC } from 'react';

import { StyledSelect } from 'components/CustomSelector/index.styled';

import { Option } from 'types/option.interface';

interface CustomSelectorProps {
  options: Array<Option>;
  name: string;
  value: unknown;
}

const CustomSelector:FC<SelectProps & CustomSelectorProps> = (props) => {
  const {
    name, options, value, ...remainingProps
  } = props;

  return (
    <StyledSelect
      {...remainingProps}
      value={value}
      name={name}
      MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
    >
      {options.map(({ key, value, ...remainingOptionProps }) => (
        <MenuItem value={key} key={key} {...remainingOptionProps}>
          {value}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default CustomSelector;
