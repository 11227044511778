import { styled } from '@mui/material/styles';

import CustomDropdown from 'components/CustomDropdown';

export const StyledCustomDropdown = styled(CustomDropdown)(({ theme }) => ({
  '& fieldset': {
    border: `2px solid ${theme.palette.general.lightGrey}`,
    borderRadius: '8px',
  },
  fontSize: '1rem',
}));
