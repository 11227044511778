import { Typography, Rating, Box } from '@mui/material';
import { FC } from 'react';

import { RatingStats } from 'types/rating.interface';

interface ProductRatingProps {
  ratingStats: RatingStats;
}

const ProductRating:FC<ProductRatingProps> = ({ ratingStats }) => (
  <Box display="flex" alignItems="center">
    <Rating value={ratingStats.averageRating} precision={0.5} readOnly />
    <Typography variant="body2" color="textSecondary" ml={0.5}>
      {ratingStats.averageRating}
      {' '}
      (
      {ratingStats.ratingCount}
      {' '}
      reviews)
    </Typography>
  </Box>
);

export default ProductRating;
