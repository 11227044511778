import { FieldRuleType } from 'constants/enums';

export const MEDICAL_BALANCE_USER_FIELD_RULES = {
  firstName: FieldRuleType.Required,
  lastName: FieldRuleType.Required,
  dob: FieldRuleType.Required,
  email: FieldRuleType.Required,
  phoneNumber: FieldRuleType.Required,
  address: FieldRuleType.Hidden,
};

export const MEDICAL_BALANCE_DRIVER_LICENSE_FIELD_RULES = {
  driverLicenseState: FieldRuleType.Optional,
  driverLicenseNumber: FieldRuleType.Optional,
  driverLicenseExpirationDate: FieldRuleType.Optional,
};
