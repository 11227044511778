import { Typography } from '@mui/material';
import { FC } from 'react';

import { ORDER_DELIVERY_METHOD } from 'constants/labels';
import { Address } from 'types/address.interface';
import { Delivery } from 'types/order.interface';
import { isPickupMethod } from 'utils/checkoutUtils';
import { formatAddress } from 'utils/formatters';

interface OrderTypeInfoProps {
  delivery: Delivery;
  address?: Address;
}

const OrderTypeInfo: FC<OrderTypeInfoProps> = ({ delivery, address }) => {
  const isPickup = isPickupMethod(delivery?.method);

  return (
    <>
      <Typography variant="body2" fontWeight={600} mb={2}>
        Order type:
      </Typography>

      <Typography variant="body2" fontWeight={600} mb={2} sx={{ opacity: 0.7 }}>
        {delivery?.method ? ORDER_DELIVERY_METHOD[delivery?.method] : ''}
      </Typography>

      {address && (
        <>
          <Typography variant="body2" fontWeight={600} sx={{ opacity: 0.7 }}>
            {isPickup ? 'Pickup Location' : 'Delivery Location'}
          </Typography>
          <Typography variant="body2" mb={2}>
            {formatAddress(address)}
          </Typography>
        </>
      )}

      {delivery?.pickupDate && (
        <>
          <Typography variant="body2" fontWeight={600} sx={{ opacity: 0.7 }}>
            {isPickup ? 'Pickup Date' : 'Delivery Date'}
          </Typography>
          <Typography variant="body2" mb={2}>
            {delivery?.pickupDate}
          </Typography>
        </>
      )}

      {delivery?.pickupTime && isPickup && (
        <>
          <Typography variant="body2" fontWeight={600} sx={{ opacity: 0.7 }}>
            Pickup Time
          </Typography>
          <Typography variant="body2" mb={2}>
            {delivery?.pickupTime}
          </Typography>
        </>
      )}

      {delivery?.address?.phoneNumber && (
        <>
          <Typography variant="body2" fontWeight={600} sx={{ opacity: 0.7 }}>
            Phone Number
          </Typography>
          <Typography variant="body2" mb={2}>
            {delivery?.address?.phoneNumber}
          </Typography>
        </>
      )}

      {delivery?.note && (
        <>
          <Typography variant="body2" fontWeight={600} sx={{ opacity: 0.7 }}>
            Delivery Notes
          </Typography>
          <Typography variant="body2">
            {delivery?.note}
          </Typography>
        </>
      )}
    </>
  );
};

export default OrderTypeInfo;
