import HomeIcon from '@mui/icons-material/Home';
import { isEmpty } from 'lodash';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { CartContext } from 'context/CartContext';
import { PaymentContext, PaymentDispatchContext } from 'context/PaymentContext';

import { getHomeRoute } from 'hooks/useRouting';

import { CART_ROUTE, CHECKOUT_ROUTE } from 'constants/clientRoutes';
import { PaymentSteps } from 'constants/enums';

export const useHeaderProperties = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { paymentFlow } = useContext(PaymentContext);
  const { updatePaymentFlow, resetPaymentFlow } = useContext(PaymentDispatchContext);
  const homeRoute = getHomeRoute();
  const cart = useContext(CartContext);

  if (pathname === CART_ROUTE) {
    return {
      title: 'Verify Your Order',
      onClick: () => navigate(homeRoute),
    };
  }

  if (pathname === CHECKOUT_ROUTE) {
    if (paymentFlow.showKioskPaymentMethods) {
      if (paymentFlow.paymentSteps === PaymentSteps.HowToPay) {
        return {
          title: 'Payment Method',
          ...(paymentFlow.hasPaymentFailed
            ? {}
            : { onClick: () => updatePaymentFlow({ showKioskPaymentMethods: false }) }),
        };
      }

      if (paymentFlow.paymentSteps === PaymentSteps.WaitForCard) {
        return {
          title: 'Pay with Card',
          onClick: () => updatePaymentFlow({ paymentSteps: PaymentSteps.HowToPay, hasPaymentFailed: true }),
        };
      }
    }

    return {
      title: 'Complete Checkout',
      onClick: () => {
        navigate(isEmpty(cart?.products) ? homeRoute : `${CART_ROUTE}?checkout`);
        resetPaymentFlow();
      },
    };
  }

  if (pathname.includes(CHECKOUT_ROUTE) && pathname.includes('complete')) {
    return {
      title: 'Your Order',
      onClick: () => {
        navigate(homeRoute);
        resetPaymentFlow();
      },
      Icon: HomeIcon,
    };
  }

  return {
    title: '',
    onClick: () => navigate(homeRoute),
  };
};
