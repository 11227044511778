import { CardMedia, Typography } from '@mui/material';
import { FC, useContext } from 'react';

import { Content, Root } from 'components/_MissingClient/index.styled';
import Loader from 'components/Loader';
import { SystemContext } from 'context/SystemContext';

import { ClientErrorCodes } from 'constants/enums';
import { getPublicUrlForImage } from 'utils/publicUrl';

const MissingClient:FC = () => {
  const { clientError } = useContext(SystemContext);

  if (clientError === ClientErrorCodes.missing) {
    window.location.href = 'https://www.alleaves.com/';
    return <Loader />;
  }

  return (
    <Root>
      <CardMedia
        component="img"
        src={getPublicUrlForImage('images/appLogoTextWhite.png')}
        alt="alleaves"
        sx={{ width: '300px', mb: 4 }}
      />
      {clientError === ClientErrorCodes.wrong && (
        <>
          <Typography fontSize="1.3rem" color="common.white">
            Just a heads up – we think the URL might need a little tweaking.
          </Typography>
          <Typography fontSize="1.3rem" color="common.white">
            Format should be:
          </Typography>
          <Typography fontSize="1.3rem" color="common.white">
            <em>vendor</em>
            .alleaves.shop
          </Typography>
          <Typography fontSize="1.3rem" color="common.white">
            where “vendor” is the name of the vendor you’re looking for
          </Typography>
        </>
      )}

      {clientError === ClientErrorCodes.unknown && (
        <Content>
          <Typography fontSize="1.5rem" color="common.white" mb={2} fontWeight={700}>
            There is a server error please stand by, or reach out
            {' '}
            <br />
            to Hey Bud! Support
          </Typography>
        </Content>
      )}
    </Root>
  );
};

export default MissingClient;
