import { Typography } from '@mui/material';
import { FC } from 'react';

const DEFAULT_MESSAGE = 'Estimated total may not reflect actual taxes owed or other promotions.\n'
  + '    Payment is made directly to the dispensary at time of pickup/delivery.\n'
  + '    Pickup/delivery orders that exceed local limits or that include items no\n'
  + '    longer available may be modified. A dispensary agent may reach out to you\n'
  + '    if modifications must be made.';

interface OrderDisclaimerMessageProps {
  message?: string;
}

const OrderDisclaimerMessage:FC<OrderDisclaimerMessageProps> = ({ message = DEFAULT_MESSAGE }) => (
  <Typography variant="body2" fontSize={12} mt={3}>
    {message}
  </Typography>
);

export default OrderDisclaimerMessage;
