import { Params } from 'react-router-dom';

import { PRODUCT_DETAILS_ROUTE, PRODUCTS_ROUTE } from 'constants/clientRoutes';
import { PromotionActions } from 'constants/enums';
import { FILTER_CODE_SEPARATOR } from 'constants/general';
import { Promotion } from 'types/promotion.interface';
import { formatRoute } from 'utils/formatters';

export const getPromotionRoute = (promotion: Promotion, params: Params<any>) => {
  const { action } = promotion;
  let route = '';

  if (action?.actionType === PromotionActions.OpenProduct) {
    route = formatRoute(
      PRODUCT_DETAILS_ROUTE,
      { ...params, productId: action?.bountyIds },
    );
  }

  if (action?.actionType === PromotionActions.Search) {
    const queryParams = [];

    if (action?.sort) {
      queryParams.push(`sort=${action?.sort}`);
    }

    if (action?.categories) {
      const category = action?.categories?.includes('root') ? 'all' : action?.categories;
      queryParams.push(`category=${category}`);
    }

    if (action?.tags) {
      const filters = action?.tags ? action?.tags?.split(',') : [];
      filters.forEach((filter) => {
        const [filterId, filterCode] = filter.split(FILTER_CODE_SEPARATOR);
        queryParams.push(`filter.${filterId}=${filterCode}`);
      });
    }

    route = `${PRODUCTS_ROUTE}?${queryParams?.join('&')}`;
  }

  return route;
};
