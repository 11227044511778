import { Typography } from '@mui/material';
import { FC, useContext } from 'react';

import ChipButton from 'components/ChipButton';
import DialogTitle from 'containers/Onboarding/DialogTitle';
import { DialogContent } from 'containers/Onboarding/index.styled';
import { OnboardingContext, OnboardingContextDispatchContext } from 'context/OnboardingContext';
import { SystemContext } from 'context/SystemContext';

import { AgeFilterType, MedicalIdPromptType, ShopStatusType } from 'constants/enums';
import { USE_TYPE_OPTIONS } from 'constants/options';
import { OnboardingInfo } from 'types/onboarding.interface';
import { isSetupCompleted } from 'utils/storeUtils';

import { TermsAndConditionsWrapper } from './index.styled';

interface AgePickerProps {
  updateSettings: (data: Partial<OnboardingInfo>) => void;
  allowedAge: Exclude<AgeFilterType, AgeFilterType.None>;
  setup: OnboardingInfo;
  onClose: () => void;
}

const AgePicker:FC<AgePickerProps> = ({
  updateSettings,
  allowedAge,
  setup,
  onClose,
}) => {
  const { closeOnboardingFlow } = useContext(OnboardingContextDispatchContext);
  const { locationSettings } = useContext(OnboardingContext);
  const { shopSettings, clientInfo } = useContext(SystemContext);
  const { branding } = clientInfo || {};

  const termsUrl = branding?.termsAndConditionsUrl || '';
  const privacyUrl = branding?.privacyPolicyUrl || '';

  const leaveSite = () => {
    if (isSetupCompleted(shopSettings)) {
      onClose();
    } else {
      window.location.href = 'https://google.com';
    }
  };

  const isCompleted = () => {
    const { medicalIdPrompt } = shopSettings;
    const hasStoreInfo = locationSettings.shopStatus !== ShopStatusType.DISABLED && setup.storeId && setup.useType;
    const showMedicalPicker = setup.useType === USE_TYPE_OPTIONS[1].key
      && medicalIdPrompt === MedicalIdPromptType.AtStart
      && !setup?.medical?.medicalId
      && !setup?.medical?.medicalIdExp;

    return hasStoreInfo && !showMedicalPicker;
  };

  const allowNextStep = async () => {
    if (isCompleted()) {
      closeOnboardingFlow({
        ...setup,
        age: allowedAge,
      });
    } else {
      updateSettings({ age: allowedAge });
    }
  };

  return (
    <div>
      <DialogTitle sx={{ pt: 3 }}>
        {`Please confirm if you are ${allowedAge} or older…`}
      </DialogTitle>

      <DialogContent>
        <ChipButton
          sx={{ mb: 2 }}
          height="44px"
          onClick={allowNextStep}
          hasCheckmark
        >
          <Typography fontWeight={700} className="title" variant="body1">
            Yes, I am!
          </Typography>
        </ChipButton>
        <ChipButton
          onClick={leaveSite}
          height="44px"
          hasCheckmark
        >
          <Typography fontWeight={700} className="title" variant="body1">
            No, not yet.
          </Typography>
        </ChipButton>

        <TermsAndConditionsWrapper>
          <Typography variant="caption" color="textSecondary" sx={{ display: 'block' }}>
            By clicking yes and entering the website, I agree to be bound by the
            &nbsp;
            <a href={termsUrl} rel="noreferrer" target="_blank">Terms of Service</a>
            &nbsp;
            and
            &nbsp;
            <a href={privacyUrl} rel="noreferrer" target="_blank">Privacy Policy</a>
          </Typography>
        </TermsAndConditionsWrapper>
      </DialogContent>
    </div>
  );
};

export default AgePicker;
