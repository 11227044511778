import { Container, Box } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { StyledHeader, CardMedia, ActionWrapper } from 'components/_Navbar/index.styled';
import ShoppingCart from 'components/ShoppingCart';
import StoreLocation from 'containers/StoreLocation';
import User from 'containers/User';

import { getHomeRoute } from 'hooks/useRouting';

import { ClientInfoInterface } from 'types/clientInfo.interface';
import { getPublicUrlForImage } from 'utils/publicUrl';

interface NavbarProps {
  hasShoppingCart?: boolean;
  hasUser?: boolean;
  clientInfo?: ClientInfoInterface | null;
  hasStores?: boolean;
  hideActions?: boolean;
}

const Navbar:FC<NavbarProps> = ({
  hasShoppingCart = false,
  hasUser = false,
  clientInfo = null,
  hasStores = false,
  hideActions = false,
}) => {
  const { name, branding } = clientInfo || {};

  return (
    <StyledHeader>
      <Container>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Link to={getHomeRoute()}>
            <CardMedia
              component="img"
              src={branding?.logoUrl || getPublicUrlForImage('images/appLogoText.png')}
              alt={name}
            />
          </Link>
          {!hideActions && (
            <ActionWrapper>
              {hasStores && <StoreLocation />}
              {hasShoppingCart && <ShoppingCart />}
              {hasUser && <User />}
            </ActionWrapper>
          )}
        </Box>
      </Container>
    </StyledHeader>
  );
};

export default Navbar;
