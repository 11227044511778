import { Skeleton, Box } from '@mui/material';

import { StyledSkeletonSection } from 'components/ProductCard/index.styled';

import { useKiosk } from 'hooks/useKiosk';

const ProductCardSkeleton = () => {
  const { kioskMode } = useKiosk();

  return (
    <StyledSkeletonSection>
      <Skeleton animation="wave" variant="rounded" width="100%" height={150} sx={{ mb: 1 }} />

      <Skeleton animation="wave" variant="text" width="100%" height={32} />
      <Skeleton animation="wave" variant="text" width={75} height={32} sx={{ mb: 1 }} />
      <Skeleton animation="wave" variant="rounded" width="100%" height={75} />

      <Box display="flex" justifyContent="space-between" alignItems="center" mt={1.5}>
        <Skeleton animation="wave" variant="text" width={50} height={36} />
        {!kioskMode && <Skeleton animation="wave" variant="circular" width={36} height={36} />}
      </Box>
    </StyledSkeletonSection>
  );
};

export default ProductCardSkeleton;
