import { FC, ReactNode } from 'react';

import CustomContent from 'components/CustomContent';
import SpacingContent from 'components/SpacingContent';
import { Root } from 'containers/OrderConfirmationPage/OrderConfirmationLayout/index.styled';

interface OrderConfirmationLayoutProps {
  kioskMode?: boolean;
  children: ReactNode | ReactNode[];
}

const OrderConfirmationLayout:FC<OrderConfirmationLayoutProps> = ({
  children,
  kioskMode,
}) => (
  kioskMode
    ? (
      <Root>
        <SpacingContent>
          <CustomContent kioskMode>
            {children}
          </CustomContent>
        </SpacingContent>
      </Root>
    ) : (
      <CustomContent>
        {children}
      </CustomContent>
    )
);

export default OrderConfirmationLayout;
