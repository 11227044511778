import {
  FormControl, FormLabel, Rating, Typography,
} from '@mui/material';
import { get } from 'lodash';
import { ChangeEvent, FC, SyntheticEvent } from 'react';

import CustomInput from 'components/CustomInput';

import { REVIEW } from 'constants/fields';
import { ReviewForm as ReviewFormInterface } from 'types/review.interface';

const fieldSx = { mb: 2 };

interface ReviewFormProps {
  state: ReviewFormInterface;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  fieldErrors: Record<string, string>;
  onRatingChange: (e: SyntheticEvent<Element, Event>, newValue: number | null) => void;
}

const ReviewForm:FC<ReviewFormProps> = ({
  onChange,
  onRatingChange,
  state,
  fieldErrors,
}) => (
  <form>
    <FormControl sx={fieldSx}>
      <FormLabel required>
        <Typography component="span" color="textPrimary">
          Rating
        </Typography>
      </FormLabel>
      <Rating
        name="rating"
        size="large"
        value={state.rating}
        onChange={onRatingChange}
      />
    </FormControl>
    <CustomInput
      {...REVIEW}
      sx={fieldSx}
      size="small"
      onChange={onChange}
      error={!!get(fieldErrors, REVIEW.name)}
      helperText={get(fieldErrors, REVIEW.name)}
    />
  </form>
);

export default ReviewForm;
