import { Box, Skeleton, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMemo } from 'react';
import Slider from 'react-slick';

import CarouselNextArrow from 'components/CarouselNextArrow';
import CarouselPrevArrow from 'components/CarouselPrevArrow';
import ProductCardSkeleton from 'components/ProductCard/index.skeleton';

import { getCategoriesCarouselSettings } from 'utils/carousel';

const CategoryCarouselSkeleton = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const settings = {
    ...getCategoriesCarouselSettings(theme),
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
  };

  const renderMobileSlider = useMemo(() => (
    <Slider {...settings}>
      <ProductCardSkeleton />
    </Slider>
  ), []);

  const renderSlider = useMemo(() => (
    <Slider {...settings}>
      <ProductCardSkeleton />
      <ProductCardSkeleton />
      <ProductCardSkeleton />
      <ProductCardSkeleton />
    </Slider>
  ), []);

  return (
    <Box mb={10}>
      <Skeleton animation="wave" variant="text" width={250} height={36} sx={{ mb: 2 }} />
      {isMobile ? renderMobileSlider : renderSlider}
    </Box>
  );
};

export default CategoryCarouselSkeleton;
