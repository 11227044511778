import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import { Box, Typography } from '@mui/material';
import { FC, useContext } from 'react';

import ChipButton from 'components/ChipButton';
import DialogTitle from 'containers/Onboarding/DialogTitle';
import { DialogContent } from 'containers/Onboarding/index.styled';
import { LocationContext, LocationContextDispatchContext } from 'context/LocationContext';

import { OnboardingUseType } from 'constants/enums';
import { USE_TYPE_OPTIONS } from 'constants/options';

interface KioskUseTypePickerProps {
  onClose: () => void;
}

const KioskUseTypePicker:FC<KioskUseTypePickerProps> = ({ onClose }) => {
  const { saveOnboardingInfo } = useContext(LocationContextDispatchContext);
  const { onboardingInfo } = useContext(LocationContext);

  const handleNext = (useType: OnboardingUseType) => {
    saveOnboardingInfo({
      ...onboardingInfo,
      useType,
    });
    onClose();
  };

  return (
    <>
      <DialogTitle>
        Please choose
        <br />
        your experience
      </DialogTitle>
      <DialogContent>
        <Box py={0}>
          <ChipButton
            height="100%"
            sx={{ mb: 1 }}
            icon={<SelfImprovementIcon className="useType" sx={{ fontSize: '40px' }} />}
            onClick={() => handleNext(USE_TYPE_OPTIONS[0].key)}
          >
            <div>
              <Typography fontWeight={700} className="title" variant="body1">
                {USE_TYPE_OPTIONS[0].value.toUpperCase()}
              </Typography>
              <Typography variant="body2">
                No medical card
              </Typography>
            </div>
          </ChipButton>

          <ChipButton
            icon={<MedicalServicesOutlinedIcon className="useType" sx={{ fontSize: '40px' }} />}
            height="100%"
            onClick={() => handleNext(USE_TYPE_OPTIONS[1].key)}
          >
            <div>
              <Typography fontWeight={700} className="title" variant="body1">
                {USE_TYPE_OPTIONS[1].value.toUpperCase()}
              </Typography>
              <Typography variant="body2">
                Medical card required
              </Typography>
            </div>
          </ChipButton>
        </Box>
      </DialogContent>
    </>
  );
};

export default KioskUseTypePicker;
