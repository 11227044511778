import { useContext, useEffect, useState } from 'react';

import { PaymentContext } from 'context/PaymentContext';

import { patchOrder } from 'services/Order';

import { PaymentMethod } from 'constants/enums';
import { BillMyBankSDK, BMBSuccessResponse } from 'types/billMyBankSdk.interface';
import { loadSDK } from 'utils/bmbUtils';
import { isIntegratedPayment } from 'utils/checkoutUtils';
import { handleApiErrors } from 'utils/errorUtils';

export const useBillMyBank = (onSuccess?: () => void) => {
  const {
    paymentDetails: {
      orderAmount, orderId, bmbAuthToken, bmbEnv, paymentMethod,
    },
  } = useContext(PaymentContext);
  const [sdk, setSdk] = useState({} as BillMyBankSDK);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSuccessCallback = async (response: BMBSuccessResponse) => {
    const { paymentDetails, paymentToken } = response || {};

    try {
      const payload = {
        paymentId: paymentDetails?.paymentId,
        customerId: paymentDetails?.customerId,
        paymentToken,
      };
      await patchOrder(payload, orderId);

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      handleApiErrors(error);
    }
  };

  const onFailureCallback = () => {
    setShowErrorDialog(true);
  };

  const startPayment = () => {
    setIsLoading(true);
    const paymentRequest = sdk.paymentRequest({
      amount: orderAmount,
      orderId,
      paymentMode: 'DIRECT_ONLY',
    });

    sdk.paymentButton({
      paymentRequest,
    }).mount();

    const paymentWidget = document.getElementById('payment-modal__smartach') as HTMLElement;

    if (paymentWidget) {
      paymentWidget.style.zIndex = '1200';
    }

    setIsLoading(false);
  };

  const initBMBSDK = () => {
    setSdk(window?.BillMyBank({
      apiToken: bmbAuthToken,
      env: bmbEnv,
      onSuccess: onSuccessCallback,
      onFailure: onFailureCallback,
      paymentMode: 'DEFAULT',
    }));
  };

  const initChargeeSDK = () => {
    setSdk(window?.Chargee({
      apiToken: bmbAuthToken,
      env: bmbEnv,
      onSuccess: onSuccessCallback,
      onFailure: onFailureCallback,
      paymentMode: 'DEFAULT',
    }));
  };

  useEffect(() => {
    const isBmb = paymentMethod && isIntegratedPayment(paymentMethod);

    if (isBmb && bmbAuthToken && +orderAmount > 0) {
      if (paymentMethod === PaymentMethod.BILLMYBANK) {
        if (!window.BillMyBank) {
          loadSDK('https://widget.billmybank.com', initBMBSDK);
        } else {
          initBMBSDK();
        }
      } else if (paymentMethod === PaymentMethod.CHARGEE) {
        if (!window.Chargee) {
          loadSDK('https://widget.chargee.co', initChargeeSDK);
        } else {
          initChargeeSDK();
        }
      }
    }
  }, [bmbAuthToken, paymentMethod]);

  return {
    startPayment,
    sdk,
    isLoading,
    showErrorDialog,
    handleCloseDialog: () => setShowErrorDialog(false),
  };
};
