import { Box, Grid2 as Grid, SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FC, useContext } from 'react';

import ProductCard from 'components/ProductCard';
import { LocationContext } from 'context/LocationContext';
import { SystemContext } from 'context/SystemContext';

import { ViewMode } from 'constants/enums';
import { Bounty } from 'types/bounty.interface';
import { Promotion } from 'types/promotion.interface';

interface RecommendationsBannerProps {
  recommendations: Promotion[];
  sx?: SxProps;
  openDetails: (bounty: Bounty) => void;
}

const RecommendationsBanner: FC<RecommendationsBannerProps> = ({
  recommendations,
  sx,
  openDetails,
}) => {
  const { shopSettings } = useContext(SystemContext);
  const { onboardingInfo } = useContext(LocationContext);
  const isDescriptionVisible = shopSettings?.viewConfigs?.PRODUCT_DESCRIPTION?.viewMode === ViewMode.Full;

  return (
    <Box sx={sx}>
      <Typography variant="h2" fontWeight={600} mb={4}>
        Today&apos;s Recommendations
      </Typography>

      <Grid container spacing={2}>
        {recommendations?.map((recommendation) => {
          const bounty = recommendation?.action?.bounties?.[0];

          return bounty ? (
            <Grid
              size={[12, 12, 4, 3]}
              key={bounty?.id}
            >
              <Box
                className="pointer"
                onClick={() => openDetails(bounty)}
                height="100%"
                width="100%"
              >
                <ProductCard
                  bounty={bounty}
                  isDescriptionVisible={isDescriptionVisible}
                  useType={onboardingInfo?.useType}
                />
              </Box>
            </Grid>
          ) : null;
        })}
      </Grid>
    </Box>
  );
};

export default RecommendationsBanner;
