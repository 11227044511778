import { styled } from '@mui/material/styles';

export const StyledImage = styled('img')(({ theme }) => ({
  height: '50px',
  width: '50px',
  marginRight: theme.spacing(1),
  objectFit: 'contain',
}));

export const FreeText = styled('span')(({ theme }) => ({
  color: theme.palette.success.main,
  paddingRight: theme.spacing(0.5),
}));
