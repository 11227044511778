import { Box } from '@mui/material';

import { StyledSkeleton } from 'containers/ProductDetailsPage/index.styled';

const ProductDetailsSkeleton = () => (
  <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
    <Box margin="0 auto">
      <StyledSkeleton animation="wave" variant="rounded" width={200} height={150} />
    </Box>
    <StyledSkeleton animation="wave" variant="rounded" height={30} width={300} />
    <StyledSkeleton animation="wave" variant="rounded" height={30} width={80} />

    <StyledSkeleton animation="wave" variant="rounded" height={40} width="100%" />
    <StyledSkeleton animation="wave" variant="rounded" height={150} width="100%" />
  </Box>
);

export default ProductDetailsSkeleton;
