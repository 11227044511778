import MuiChip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

export const Chip = styled(MuiChip)(({ theme }) => ({
  padding: theme.spacing(1, 0.5),
  borderRadius: '20px',
  backgroundColor: theme.palette.common.white,
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    paddingRight: 0,
    '& .MuiChip-label': {
      display: 'none',
    },
    '& .MuiChip-icon': {
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
    },
  },
  '&.MuiButtonBase-root.MuiChip-root.Mui-disabled': {
    opacity: 1,
  },
  '&.MuiButtonBase-root.MuiChip-root.MuiChip-clickable:hover': {
    backgroundColor: theme.palette.background.secondary,
  },

  [theme.breakpoints.down('sm')]: {
    '& .MuiChip-icon': {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
})) as typeof MuiChip;

export const Dot = styled('div')(({ theme }) => ({
  height: '4px',
  width: '4px',
  borderRadius: '50%',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  backgroundColor: theme.palette.text.secondary,
}));
