import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const InnerWrapper = styled(
  Box,
  { shouldForwardProp: (props) => props !== 'isMobileView' },
)<{isMobileView: boolean}>(({ isMobileView, theme }) => (
  isMobileView ? {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    borderRadius: '8px',
    border: `1px solid ${theme.palette.primary.main}`,
    width: 'fit-content',

    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
    },

    '& svg path': {
      fill: theme.palette.primary.main,
    },
  } : {}
));
