import CloseIcon from '@mui/icons-material/Close';
import {
  Box, Dialog, IconButton, Typography,
} from '@mui/material';
import {
  FC, MouseEvent, useContext,
} from 'react';

import CustomButton from 'components/CustomButton';
import ProductShortPreview from 'components/ProductShortPreview';
import TierPrices from 'components/TierPrices';
import TierPriceSelector from 'containers/TierPriceSelector';
import { LocationContext } from 'context/LocationContext';

import { Bounty } from 'types/bounty.interface';
import { shouldUseProductWeight } from 'utils/productUtils';

interface ProductTierPriceDialogProps {
  bounty: Bounty;
  isOpen: boolean;
  onClose: (e: MouseEvent<HTMLElement>) => void;
}

const ProductTierPriceDialog: FC<ProductTierPriceDialogProps> = ({
  bounty,
  isOpen,
  onClose,
}) => {
  const { onboardingInfo } = useContext(LocationContext);
  const { product } = bounty;

  const handleClick = (e: MouseEvent<HTMLElement>) => e.preventDefault();

  return (
    <Dialog
      open={isOpen}
      fullWidth
      onClose={onClose}
      aria-describedby="select-tier-price"
      PaperProps={{ sx: { maxWidth: 560, borderRadius: '16px', p: 2.5 } }}
    >
      <Box onClick={handleClick}>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="h5" fontWeight={700}>
            Select quantity
          </Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <ProductShortPreview bounty={bounty} sx={{ mb: 2 }} />

        <TierPrices
          tierPrices={product?.altPrices?.tierPrices}
          useType={onboardingInfo?.useType}
          product={product}
          shouldUseProductWeight={shouldUseProductWeight(product)}
        />

        <Box mt={2}>
          <TierPriceSelector
            bounty={bounty}
            tierPrices={product?.altPrices?.tierPrices}
            shouldUseProductWeight={shouldUseProductWeight(product)}
            useType={onboardingInfo?.useType}
          >
            {({ onAddToCart, isDisabled }) => (
              <Box display="flex" gap={2} mt={2} justifyContent="flex-end">
                <CustomButton
                  id="btn-closeTierPriceDialog"
                  onClick={onClose}
                  variant="outlined"
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  id="btn-addToCard-TierPriceDialog"
                  onClick={(e) => {
                    onAddToCart();
                    onClose(e);
                  }}
                  disabled={isDisabled}
                >
                  Add to cart
                </CustomButton>
              </Box>
            )}
          </TierPriceSelector>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ProductTierPriceDialog;
