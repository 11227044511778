import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';

import { Chip } from 'components/ChipButton/index.styled';

interface ChipButtonProps {
  isActive?: boolean;
  hasCheckmark?: boolean;
  onClick: () => void;
  children: ReactNode;
  sx?: object;
  height?: string;
  icon?: ReactNode;
}

const ChipButton:FC<ChipButtonProps> = ({
  isActive,
  hasCheckmark,
  onClick,
  children,
  sx = {},
  height = 'inherit',
  icon = null,
}) => (
  <Chip
    isActive={isActive}
    onClick={onClick}
    sx={sx}
  >
    <Box display="flex" alignItems="center" height={height}>
      {hasCheckmark && (
        <CheckCircleOutlineOutlinedIcon fontSize="large" />
      )}
      {icon}
      <Box className="label" pr={icon ? 6.5 : 4}>
        {children}
      </Box>
    </Box>
  </Chip>
);

export default ChipButton;
