import { styled } from '@mui/material/styles';

export const Root = styled('div')<{ hide?: boolean }>(({ theme, hide }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.border.main}`,
  display: 'flex',
  justifyContent: 'center',
  color: theme.palette.general.lightGrey1,
  marginTop: 'auto',
  visibility: hide ? 'hidden' : 'visible',
}));

export const KioskRoot = styled('div')(({ theme }) => ({
  paddingRight: theme.spacing(3),
  backgroundColor: theme.palette.headerBackground.main,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  position: 'sticky',
  bottom: 0,
  height: '120px',
  gap: theme.spacing(2),
}));
