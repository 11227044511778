import { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';

import CustomInput from 'components/CustomInput';

const NumberFormatCustom = forwardRef((props: any, ref: any) => {
  const preventNegativeNumbers = (e: any) => {
    if (e.key === '-') {
      e.preventDefault();
    }
  };

  return (
    <NumericFormat
      {...props}
      getInputRef={ref}
      thousandSeparator=","
      decimalSeparator="."
      decimalScale={props?.decimalScale}
      fixedDecimalScale
      onKeyDown={preventNegativeNumbers}
    />
  );
});

const NumberFormatField = ({
  InputProps,
  inputProps,
  isAllowed,
  decimalScale = 1,
  suffix = '',
  ...remainingProps
}: any) => (
  <CustomInput
    {...remainingProps}
    InputProps={{
      ...InputProps,
      inputProps: {
        ...inputProps,
        isAllowed,
        decimalScale,
        suffix,
      },
      inputComponent: NumberFormatCustom,
    }}
  />
);

export default NumberFormatField;
