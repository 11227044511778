import { format } from 'date-fns';

import { ChatState } from 'constants/enums';
import { ChatMessage, UserChat } from 'types/chat.interface';

export const formatChatTimestamp = (date: number) => format(new Date(date), 'MMM dd, yyyy HH:mm a');

export const formatAuthorName = (message: ChatMessage): string => {
  if (!message.authorId) {
    return 'Support';
  }

  return message?.author?.name || '';
};

export const hasUnreadMessages = ({ lastReadMsgAt, notifiedRecvMsgAt, state }: UserChat) => {
  if (!lastReadMsgAt && notifiedRecvMsgAt) {
    return true;
  }

  if (!notifiedRecvMsgAt) {
    return false;
  }

  if (state === ChatState.Dismissed) {
    return false;
  }

  return notifiedRecvMsgAt > lastReadMsgAt;
};
