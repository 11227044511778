import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Grid, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import BreadcrumbsNavigation from 'components/BreadcrumbsNavigation';
import CustomButton from 'components/CustomButton';
import ProductInformation from 'components/ProductInformation';
import ProductPictures from 'components/ProductPictures';
import SubheaderWrapper from 'components/SubheaderWrapper';
import ProductQuantitySelector from 'containers/ProductDetailsPage/ProductQuantitySelector';
import PreviewLayout from 'layouts/PreviewLayout';

import { PREVIEW_CHEMICALS_IN_STORE } from 'constants/clientRoutes';
import { MOCK_BREADCRUMB, MOCK_PRODUCT } from 'constants/mockData';
import { getPreviewChemicals } from 'utils/productUtils';

const PreviewProductPage = () => {
  const [searchParams] = useSearchParams();
  const chemicalKeys = searchParams?.get('keys') || '';
  const [bounty, setBounty] = useState(MOCK_PRODUCT);

  const handleClickPicture = () => {};

  const handleVisiblePictureIndex = () => {};

  useEffect(() => {
    if (chemicalKeys) {
      const keys = chemicalKeys?.split(',');
      const chemicals = getPreviewChemicals(searchParams, keys);

      setBounty({ ...MOCK_PRODUCT, product: { ...MOCK_PRODUCT.product, chemicalComposition: chemicals } });
    }
  }, [chemicalKeys]);

  const generateUrlWithParams = () => {
    const params = new URLSearchParams(searchParams);
    return `${PREVIEW_CHEMICALS_IN_STORE}?${params.toString()}`;
  };

  return (
    <PreviewLayout hideFooter hideNavbarActions>
      <div>
        <SubheaderWrapper>
          <BreadcrumbsNavigation breadcrumbs={[
            { ...MOCK_BREADCRUMB, link: generateUrlWithParams() },
            { label: bounty?.product?.name, name: '' },
          ]}
          />
        </SubheaderWrapper>
        <Container>
          <Grid container spacing={2} my={2}>
            <Grid item xs={12} md={6}>
              <ProductPictures
                product={bounty?.product}
                onClick={handleClickPicture}
                attachments={bounty.attachments}
                visiblePictureIndex={0}
                setVisiblePictureIndex={handleVisiblePictureIndex}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ProductInformation bounty={bounty} />
              <ProductQuantitySelector bounty={bounty}>
                {({ onAddToCart, isDisabled = false }) => (
                  <CustomButton
                    id="button-addToCart"
                    variant="contained"
                    onClick={onAddToCart}
                    icon={<AddShoppingCartIcon />}
                    disabled={isDisabled}
                  >
                    Add to cart
                  </CustomButton>
                )}
              </ProductQuantitySelector>
            </Grid>
          </Grid>
        </Container>
      </div>
    </PreviewLayout>
  );
};

export default PreviewProductPage;
