import { Grid2 as Grid } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';

export const CategoryCard = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '4px',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '&:hover': {
    transform: 'scale(1.015)',
    backgroundColor: theme.palette.background.secondary,
  },
}));

export const StyledImage = styled(CardMedia)(() => ({
  height: '120px',
  width: '120px',
  objectFit: 'cover',
  borderRadius: '50%',
})) as typeof CardMedia;
