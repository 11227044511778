import { SxProps, Typography } from '@mui/material';
import { FC } from 'react';

import Dot from 'components/Dot';

import { Root } from './index.styled';

interface FilterStatsProps {
  name?: string;
  count?: number;
  fontWeight?: number;
  sx?: SxProps;
}

const FilterStats: FC<FilterStatsProps> = ({
  name,
  count = 0,
  fontWeight = 400,
  sx,
}) => (
  <Root sx={sx}>
    <Typography variant="body1" fontWeight={fontWeight}>
      {name}
    </Typography>

    {count > 0 && (
      <>
        <Dot sx={{ height: '6px', width: '6px' }} />
        <Typography variant="body1" fontWeight={fontWeight}>
          {count}
        </Typography>
      </>
    )}
  </Root>
);

export default FilterStats;
