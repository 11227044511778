import {
  Dialog, DialogTitle, DialogContent, DialogActions,
} from '@mui/material';
import {
  ChangeEvent, FC, SyntheticEvent, useContext, useState,
} from 'react';
import { toast } from 'react-toastify';

import CustomButton from 'components/CustomButton';
import ReviewForm from 'components/ReviewForm';
import { UserContext } from 'context/UserContext';

import { addBountyComment, increaseBountyComments } from 'services/Comment';

import { CommentType, PointCurrency } from 'constants/enums';
import { Bounty } from 'types/bounty.interface';
import { ReviewForm as ReviewFormInterface } from 'types/review.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { reviewFormSchema } from 'validation/reviewSchema';

const initialState = {
  rating: 0,
  review: '',
};

interface AddProductReviewProps {
  bounty: Bounty;
  fullWidth?: boolean;
}

const AddProductReview:FC<AddProductReviewProps> = ({ fullWidth = false, bounty }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});
  const [comment, setComment] = useState<ReviewFormInterface>(initialState);
  const { userData } = useContext(UserContext);

  const handleClose = () => {
    setIsOpen(false);
    setComment(initialState);
  };

  const handleOpen = () => setIsOpen(true);

  const handleSubmit = async () => {
    try {
      await reviewFormSchema.validate(comment, { abortEarly: false });

      const payload = {
        creator: userData?.basicProfile?.user,
        sentAt: new Date().getTime(),
        text: comment?.review,
        author: userData?.basicProfile?.user,
        commentType: CommentType.B,
        entityId: bounty.id,
        reward: {
          points: {
            [PointCurrency.RATING]: {
              amount: comment?.rating,
              currency: PointCurrency.RATING,
            },
          },
        },
      };

      await addBountyComment(bounty?.id, payload);
      await increaseBountyComments(bounty?.stats, bounty?.id, userData?.basicProfile?.user?.id);

      // TODO: check the shop settings, maybe the shop doesn't require approval
      toast.success('The review was created successfully! It will be visible after approval!');
      handleClose();
    } catch (error) {
      handleApiErrors(error, setFieldErrors);
    }
  };

  const handleChanges = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    setComment(((prevState) => ({ ...prevState, [name]: value })));
  };

  const handleRatingChanges = (e: SyntheticEvent<Element, Event>, newValue: number | null) => {
    setComment(((prevState) => ({ ...prevState, rating: newValue || 0 })));
  };

  return (
    <div>
      <CustomButton
        id="button-writeReview"
        variant="outlined"
        sx={{ mt: 2 }}
        fullWidth={fullWidth}
        onClick={handleOpen}
      >
        Write a review
      </CustomButton>

      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-describedby="review-product"
      >
        <DialogTitle>Review Product</DialogTitle>
        <DialogContent>
          <ReviewForm
            state={comment}
            onChange={handleChanges}
            fieldErrors={fieldErrors}
            onRatingChange={handleRatingChanges}
          />
        </DialogContent>
        <DialogActions sx={{ p: '0 24px 24px' }}>
          <CustomButton
            id="button-cancelAddReview"
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </CustomButton>
          <CustomButton
            id="button-submitAddReview"
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddProductReview;
