import { FC, ReactElement } from 'react';

import { StyledLink } from 'components/UnstyledLink/index.styled';

interface UnstyledLinkProps {
  to: string;
  children: ReactElement;
  height?: string;
  width?: string;
}

const UnstyledLink:FC<UnstyledLinkProps> = ({
  to,
  children,
  height = 'inherit',
  width = 'inherit',
}) => (
  <StyledLink to={to} height={height} width={width}>
    {children}
  </StyledLink>
);

export default UnstyledLink;
