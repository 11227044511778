import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useContext } from 'react';

import { CategoryContext } from 'context/CategoryContext';

import { getHomeRoute, getProductsRoute } from 'hooks/useRouting';

import {
  formatFiltersItem,
  formatNavigableCategoryItem,
  formatProductItem,
  formatProductNameItem,
} from 'constants/breadcrumbs';
import { BountyCategory } from 'types/bounty.interface';
import { Breadcrumb } from 'types/breadcrumbs.interface';
import { Filter } from 'types/filter.interface';
import { findRoot } from 'utils/categoriesUtils';
import { getActiveFilterNames } from 'utils/filterUtils';

interface ProductDetailsBreadcrumbsProps {
  productName: string;
  bountyCategories: {
    [key: string]: BountyCategory;
  };
}

export const useBreadcrumbs = () => {
  const theme = useTheme();
  const matchesScreenSizeMd = useMediaQuery(theme.breakpoints.down('md'));
  const { categories } = useContext(CategoryContext);

  const getProductDetailsBreadcrumbs = ({ productName = '', bountyCategories }: ProductDetailsBreadcrumbsProps) => {
    const breadcrumbs = [formatProductItem(getHomeRoute())] as Breadcrumb[];

    if (bountyCategories) {
      const productCategory = Object.values(bountyCategories)?.[0] || {};
      const root = findRoot(productCategory.categoryCode, categories);

      if (root?.code) {
        const link = getProductsRoute(`category=${root.code}`);
        breadcrumbs.push(formatNavigableCategoryItem(productCategory?.categoryName, link));
      }
    }

    if (productName) {
      breadcrumbs.push(formatProductNameItem(productName));
    }

    return breadcrumbs;
  };

  const getProductsBreadcrumbs = (activeFilters: {[key: string]: string[]}, filters: Filter[]) => {
    const breadcrumbs = [formatProductItem(getHomeRoute())] as Breadcrumb[];

    if (matchesScreenSizeMd) {
      return breadcrumbs;
    }

    const activeFilterNames = getActiveFilterNames(activeFilters, filters);
    const hasActiveFilters = activeFilterNames.length > 0;

    if (hasActiveFilters) {
      breadcrumbs.push(formatFiltersItem(activeFilterNames));
    }

    return breadcrumbs;
  };

  return {
    getProductDetailsBreadcrumbs,
    getProductsBreadcrumbs,
  };
};
