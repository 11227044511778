import { Box, Typography } from '@mui/material';
import {
  ChangeEvent, FC, ReactNode, useContext, useState,
} from 'react';
import { toast } from 'react-toastify';

import WeightSelector from 'components/WeightSelector';
import { CartContext, CartDispatchContext } from 'context/CartContext';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

import { WeightUnit } from 'constants/enums';
import { WEIGHT_UNIT_LABELS } from 'constants/labels';
import { Bounty } from 'types/bounty.interface';
import { getTopLevelVariant } from 'utils/priceUtils';
import { formatProductWeight, getRealWeight } from 'utils/productUtils';

interface ProductWeightSelectorProps {
  isInline?: boolean;
  bounty: Bounty;
  children: (params: { onAddToCart: () => void; isDisabled?: boolean }) => ReactNode;
}

const ProductWeightSelector: FC<ProductWeightSelectorProps> = ({ bounty, children, isInline }) => {
  const { addItemWithWeight } = useContext(CartDispatchContext);
  const cart = useContext(CartContext);
  const { weightUnit, quantity = 0 } = getTopLevelVariant(bounty?.product) || {};
  const { handleTrackAddItemToCart } = useGoogleAnalytics();
  const productQuantity = Math.max(0, quantity || 0);
  const realWeight = getRealWeight(bounty?.product, bounty.id, cart);
  const showRemainingStock = realWeight > 0 && realWeight <= 10;
  const [currentWeight, setCurrentWeight] = useState<string>(bounty?.product?.sortWeight?.toString() || '');

  const handleInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setCurrentWeight(value);
  };

  const handleAddToCart = () => {
    addItemWithWeight({
      bounty,
      weight: currentWeight,
      onSuccess: () => {
        toast.success('Product added to cart!');
        handleTrackAddItemToCart({ bounty, weight: currentWeight });
      },
    });
  };

  return (
    <>
      <Box display="flex" alignItems="center" gap={2}>
        {productQuantity <= 0
          ? (
            <Typography variant="body2" color="error">
              Out of stock
            </Typography>
          ) : (
            <Typography variant="body1">
              WEIGHT
            </Typography>
          )}

        {realWeight > 0 && (
          <WeightSelector
            selectedWeight={currentWeight}
            onChange={handleInputChange}
            weightUnit={weightUnit}
          />
        )}

        {isInline && children({
          onAddToCart: handleAddToCart,
          isDisabled: +currentWeight === 0 || !currentWeight || !realWeight,
        })}

        {showRemainingStock && (
          <Typography variant="body2" fontWeight={600} color="error" mt={2}>
            Only
            {' '}
            {`${formatProductWeight(realWeight)} ${WEIGHT_UNIT_LABELS[weightUnit || WeightUnit.G]}`}
            {' '}
            left in stock!
          </Typography>
        )}
      </Box>
      {!isInline && children({
        onAddToCart: handleAddToCart,
        isDisabled: +currentWeight === 0 || !currentWeight || !realWeight,
      })}
    </>
  );
};

export default ProductWeightSelector;
