import { alpha, styled } from '@mui/material/styles';

import { KIOSK_HEADER_HEIGHT, KIOSK_SIDEBAR_WEIGHT } from 'constants/general';

export const Root = styled('div')<{ level: number }>(({ theme, level }) => ({
  backgroundColor: theme.palette.headerBackground.main,
  ...(level ? {
    backgroundColor: alpha(theme.palette.common.white, 0.3),
    borderRadius: '20px',
    textAlign: 'center',
  } : {}),

  '& .MuiSkeleton-root': {
    backgroundColor: alpha(theme.palette.common.white, 0.3),
  },
}));

export const Content = styled('div')<{ level: number }>(({ theme, level }) => ({
  ...(!level ? {
    padding: theme.spacing(0, 2),
    maxHeight: `calc(100vh - ${KIOSK_HEADER_HEIGHT}px)`,
    width: `${KIOSK_SIDEBAR_WEIGHT}px`,
  } : {}),
}));
