import { useEffect } from 'react';

import { getShopConfiguration } from 'utils/storeUtils';

export const setDocumentTitle = ({ title }: { title: string }) => {
  const { embed, setDocTitle, hideHeader } = getShopConfiguration();
  if (setDocTitle === undefined ? !(embed && hideHeader) : setDocTitle) {
    document.title = title;
  }
};

export const useDocumentTitle = ({ title }: { title: string }) => {
  useEffect(() => {
    setDocumentTitle({ title });

    return () => {
      setDocumentTitle({ title: 'Loading...' });
    };
  }, []);
};
