import { Container, Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { CardMedia, Content } from 'containers/PreviewOnboardingPage/index.styled';
import PreviewLayout from 'layouts/PreviewLayout';

import { getPublicUrlForImage } from 'utils/publicUrl';

const PreviewOnboardingPage = () => {
  const [searchParams] = useSearchParams();
  const backgroundUrl = searchParams?.get('onboardingBackgroundUrl');

  return (
    <PreviewLayout hideFooter hideNavbarActions>
      <Content>
        <Container sx={{ height: '100%' }}>
          <Box pt={2} mt={-8} height="100%" display="flex" justifyContent="center">
            <CardMedia
              component="img"
              alt="onboarding flow"
              src={backgroundUrl || getPublicUrlForImage('images/default-onboarding.jpg')}
            />
          </Box>
        </Container>
      </Content>
    </PreviewLayout>
  );
};

export default PreviewOnboardingPage;
