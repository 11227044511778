import { Variant } from '@mui/material/styles/createTypography';
import { FC } from 'react';

import { FreeText } from 'components/FreeTag/index.styled';

interface FreeTagProps {
  fontWeight?: number;
  variant?: Variant;
}

const FreeTag:FC<FreeTagProps> = ({ fontWeight = 500, variant = 'body2' }) => (
  <FreeText variant={variant} fontWeight={fontWeight}>
    Free
  </FreeText>
);

export default FreeTag;
