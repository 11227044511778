import { off, onValue, query } from 'firebase/database';
import { useContext, useEffect, useState } from 'react';

import { UserContext } from 'context/UserContext';

import { getReceiptStatusRef } from 'services/Order';

import { DeliveryMethod, ReceiptStatus } from 'constants/enums';

export function useReceipt(orderId?: string, deliveryMethod?: DeliveryMethod) {
  const { user } = useContext(UserContext);
  const [receiptStatus, setReceiptStatus] = useState<ReceiptStatus>(ReceiptStatus.Created);

  useEffect(() => {
    if (orderId && user?.uid && deliveryMethod === DeliveryMethod.PICKUP) {
      subscribeOnReceiptStatus(orderId);
    }

    return () => {
      if (orderId && user?.uid && deliveryMethod === DeliveryMethod.PICKUP) {
        unsubscribeFromOrderReceipt(orderId);
      }
    };
  }, [orderId, user?.uid, deliveryMethod]);

  const unsubscribeFromOrderReceipt = (id: string) => off(getReceiptStatusRef(id, user?.uid));

  const subscribeOnReceiptStatus = (id: string) => {
    const dbQuery = query(getReceiptStatusRef(id, user?.uid));

    onValue(dbQuery, (snapshot) => {
      const data = snapshot.val() as ReceiptStatus;
      setReceiptStatus(data);
    });
  };

  return { receiptStatus };
}
