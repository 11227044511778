import { SEOVariables } from 'constants/enums';
import { Bounty } from 'types/bounty.interface';
import { ClientInfoInterface } from 'types/clientInfo.interface';
import { OnboardingInfo } from 'types/onboarding.interface';
import { SEO } from 'types/shopSettings.interface';
import { formatAddress } from 'utils/formatters';
import { getManufacturer, getProductStrainType } from 'utils/productUtils';

interface SEOInfo {
  seo?: SEO;
  clientInfo?: ClientInfoInterface;
  bounty?: Bounty;
  onboardingInfo?: OnboardingInfo;
}

const populateVariables = ({
  text, clientInfo, bounty, onboardingInfo,
}: { text?: string } & SEOInfo) => {
  if (!text) {
    return '';
  }

  const storeAddress = onboardingInfo?.store?.address
    ? formatAddress(onboardingInfo?.store?.address)
    : '';
  const brand = bounty?.product ? getManufacturer(bounty?.product) : '';
  const strainType = bounty?.tags?.tags ? getProductStrainType(bounty?.tags?.tags) : null;
  const categories = bounty?.bountyCategories
    ? Object?.values(bounty?.bountyCategories)?.map(({ categoryName }) => categoryName)?.join(', ')
    : '';

  let formattedText = text;
  const replacements = {
    [SEOVariables.StoreName]: clientInfo?.name || '',
    [SEOVariables.StoreAddress]: storeAddress,
    [SEOVariables.StoreWebsite]: clientInfo?.branding?.websiteUrl || '',
    [SEOVariables.ProductName]: bounty?.product?.name || '',
    [SEOVariables.ProductDescription]: bounty?.product?.description || '',
    [SEOVariables.ProductBrand]: brand,
    [SEOVariables.ProductCategory]: categories,
    [SEOVariables.ProductCannabisType]: strainType?.displayName || '',
  };

  Object.entries(replacements).forEach(([key, value]) => {
    formattedText = formattedText.replaceAll(key, value);
  });

  return formattedText;
};

export const getFormattedSeoInfo = ({
  seo, clientInfo, bounty, onboardingInfo,
}: SEOInfo) => ({
  title: populateVariables({
    text: seo?.title, clientInfo, bounty, onboardingInfo,
  }),
  description: populateVariables({
    text: seo?.description, clientInfo, bounty, onboardingInfo,
  }),
});
