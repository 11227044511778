import { Grid } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '8px',
  borderRadius: '8px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.common.white,
  },
}));

export const GridHeader = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  padding: theme.spacing(0, 3),
  left: 0,
  top: '60px',
  height: 'calc(100% - 70px)',

  '& .MuiGrid-item:not(:first-of-type)': {
    borderLeft: `1px solid ${theme.palette.general.lightGrey3}`,
    paddingLeft: theme.spacing(1.25),
  },
}));

export const ProgressWrapper = styled('div')<{ limit?: number; amount?: number }>(({ theme, amount, limit }) => {
  let backgroundColor = theme.palette.general.lightGreen;

  if (limit && amount) {
    if (amount === limit) {
      backgroundColor = theme.palette.general.lightBlue;
    }

    if (amount > limit) {
      backgroundColor = theme.palette.general.lightOrange;
    }
  }

  return {
    height: '16px',
    borderRadius: '8px',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0.5),

    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: '8px',
      backgroundColor,
    },
  };
});

export const AmountWrapper = styled('div')<{ hasReachedLimit: boolean }>(({ theme, hasReachedLimit }) => ({
  color: hasReachedLimit ? theme.palette.general.lightOrange : 'initial',
}));
