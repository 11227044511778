import { Box } from '@mui/material';
import { FC } from 'react';

import CustomButton from 'components/CustomButton';

interface KioskProductActionsProps {
  disabled?: boolean;
  submitLabel?: string;
  cancelLabel?: string;
  onCancel: () => void;
  onSubmit: () => void;
}

const KioskProductActions:FC<KioskProductActionsProps> = ({
  submitLabel = 'Add to Cart',
  cancelLabel = 'Cancel',
  onCancel,
  onSubmit,
  disabled,
}) => (
  <Box display="flex" gap={1} width="100%" mt={3}>
    <CustomButton
      id="button-cancel"
      variant="outlined"
      fullWidth
      onClick={onCancel}
    >
      {cancelLabel}
    </CustomButton>
    <CustomButton
      disabled={disabled}
      id="button-addProduct"
      fullWidth
      onClick={onSubmit}
    >
      {submitLabel}
    </CustomButton>
  </Box>
);

export default KioskProductActions;
