import DownloadIcon from '@mui/icons-material/Download';
import { Box, IconButton, Typography } from '@mui/material';
import { FC, useState } from 'react';

import Attachment from 'components/Attachment';

import { downloadFile } from 'services/DownloadFile';

import { Attachment as AttachmentInterface } from 'types/attachment.interface';
import { formatBytes } from 'utils/attachmentsUtils';

interface MessageAttachmentsProps {
  attachments: { [key: string]: AttachmentInterface };
}

const MessageAttachments:FC<MessageAttachmentsProps> = ({ attachments }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async (attachment: AttachmentInterface) => {
    try {
      setIsDownloading(true);
      await downloadFile(attachment.url, attachment.filename);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    Object.values(attachments).map((att) => (
      <Box key={att.id} mb={2}>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
          <Box width="calc(100% - 30px)" className="attachmentDetails">
            {att.filename && (
              <Typography variant="caption" color="textSecondary" component="div" fontWeight={700} noWrap>
                {att.filename}
              </Typography>
            )}
            {att?.size && (
              <Typography variant="caption" pb={1} color="textSecondary" component="div">
                {formatBytes(att?.size)}
              </Typography>
            )}
          </Box>

          <Box className="downloadButton" width="30px">
            <IconButton
              disabled={isDownloading}
              onClick={() => handleDownload(att)}
              id="iconButton-download"
            >
              <DownloadIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        <Attachment
          key={att.id}
          attachment={att}
          height="120px"
        />
      </Box>
    ))
  );
};

export default MessageAttachments;
