import { Typography } from '@mui/material';

import { StyledSection } from 'containers/CategoryCarousel/ViewAllCard/index.styled';

const ViewAllCard = () => (
  <StyledSection>
    <Typography fontWeight="700" color="primary">
      View All &gt;
    </Typography>
  </StyledSection>
);

export default ViewAllCard;
