import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledChip = styled(
  Chip,
  { shouldForwardProp: (props) => props !== 'kioskMode' },
)<{ kioskMode?: boolean }>(({ theme, kioskMode }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.text.secondary,
  borderColor: theme.palette.border.main,
  fontSize: theme.typography.button.fontSize,
  height: kioskMode ? '35px' : '28px',
  '&:hover': {
    backgroundColor: `${theme.palette.common.white} !important`,
    borderColor: theme.palette.border.main,
  },
}));
