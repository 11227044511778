import { SxProps, useTheme } from '@mui/material';
import { FC, useContext } from 'react';
import Slider from 'react-slick';

import CarouselNextArrow from 'components/CarouselNextArrow';
import CarouselPrevArrow from 'components/CarouselPrevArrow';
import { Root, StyledCardMedia } from 'containers/PromotionBanner/index.styled';
import { getPromotionRoute } from 'containers/PromotionBanner/utils';
import { SystemContext } from 'context/SystemContext';

import { useBannerSearchParams } from 'hooks/useRouting';

import { PromotionActions } from 'constants/enums';
import { Promotion } from 'types/promotion.interface';
import { getBannerSettings } from 'utils/carousel';
import { checkUrl } from 'utils/yupUtils';

interface PromotionBannerProps {
  promotions: Promotion[];
  sx?: SxProps;
}

const PromotionBanner:FC<PromotionBannerProps> = (props) => {
  const { promotions, sx } = props;
  const theme = useTheme();
  const params = useBannerSearchParams();
  const { detailedClientData } = useContext(SystemContext);
  const settings = {
    ...getBannerSettings(theme),
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
  };

  const handleClickPromotion = (promotion: Promotion): void => {
    const { actionUrl, action } = promotion;
    const baseUrl = `${detailedClientData?.baseUrl}${detailedClientData?.basePath}`;

    if (action?.actionType === PromotionActions.OpenUrl) {
      const url = checkUrl(actionUrl, '');
      if (url) {
        window.open(actionUrl, '_blank');
        return;
      }
    }

    const route = getPromotionRoute(promotion, params);
    window.location.href = `${baseUrl || window.location.origin}${route}`;
  };

  return (
    <Root sx={sx}>
      <Slider {...settings}>
        {promotions?.map((promotion, index) => (
          <StyledCardMedia
            key={index}
            component="img"
            src={promotion?.cdnImageUrl || promotion?.imageUrl}
            alt={promotion?.alt || promotion?.imageUrl}
            onClick={() => handleClickPromotion(promotion)}
          />
        ))}
      </Slider>
    </Root>
  );
};

export default PromotionBanner;
