import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { FC, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { StyledBrand } from 'components/Brand/index.styled';

import { getProductsRoute } from 'hooks/useRouting';

import { formatManufacturerForFilter } from 'utils/formatters';

interface BrandProps {
  brand: string;
  variant?: Variant | 'body3';
  disabled?: boolean;
  sx?: { [key: string]: string|number };
}

const Brand:FC<BrandProps> = ({
  brand, variant = 'body1', sx = {}, disabled = false,
}) => {
  const navigate = useNavigate();

  const seeBrandProducts = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    if (!disabled) {
      const link = getProductsRoute(`filter.brand=${formatManufacturerForFilter(brand)}`);
      navigate(link);
    }
  };

  return (
    <Typography color="textSecondary" variant={variant} sx={sx}>
      Brand:
      <StyledBrand component="span" onClick={seeBrandProducts} disabled={disabled} variant={variant}>
        {brand}
      </StyledBrand>
    </Typography>
  );
};

export default Brand;
