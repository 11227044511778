import {
  Grid, Skeleton, Box, useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { StyledSkeleton } from 'containers/ProductDetailsPage/index.styled';

const ProductDetailsSkeleton = () => {
  const theme = useTheme();
  const matchesScreenSizeMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container spacing={2} my={2}>
      <Grid item xs={12} md={6}>
        <StyledSkeleton animation="wave" variant="rounded" width="100%" height={300} />

        <Box display="flex" alignItems="center" height="80px" mt={3} mb={3} justifyContent="center">
          <StyledSkeleton animation="wave" variant="rounded" height={40} width={30} />

          <StyledSkeleton animation="wave" variant="rounded" height={80} width={80} sx={{ ml: 1.5 }} />
          {!matchesScreenSizeMd && (
            <StyledSkeleton animation="wave" variant="rounded" height={80} width={80} sx={{ m: 1.5, mr: 0 }} />
          )}
          <StyledSkeleton animation="wave" variant="rounded" height={80} width={80} sx={{ m: 1.5 }} />
          <StyledSkeleton animation="wave" variant="rounded" height={80} width={80} sx={{ mr: 1.5 }} />

          <StyledSkeleton animation="wave" variant="rounded" height={40} width={30} />
        </Box>

        <Box display="flex" alignItems="center" mb={3} justifyContent="center">
          <StyledSkeleton animation="wave" variant="circular" height={10} width={10} sx={{ mr: 1.5 }} />
          <StyledSkeleton animation="wave" variant="circular" height={10} width={10} />
        </Box>
      </Grid>

      <Grid item xs={12} md={6}>
        <Skeleton animation="wave" variant="rounded" width="100%" height={24} />
        <Skeleton animation="wave" variant="text" width={200} height={24} />
        <Skeleton animation="wave" variant="rounded" width="100%" height={150} sx={{ my: 2 }} />
        <Skeleton animation="wave" variant="text" width={100} height={32} />

        <Box display="flex" alignItems="center">
          <Skeleton animation="wave" variant="rounded" width={50} height={38} sx={{ mr: 1 }} />
          <Skeleton animation="wave" variant="rounded" width={150} height={38} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ProductDetailsSkeleton;
