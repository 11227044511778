import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Box, Divider } from '@mui/material';
import { FC } from 'react';

import CustomButton from 'components/CustomButton';
import Dot from 'components/Dot';
import FreeTag from 'components/FreeTag';
import ProductPrice from 'components/ProductPrice';
import ProductWeight from 'components/ProductWeight';
import TierPrices from 'components/TierPrices';
import PriceVariantsSelector from 'containers/PriceVariantsSelector';
import ProductQuantitySelector from 'containers/ProductDetailsPage/ProductQuantitySelector';
import ProductWeightSelector from 'containers/ProductDetailsPage/ProductWeightSelector';
import TierPriceSelector from 'containers/TierPriceSelector';

import { OnboardingUseType } from 'constants/enums';
import { Bounty } from 'types/bounty.interface';
import { getProductPrice, getTopLevelVariant, isFree } from 'utils/priceUtils';
import { formatProductWeight, isFlwr, shouldUseProductWeight } from 'utils/productUtils';

interface ProductPriceSectionProps {
  bounty: Bounty;
  useType: OnboardingUseType;
}

const ProductPriceSection: FC<ProductPriceSectionProps> = ({
  bounty,
  useType,
}) => {
  const { product } = bounty;
  const { price, msrp } = getProductPrice(product, useType);
  const isProductFree = isFree(price);
  const { weightUnit } = getTopLevelVariant(product) || {};

  if (isFlwr(product?.sortUnit)) {
    return (
      <PriceVariantsSelector bounty={bounty} useType={useType}>
        {({ onAddToCart, isDisabled, label }) => (
          <CustomButton
            id="button-addToCart"
            variant="contained"
            onClick={onAddToCart}
            icon={<AddShoppingCartIcon />}
            disabled={isDisabled}
            sx={{ mt: 2 }}
          >
            {label}
          </CustomButton>
        )}
      </PriceVariantsSelector>
    );
  }

  if (product?.altPrices?.tierPrices?.length > 0) {
    return (
      <Box display="flex" flexDirection="column" gap={2}>
        <TierPrices
          tierPrices={product?.altPrices?.tierPrices}
          useType={useType}
          product={product}
          shouldUseProductWeight={shouldUseProductWeight(product)}
        />
        <TierPriceSelector
          bounty={bounty}
          tierPrices={product?.altPrices?.tierPrices}
          shouldUseProductWeight={shouldUseProductWeight(product)}
          useType={useType}
        >
          {({ onAddToCart, isDisabled = false }) => (
            <CustomButton
              id="button-addToCart"
              variant="contained"
              onClick={onAddToCart}
              icon={<AddShoppingCartIcon />}
              disabled={isDisabled}
              sx={{ mt: 2 }}
            >
              Add to cart
            </CustomButton>
          )}
        </TierPriceSelector>
      </Box>
    );
  }

  return (
    <>
      <Box mb={2} display="flex" alignItems="center" gap={1}>
        {isProductFree
          ? <FreeTag fontWeight={700} variant="body1" />
          : <ProductPrice price={price} msrp={msrp} fontWeight={500} variant="h5" />}
        {product?.sortWeight && (
          <>
            <Dot />
            <ProductWeight
              fontWeight={500}
              variant="h5"
              weight={formatProductWeight(product.sortWeight)}
              weightUnit={weightUnit}
            />
          </>
        )}
      </Box>
      <Divider sx={{ mb: 2 }} />
      {shouldUseProductWeight(bounty?.product)
        ? (
          <ProductWeightSelector isInline bounty={bounty}>
            {({ onAddToCart, isDisabled = false }) => (
              <CustomButton
                id="button-addToCart"
                variant="contained"
                onClick={onAddToCart}
                icon={<AddShoppingCartIcon />}
                disabled={isDisabled}
              >
                Add to cart
              </CustomButton>
            )}
          </ProductWeightSelector>
        )
        : (
          <ProductQuantitySelector isInline bounty={bounty}>
            {({ onAddToCart, isDisabled = false }) => (
              <CustomButton
                id="button-addToCart"
                variant="contained"
                onClick={onAddToCart}
                icon={<AddShoppingCartIcon />}
                disabled={isDisabled}
              >
                Add to cart
              </CustomButton>
            )}
          </ProductQuantitySelector>
        )}
    </>
  );
};

export default ProductPriceSection;
