import { styled } from '@mui/material/styles';

export const Content = styled('div')(({ theme }) => ({
  minHeight: '400px',
  width: '350px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
}));
