import {
  add, format, isAfter, isBefore, isToday,
} from 'date-fns';
import { isNil } from 'lodash';
import * as Yup from 'yup';

import {
  DeliveryMethod, FieldRuleType, OnboardingUseType, PaymentMethod,
} from 'constants/enums';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from 'constants/general';
import { hasOrderDateRestrictions, isPickupMethod } from 'utils/checkoutUtils';
import { checkValidPhone } from 'utils/phoneUtils';
import { isShopCloseByTime, isShopClosed } from 'utils/shopOperation';
import {
  addressRules,
  getCustomerRules,
  getDriverLicenseRules,
  medicalRules,
} from 'validation/genericRules';

const yesterday = new Date(new Date(new Date().setDate(new Date().getDate())).setHours(0, 0, 0, 0));
const minPickupTime = new Date();

const isShopOpen = (value: any, context: any) => {
  const { parent, originalValue } = context;

  return !isShopClosed(originalValue, parent.method);
};

const isShopOpenByHour = (value: any, context: any) => {
  const { pickupDate, pickupTime, method } = context.parent;
  const { disabled, minTime, maxTime } = isShopCloseByTime(pickupDate, method);

  if (disabled) {
    return false;
  }

  //  if we dont have restrictions the shop is open
  if (!minTime || !maxTime) {
    return true;
  }

  const formattedPickTime = new Date(pickupTime);
  const formattedMinTime = new Date(minTime);
  const formattedMaxTime = new Date(maxTime);

  return !(isBefore(formattedPickTime, formattedMinTime) || isAfter(formattedPickTime, formattedMaxTime));
};

export const medicalSchema = Yup.object({
  medical: medicalRules,
});

const defaultPickupDateSchema = Yup
  .date()
  .transform((castValue, originalValue) => (originalValue ? new Date(originalValue) : null))
  .nullable()
  .required('Pickup Date is required')
  .min(yesterday, `Select a date starting with: ${format(yesterday, DEFAULT_DATE_FORMAT)}`)
  .test(
    'isShopOpen',
    'Shop is closed on this day',
    (value, context) => isShopOpen(value, context),
  );

const getPickupFields = (dayCap: number, deliveryMethod: DeliveryMethod, timeReq: boolean) => {
  let pickupDateSchema = defaultPickupDateSchema;

  if (dayCap) {
    pickupDateSchema = defaultPickupDateSchema.max(
      add(new Date(), { days: dayCap }),
      `Select a date before: ${format(add(new Date(), { days: dayCap + 1 }), DEFAULT_DATE_FORMAT)}`,
    );
  }

  const genericPickupTime = Yup
    .date()
    .transform((castValue, originalValue) => (originalValue ? new Date(originalValue) : null))
    .nullable()
    .when('pickupDate', {
      is: (pickupDate: Date) => isToday(pickupDate),
      then: (schema) => (
        schema.min(
          minPickupTime,
          `Select a time starting with: ${format(add(minPickupTime, { minutes: 5 }), DEFAULT_TIME_FORMAT)}`,
        )
      ),
    })
    .test(
      'isShopOpen',
      'Shop is closed at this hour',
      (value, context) => isNil(value) || isShopOpenByHour(value, context),
    );
  const pickupTime = timeReq
    ? { pickupTime: genericPickupTime.required('Pickup Time is required') }
    : { pickupTime: genericPickupTime };

  return {
    pickupDate: pickupDateSchema,
    ...(isPickupMethod(deliveryMethod) ? pickupTime : {}),
  };
};

const checkoutFormSchema = (
  dayCap: number,
  deliveryMethod: DeliveryMethod,
  fieldsRules?: Record<string, FieldRuleType>,
) => Yup.object({
  customer: getCustomerRules(fieldsRules),
  delivery: Yup.object({
    method: Yup
      .string()
      .oneOf(Object.values(DeliveryMethod), 'Delivery Method is required')
      .required('Delivery Method is required'),
    ...(hasOrderDateRestrictions(deliveryMethod)
      ? getPickupFields(dayCap, deliveryMethod, fieldsRules?.pickupTime === FieldRuleType.Required)
      : {}),
  }),
  payment: Yup.object({
    paymentMethod: Yup
      .string()
      .oneOf(Object.values(PaymentMethod), 'Payment method is required')
      .required('Payment is required'),
  }),
  medical: Yup
    .object()
    .when('useType', {
      is: (useType: string) => useType === OnboardingUseType.is_medical_use,
      then: () => medicalRules,
    }),
  address: Yup
    .object()
    .when('delivery', {
      is: ({ method }: any) => method === DeliveryMethod.DELIVERY,
      then: () => addressRules,
    })
    .when(['delivery', 'customer'], {
      is: ({ method }: any, { address: { phoneNumber } }: any) => method === DeliveryMethod.CURBSIDE && !phoneNumber,
      then: () => Yup.object({
        phoneNumber: Yup.string()
          .test('checkValidPhone', () => 'Invalid phone number', checkValidPhone)
          .required('Number is required'),
        countryCode: Yup.string().required('Country code is required'),
      }),
    }),
  driverLicense: getDriverLicenseRules({ fieldsRules }),
});

const kioskCheckoutFormSchema = (fieldsRules?: Record<string, FieldRuleType>) => (
  Yup.object({
    customer: getCustomerRules(fieldsRules),
    driverLicense: getDriverLicenseRules({ fieldsRules }),
  })
);

export const getCheckoutFormSchema = (
  dayCap: number,
  deliveryMethod: DeliveryMethod,
  fieldsRules?: Record<string, FieldRuleType>,
  kioskMode = false,
) => {
  if (kioskMode) {
    return kioskCheckoutFormSchema(fieldsRules);
  }

  return checkoutFormSchema(dayCap, deliveryMethod, fieldsRules);
};
