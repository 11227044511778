import { Avatar, Box, Typography } from '@mui/material';
import { User } from 'firebase/auth';
import { FC, useMemo } from 'react';

import { MessageBox, MessageBubble, Root } from 'containers/SupportChat/Message/index.styled';
import MessageAttachments from 'containers/SupportChat/MessageAttachments';

import { ChatMessage } from 'types/chat.interface';
import { formatAuthorName, formatChatTimestamp } from 'utils/chatUtils';
import { formatUserInitials } from 'utils/formatters';

import SupportChat from 'assets/icons/SupportChat';

interface MessageProps {
  message: ChatMessage;
  currentUser: User;
}

const Message:FC<MessageProps> = ({ currentUser, message }) => {
  const isMine = useMemo(
    () => message.authorId === currentUser.uid,
    [message.authorId, currentUser.uid],
  );
  const authorName = useMemo(() => formatAuthorName(message), [message.id]);
  const date = message.sentAt ? formatChatTimestamp(message.sentAt) : '';
  const attachments = message?.attachments && message?.attachments?.attachments;

  return (
    <Root isMine={isMine}>
      <Box display="flex" alignItems="flex-start" gap={1}>
        {!isMine && <Box width="30px"><SupportChat /></Box>}
        <MessageBox>
          <MessageBubble isMine={isMine}>
            {attachments && <MessageAttachments attachments={attachments} />}
            {message.msg && (
              <Typography variant="body2">
                {message.msg}
              </Typography>
            )}
          </MessageBubble>
          <Typography component="div" variant="caption" color="textSecondary" textAlign={isMine ? 'right' : 'left'}>
            {authorName ? `${authorName}, ${date}` : date}
          </Typography>
        </MessageBox>
        {isMine && (
          <Avatar sx={{ width: 30, height: 30, fontSize: '1rem' }}>
            {currentUser?.displayName ? formatUserInitials(currentUser.displayName) : 'UN'}
          </Avatar>
        )}
      </Box>
    </Root>
  );
};

export default Message;
