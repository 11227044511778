import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CloseDialog = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 8,
  top: 8,
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
  zIndex: 1000,
}));
