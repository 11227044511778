import { Typography, Box } from '@mui/material';
import { User as FbUser } from 'firebase/auth';
import { auth as authUi } from 'firebaseui';
import {
  FC, useContext, useState, useEffect,
} from 'react';
import { toast } from 'react-toastify';

import Loader from 'components/Loader';
import { Content } from 'containers/Login/index.styled';
import Recaptcha from 'containers/Login/Recaptcha';
import StyledFirebaseAuth from 'containers/StyledFirebaseAuth';
import { SystemContext } from 'context/SystemContext';
import { UserContext, UserDispatchContext } from 'context/UserContext';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { updateUserInfo } from 'services/User';

import { auth } from 'config/firebase';
import { getLegalUrls, getLoginMethods } from 'utils/clientUtils';
import { reportAlert } from 'utils/utils';

interface LoginProps {
  onSuccess: (user?: FbUser) => void;
  onFail: () => void;
}

const Login:FC<LoginProps> = ({ onSuccess, onFail }) => {
  const { user } = useContext(UserContext);
  const { setIsUpdating, refreshPaymentAccount } = useContext(UserDispatchContext);

  const { clientInfo } = useContext(SystemContext);
  const { handleTrackSignUp } = useGoogleAnalytics();
  const [isRecaptchaSolved, setIsRecaptchaSolved] = useState<boolean>(false);
  const [showRecaptcha, setShowRecaptcha] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const updateUser = async (fbUser: FbUser) => {
    try {
      setIsUpdating(true);
      const payload = {
        userId: fbUser.uid,
        name: fbUser.displayName,
        email: fbUser.email,
        anonUserId: user?.isAnonymous ? user.uid : null,
        shopId: clientInfo.companyId,
        skipEmailValidation: isRecaptchaSolved,
      };
      await updateUserInfo(payload);
      refreshPaymentAccount();

      onSuccess(user);
      setIsUpdating(false);
    } catch (e) {
      await auth.signOut();
      setShowRecaptcha(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => () => {
    setIsUpdating(false);
  }, []);

  const firebaseUiWidget = {
    signInFlow: 'popup',
    ...getLegalUrls(),
    signInOptions: getLoginMethods(),
    callbacks: {
      signInSuccessWithAuthResult: ({ user }: { user: FbUser}) => {
        handleTrackSignUp();
        setIsLoading(true);
        updateUser(user);
        return false;
      },
      signInFailure: (error: authUi.AuthUIError) => {
        if (error.message) {
          toast.error(error.message);
        }

        reportAlert(`login failed: ${JSON.stringify(error)}`);
        Promise.resolve();
        onFail();
      },
    },
  };

  return (
    <Content>
      <Recaptcha
        setIsRecaptchaSolved={setIsRecaptchaSolved}
        isRecaptchaSolved={isRecaptchaSolved}
        showRecaptcha={showRecaptcha}
        setShowRecaptcha={setShowRecaptcha}
      />
      {!showRecaptcha && !isLoading && (
        <>
          <Box maxWidth="360px">
            <Typography fontSize="12px" lineHeight="16px" px={3} color="textSecondary">
              If you don&#39;t have an existing account, enter your email to create a new account.
            </Typography>
          </Box>
          <StyledFirebaseAuth
            uiConfig={firebaseUiWidget}
            firebaseAuth={auth}
          />
        </>
      )}
      {isLoading && <Loader />}
    </Content>
  );
};

export default Login;
