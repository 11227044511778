import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  height: '300px',
  [theme.breakpoints.down('md')]: {
    height: '200px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '150px',
  },
})) as typeof Skeleton;
