import CardMediaMui from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';

export const Content = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.subheaderBackground.main,
  height: 'calc(100vh - 64px)',
}));

export const CardMedia = styled(CardMediaMui)(({ theme }) => ({
  backgroundColor: theme.palette.subheaderBackground.main,
  height: 'calc(100vh - 96px)',
  width: '100%',
  objectFit: 'cover',
  borderRadius: '8px',
})) as typeof CardMediaMui;
