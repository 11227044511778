import MuiMenu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

export const Menu = styled(MuiMenu)(() => ({
  '& .MuiPaper-root': {
    minWidth: '170px',
  },
}));

export const LinkBox = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));
