import CardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

export const StyledSection = styled('div')<{ kioskMode: boolean }>(({ theme, kioskMode }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '4px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all .15s ease-in-out',
  backgroundColor: theme.palette.common.white,

  '&:hover': {
    transform: 'scale(1.015)',
    backgroundColor: kioskMode ? theme.palette.common.white : theme.palette.background.secondary,
  },
}));

export const StyledImage = styled(CardMedia)<{ kioskMode?: boolean }>(({ kioskMode }) => ({
  maxHeight: kioskMode ? '300px' : '150px',
  maxWidth: '100%',
  objectFit: 'contain',
})) as typeof CardMedia | FC<{ kioskMode?: boolean }>;

export const StyledPriceBox = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 'auto',
}));

export const StyledSkeletonSection = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.border.main}`,
  backgroundColor: theme.palette.common.white,
  borderRadius: '4px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all .15s ease-in-out',
}));
