import { FC, SyntheticEvent } from 'react';

import FilterGroup from 'components/Filters/FilterGroup';

import { FilterType } from 'constants/enums';
import { Filter } from 'types/filter.interface';

interface FiltersProps {
  activeFilters: { [key: string]: string[] };
  filters: Filter[];
  onSelectFilter: (filterId: string, filterType: FilterType) => (e: SyntheticEvent, checked: boolean) => void;
  isLoading: boolean;
}

const Filters:FC<FiltersProps> = ({
  filters,
  onSelectFilter,
  activeFilters,
  isLoading,
}) => (
  <div>
    {filters.map((filter) => (
      <FilterGroup
        isLoading={isLoading}
        key={filter.id}
        activeFilters={activeFilters[filter.id]}
        filter={filter}
        onSelectFilter={onSelectFilter}
      />
    ))}
  </div>
);

export default Filters;
