import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import * as React from 'react';

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border.main}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&.MuiAccordion-root.Mui-expanded': {
    marginBottom: theme.spacing(1),
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  [theme.breakpoints.up('md')]: {
    paddingLeft: 0,
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '&:hover': {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: '4px',
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  paddingLeft: theme.spacing(4),
  '& > .MuiFormGroup-root > *': {
    '&:hover': {
      backgroundColor: theme.palette.background.secondary,
      borderRadius: '4px',
    },
  },
}));

export const FormControlLabel = styled(
  MuiFormControlLabel,
  { shouldForwardProp: (props) => props !== 'kioskMode' },
)<{ kioskMode?: boolean }>(({ theme, kioskMode }) => ({
  '&.MuiFormControlLabel-root': {
    alignItems: 'flex-start',
  },
  '& .MuiFormControlLabel-label': {
    paddingTop: kioskMode ? '12px' : '9px',
    color: theme.palette.text.primary,
  },
}));
