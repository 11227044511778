import { isNil } from 'lodash';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import * as Routes from 'constants/clientRoutes';
import { STORE_ROUTE } from 'constants/clientRoutes';
import { ShopPages } from 'constants/enums';
import { formatRoute } from 'utils/formatters';
import { getShopConfiguration } from 'utils/storeUtils';

const extractSearchParams = (searchParams: URLSearchParams) => {
  const shopConfiguration = getShopConfiguration();
  return ({
    client: shopConfiguration?.client || searchParams.get('client') || undefined, // This is used only for testing envs
    location: shopConfiguration?.location || searchParams.get('location') || undefined,
    page: searchParams.get('page') || undefined,
    product: searchParams.get('product') || undefined,
    order: searchParams.get('order') || undefined,
  });
};

export const useNavigationSearchParams = () => {
  const [searchParams] = useSearchParams();
  return extractSearchParams(searchParams);
};

//  in compact mode we don't use client and location as query params if we have this info on shop configuration
export const hasClientParams = () => {
  const { client, location } = getShopConfiguration();
  return !(client || location);
};

export const getNavigationSearchParams = () => {
  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);
  return extractSearchParams(searchParams);
};

export const getShopId = () => {
  const shopConfiguration = getShopConfiguration();
  const { client, location } = getNavigationSearchParams();
  return shopConfiguration?.location || shopConfiguration?.client || location || client || undefined;
};

export const isRestrictedToOneLocation = () => {
  const shopConfiguration = getShopConfiguration();
  return Boolean(shopConfiguration?.location);
};

export const isCompactMode = (): boolean => {
  const shopConfiguration = getShopConfiguration();

  //  TODO: remove this if shopConfiguration works
  if (window.location.pathname.startsWith(STORE_ROUTE)) {
    return true;
  }

  return !isNil(shopConfiguration?.useDynamicRoutes) ? !shopConfiguration.useDynamicRoutes : false;
};

export const getClientParams = (): string[] => {
  const shopConfiguration = getShopConfiguration();

  const { client, location } = getNavigationSearchParams();
  const queryParams = [];

  if (client && !shopConfiguration?.client) {
    queryParams.push(`client=${client}`);
  }

  if (location && !shopConfiguration?.location) {
    queryParams.push(`location=${location}`);
  }

  return queryParams;
};

export const getRouteParams = ({ page, details, search }: { page: string; details?: string; search?: string }) => {
  const queryParams = [
    ...getClientParams(),
    `page=${page}`,
  ];

  if (details) {
    queryParams.push(details);
  }

  if (search) {
    queryParams.push(search);
  }

  return queryParams;
};

export const getHomeRoute = (search?: string) => {
  if (isCompactMode()) {
    const queryParams = getClientParams();

    if (search) {
      queryParams.push(search);
    }

    return `?${queryParams.join('&')}`;
  }

  return search ? `${Routes.HOME_ROUTE}?${search}` : Routes.HOME_ROUTE;
};

export const getProductDetailsRoute = (productId: string) => {
  if (isCompactMode()) {
    const queryParams = getRouteParams({ page: ShopPages.Products, details: `product=${productId}` });
    return `?${queryParams.join('&')}`;
  }

  return formatRoute(Routes.PRODUCT_DETAILS_ROUTE, { productId });
};

export const getProductsRoute = (search?: string) => {
  if (isCompactMode()) {
    const queryParams = getRouteParams({ page: ShopPages.Products, search });
    return `?${queryParams.join('&')}`;
  }

  return search ? `${Routes.PRODUCTS_ROUTE}?${search}` : Routes.PRODUCTS_ROUTE;
};

export const getCartRoute = (search?: string) => {
  if (isCompactMode()) {
    const queryParams = getRouteParams({ page: ShopPages.Cart, search });
    return `?${queryParams.join('&')}`;
  }

  return search ? `${Routes.CART_ROUTE}?${search}` : Routes.CART_ROUTE;
};

export const getOrderRoute = () => {
  if (isCompactMode()) {
    const queryParams = getRouteParams({ page: ShopPages.Orders });
    return `?${queryParams.join('&')}`;
  }

  return Routes.ORDERS_ROUTE;
};

export const getOrderDetailsRoute = (orderId: string) => {
  if (isCompactMode()) {
    const queryParams = getRouteParams({ page: ShopPages.Orders, details: `order=${orderId}` });
    return `?${queryParams.join('&')}`;
  }

  return formatRoute(Routes.ORDER_DETAILS_ROUTE, { orderId });
};

export const getDigitalReceiptRoute = (orderId: string) => {
  if (isCompactMode()) {
    const queryParams = getRouteParams({ page: ShopPages.DigitalReceipt, details: `order=${orderId}` });
    return `?${queryParams.join('&')}`;
  }

  return formatRoute(Routes.ORDER_DIGITAL_RECEIPT_ROUTE, { orderId });
};

export const getCheckoutRoute = () => {
  if (isCompactMode()) {
    const queryParams = getRouteParams({ page: ShopPages.Checkout });
    return `?${queryParams.join('&')}`;
  }

  return Routes.CHECKOUT_ROUTE;
};

export const getPaymentWidgetRoute = (orderId: string, search: string) => {
  if (isCompactMode()) {
    const queryParams = getRouteParams({ page: ShopPages.CheckoutPayment, details: `order=${orderId}`, search });
    return `?${queryParams.join('&')}`;
  }

  const route = formatRoute(Routes.CHECKOUT_PAYMENT_WIDGET_ROUTE, { orderId });
  return `${route}?${search}`;
};

export const getCheckoutCompleteRoute = (orderId: string, search: string) => {
  if (isCompactMode()) {
    const queryParams = getRouteParams({ page: ShopPages.CheckoutComplete, details: `order=${orderId}`, search });
    return `?${queryParams.join('&')}`;
  }

  const route = formatRoute(Routes.CHECKOUT_COMPLETE_ROUTE, { orderId });
  return `${route}?${search}`;
};

export const useProductId = () => {
  const { product } = useNavigationSearchParams();
  const { productId } = useParams();
  return productId || product;
};

export const useOrderId = () => {
  const { order } = useNavigationSearchParams();
  const { orderId } = useParams();
  return orderId || order;
};

export const useBannerSearchParams = () => {
  const { pathname } = useLocation();
  const params = useParams();

  if (pathname === STORE_ROUTE) {
    return getNavigationSearchParams();
  }

  return params;
};
