import { onAuthStateChanged } from 'firebase/auth';
import 'firebaseui/dist/firebaseui.css';
import { auth } from 'firebaseui';
import {
  FC, useEffect, useRef, useState,
} from 'react';

import { StyledBox } from 'containers/StyledFirebaseAuth/index.styled';

interface StyledFirebaseAuthProps {
  uiConfig: auth.Config;
  firebaseAuth: any; // Firebase App auth instance
  uiCallback?: (ui: auth.AuthUI) => void;
}

const StyledFirebaseAuth:FC<StyledFirebaseAuthProps> = ({ uiConfig, firebaseAuth, uiCallback }) => {
  const [userSignedIn, setUserSignedIn] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    if (!auth) return;
    // Get or Create a firebaseUI instance.
    const firebaseUiWidget = auth.AuthUI.getInstance() || new auth.AuthUI(firebaseAuth);

    if (uiConfig.signInFlow === 'popup') {
      firebaseUiWidget.reset();
    }

    // We track the auth state to reset firebaseUi if the user signs out.
    const unregisterAuthObserver = onAuthStateChanged(firebaseAuth, (user: any) => {
      if (!user && userSignedIn) {
        firebaseUiWidget.reset();
      }

      setUserSignedIn(!!user);
    });

    // Trigger the callback if any was set.
    if (uiCallback) {
      uiCallback(firebaseUiWidget);
    }

    // Render the firebaseUi Widget.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    firebaseUiWidget.start(elementRef.current, uiConfig);

    // eslint-disable-next-line consistent-return
    return () => {
      unregisterAuthObserver();
      firebaseUiWidget.reset();
    };
  }, [uiConfig]);

  return (
    <StyledBox
      ref={elementRef}
    />
  );
};

export default StyledFirebaseAuth;
