import { useContext, useEffect, useState } from 'react';

import Dashboard from 'containers/Dashboard';
import { LocationContext } from 'context/LocationContext';

import { getPromotions } from 'services/Client';

import { BannerType } from 'constants/enums';
import { Promotion } from 'types/promotion.interface';

const DashboardPage = () => {
  const { onboardingInfo } = useContext(LocationContext);
  const { storeId } = onboardingInfo;
  const [promotions, setPromotions] = useState<Promotion[]>([]);

  useEffect(() => {
    if (storeId) {
      fetchPromotions();
    }
  }, [storeId]);

  const fetchPromotions = async () => {
    try {
      const { data } = await getPromotions(BannerType.Hero, { locationPin: storeId });
      setPromotions(data || []);
    } catch (e) {
      setPromotions([]);
    }
  };

  return <Dashboard promotions={promotions} />;
};

export default DashboardPage;
