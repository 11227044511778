import {
  Container, Grid, Box, useTheme, Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Suspense } from 'react';
import { useSearchParams } from 'react-router-dom';
import Slider from 'react-slick';

import BreadcrumbsNavigation from 'components/BreadcrumbsNavigation';
import CarouselNextArrow from 'components/CarouselNextArrow';
import CarouselPrevArrow from 'components/CarouselPrevArrow';
import CustomSelector from 'components/CustomSelector';
import Filters from 'components/Filters';
import FiltersMobile from 'components/FiltersMobile';
import ProductCard from 'components/ProductCard';
import ProductCardSkeleton from 'components/ProductCard/index.skeleton';
import SectionTitle from 'components/SectionTitle';
import SubheaderWrapper from 'components/SubheaderWrapper';
import PreviewLayout from 'layouts/PreviewLayout';

import { LandingPageType } from 'constants/enums';
import {
  MOCK_BREADCRUMB,
  MOCK_CATEGORIES,
  MOCK_PREVIEW_PRODUCTS,
  MOCK_PRODUCT_FILTER_1,
} from 'constants/mockData';
import { getCategoriesCarouselSettings } from 'utils/carousel';
import { formatCategoryOptions } from 'utils/formatters';

export default function PreviewStorePage() {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const landingPage = searchParams.get('landingPage');
  const isDashboard = LandingPageType.Default === landingPage;
  const matchesScreenSizeMd = useMediaQuery(theme.breakpoints.down('md'));
  const options = formatCategoryOptions([]);
  const settings = {
    ...getCategoriesCarouselSettings(theme),
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleSelectFilter = () => (): void => {};

  return (
    <PreviewLayout hideFooter hideNavbarActions>
      <div>
        <SubheaderWrapper>
          <Box display="flex" gap={1} alignItems="center">
            {!isDashboard && (
              <>
                <BreadcrumbsNavigation breadcrumbs={[MOCK_BREADCRUMB]} />
                <Typography variant="subheader" fontWeight={600}>
                  /
                </Typography>
              </>
            )}
            <CustomSelector
              variant="outlined"
              value={isDashboard ? '' : 'all'}
              name="category"
              options={options}
              displayEmpty={isDashboard}
            />
          </Box>
        </SubheaderWrapper>

        <Container>
          {isDashboard ? (
            MOCK_CATEGORIES.map((category) => (
              <Box mb={10} key={category.name}>
                <SectionTitle title={category.name} variant="h5" sx={{ mb: 2 }} />
                <Slider {...settings}>
                  {MOCK_PREVIEW_PRODUCTS.map((bounty) => (
                    <ProductCard
                      key={`${category.name}-${bounty.id}`}
                      bounty={bounty}
                      hasAddButton={false}
                      isBrandDisabled
                      isDescriptionVisible
                    />
                  ))}
                </Slider>
              </Box>
            ))
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                {matchesScreenSizeMd ? (
                  <FiltersMobile
                    isLoading={false}
                    filters={MOCK_PRODUCT_FILTER_1}
                    activeFilters={{}}
                    onSelectFilter={handleSelectFilter}
                  />
                ) : (
                  <Filters
                    isLoading={false}
                    filters={MOCK_PRODUCT_FILTER_1}
                    activeFilters={{}}
                    onSelectFilter={handleSelectFilter}
                  />
                )}
              </Grid>
              <Grid item container xs={12} md={isDashboard ? 12 : 9} spacing={2}>
                {MOCK_PREVIEW_PRODUCTS.map((bounty) => (
                  <Grid key={bounty.id} item xs={12} sm={6} md={6} lg={4}>
                    <Suspense fallback={<ProductCardSkeleton />}>
                      <ProductCard
                        bounty={bounty}
                        hasAddButton={false}
                        isBrandDisabled
                        isDescriptionVisible
                      />
                    </Suspense>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Container>
      </div>
    </PreviewLayout>
  );
}
