import { Box } from '@mui/material';
import { FC, ReactElement, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import Footer from 'components/_Footer';
import Navbar from 'components/_Navbar';
import SupportChat from 'containers/SupportChat';
import { SystemContext } from 'context/SystemContext';

import { useKiosk } from 'hooks/useKiosk';

import { CHAT_RESTRICTED_ROUTES } from 'constants/clientRoutes';
import { ClientInfoInterface, DetailedClientInfo } from 'types/clientInfo.interface';
import { getShopConfiguration } from 'utils/storeUtils';

import { ContentWrapper } from './index.styled';

const { asFullPage, hideHeader } = getShopConfiguration();

interface ShopProps {
  children: ReactElement;
  hasStores?: boolean;
  clientInfo?: ClientInfoInterface | null;
  detailedClientData?: DetailedClientInfo | null;
  hideFooter?: boolean;
  isClosed?: boolean;
  hideNavbarActions?: boolean;
}

const Shop:FC<ShopProps> = ({
  clientInfo = null,
  detailedClientData = null,
  children,
  hasStores,
  hideFooter = false,
  isClosed = false,
  hideNavbarActions = false,
}) => {
  const location = useLocation();
  const { kioskMode } = useKiosk();
  const { layoutType } = useContext(SystemContext);
  const isChatRestrictedRoute = CHAT_RESTRICTED_ROUTES.includes(location.pathname);
  const hideChat = isChatRestrictedRoute || detailedClientData?.kioskMode || !asFullPage;

  const getPt = () => {
    if (kioskMode) return 12;
    if (asFullPage) return 16;
    return 0;
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      {!hideHeader && (
        <Navbar
          hasShoppingCart={!isClosed}
          hasUser={!isClosed}
          clientInfo={clientInfo}
          hasStores={hasStores}
          hideActions={hideNavbarActions}
        />
      )}
      <ContentWrapper pt={getPt()} pb={hideFooter ? 0 : 4} layoutType={layoutType}>
        {children}
      </ContentWrapper>
      {!hideChat && <SupportChat />}
      {!hideFooter && <Footer />}
    </Box>
  );
};

export default Shop;
