import {
  Fragment,
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import Dashboard from 'containers/Dashboard';
import ProductsProviderPage from 'containers/ProductsProviderPage';
import { LocationContext } from 'context/LocationContext';
import { SystemContext } from 'context/SystemContext';

import useFilters from 'hooks/useFilters';
import { useKiosk } from 'hooks/useKiosk';
import { getHomeRoute } from 'hooks/useRouting';
import { getPromotions } from 'services/Client';

import { BannerType, LandingPageType, SEOType } from 'constants/enums';
import { Promotion } from 'types/promotion.interface';
import { getFormattedSeoInfo } from 'utils/seoUtils';

const LandingPage = () => {
  const navigate = useNavigate();
  const { shopSettings, clientInfo } = useContext(SystemContext);
  const { onboardingInfo } = useContext(LocationContext);
  const { storeId } = onboardingInfo;
  const { kioskMode } = useKiosk();
  const { activeFilters, isLoading: areFiltersLoading } = useFilters();
  const defaultPromotions = {
    [BannerType.Hero]: [],
    [BannerType.Recommendations]: [],
    [BannerType.Highlights]: [],
  };
  const [promotions, setPromotions] = useState<Record<BannerType, Promotion[]>>(defaultPromotions);

  const landingPage = shopSettings?.landingPage || LandingPageType.Default;
  const isDashboard = LandingPageType.Default === landingPage;

  useEffect(() => {
    if (!isDashboard && !areFiltersLoading) {
      let search = 'category=all';
      Object.keys(activeFilters).forEach((key) => { search += `&filter.${key}=${activeFilters[key]}`; });

      if (Object.keys(activeFilters)?.length > 0) {
        navigate(getHomeRoute(search));
      }
    }
  }, [isDashboard, areFiltersLoading, activeFilters]);

  useEffect(() => {
    if (storeId && !kioskMode) {
      fetchPromotions();
    }
  }, [storeId, kioskMode]);

  const fetchPromotions = async () => {
    try {
      const [heroes, highlights, recommendations] = await Promise.all([
        getPromotions(BannerType.Hero, { locationPin: storeId }),
        getPromotions(BannerType.Highlights, { locationPin: storeId }),
        getPromotions(BannerType.Recommendations, { locationPin: storeId }),
      ]);
      setPromotions({
        [BannerType.Hero]: heroes.data || [],
        [BannerType.Highlights]: highlights.data || [],
        [BannerType.Recommendations]: recommendations.data || [],
      });
    } catch (e) {
      setPromotions(defaultPromotions);
    }
  };

  const seo = useMemo(() => (
    getFormattedSeoInfo({ seo: shopSettings?.seo?.[SEOType.Landing], clientInfo, onboardingInfo })
  ), [shopSettings?.seo?.[SEOType.Landing], clientInfo, onboardingInfo]);

  const defaultTitle = useMemo(() => (
    !kioskMode && isDashboard ? 'Dashboard' : 'Products'
  ), [kioskMode, isDashboard]);

  return (
    <>
      <Helmet>
        <title>{seo?.title || defaultTitle}</title>
        <meta property="og:title" content={seo?.title || defaultTitle} />
        <meta name="twitter:title" content={seo?.title || defaultTitle} />
        <meta name="description" content={seo?.description} />
        <meta property="og:description" content={seo?.description} />
        <meta name="twitter:description" content={seo?.description} />
      </Helmet>
      {!kioskMode && isDashboard
        ? <Dashboard promotions={promotions?.[BannerType.Hero]} />
        : <ProductsProviderPage promotions={promotions} />}
    </>
  );
};

export default LandingPage;
