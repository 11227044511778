import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { KIOSK_HEADER_HEIGHT } from 'constants/general';

export const StyledHeader = styled('div')(({ theme }) => ({
  height: `${KIOSK_HEADER_HEIGHT}px`,
  top: 0,
  backgroundColor: theme.palette.headerBackground.main,
  position: 'fixed',
  zIndex: theme.zIndex.drawer - 2,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(0, 3, 0, 3),
}));

export const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexGrow: 1,
  gap: theme.spacing(2),
}));

export const Title = styled(Typography)(() => ({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  textAlign: 'center',
  fontWeight: 700,
}));
