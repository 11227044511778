import {
  ChangeEvent, FC, FormEvent, SyntheticEvent, useContext,
} from 'react';

import { SystemContext } from 'context/SystemContext';

import { useKiosk } from 'hooks/useKiosk';

import { CancelIcon, SearchIcon, SearchInput } from './index.styled';

interface SearchFormProps {
  search?: string;
  isFocused: boolean;
  onSearch: (e: SyntheticEvent | FormEvent<HTMLFormElement>) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  onClear: () => void;
}

const SearchForm: FC<SearchFormProps> = ({
  search = '',
  isFocused,
  onSearch,
  onChange,
  onBlur,
  onClear,
}) => {
  const { clientInfo } = useContext(SystemContext);
  const { kioskMode } = useKiosk();

  return (
    <form onSubmit={onSearch}>
      <SearchInput
        kioskMode={kioskMode}
        name="search-products"
        size="small"
        fullWidth
        autoFocus={isFocused}
        placeholder={`Search in ${clientInfo?.name || ''}...`}
        onChange={onChange}
        value={search}
        onBlur={onBlur}
        slotProps={{
          input: {
            endAdornment: search ? <CancelIcon kioskMode={kioskMode} onClick={onClear} /> : null,
            startAdornment: <SearchIcon kioskMode={kioskMode} onClick={onSearch} sx={{ mr: 1 }} />,
          },
        }}
        className="searchInput"
      />
      <input type="submit" hidden />
    </form>
  );
};

export default SearchForm;
