import { GoogleFields } from 'constants/enums';
import {
  ADDRESS_LINE1,
  CITY,
  CUSTOMER_ADDRESS_LINE1,
  CUSTOMER_CITY,
  CUSTOMER_STATE,
  CUSTOMER_ZIP_CODE,
  TEMP_STATE,
  ZIP_CODE,
} from 'constants/fields';

export const CUSTOMER_ADDRESS = {
  [GoogleFields.LINE_1]: CUSTOMER_ADDRESS_LINE1.name,
  [GoogleFields.CITY]: CUSTOMER_CITY.name,
  [GoogleFields.ZIP_CODE]: CUSTOMER_ZIP_CODE.name,
  [GoogleFields.STATE]: CUSTOMER_STATE.name,
};

export const ORDER_ADDRESS = {
  [GoogleFields.LINE_1]: ADDRESS_LINE1.name,
  [GoogleFields.CITY]: CITY.name,
  [GoogleFields.ZIP_CODE]: ZIP_CODE.name,
  [GoogleFields.STATE]: TEMP_STATE.name,
};
