import { GoogleFormattedAddress } from 'types/address.interface';

const useGooglePlaceAutoComplete = () => {
  const initGooglePlacesAutoComplete = async (input: any, callback: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const autoComplete = new window.google.maps.places.Autocomplete(
      input,
      {
        componentRestrictions: {
          country: ['us'],
        },
        fields: ['address_component'],
        types: ['address'],
      },
    );
    autoComplete.addListener('place_changed', callback);

    return autoComplete;
  };

  const getFormattedAddress = async (autoComplete: any): Promise<GoogleFormattedAddress> => {
    const place = autoComplete.getPlace();
    const { address_components: addressComponents } = place || {};

    let line1 = '';
    let postalCode = '';
    let tempState = '';
    let city = '';

    addressComponents?.forEach((component: any) => {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          line1 = `${component.long_name} ${line1}`;
          break;
        }

        case 'route': {
          line1 += component.long_name;
          break;
        }

        case 'postal_code': {
          postalCode = component.long_name;
          break;
        }

        case 'administrative_area_level_1': {
          tempState = component.short_name;
          break;
        }

        case 'locality':
          city = component.long_name;
          break;
        default:
          break;
      }
    });

    return {
      line1,
      postalCode,
      tempState,
      city,
    };
  };

  return {
    initGooglePlacesAutoComplete,
    getFormattedAddress,
  };
};

export default useGooglePlaceAutoComplete;
