import flavourConfig from 'config';
import { getClientId, getStoreId } from 'utils/storageUtils';
import { getEnv } from 'utils/utils';

export function getUserAgent() {
  const env = getEnv();
  return `${flavourConfig.id}/release/web/${env}/0 ${navigator.userAgent}`;
}

export function getHeaders(enforceClient = false) {
  const clientId = getClientId();
  const storeId = getStoreId();

  const headers: any = {};

  if (storeId && !enforceClient) {
    headers['X-edge-client-id'] = storeId;
  } else if (clientId) {
    headers['X-edge-client-id'] = clientId;
  }

  return headers;
}

export function getBaseUrl() {
  let baseUrl = `${flavourConfig.API_BASE_URL}`;

  if (!baseUrl) {
    baseUrl = `https://api.${flavourConfig.appTld}`;
  }

  return baseUrl;
}
