import { TerminalPosition } from 'constants/enums';

export const rotationDegreesByTerminalPosition = {
  [TerminalPosition.Left]: -135,
  [TerminalPosition.UpLeft]: -90,
  [TerminalPosition.Up]: -45,
  [TerminalPosition.UpRight]: 0,
  [TerminalPosition.Right]: 45,
  [TerminalPosition.DownRight]: 95,
  [TerminalPosition.Down]: 135,
  [TerminalPosition.DownLeft]: 180,
};
