import { format } from 'date-fns';
import { isEqual, isNull } from 'lodash';
import { useContext, useEffect, useState } from 'react';

import { LocationContext } from 'context/LocationContext';
import { SystemContext } from 'context/SystemContext';
import { UserContext } from 'context/UserContext';

import { useDebounce } from 'hooks/useDebounce';
import { getPickupOrdersLimit } from 'services/Client';

import { DeliveryMethod, FieldRuleType, PhoneStatus } from 'constants/enums';
import { DEFAULT_STATE_CODE, PICKUP_DATE_FORMAT, US_COUNTRY_CODE } from 'constants/general';
import { OrderDateRestrictions, PlaceOrderInfo } from 'types/checkout.interface';
import { hasOrderDateRestrictions, isPickupMethod } from 'utils/checkoutUtils';
import { handleApiErrors } from 'utils/errorUtils';
import { splitDisplayName } from 'utils/formatters';
import { getFirstValidHour } from 'utils/shopOperation';
import { getCheckoutDetails, getClientId, getStoreId } from 'utils/storageUtils';
import { getStorePaymentMethods } from 'utils/storeUtils';

const initCheckoutForm = {
  useType: '',
  customer: {
    firstName: '',
    lastName: '',
    email: '',
    dob: null,
    address: {
      line1: '',
      postalCode: '',
      state: '',
      city: '',
      phoneNumber: '',
      countryCode: US_COUNTRY_CODE,
      phoneStatus: PhoneStatus.UNVERIFIED,
    },
  },
  delivery: {
    method: DeliveryMethod.PICKUP,
    pickupDate: null,
    pickupTime: null,
    note: '',
  },
  payment: {
    paymentMethod: '',
  },
  medical: {
    medicalId: '',
    medicalIdExp: null,
  },
  address: {
    line1: '',
    postalCode: '',
    state: '',
    city: '',
    tempState: '',
    phoneNumber: '',
    countryCode: US_COUNTRY_CODE,
    phoneStatus: PhoneStatus.UNVERIFIED,
  },
  driverLicense: {
    countryCode: US_COUNTRY_CODE,
    state: '',
    number: '',
    expDate: null,
  },
};

const useCheckoutState = () => {
  const { user, paymentAccount } = useContext(UserContext);
  const { onboardingInfo } = useContext(LocationContext);
  const { shopSettings } = useContext(SystemContext);

  const [isLoadingDateRestrictions, setIsLoadingDateRestrictions] = useState(false);
  const [checkoutForm, setCheckoutForm] = useState<PlaceOrderInfo>(initCheckoutForm);
  const [orderDateRestrictions, setOrderDateRestrictions] = useState<OrderDateRestrictions | null>(null);
  const debounceDelivery = useDebounce(
    [checkoutForm.delivery.method, checkoutForm.delivery.pickupDate],
    250,
  );

  useEffect(() => {
    if (onboardingInfo.state) {
      const {
        useType,
        state,
        zipcode,
        medical,
        deliveryMethod,
      } = onboardingInfo;

      const storedInfo = getCheckoutDetails();
      const checkoutDetails = storedInfo || initCheckoutForm; // TODO: maybe is better to merge this two?
      const { firstName = '', lastName = '' } = user?.displayName ? splitDisplayName(user.displayName) : {};

      const allowedPaymentMethods = getStorePaymentMethods({
        storedDeliveryMethod: deliveryMethod || DeliveryMethod.PICKUP,
      });

      const initialState = {
        ...checkoutDetails,
        payment: {
          paymentMethod: allowedPaymentMethods?.length === 1
            ? allowedPaymentMethods[0]
            : (checkoutDetails?.payment?.paymentMethod || ''),
        },
        delivery: {
          method: deliveryMethod || DeliveryMethod.PICKUP,
          pickupDate: new Date(),
          pickupTime: null,
          note: '',
        },
        customer: {
          ...checkoutDetails?.customer,
          firstName: paymentAccount?.firstName || firstName || checkoutDetails?.customer?.firstName,
          lastName: paymentAccount?.lastName || lastName || checkoutDetails?.customer?.lastName,
          email: paymentAccount?.email || user?.email || checkoutDetails?.customer?.email || '',
          dob: paymentAccount?.birthday ? new Date(paymentAccount.birthday) : null,
          address: {
            ...formatPhone(checkoutDetails),
            state: checkoutDetails?.customer?.address?.state || paymentAccount?.state || state || DEFAULT_STATE_CODE,
            line1: checkoutDetails?.customer?.address?.line1 || '',
            city: checkoutDetails?.customer?.address?.city || '',
            postalCode: checkoutDetails?.customer?.address?.postalCode || zipcode || '',
          },
        },
        address: {
          line1: checkoutDetails.address?.line1 || '',
          city: checkoutDetails.address?.city || '',
          postalCode: checkoutDetails?.address?.postalCode || zipcode || '',
          state: state || '',
          tempState: checkoutDetails.address?.tempState || '',
          phoneNumber: checkoutDetails.address?.phoneNumber || '',
          countryCode: checkoutDetails.address?.countryCode || US_COUNTRY_CODE,
          phoneStatus: checkoutDetails.address?.phoneStatus || PhoneStatus.UNVERIFIED,
        },
        medical: {
          medicalId: medical?.medicalId || '',
          medicalIdExp: medical?.medicalIdExp ? new Date(medical.medicalIdExp) : null,
        },
        useType,
        driverLicense: {
          countryCode: US_COUNTRY_CODE,
          state: paymentAccount?.driverLicenseState
            || state
            || DEFAULT_STATE_CODE,
          number: paymentAccount?.driverLicenseNumber || checkoutDetails?.driverLicense?.number || '',
          expDate: paymentAccount?.driverLicenseExpirationDate
            || checkoutDetails?.driverLicense?.expDate || null,
        },
      };

      if (!isEqual(checkoutForm, initialState)) {
        setCheckoutForm(initialState);
      }
    }
  }, [user.uid, onboardingInfo.state]);

  useEffect(() => {
    const { method, pickupDate } = checkoutForm.delivery || {};
    const hasTimeField = shopSettings?.checkoutFields?.pickupTime !== FieldRuleType.Hidden;

    if (hasOrderDateRestrictions(method) && pickupDate && hasTimeField) {
      const date = new Date(pickupDate).getTime();

      fetchDateRestrictions(date, method);
    }
  }, [debounceDelivery, shopSettings?.checkoutFields?.pickupTime]);

  const fetchDateRestrictions = async (pickupDate: number, method: DeliveryMethod) => {
    setIsLoadingDateRestrictions(true);

    try {
      const clientId = getClientId();
      const storeId = getStoreId();
      const id = storeId || clientId;

      if (id) {
        const response = await getPickupOrdersLimit(id, format(pickupDate, PICKUP_DATE_FORMAT), method);
        const limit = response.data?.[0];
        setOrderDateRestrictions(limit);
        const isTimeRequired = shopSettings?.checkoutFields?.pickupTime === FieldRuleType.Required;

        if (limit?.hourlyOrders?.length > 0 && pickupDate && isPickupMethod(method) && isTimeRequired) {
          const availablePickupTime = getFirstValidHour(new Date(pickupDate), limit.hourlyOrders, limit.pickupLimit);

          setCheckoutForm((prevState) => ({
            ...prevState,
            delivery: {
              ...prevState.delivery,
              pickupTime: !isNull(availablePickupTime) ? new Date(availablePickupTime) : null,
            },
          }));
        }
      }
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsLoadingDateRestrictions(false);
    }
  };

  const formatPhone = (checkoutDetails: PlaceOrderInfo | null) => {
    if (paymentAccount?.phone) {
      return {
        phoneNumber: paymentAccount?.phone || '',
        countryCode: paymentAccount?.countryCode || US_COUNTRY_CODE,
      };
    }

    return {
      phoneNumber: checkoutDetails?.customer?.address?.phoneNumber || '',
      countryCode: checkoutDetails?.customer?.address?.countryCode || US_COUNTRY_CODE,
    };
  };

  return {
    checkoutForm, setCheckoutForm, orderDateRestrictions, isLoadingDateRestrictions,
  };
};

export default useCheckoutState;
