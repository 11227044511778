import { Breadcrumbs, Typography } from '@mui/material';
import { FC } from 'react';

import { LinkName } from 'components/BreadcrumbsNavigation/index.styled';
import UnstyledLink from 'components/UnstyledLink';

import { Breadcrumb } from 'types/breadcrumbs.interface';

interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
}

const BreadcrumbsNavigation :FC<BreadcrumbsProps> = (props) => {
  const { breadcrumbs } = props;

  const renderBreadcrumb = (breadcrumb: Breadcrumb) => (
    <div key={breadcrumb?.name}>
      {breadcrumb?.link ? (
        <UnstyledLink to={breadcrumb?.link}>
          <LinkName variant="subheader" fontWeight={600}>
            {breadcrumb?.label}
          </LinkName>
        </UnstyledLink>
      ) : (
        <Typography variant="subheader">{breadcrumb?.label}</Typography>
      )}
    </div>
  );

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {breadcrumbs.map((breadcrumb) => renderBreadcrumb(breadcrumb))}
    </Breadcrumbs>
  );
};

export default BreadcrumbsNavigation;
