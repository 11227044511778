import MuiBadge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

export const Badge = styled(MuiBadge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    border: `2px solid ${theme.palette.common.white}`,
    width: '10px',
    height: '10px',
    borderRadius: '50%',
  },
}));
