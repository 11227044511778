import imageCompression, { Options } from 'browser-image-compression';
import { isEmpty } from 'lodash';

import {
  AttachmentMeaning, AttachmentType, AttachmentVisibility, DisplayStyle,
} from 'constants/enums';
import {
  Attachment, CompressedAttachment, Cover, MimeType,
} from 'types/attachment.interface';
import { Bounty } from 'types/bounty.interface';
import { ImageCdn } from 'types/product.interface';

export const getAttachmentsUrls = (attachments = {} as { [key: string]: Attachment }) => {
  if (!isEmpty(attachments)) {
    return Object.values(attachments).map((attachment) => attachment.cdnUrl || attachment.url);
  }

  return [];
};

/**
 * Pictures array might or might not contain main picture url.
 */
export const filterProductPicture = (bounty: Bounty) => {
  const { product, attachments } = bounty;
  const bountyAttachments = attachments?.attachments || {};
  const picturesWithoutMain = Object
    .values(bountyAttachments)
    .filter((attachment) => attachment.url !== product?.imageUrl)
    .map((attachment) => attachment.cdnUrl || attachment.url);
  const mainPictureUrl = product?.imageCdn?.imageUrl || product?.imageUrl;

  const allPictures = mainPictureUrl ? [mainPictureUrl, ...picturesWithoutMain] : [];

  return {
    picturesWithoutMain,
    allPictures,
  };
};

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['bytes', 'kb', 'mb', 'gb'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export function addNewFile(data: File) {
  if (!data) {
    return null;
  }

  const type = getAttachmentType(data.type);

  return {
    mimeType: data.type as MimeType,
    filename: data.name,
    size: data.size,
    type,
    data,
  };
}

export function getAttachmentType(mimeType: string): AttachmentType {
  const [baseType] = mimeType.split('/');

  if (mimeType === 'application/pdf') {
    return AttachmentType.PDF;
  }

  switch (baseType) {
    case 'image':
      return AttachmentType.IMAGE;
    case 'audio':
      return AttachmentType.AUDIO;
    case 'video':
      return AttachmentType.VIDEO;
    default:
      return AttachmentType.OTHER;
  }
}

export const transformImageBlobToFile = async (blobFile: File, options: Options) => {
  const blob = await imageCompression(blobFile, options);
  const fileOptions = { lastModified: blob?.lastModified, type: blob?.type };

  return new File([blob], blob?.name || 'name', fileOptions);
};

export const convertBytesToMb = (bytes: number) => bytes / 1000000;

export const getRandomInt = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1) + min) + 2;

export const getNextAttachmentId = (ids: string[]): string => {
  let attachId = null;

  while (!attachId) {
    const nr = getRandomInt(0, 1000);
    const key = `a${nr}`;

    if (!ids.includes(key)) {
      attachId = key;
    }
  }

  return attachId;
};

export const valueOfUploadFile = (attach: Attachment): CompressedAttachment => {
  const { url, data } = attach;

  return {
    ...attach,
    fileUri: url || null,
    fileContent: attach.type === AttachmentType.IMAGE ? data : null,
  };
};

export const formatAttachment = (attachment: Attachment, attachmentId: string, order: number) => ({
  id: attachmentId,
  url: attachment?.url?.toString() || null,
  type: attachment.type,
  filename: attachment.filename,
  size: attachment.size,
  mimeType: attachment.mimeType,
  meaning: attachment.meaning || AttachmentMeaning.OTHER,
  order: attachment?.order || getAttachmentNextOrder(order),
  visibility: AttachmentVisibility.ALL,
  displayStyle: DisplayStyle.INLINE,
  actionUrl: attachment?.actionUrl || null,
  altText: attachment?.altText || null,
});

export function getAttachmentNextOrder(order: number) {
  const newOrder = `000${order}`;
  return newOrder.substring(newOrder.length - 4);
}

export const attachmentsAsObject = (attachments: Attachment[]): { [key: string]: Attachment } => (
  attachments.reduce(
    (prev: { [key: string]: Attachment }, current: Attachment) => ({ ...prev, [current.id]: current }),
    {},
  )
);

export const getProductCover = (imageUrl: string, w: number, h: number, imageCdn?: ImageCdn) => {
  const attachment = {
    coverList: imageCdn?.coverList,
    url: imageCdn?.imageUrl || imageUrl,
  };
  return getCover(attachment, w, h);
};

export const getCover = (attachment: Partial<Attachment>, w: number, h: number) => {
  const { cdnUrl, url, coverList } = attachment;

  if (!coverList || !coverList.length) {
    return {
      cdnUrl,
      url,
    };
  }

  if (coverList.length === 1) {
    return coverList[0];
  }

  const score = (cw: number, ch: number) => {
    let s = 0;

    if (w !== 0) {
      if (cw !== null && cw !== 0) {
        s += Math.abs(w - cw);
      }
    }

    if (h !== 0) {
      if (ch !== null && ch !== 0) {
        s += Math.abs(h - ch);
      }
    }

    return s;
  };

  const compare = (o1: Cover, o2: Cover) => {
    if (w === 0 && h === 0) {
      return 0;
    }
    return score(o1.w, o1.h) - score(o2.w, o2.h);
  };

  const list = coverList.sort(compare);

  return list[0];
};
