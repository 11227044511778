import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Box, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomButton from 'components/CustomButton';
import { PaymentDispatchContext } from 'context/PaymentContext';

import { getHomeRoute } from 'hooks/useRouting';

interface EmptyPreviewProps {
  hasButton: boolean;
  fontSize: 'small' | 'inherit' | 'large' | 'medium' | undefined;
  variant: Variant;
  shouldResetPayment?: boolean;
}

const EmptyPreviewCart :FC<EmptyPreviewProps> = ({
  hasButton,
  fontSize,
  variant,
  shouldResetPayment,
}) => {
  const navigate = useNavigate();
  const { resetPaymentFlow } = useContext(PaymentDispatchContext);

  const handleAddItems = () => {
    navigate(getHomeRoute());

    if (shouldResetPayment) {
      resetPaymentFlow();
    }
  };

  return (
    <Box display="flex" alignItems="center" flexDirection="column" p={2}>
      <Typography variant={variant} sx={{ mt: 2 }} color="textSecondary" textAlign="center">
        <ShoppingCartOutlinedIcon fontSize={fontSize} />
        <br />
        Your cart is empty.
      </Typography>
      {hasButton && (
        <CustomButton
          id="button-addItems"
          variant="contained"
          onClick={handleAddItems}
          sx={{ mt: 2 }}
        >
          Add items
        </CustomButton>
      )}
    </Box>
  );
};

export default EmptyPreviewCart;
