import { Dialog, Typography } from '@mui/material';
import {
  useState, useEffect, useContext, useMemo,
} from 'react';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';

import WarningInactivityMessage from 'containers/KioskInactivityChecker/WarningInactivityMessage';
import KioskUseTypePicker from 'containers/Onboarding/KioskUseTypePicker';
import { CartDispatchContext } from 'context/CartContext';
import { SystemContext, SystemDispatchContext } from 'context/SystemContext';

import { HOME_ROUTE } from 'constants/clientRoutes';
import { BackgroundArea } from 'constants/enums';
import { INACTIVE_USER_TIMESTAMP, WARNING_INACTIVE_USER_TIMESTAMP } from 'constants/general';
import { getCompanyLocationsData } from 'utils/storageUtils';

import {
  Paper, PlayerWrapper, WarningPaper, StartOrderDialog,
} from './index.styled';

const KioskInactivityChecker = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    isInactive: false,
    showWarning: false,
    isOpen: false,
    isWarningOpen: false,
    isTypePickerOpen: false,
  });

  const { showStartNewOrder } = useContext(SystemContext);
  const { removeItems } = useContext(CartDispatchContext);
  const { setShowStartNewOrder } = useContext(SystemDispatchContext);
  const { clientInfo, shopSettings, detailedClientData } = useContext(SystemContext);
  const { list } = getCompanyLocationsData() || {};
  const url = shopSettings?.backgrounds?.[BackgroundArea.LandingPage]?.urls?.[BackgroundArea?.LandingPage]?.url;
  const kioskSettings = detailedClientData?.kioskId
    ? shopSettings?.kioskSettings?.[detailedClientData?.kioskId]
    : null;

  const store = useMemo(() => (
    list?.find(({ id }) => id === clientInfo?.id)
  ), [clientInfo, list]);

  useEffect(() => {
    let inactivityTimeout: ReturnType<typeof setTimeout> | null = null;
    let delayTimeout: ReturnType<typeof setTimeout> | null = null;
    let warningTimeout: ReturnType<typeof setTimeout> | null = null;

    const resetInactivityTimer = () => {
      if (inactivityTimeout) {
        clearTimeout(inactivityTimeout);
      }

      inactivityTimeout = setTimeout(() => {
        setState((prevState) => ({ ...prevState, isInactive: true }));
      }, INACTIVE_USER_TIMESTAMP);
    };

    const resetWarningTimer = () => {
      if (warningTimeout) {
        clearTimeout(warningTimeout);
      }

      warningTimeout = setTimeout(() => {
        setState((prevState) => ({ ...prevState, showWarning: true }));
      }, WARNING_INACTIVE_USER_TIMESTAMP);
    };

    const handleUserActivity = () => {
      delayTimeout = setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          isInactive: false,
          showWarning: false,
          isWarningOpen: false,
        }));
        resetInactivityTimer();
        resetWarningTimer();
      }, 300);
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('touchstart', handleUserActivity);
    resetInactivityTimer();
    resetWarningTimer();

    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('touchstart', handleUserActivity);

      if (inactivityTimeout) {
        clearTimeout(inactivityTimeout);
      }

      if (delayTimeout) {
        clearTimeout(delayTimeout);
      }
    };
  }, []);

  useEffect(() => {
    if (showStartNewOrder) {
      setState((prevState) => ({ ...prevState, isOpen: true }));
    }
  }, [showStartNewOrder]);

  useEffect(() => {
    if (state.isInactive && state.isWarningOpen) {
      setState((prevState) => ({
        ...prevState,
        isOpen: true,
        isWarningOpen: false,
        isTypePickerOpen: false,
      }));
      navigate(`${HOME_ROUTE}?category=all`, { replace: true });
    }
  }, [state.isInactive, state.isWarningOpen]);

  useEffect(() => {
    if (state.showWarning && !state.isOpen) {
      setState((prevState) => ({ ...prevState, isWarningOpen: true }));
    }
  }, [state.showWarning, state.isOpen]);

  const handleStartNewOrder = () => {
    setState((prevState) => ({
      ...prevState,
      isOpen: false,
      isInactive: false,
      showWarning: false,
      isWarningOpen: false,
    }));
    setShowStartNewOrder(false);
    removeItems();

    if (store && store?.adultUse && store?.medicalUse) {
      setState((prevState) => ({ ...prevState, isTypePickerOpen: true }));
    }
  };

  const handleCloseUseTypePicker = () => setState((prevState) => ({ ...prevState, isTypePickerOpen: false }));

  const handleCloseDialog = () => setState((prevState) => ({ ...prevState, isOpen: false }));

  return (
    <div>
      {state.isOpen && (
        <>
          {url && (
            <PlayerWrapper>
              <ReactPlayer
                loop
                playing
                controls={false}
                width="100%"
                height="100vh"
                muted
                url={url}
              />
            </PlayerWrapper>
          )}
          <StartOrderDialog
            open={state.isOpen}
            aria-describedby="start-new-order"
            startBtnPosition={kioskSettings?.startBtnPosition}
            PaperComponent={Paper}
            PaperProps={{ onClick: handleStartNewOrder }}
          >
            <Typography variant="h3" fontWeight={700}>Start New Order</Typography>
          </StartOrderDialog>
        </>
      )}
      {state.isWarningOpen && !showStartNewOrder && (
        <Dialog
          open={state.isWarningOpen}
          aria-describedby="warning-start-new-order"
          sx={{ background: '#000000B3 0% 0% no-repeat padding-box;' }}
          PaperComponent={WarningPaper}
          onClose={handleCloseDialog}
        >
          <WarningInactivityMessage />
        </Dialog>
      )}
      {state.isTypePickerOpen && (
        <Dialog
          open={state.isTypePickerOpen}
          aria-describedby="use-type-picker"
          PaperProps={{ sx: { width: '480px', borderRadius: '16px', py: 3 } }}
        >
          <KioskUseTypePicker onClose={handleCloseUseTypePicker} />
        </Dialog>
      )}
    </div>
  );
};

export default KioskInactivityChecker;
