import { FC, useState } from 'react';

import PicturesCarousel from 'components/PicturePreview';

interface PreviewProductPicturesProps {
  pictures: string[];
  open: boolean;
  setOpen(open: boolean): void;
  visiblePictureIndex: number;
}

const PreviewProductPictures: FC<PreviewProductPicturesProps> = (props) => {
  const {
    pictures, open, setOpen, visiblePictureIndex,
  } = props;
  const [currentImgIndex, setCurrentImgIndex] = useState(visiblePictureIndex);
  const numberOfPictures = pictures?.length || 0;

  const isLastPicture = numberOfPictures > 0
    ? currentImgIndex === numberOfPictures - 1
    : true;

  const handleClosePreview = () => {
    setOpen(false);
    setCurrentImgIndex(0);
  };

  const handleNext = () => {
    if (currentImgIndex < pictures.length - 1) {
      setCurrentImgIndex(currentImgIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentImgIndex > 0) {
      setCurrentImgIndex(currentImgIndex - 1);
    }
  };

  return (
    <PicturesCarousel
      open={open}
      onClose={handleClosePreview}
      pictureUrl={pictures[currentImgIndex]}
      onNext={handleNext}
      onPrev={handlePrev}
      isFirstPicture={currentImgIndex === 0}
      isLastPicture={isLastPicture}
    />
  );
};

export default PreviewProductPictures;
