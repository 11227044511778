import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, useContext, useMemo } from 'react';

import ProductMedicalBalanceInfo from 'components/ProductMedicalBalanceInfo';
import CartProduct from 'containers/CartProduct';
import { CartContext } from 'context/CartContext';

import { ProductInfo } from 'types/cart.interface';

interface ProductsProps {
  updatedProducts: Record<string, ProductInfo>;
  adjustedProducts: string[];
  setAdjustedProducts: (products: string[]) => void;
  summaryFailedDetails: { hasFailed: boolean; productSkus: string[] };
}
const Products:FC<ProductsProps> = ({
  updatedProducts,
  summaryFailedDetails,
  adjustedProducts,
  setAdjustedProducts,
}) => {
  const cart = useContext(CartContext);
  const products = useMemo(() => Object.values(cart?.products) || [], [cart?.products]);

  const handleChangeQuantity = (productId: string) => {
    if (updatedProducts?.[productId] && !adjustedProducts.includes(productId)) {
      setAdjustedProducts([...adjustedProducts, productId]);
    }
  };

  return (
    products?.map((product, index) => {
      const wasAdjusted = adjustedProducts?.includes(product.id);
      const isProductUpdated = !isEmpty(updatedProducts?.[product?.id] || {});
      const newRawQuantity = updatedProducts?.[product?.id]?.quantity || 0;
      const updatedQuantity = !Number.isNaN(newRawQuantity) ? Math.max(0, newRawQuantity) : 0;
      const { balances = {} } = product;
      const showErrorMessage = summaryFailedDetails?.productSkus?.length
        ? summaryFailedDetails?.productSkus?.some((sku) => sku === product?.productSku)
        : false;

      return (
        <Box mb={index + 1 !== products?.length ? 1.5 : 0} key={product?.id}>
          <CartProduct
            product={product}
            isProductUpdated={isProductUpdated}
            updatedQuantity={updatedQuantity}
            handleChange={handleChangeQuantity}
            wasAdjusted={wasAdjusted}
            showErrorMessage={showErrorMessage}
          />

          {balances && Object.values(balances).map((balance) => (
            <ProductMedicalBalanceInfo balance={balance} key={balance?.key} />
          ))}
        </Box>
      );
    })
  );
};

export default Products;
