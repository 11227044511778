import { OrderStatus } from 'constants/enums';

export const ORDER_STATUS = {
  [OrderStatus.NONE]: 'None',
  [OrderStatus.NEW]: 'New',
  [OrderStatus.PROPOSED]: 'Proposed',
  [OrderStatus.PAYED]: 'Payed',
  [OrderStatus.PENDING]: 'Pending',
  [OrderStatus.PROCESSING]: 'Processing',
  [OrderStatus.READY_FOR_PICKUP]: 'Ready for pickup',
  [OrderStatus.SHIPPED]: 'Shiped',
  [OrderStatus.MERCH_RECEIVED]: 'Merch received',
  [OrderStatus.SETTLED]: 'Settled',
  [OrderStatus.CHARGEBACK]: 'Chargeback',
  [OrderStatus.DISPUTED]: 'Disputed',
  [OrderStatus.PAYMENT_FAILED]: 'Payment failed',
  [OrderStatus.NEGOTIATION]: 'Negotiation',
  [OrderStatus.DECLINED]: 'Declined',
  [OrderStatus.CANCELLED]: 'Cancelled',
  [OrderStatus.UNKNOWN]: 'Unknown',
};
