import MuiSearchIcon from '@mui/icons-material/Search';
import {
  Badge, Dialog, DialogActions, useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  ChangeEvent, FC, FormEvent, SyntheticEvent, useEffect, useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import CustomButton from 'components/CustomButton';
import SearchForm from 'containers/SearchProducts/SearchForm';

import { useKiosk } from 'hooks/useKiosk';
import { getProductsRoute } from 'hooks/useRouting';

import {
  StyledButton, StyledShoppingCart, DialogContent,
} from './index.styled';

interface SearchProductsProps {
  searchValue?: string;
}

const SearchProducts: FC<SearchProductsProps> = ({ searchValue = '' }) => {
  const { kioskMode } = useKiosk();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(false);
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(kioskMode);
  const categoryCode = searchParams?.get('category') || 'all';
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const useAsPopup = Boolean(isMobile);

  const handleClose = () => {
    setIsOpen(false);
    setSearch('');
  };

  useEffect(() => {
    setSearch(searchValue);

    if (!kioskMode) {
      setIsOpen(!!searchValue);
    }
  }, [searchValue]);

  const handleOpen = () => {
    setIsFocused(true);
    setIsOpen(true);
  };

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
  };

  const handleSearch = (e: SyntheticEvent | FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (useAsPopup) {
      handleClose();
    }

    const searchParam = search
      ? `&filter.q=${encodeURIComponent(search)}`
      : '';
    const route = getProductsRoute(`category=all${searchParam}`);
    navigate(route);
  };

  const handleClearSearch = () => {
    setSearch('');

    if (!useAsPopup) {
      navigate(getProductsRoute(`category=${categoryCode}`));

      if (!kioskMode) {
        setIsOpen(false);
      }
    }
  };

  const handleBlur = () => {
    if (!kioskMode && !useAsPopup && !search) {
      setIsOpen(false);
    }
  };

  return (
    <div>
      {!isOpen && (
        <StyledButton
          disableRipple
          aria-label="cart"
        >
          <StyledShoppingCart kioskMode={kioskMode} onClick={handleOpen}>
            <Badge color="primary">
              <MuiSearchIcon />
            </Badge>
          </StyledShoppingCart>
        </StyledButton>
      )}

      {isOpen && (
        useAsPopup ? (
          <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-describedby="search-products"
            disableRestoreFocus
            PaperProps={{
              sx: {
                borderRadius: 4, pt: 3, pb: 4, px: 2,
              },
            }}
          >
            <DialogContent>
              <SearchForm
                search={search}
                isFocused={isFocused}
                onSearch={handleSearch}
                onChange={handleChange}
                onBlur={handleBlur}
                onClear={handleClearSearch}
              />
            </DialogContent>

            <DialogActions sx={{ p: '0 24px' }}>
              <CustomButton id="button-cancelSearch" variant="outlined" onClick={handleClose}>
                Cancel
              </CustomButton>
              <CustomButton id="button-submitSearch" variant="contained" onClick={handleSearch}>
                Search
              </CustomButton>
            </DialogActions>
          </Dialog>
        ) : (
          <SearchForm
            search={search}
            isFocused={isFocused}
            onSearch={handleSearch}
            onChange={handleChange}
            onBlur={handleBlur}
            onClear={handleClearSearch}
          />
        )
      )}
    </div>
  );
};

export default SearchProducts;
