import { Grid2 as Grid, Typography } from '@mui/material';
import {
  ChangeEvent, FC, FormEvent, useState,
} from 'react';

import { BoxWrapper, GridInput } from 'components/Coupon/index.styled';
import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';

interface CouponProps {
  onChangeSummary: (coupon: string) => void;
  kioskMode?: boolean;
}

const Coupon: FC<CouponProps> = ({ onChangeSummary, kioskMode }) => {
  const [coupon, setCoupon] = useState('');
  const handleChanges = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setCoupon(value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (coupon) {
      onChangeSummary(coupon);
    }
  };

  return (
    <BoxWrapper className="coupon">
      <Typography fontWeight={700} mb={1}>
        Do you have a Promo Code?
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <GridInput size={[9, 9, kioskMode ? 9 : 12, 9]}>
            <CustomInput
              name="coupon"
              size="small"
              fullWidth
              placeholder="Type Promo Code…"
              onChange={handleChanges}
              value={coupon}
              // error={!!get(fieldErrors, LAST_NAME.name)}
              // helperText={get(fieldErrors, LAST_NAME.name)}
            />
          </GridInput>
          <Grid size={[3, 3, kioskMode ? 3 : 12, 3]}>
            <CustomButton
              id="button-applyCoupon"
              variant="contained"
              fullWidth
              type="submit"
            >
              Apply
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    </BoxWrapper>
  );
};

export default Coupon;
