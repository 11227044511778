import { Box } from '@mui/material';
import { FC, useContext } from 'react';

import CartTotal from 'components/CartTotal';
import Coupon from 'components/Coupon';
import CustomButton from 'components/CustomButton';
import FailedSummaryMessage from 'components/FailedSummaryMessage';
import OrderDisclaimerMessage from 'components/OrderDisclaimerMessage';
import { StyledSummary, StyledSummaryPrice } from 'containers/CartListingPage/Summary/index.styled';
import MedicalBalance from 'containers/MedicalBalance';
import { SystemContext } from 'context/SystemContext';

import { CartPriceDetails } from 'types/cart.interface';

interface SummaryProps {
  onSubmit: () => void;
  isSummaryLoading: boolean;
  hasSummaryFailed: boolean;
  disabled?: boolean;
  showMedicalBalance: boolean;
  cartPriceDetails: CartPriceDetails;
  onChangeSummary: (coupon?: string) => void;
}

const Summary:FC<SummaryProps> = ({
  onSubmit,
  isSummaryLoading,
  hasSummaryFailed,
  cartPriceDetails,
  disabled,
  onChangeSummary,
  showMedicalBalance,
}) => {
  const { shopSettings } = useContext(SystemContext);

  return (
    <StyledSummary>
      <StyledSummaryPrice>
        <CartTotal
          cartPriceDetails={cartPriceDetails}
          isLoading={isSummaryLoading}
          hasFailed={hasSummaryFailed}
        />
        <CustomButton
          id="button-goToCheckout"
          variant="contained"
          fullWidth
          sx={{ mt: 3 }}
          onClick={onSubmit}
          disabled={disabled}
          isLoading={isSummaryLoading}
        >
          Go to checkout
        </CustomButton>

        {shopSettings?.orderDisclaimerMessage && (
          <OrderDisclaimerMessage message={shopSettings?.orderDisclaimerMessage} />
        )}
      </StyledSummaryPrice>

      <Box mt={5}>
        <Coupon onChangeSummary={onChangeSummary} />
      </Box>

      {hasSummaryFailed && <FailedSummaryMessage sx={{ mt: 5 }} />}

      {showMedicalBalance && (
        <Box mt={5}>
          <MedicalBalance
            balances={cartPriceDetails.balances}
            checkSummary={onChangeSummary}
          />
        </Box>
      )}
    </StyledSummary>
  );
};

export default Summary;
