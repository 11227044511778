import { useEffect, useState } from 'react';

export function useDebounce(values: unknown[], delay:number) {
  const [debouncedValue, setDebouncedValue] = useState(values);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(values);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    [...values, delay],
  );

  return debouncedValue;
}
