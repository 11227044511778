import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FreeText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.success.main,
  display: 'inline-block',
  padding: theme.spacing(0.5, 2.5),
  borderRadius: '20px',
}));
