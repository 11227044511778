import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.general.alleavesBackground,
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export const Content = styled('div')(({ theme }) => ({
  width: '40%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}));
