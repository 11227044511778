import { Point, Reward } from 'types/money.interface';
import { multiplier, normalizeCurrency, pointCurrencyAsEnum } from 'utils/currencyUtils';

export function normalizePoints(point: Point) {
  const { amount, currency } = point;
  const pointCurrency = pointCurrencyAsEnum(currency);
  const norm = normalizeCurrency(pointCurrency);

  if (pointCurrency === norm) {
    return point;
  }

  return {
    currency: norm,
    amount: amount * multiplier(currency),
  };
}

export function sumOfPointsAbs(points: { [key: string]: Point }): number {
  if (!points || (points && !Object.keys(points).length)) {
    return 0;
  }

  return Object
    .values(points)
    .reduce((acc: number, { amount }: Point) => {
      acc += Math.abs(amount);
      return acc;
    }, 0);
}

export function getNormalizedPoints(points: { [key: string]: Point }, currency: string) {
  if (!points || (points && !Object.keys(points).length)) {
    return 0;
  }

  return Object
    .values(points)
    .reduce((acc, point) => {
      const normPoint = normalizePoints(point);

      if (pointCurrencyAsEnum(normPoint.currency) === currency) {
        acc += normPoint.amount;
      }
      return acc;
    }, 0);
}

export function getPointsAmount(reward: Reward, currency: string) {
  if (!reward) {
    return 0;
  }

  if (!reward?.points || !Object.keys(reward?.points).length) {
    return 0;
  }

  return reward?.points[currency]?.amount || 0;
}
