import { Box, Grid2 as Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import CheckoutFooter from 'components/_CheckoutFooter';
import BackButton from 'components/BackButton';
import CustomContent from 'components/CustomContent';
import EmptyPreviewCart from 'components/EmptyPreviewCart';
import OrderWarningMessage from 'components/OrderWarningMessage';
import SectionTitle from 'components/SectionTitle';
import SubheaderWrapper from 'components/SubheaderWrapper';
import { Root } from 'containers/CartListingPage/index.styled';
import Products from 'containers/CartListingPage/Products';
import Summary from 'containers/CartListingPage/Summary';
import { CartContext } from 'context/CartContext';
import { SystemContext } from 'context/SystemContext';
import { UserContext } from 'context/UserContext';

import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useKiosk } from 'hooks/useKiosk';
import { getCheckoutRoute, getHomeRoute } from 'hooks/useRouting';
import useSummary from 'hooks/useSummary';

import { reportAlert } from 'utils/utils';

const CartListing = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { shopSettings } = useContext(SystemContext);
  const cart = useContext(CartContext);
  const { paymentAccount } = useContext(UserContext);
  const [summaryFailedDetails, setSummaryFailedDetails] = useState<{ hasFailed: boolean; productSkus: string[] }>({
    hasFailed: false,
    productSkus: [],
  });
  const {
    checkSummary,
    setIsDisabled,
    formattedCartDetails,
    isDisabled,
    updatedProducts,
    cartPriceDetails,
    isLoading: isSummaryLoading,
  } = useSummary();
  const { handleTrackViewCart } = useGoogleAnalytics();
  const { kioskMode } = useKiosk();

  const [adjustedProducts, setAdjustedProducts] = useState([] as string[]);

  const isFromCheckout = search.includes('checkout');
  const products = useMemo(() => Object.values(cart?.products) || [], [cart?.products]);
  const showMedicalBalance = Boolean(!summaryFailedDetails?.hasFailed && !isSummaryLoading
    && (!paymentAccount?.alleavesCustomerId || formattedCartDetails.balances));

  useDocumentTitle({ title: 'Cart summary' });

  useEffect(() => {
    if (!isEmpty(cart?.products)) {
      verifySummary();
    }
  }, [cart?.products]);

  const verifySummary = async () => {
    try {
      await checkSummary();
      setSummaryFailedDetails({
        hasFailed: false,
        productSkus: [],
      });
    } catch (e: any) {
      setSummaryFailedDetails({
        hasFailed: true,
        productSkus: e?.data?.productSkus || [],
      });
      reportAlert(`summary failed: ${JSON.stringify(e)}`);
    }
  };

  useEffect(() => {
    if (!isEmpty(cartPriceDetails)) {
      handleTrackViewCart(cartPriceDetails, products);
    }
  }, [cartPriceDetails]);

  useEffect(() => {
    if (adjustedProducts.length > 0 && adjustedProducts.length === Object.keys(updatedProducts).length) {
      setIsDisabled(false);
    }
  }, [adjustedProducts]);

  const handleRedirectCheckout = () => {
    navigate(getCheckoutRoute());
  };

  if (kioskMode) {
    return (
      <Root>
        {products?.length > 0 ? (
          <>
            <CustomContent kioskMode sx={{ flexGrow: 1 }} className="hidden-scroll">
              <Products
                updatedProducts={updatedProducts}
                adjustedProducts={adjustedProducts}
                setAdjustedProducts={setAdjustedProducts}
                summaryFailedDetails={summaryFailedDetails}
              />
              {shopSettings?.orderWarningMessage && (
                <Box mt={2.5}>
                  <OrderWarningMessage message={shopSettings?.orderWarningMessage} />
                </Box>
              )}
            </CustomContent>
            <CheckoutFooter
              hasBack
              onSubmit={handleRedirectCheckout}
              disabled={isDisabled || summaryFailedDetails?.hasFailed}
              isSummaryLoading={isSummaryLoading}
              hasSummaryFailed={summaryFailedDetails?.hasFailed}
              cartPriceDetails={formattedCartDetails}
              onChangeSummary={checkSummary}
              continueLabel="Go to Checkout"
            />
          </>
        ) : (
          <Box mt={12}>
            <EmptyPreviewCart hasButton fontSize="large" variant="h6" />
          </Box>
        )}
      </Root>
    );
  }

  return (
    <Box height="100%">
      <SubheaderWrapper>
        <Box display="flex" alignItems="center">
          <BackButton href={isFromCheckout ? getHomeRoute() : ''} label="Back" />
          <SectionTitle variant="subheader" title="Your cart" sx={{ ml: 2.5 }} />
        </Box>
      </SubheaderWrapper>

      <CustomContent>
        {products?.length > 0
          ? (
            <Grid container spacing={5} alignItems="stretch">
              <Grid size={{ xs: 12, md: 7.5, lg: 8 }}>
                <Products
                  updatedProducts={updatedProducts}
                  adjustedProducts={adjustedProducts}
                  setAdjustedProducts={setAdjustedProducts}
                  summaryFailedDetails={summaryFailedDetails}
                />
                {shopSettings?.orderWarningMessage && (
                  <Box mt={2.5}>
                    <OrderWarningMessage message={shopSettings?.orderWarningMessage} />
                  </Box>
                )}
              </Grid>

              <Grid size={{ xs: 12, md: 4.5, lg: 4 }}>
                <Summary
                  onSubmit={handleRedirectCheckout}
                  disabled={isDisabled || summaryFailedDetails?.hasFailed}
                  isSummaryLoading={isSummaryLoading}
                  hasSummaryFailed={summaryFailedDetails?.hasFailed}
                  cartPriceDetails={formattedCartDetails}
                  onChangeSummary={checkSummary}
                  showMedicalBalance={showMedicalBalance}
                />
              </Grid>
            </Grid>
          ) : (
            <Box mt={12}>
              <EmptyPreviewCart hasButton fontSize="large" variant="h6" />
            </Box>
          )}
      </CustomContent>
    </Box>
  );
};

export default CartListing;
