import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  useContext, useState, useEffect, useMemo, FC,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import KioskProducts from 'containers/ProductsProviderPage/KioskProducts';
import Products from 'containers/ProductsProviderPage/Products';
import { CategoryContext } from 'context/CategoryContext';
import { LocationContext } from 'context/LocationContext';
import { SystemContext } from 'context/SystemContext';

import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useKiosk } from 'hooks/useKiosk';
import { getProducts } from 'services/Product';

import { BannerType, ProductSortOptions } from 'constants/enums';
import {
  DEFAULT_MOBILE_PRODUCTS_SKELETONS,
  DEFAULT_PAGINATION_LIMIT,
  DEFAULT_PRODUCTS_SKELETONS,
} from 'constants/general';
import { Bounty } from 'types/bounty.interface';
import { Promotion } from 'types/promotion.interface';
import { getCategoryLeaf } from 'utils/categoriesUtils';
import { handleApiErrors } from 'utils/errorUtils';
import { formatSearchParams } from 'utils/formatters';
import { generateEmptyArray } from 'utils/productUtils';

interface ProductsProviderPageProps {
  promotions?: Record<BannerType, Promotion[]>;
}
const ProductsProviderPage: FC<ProductsProviderPageProps> = ({ promotions }) => {
  const theme = useTheme();
  const { kioskMode } = useKiosk();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const { categories } = useContext(CategoryContext);
  const { onboardingInfo } = useContext(LocationContext);
  const [searchParams] = useSearchParams();
  const { shopSettings } = useContext(SystemContext);

  const { storeId, useType } = onboardingInfo;

  const [products, setProducts] = useState<Bounty[] | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [sortOption, setSortOption] = useState(ProductSortOptions.Featured);
  const hasMore = useMemo(() => !!products && totalCount > products?.length, [totalCount, products?.length]);
  const skeletonProducts = useMemo(
    () => generateEmptyArray(isMobile ? DEFAULT_MOBILE_PRODUCTS_SKELETONS : DEFAULT_PRODUCTS_SKELETONS),
    [],
  );
  const searchFilter = searchParams?.get('filter.q') || '';
  const sortFilter = searchParams?.get('sort') as ProductSortOptions;
  const categoryCode = getCategoryLeaf(searchParams?.get('category'));

  const { handleTrackViewItems } = useGoogleAnalytics();

  useDocumentTitle({ title: 'Products' });

  useEffect(() => {
    const defaultSort = shopSettings?.sortConfigs?.PRODUCT?.sort as ProductSortOptions;

    if (defaultSort || sortFilter) {
      setSortOption(sortFilter || defaultSort);
    }
  }, [shopSettings?.sortConfigs?.PRODUCT?.sort, sortFilter]);

  useEffect(() => {
    fetchProducts(0, setIsLoading);
  }, [searchParams, storeId, useType, sortOption]);

  useEffect(() => {
    const category = categories.find((item) => item.code === categoryCode);

    if (products && products.length > 0) {
      handleTrackViewItems(
        !category ? { code: 'all', name: 'All categories' } : category,
        products,
      );
    }
  }, [categoryCode, products]);

  const loadProducts = async () => {
    const maxPage = totalCount ? Math.floor(totalCount / DEFAULT_PAGINATION_LIMIT) : 0;
    const newPage = currentPage + 1;

    if (newPage > maxPage) {
      setIsLoading(false);
    } else if (!(isLoading || isLoadingMore)) {
      fetchProducts(newPage, setIsLoadingMore);
    }
  };

  const fetchProducts = async (page: number, loadingFn: (load: boolean) => void) => {
    try {
      const formattedSearch = searchFilter.replace(/\s+/g, '+');
      const params = formatSearchParams(searchParams);
      params.set('start', (page * DEFAULT_PAGINATION_LIMIT).toString());

      if (formattedSearch) {
        searchParams.set('filter.q', searchFilter);
        params.set('q', formattedSearch);
      }

      if (sortOption) {
        params.set('order', sortOption);
      }

      setCurrentPage(page);
      loadingFn(true);

      const { data } = await getProducts(params);
      const prevProducts = page === 0 ? [] : products || [];

      setProducts([...prevProducts, ...data?.list ?? []]);
      setTotalCount(data.totalCount);
    } catch (e) {
      handleApiErrors(e);
    } finally {
      loadingFn(false);
    }
  };

  const Container = kioskMode ? KioskProducts : Products;

  return (
    <Container
      promotions={promotions}
      onLoadProducts={loadProducts}
      skeletonProducts={skeletonProducts}
      products={products}
      hasMore={hasMore}
      isLoading={isLoading}
      isLoadingMore={isLoadingMore}
      onSort={setSortOption}
      sortOption={sortOption}
    />
  );
};

export default ProductsProviderPage;
