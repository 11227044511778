import { Box } from '@mui/material';
import { FC } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';

import Header from 'layouts/CheckoutLayout/Header';
import { Root } from 'layouts/CheckoutLayout/index.styled';
import MainLayout from 'layouts/MainLayout';

import { useKiosk } from 'hooks/useKiosk';

import { KIOSK_HEADER_HEIGHT } from 'constants/general';

const CheckoutLayout:FC = () => {
  const { kioskMode } = useKiosk();

  return (
    kioskMode
      ? (
        <Root>
          <Header />
          <Box
            flexGrow={1}
            mt={`${KIOSK_HEADER_HEIGHT}px`}
          >
            <Outlet />
            <ScrollRestoration />
          </Box>
        </Root>
      ) : (
        <MainLayout />
      )
  );
};

export default CheckoutLayout;
