import { Box, Container } from '@mui/material';

import SubheaderWrapper from 'components/SubheaderWrapper';
import CategoryCarouselSkeleton from 'containers/CategoryCarousel/index.skeleton';

const DashboardSkeleton = () => (
  <Box>
    <SubheaderWrapper />
    <Container>
      <Box mb={10}>
        <CategoryCarouselSkeleton />
      </Box>
      <Box mb={10}>
        <CategoryCarouselSkeleton />
      </Box>
    </Container>
  </Box>
);

export default DashboardSkeleton;
