import compression from 'browser-image-compression';
import {
  ref, uploadBytes, uploadString, getDownloadURL, StorageReference,
} from 'firebase/storage';

import { storage } from 'config/firebase';
import { AttachmentType } from 'constants/enums';
import { Attachment, CompressedAttachment } from 'types/attachment.interface';
import {
  formatAttachment,
  getNextAttachmentId,
  transformImageBlobToFile,
  valueOfUploadFile,
} from 'utils/attachmentsUtils';

const UPLOADS_DIR = 'uploads';
const DOCS_DIR = 'docs';
const PICTURES_DIR = 'pictures';
const VIDEOS_DIR = 'video';
const AUDIOS_DIR = 'audio';
const MESSAGES_DIR = 'messages';

const CHILD_BY_ATTACHMENT_TYPE: { [k in AttachmentType]?: string } = {
  [AttachmentType.IMAGE]: PICTURES_DIR,
  [AttachmentType.GIF]: PICTURES_DIR,
  [AttachmentType.VIDEO]: VIDEOS_DIR,
  [AttachmentType.AUDIO]: AUDIOS_DIR,
  [AttachmentType.PDF]: DOCS_DIR,
  [AttachmentType.FILE]: DOCS_DIR,
  [AttachmentType.OTHER]: DOCS_DIR,
};

export const getChatUploadRef = (
  userId: string,
  attachType: AttachmentType,
  attachId: string,
  msgId: string,
  filename: string,
) => {
  const childName = CHILD_BY_ATTACHMENT_TYPE[attachType];
  return ref(storage, `${UPLOADS_DIR}/${userId}/${MESSAGES_DIR}/${msgId}/${childName}/${attachId}/${filename}`);
};

const getUploadMethod = (storageRef: StorageReference, attachment: CompressedAttachment) => {
  if (attachment.fileContent) {
    return uploadBytes(storageRef, attachment.fileContent);
  }

  if (attachment.fileUri) {
    return uploadString(storageRef, attachment.fileUri, 'data_url');
  }

  return null;
};

const uploadFile = async (storageRef: StorageReference, attachment: Attachment): Promise<string> => {
  const uploadResponse = await getUploadMethod(storageRef, attachment);

  if (!uploadResponse?.ref) {
    throw new Error('No resource found!');
  }

  return getDownloadURL(uploadResponse?.ref);
};

const compressAttachment = async (attachment: Attachment) => {
  if (!attachment.data) {
    return attachment;
  }

  try {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const compressedFile = await transformImageBlobToFile(attachment.data, options);
    const url = await compression.getDataUrlFromFile(compressedFile);

    return {
      ...attachment,
      data: compressedFile,
      url,
    };
  } catch (e) {
    return attachment;
  }
};

export const uploadChatAttachments = async (attachments: Attachment[], userId: string, msgId: string) => {
  const takenAttachIds: string[] = [];
  let order = 0;

  return Promise.allSettled(attachments.map(async (attachment) => {
    const attachmentId = getNextAttachmentId(takenAttachIds);
    takenAttachIds.push(attachmentId);

    const attachmentStorageRef = getChatUploadRef(
      userId,
      attachment.type,
      attachmentId,
      msgId,
      attachment.filename,
    );

    let compressedAttachment = await compressAttachment(attachment);
    compressedAttachment = valueOfUploadFile(compressedAttachment);
    const downloadUrl = await uploadFile(attachmentStorageRef, compressedAttachment);

    const formattedAttachments = formatAttachment({ ...compressedAttachment, url: downloadUrl }, attachmentId, order);
    order += 1;

    return formattedAttachments;
  }));
};

export const downloadUrl = (url: string) => getDownloadURL(ref(storage, url));
