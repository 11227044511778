import {
  FormLabel, Typography, SelectProps, MenuItem, FormHelperText, FormControl, Select,
} from '@mui/material';
import { FC } from 'react';

import { StyledWrapper } from 'components/CustomDropdown/index.styled';

import { Option } from 'types/option.interface';

interface CustomDropdownProps {
  options: Array<Option>;
  helperText?: string;
  placeholder?: string;
}

const CustomDropdown:FC<SelectProps & CustomDropdownProps> = (props) => {
  const {
    name,
    required,
    fullWidth,
    label,
    options,
    error,
    helperText,
    placeholder,
    ...remainingProps
  } = props;

  return (
    <StyledWrapper hasError={error}>
      <FormControl component="div" fullWidth={fullWidth}>
        {label && (
          <FormLabel required={required}>
            <Typography component="span" color="textPrimary">
              {label}
            </Typography>
          </FormLabel>
        )}
        <Select
          {...remainingProps}
          name={name}
          MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
        >
          {placeholder && (
            <MenuItem disabled value="">
              {placeholder}
            </MenuItem>
          )}
          {options.map(({ key, value }, index: number) => (
            <MenuItem value={key} key={`${index}-${key}`}>
              {value}
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </StyledWrapper>
  );
};

export default CustomDropdown;
