import { PaymentMethod } from 'constants/enums';
import { isTestChannel } from 'utils/utils';

export const loadSDK = (baseUrl: string, initCB: () => void) => {
  function loadScript(src: string, onload: () => void) {
    const script = document.createElement('script');
    script.onload = onload;
    script.src = src;
    document.head.appendChild(script);
  }

  function loadCSS(src: string) {
    const fileref = document.createElement('link');
    fileref.rel = 'stylesheet';
    fileref.type = 'text/css';
    fileref.href = src;
    document.getElementsByTagName('head')[0].appendChild(fileref);
  }

  loadCSS(`${baseUrl}/sdk/iframe.css`);
  loadScript(`${baseUrl}/sdk/index.js`, initCB);
};

export const getPaymentMethodBasedOnEnv = (paymentMethod: PaymentMethod) => (
  isTestChannel() ? `${paymentMethod}_SANDBOX` : paymentMethod
);
