import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';

interface SpacingContentProps {
  children: ReactNode;
}

const SpacingContent:FC<SpacingContentProps> = ({ children }) => (
  <Box display="flex" flexDirection="column" flexGrow={1}>
    <Box flexGrow={1} />

    {children}

    <Box flexGrow={2} />
  </Box>
);

export default SpacingContent;
