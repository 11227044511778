import {
  FormControl, FormLabel, TextField, TextFieldProps, Typography,
} from '@mui/material';
import {
  useRef, useEffect, FC, useState,
} from 'react';

import useGooglePlaceAutoComplete from 'hooks/useGooglePlaceAutoComplete';

import { GoogleFields } from 'constants/enums';
import { GoogleFormattedAddress } from 'types/address.interface';
import { loadGoogleMaps, reportAlert } from 'utils/utils';

type PlacesAutocompleteProps = TextFieldProps & {
  onValueChange: (value: string, name: string) => void;
  fieldsConfig: Record<GoogleFields, string>;
};

const PlacesAutocomplete:FC<PlacesAutocompleteProps> = ({
  fullWidth,
  required,
  label,
  onValueChange,
  fieldsConfig,
  defaultValue,
  ...remainingProps
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { initGooglePlacesAutoComplete, getFormattedAddress } = useGooglePlaceAutoComplete();
  const [hasLoadedMaps, setHasLoadedMaps] = useState(false);

  let place: GoogleFormattedAddress = {};
  let autoComplete = {};

  useEffect(() => {
    const inputElement = inputRef.current;
    const handleEvent = () => {
      if (inputRef?.current?.value === '' && fieldsConfig[GoogleFields.LINE_1]) {
        onValueChange('', fieldsConfig[GoogleFields.LINE_1]);
      }
    };

    inputElement?.addEventListener('keyup', handleEvent);

    return () => inputElement?.removeEventListener('keyup', handleEvent);
  }, []);

  const handleAddressSelect = async () => {
    place = await getFormattedAddress(autoComplete);

    if (place.line1) {
      onValueChange(place.line1, fieldsConfig[GoogleFields.LINE_1]);
    }

    if (place.postalCode) {
      onValueChange(place.postalCode, fieldsConfig[GoogleFields.ZIP_CODE]);
    }

    if (place.city) {
      onValueChange(place.city, fieldsConfig[GoogleFields.CITY]);
    }

    if (place.tempState) {
      onValueChange(place.tempState, fieldsConfig[GoogleFields.STATE]);
    }
  };

  const loadGoogleAutoComplete = async () => {
    try {
      autoComplete = await initGooglePlacesAutoComplete(inputRef.current, handleAddressSelect);
    } catch (e) {
      setTimeout(async () => {
        await loadGoogleAutoComplete();
      }, 1000);
    }
  };

  const loadMaps = async () => {
    try {
      const response = await loadGoogleMaps();
      setHasLoadedMaps(response);
    } catch (err) {
      reportAlert(`load google failed: ${JSON.stringify(err)}`);
    }
  };

  useEffect(() => {
    loadMaps();
  }, []);

  useEffect(() => {
    if (hasLoadedMaps) {
      loadGoogleAutoComplete();
    }
  }, [hasLoadedMaps]);

  return (
    <FormControl component="div" fullWidth={fullWidth}>
      {label && (
        <FormLabel required={required}>
          <Typography component="span" color="textPrimary">
            {label}
          </Typography>
        </FormLabel>
      )}
      <TextField
        size="small"
        name={fieldsConfig.LINE_1}
        inputRef={inputRef}
        defaultValue={defaultValue}
        inputProps={{
          autoComplete: 'off',
          role: 'presentation',
        }}
        {...remainingProps}
      />
    </FormControl>
  );
};
export default PlacesAutocomplete;
