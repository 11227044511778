import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Typography, Box } from '@mui/material';
import { FC } from 'react';

import { Chip, Dot } from 'components/StoreLocationInfo/index.styled';

interface StoreLocationInfoProps {
  location: string;
  useType: string;
  disabled?: boolean;
  onClick?: () => void;
}

const StoreLocationInfo:FC<StoreLocationInfoProps> = ({
  location,
  useType,
  onClick,
  disabled = false,
}) => (
  <Chip
    disabled={disabled}
    icon={<LocationOnOutlinedIcon />}
    label={(
      <Box display="flex" alignItems="center">
        <Typography variant="body1">
          {location}
        </Typography>
        <Dot />
        <Typography variant="body1">
          {useType}
        </Typography>
      </Box>
    )}
    variant="outlined"
    onClick={onClick}
  />
);

export default StoreLocationInfo;
