import Typography from '@mui/material/Typography';
import { FC } from 'react';

import { FlwrQuantityVariant, OnboardingUseType, WeightUnit } from 'constants/enums';
import { Price, TierPrice } from 'types/money.interface';
import { multiply } from 'utils/currencyUtils';
import { formatPrice, getFullTierPrice } from 'utils/priceUtils';
import { getFlwrQuantityByVariant } from 'utils/productUtils';

import { Root } from './index.styled';

interface PriceVariantProps {
  label: string;
  variant: FlwrQuantityVariant;
  tierPrices: TierPrice[];
  productPrice: Price | null;
  useType: OnboardingUseType;
  onClick: () => void;
  sortUnit?: WeightUnit;
  isActive?: boolean;
  isDisabled?: boolean;
}

const PriceVariant: FC<PriceVariantProps> = ({
  label,
  variant,
  tierPrices,
  useType,
  productPrice,
  onClick,
  sortUnit,
  isActive = false,
  isDisabled = false,
}) => {
  const foundTier = tierPrices?.find(({ items }) => (
    items === getFlwrQuantityByVariant(variant, sortUnit)
  ));
  const tierPrice = tierPrices?.length > 0 && foundTier
    ? getFullTierPrice(useType, foundTier)
    : null;
  const variantPrice = tierPrice
    ? tierPrice.mainPrice
    : multiply(productPrice?.mainPrice, getFlwrQuantityByVariant(variant, sortUnit));

  return (
    <Root
      isActive={isActive}
      isDisabled={isDisabled || !variantPrice}
      onClick={(isDisabled || !variantPrice) ? undefined : onClick}
    >
      <Typography variant="body3">
        {label}
      </Typography>
      <Typography variant="body1" fontWeight={700} mt={0.5}>
        {variantPrice ? formatPrice({ mainPrice: variantPrice }) : '-'}
      </Typography>
    </Root>
  );
};

export default PriceVariant;
