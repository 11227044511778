import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Box, Button, Typography } from '@mui/material';
import { RecaptchaVerifier } from 'firebase/auth';
import { FC, useEffect, useRef } from 'react';

import { auth } from 'config/firebase';
import { reportAlert } from 'utils/utils';

interface RecaptchaProps {
  setIsRecaptchaSolved: (value: boolean) => void;
  setShowRecaptcha: (value: boolean) => void;
  showRecaptcha: boolean;
  isRecaptchaSolved: boolean;
}

let verifier: RecaptchaVerifier|null = null;

const Recaptcha:FC<RecaptchaProps> = ({
  setIsRecaptchaSolved,
  showRecaptcha,
  isRecaptchaSolved,
  setShowRecaptcha,
}) => {
  const recaptchaRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    initRecaptcha();

    return () => {
      if (recaptchaRef.current) {
        verifier?.clear();
        recaptchaRef.current.innerHTML = '<div id="recaptcha"></div>';
      }
    };
  }, []);

  const initRecaptcha = async () => {
    try {
      verifier = new RecaptchaVerifier(auth, 'recaptcha', {
        type: 'recaptcha',
        callback: () => setIsRecaptchaSolved(true),
        'expired-callback': () => {
          setIsRecaptchaSolved(false);
        },
      });
      await verifier.render();
    } catch (e: any) {
      reportAlert(`Error initializing recaptcha: ${JSON.stringify(e)}`);
    }
  };

  //  We need the following style "display={isVisible ? 'block' : 'none'}" to prevent the timeout issue related with recaptcha widget
  return (
    <Box display={showRecaptcha ? 'block' : 'none'} p={2}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h6" mb={2}>
          Let&#39;s do a quick security check
        </Typography>
        <VerifiedUserIcon sx={{ height: '3em', width: '3em', mb: 3 }} />
      </Box>
      <Typography variant="body2" textAlign="center" color="textSecondary">
        It seems that there was an error in the registration process.
        To ensure the security of our system, we need to perform a quick CAPTCHA
        check before you can proceed with the registration flow again
      </Typography>

      <Box ref={recaptchaRef} mt={5} display="flex" justifyContent="center">
        <div id="recaptcha" className="d-inline-block" />
      </Box>

      <Button
        variant="contained"
        fullWidth
        sx={{ mt: 3 }}
        disabled={!isRecaptchaSolved}
        onClick={() => setShowRecaptcha(false)}
      >
        Proceed to signup
      </Button>
    </Box>
  );
};

export default Recaptcha;
