import { Box, Container } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import Navbar from 'components/_Navbar';
import KioskNavbar from 'components/_Navbar/index.kiosk';
import OnboardingFlow from 'containers/Onboarding';
import { LocationContextDispatchContext } from 'context/LocationContext';
import { OnboardingContextProvider } from 'context/OnboardingContext';
import { SystemContext } from 'context/SystemContext';
import { Content, CardMedia } from 'layouts/OnboardingLayout/index.styled';

import { BackgroundArea } from 'constants/enums';
import { getPublicUrlForImage } from 'utils/publicUrl';
import { getShopConfiguration } from 'utils/storeUtils';

const { hideHeader } = getShopConfiguration();

const OnboardingLayout = () => {
  const [showOnboarding, setShowOnboarding] = useState(false);
  const { setSetupCompleted } = useContext(LocationContextDispatchContext);
  const { clientInfo, shopSettings, detailedClientData } = useContext(SystemContext);
  const onboardingBackgroundUrl = shopSettings?.backgrounds?.[BackgroundArea.LandingPage]
    ?.urls?.onboardingBackgroundUrl?.url;

  useEffect(() => {
    setShowOnboarding(true);
  }, []);

  const handleClose = () => {
    setSetupCompleted(true);
    setShowOnboarding(false);
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {!hideHeader && (
        detailedClientData?.kioskMode
          ? <KioskNavbar clientInfo={clientInfo} />
          : <Navbar hasShoppingCart clientInfo={clientInfo} />
      )}
      <Content>
        <Container sx={{ height: '100%' }}>
          <Box height="100%" display="flex" justifyContent="center">
            <CardMedia
              component="img"
              alt="onboarding flow"
              src={onboardingBackgroundUrl || getPublicUrlForImage('images/default-onboarding.jpg')}
            />
          </Box>
        </Container>
      </Content>
      <OnboardingContextProvider closeModal={handleClose} isModalOpen={showOnboarding}>
        {showOnboarding && <OnboardingFlow />}
      </OnboardingContextProvider>
    </Box>
  );
};

export default OnboardingLayout;
