import { toast } from 'react-toastify';
import { ValidationError } from 'yup';

import { getValidationErrorsHash } from 'utils/yupUtils';

export const handleApiErrors = (error: any, setError?: any) => {
  if (ValidationError.isError(error)) {
    setError(getValidationErrorsHash(error as ValidationError));
  } else if (error?.data?.errors) {
    toast.error(error?.data?.errors[0]?.message);
  } else {
    const message = error?.data
      ? error.data
      : 'Something went wrong, please try again!';
    toast.error(message);
  }
};

export const getApiError = (e: any) => {
  if (e?.response?.data?.data) {
    return {
      data: e?.response?.data?.data,
      status: e?.response?.data?.statusCode,
    };
  }

  return {
    data: 'Something went wrong!',
    status: 500,
  };
};
