export const CART_PRODUCT_COVER_SIZE = {
  h: 70,
  w: 70,
};

export const CART_PRODUCT_PREVIEW_COVER_SIZE = {
  h: 70,
  w: 70,
};

export const DASHBOARD_PRODUCT_COVER_SIZE = {
  h: 300,
  w: 0,
};

export const PRODUCT_DETAILS_COVER_SIZE = {
  h: 350,
  w: 0,
};

export const PRODUCT_DETAILS_SMALL_COVER_SIZE = {
  h: 80,
  w: 80,
};
