import { Typography } from '@mui/material';
import { FC } from 'react';

import { Customer } from 'types/customer.interface';

interface OrderContactInfoProps {
  customer: Customer;
  state?: string;
}

const OrderContactInfo: FC<OrderContactInfoProps> = ({ customer, state }) => {
  const hasName = customer?.firstName || customer?.lastName;

  return (
    <>
      <Typography variant="body2" fontWeight={600} mb={2}>
        Contact info:
      </Typography>

      {hasName && (
        <>
          <Typography variant="body2" fontWeight={600} sx={{ opacity: 0.7 }}>
            Name
          </Typography>
          <Typography variant="body2" mb={2}>
            {`${customer?.firstName} ${customer?.lastName}`}
          </Typography>
        </>
      )}

      {customer?.dob && (
        <>
          <Typography variant="body2" fontWeight={600} sx={{ opacity: 0.7 }}>
            Birthday
          </Typography>
          <Typography variant="body2" mb={2}>
            {customer?.dob}
          </Typography>
        </>
      )}

      {state && (
        <>
          <Typography variant="body2" fontWeight={600} sx={{ opacity: 0.7 }}>
            From
          </Typography>
          <Typography variant="body2" mb={2}>
            {state}
          </Typography>
        </>
      )}

      <Typography variant="body2">
        {customer?.email}
      </Typography>
      <Typography variant="body2" mb={1}>
        {customer?.address?.phoneNumber}
      </Typography>
    </>
  );
};

export default OrderContactInfo;
