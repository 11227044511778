import { styled } from '@mui/material/styles';

export const StyledStockWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: theme.palette.general.darkYellow1,
  marginTop: theme.spacing(2),
  width: 'contained',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const RemoveOption = styled('div')(() => ({
  cursor: 'pointer',
  textDecoration: 'underline',
  fontSize: '14px',
}));
