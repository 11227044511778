import { alpha, styled } from '@mui/material/styles';

export const Chip = styled('div')<{ isActive?: boolean }>(({ theme, isActive }) => ({
  paddingLeft: '4px',
  paddingRight: '4px',
  borderRadius: '40px',
  width: '100%',
  padding: theme.spacing(0.5, 1, 0.5, 1),
  border: `3px solid ${theme.palette.primary.main}`,
  cursor: 'pointer',
  backgroundColor: isActive ? alpha(theme.palette.primary.main, 0.7) : theme.palette.common.white,
  color: isActive ? theme.palette.common.white : theme.palette.text.secondary,
  '& .label': {
    textAlign: 'center',
    flexGrow: 1,
  },
  '& .title': {
    color: isActive ? theme.palette.common.white : theme.palette.primary.main,
  },
  '& .MuiSvgIcon-root': {
    visibility: isActive ? 'visible' : 'hidden',
  },
  '& .useType': {
    color: isActive ? theme.palette.common.white : theme.palette.primary.main,
    visibility: 'visible',
    marginLeft: theme.spacing(1),
  },
}));
