import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Typography } from '@mui/material';
import { FC } from 'react';

import { StyledBox } from 'components/ShowMore/index.styled';

interface ShowMoreProps {
  showMore: boolean;
  toggleShowMore: () => void;
}

const ShowMore:FC<ShowMoreProps> = ({ showMore, toggleShowMore }) => (
  <StyledBox onClick={toggleShowMore}>
    {showMore
      ? <KeyboardArrowDownIcon fontSize="small" />
      : <KeyboardArrowUpIcon fontSize="small" />}
    <Typography variant="body2">
      {showMore ? 'Show more' : 'Show less'}
    </Typography>
  </StyledBox>
);

export default ShowMore;
