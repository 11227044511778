import { DialogContent as MuiDialogContent, DialogActions as MuiDialogActions } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Title = styled('div')(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(0, 6, 2),
  fontWeight: 700,

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 0, 2),
  },
}));

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(3, 8),

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3, 3),
  },
}));

export const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  padding: theme.spacing(0, 8, 3),

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 3, 3),
  },
}));
