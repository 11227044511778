import AttachmentIcon from '@mui/icons-material/Attachment';
import CloseIcon from '@mui/icons-material/Close';
import { Tooltip, Typography } from '@mui/material';
import { FC } from 'react';

import DropzoneWrapper from 'components/DropzoneWrapper';
import {
  Actions, CloseButton, Root, UploadButton,
} from 'containers/SupportChat/ChatHeader/index.styled';

import { ACCEPTED_CHAT_FILE_TYPES } from 'constants/general';

interface ChatHeaderProps {
  onClose: () => void;
  handleOnDrop: (acceptedFiles: File[]) => void;
}

const ChatHeader:FC<ChatHeaderProps> = ({ onClose, handleOnDrop }) => (
  <Root>
    <Typography color="textSecondary">Support chat</Typography>
    <Actions>
      <DropzoneWrapper onDrop={handleOnDrop} multiple supportedFileTypes={ACCEPTED_CHAT_FILE_TYPES}>
        <Tooltip title="Upload files">
          <UploadButton color="primary">
            <AttachmentIcon fontSize="small" />
          </UploadButton>
        </Tooltip>
      </DropzoneWrapper>
      <CloseButton onClick={onClose}>
        <CloseIcon fontSize="small" />
      </CloseButton>
    </Actions>
  </Root>
);

export default ChatHeader;
