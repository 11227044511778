import { Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  width: '100%',
  backgroundColor: theme.palette.background.secondary,
  borderRadius: '4px',
  position: 'relative',
}));

export const GridInput = styled(Grid)(() => ({
  '& .MuiFormControl-root': {
    height: '100%',

    '& > div': {
      height: '100%',
    },
  },
})) as typeof Grid;
