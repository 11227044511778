import { styled } from '@mui/material/styles';

export const Box = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.secondary,
  padding: theme.spacing(1, 2),
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  borderRadius: '4px',
  border: `1px solid ${theme.palette.border.main}`,
  borderTop: 0,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
}));

export const BalanceWrapper = styled('span')(() => ({
  fontWeight: 600,
}));

export const IntakeWrapper = styled('span')<{ hasReachedLimit: boolean }>(({ theme, hasReachedLimit }) => ({
  color: hasReachedLimit ? theme.palette.general.lightOrange : 'initial',
}));
