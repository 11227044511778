import { Dialog } from '@mui/material';
import {
  FC, useContext, useMemo, useState,
} from 'react';

import StoreLocationInfo from 'components/StoreLocationInfo';
import StoreOneLocation from 'components/StoreOneLocation';
import OnboardingFlow from 'containers/Onboarding';
import KioskUseTypePicker from 'containers/Onboarding/KioskUseTypePicker';
import { LocationContext } from 'context/LocationContext';
import { OnboardingContextProvider } from 'context/OnboardingContext';
import { SystemContext } from 'context/SystemContext';

import { isRestrictedToOneLocation } from 'hooks/useRouting';

import { USE_TYPE_OPTIONS } from 'constants/options';
import { textTruncate } from 'utils/formatters';
import { getCompanyLocationsData, getOnboardingInfo } from 'utils/storageUtils';

const StoreLocation: FC = () => {
  const [showOnboarding, setShowOnboarding] = useState(false);
  const { storeName } = getOnboardingInfo() || {};
  const { list } = getCompanyLocationsData() || {};
  const hasMultipleLocations = Boolean(list && list.length > 1);
  const { onboardingInfo } = useContext(LocationContext);
  const { clientInfo } = useContext(SystemContext);
  const { useType } = onboardingInfo || {};
  const hasMultipleUseTypes = useMemo(() => {
    const store = list?.find(({ id }) => id === clientInfo?.id);

    return store && store?.adultUse && store?.medicalUse;
  }, [clientInfo, list]);

  const locationName = useMemo(() => (
    storeName ? textTruncate(storeName, 25) : 'Store location'
  ), [storeName]);

  const useTypeLabel = useMemo(() => (
    useType === USE_TYPE_OPTIONS[1].key
      ? USE_TYPE_OPTIONS[1].value
      : USE_TYPE_OPTIONS[0].value
  ), [useType]);

  const handleOpen = () => {
    setShowOnboarding(true);
  };

  const handleClose = () => {
    setShowOnboarding(false);
  };

  return (
    <div>
      {isRestrictedToOneLocation()
        ? (
          <>
            <StoreOneLocation
              useTypeLabel={useTypeLabel}
              useType={useType}
              onClick={hasMultipleUseTypes ? handleOpen : undefined}
            />
            {showOnboarding && (
              <Dialog
                open={showOnboarding}
                aria-describedby="use-type-picker"
                onClose={handleClose}
                PaperProps={{ sx: { width: '480px', borderRadius: '16px', py: 3 } }}
              >
                <KioskUseTypePicker onClose={handleClose} />
              </Dialog>
            )}
          </>
        ) : (
          <>
            <StoreLocationInfo
              disabled={!hasMultipleLocations}
              location={locationName}
              useType={useTypeLabel}
              onClick={handleOpen}
            />
            <OnboardingContextProvider closeModal={handleClose} isModalOpen={showOnboarding}>
              {showOnboarding && <OnboardingFlow />}
            </OnboardingContextProvider>
          </>
        )}
    </div>
  );
};

export default StoreLocation;
