import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FilterWrapper = styled(Box)(({ theme }) => ({
  borderTop: `2px solid ${theme.palette.border.main}`,
  borderBottom: `2px solid ${theme.palette.border.main}`,
  padding: theme.spacing(2, 2.5),
}));

export const OptionWrapper = styled(
  Box,
  { shouldForwardProp: (props) => props !== 'isActive' },
)<{ isActive: boolean }>(({ theme, isActive }) => ({
  padding: theme.spacing(isActive ? 1 : 2, 0, isActive ? 1 : 2, 5),
  cursor: 'pointer',
  backgroundColor: isActive ? theme.palette.general.lightGrey5 : theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '&:hover': {
    backgroundColor: theme.palette.general.lightGrey5,
  },
}));

export const RemoveButton = styled(Box)(({ theme }) => ({
  borderRadius: '50%',
  border: `1px solid ${theme.palette.common.white}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(1),
  padding: theme.spacing(1),
  cursor: 'pointer',

  '& svg': {
    height: '29.25px',
    width: '29.25px',
  },

  '& path': {
    fill: theme.palette.general.lightGrey3,
  },
}));
