import { FlwrQuantityVariant } from 'constants/enums';

export const FLWR_QUANTITY_BY_VARIANT = {
  [FlwrQuantityVariant.eightOz]: 1,
  [FlwrQuantityVariant.quarterOz]: 2,
  [FlwrQuantityVariant.halfOz]: 4,
  [FlwrQuantityVariant.oneOz]: 8,
};

export const FLWR_2_QUANTITY_BY_VARIANT = {
  [FlwrQuantityVariant.eightOz]: 0,
  [FlwrQuantityVariant.quarterOz]: 1,
  [FlwrQuantityVariant.halfOz]: 2,
  [FlwrQuantityVariant.oneOz]: 4,
};

export const FLWR_4_QUANTITY_BY_VARIANT = {
  [FlwrQuantityVariant.eightOz]: 0,
  [FlwrQuantityVariant.quarterOz]: 0,
  [FlwrQuantityVariant.halfOz]: 1,
  [FlwrQuantityVariant.oneOz]: 2,
};

export const FLWR_VARIANT_BY_QUANTITY: Record<number, FlwrQuantityVariant> = {
  1: FlwrQuantityVariant.eightOz,
  2: FlwrQuantityVariant.quarterOz,
  4: FlwrQuantityVariant.halfOz,
  8: FlwrQuantityVariant.oneOz,
};

export const FLWR_2_VARIANT_BY_QUANTITY: Record<number, FlwrQuantityVariant> = {
  1: FlwrQuantityVariant.quarterOz,
  2: FlwrQuantityVariant.halfOz,
  4: FlwrQuantityVariant.oneOz,
};

export const FLWR_4_VARIANT_BY_QUANTITY: Record<number, FlwrQuantityVariant> = {
  1: FlwrQuantityVariant.halfOz,
  2: FlwrQuantityVariant.oneOz,
};
