import { Typography } from '@mui/material';
import { FC } from 'react';

import { Card, CategoryIconWrapper, CategoryImage } from 'containers/Categories/CategoryCard/index.styled';

import { Category } from 'types/category.interface';

interface CategoryCardProps {
  category: Category;
  isActive: boolean;
  onSelect: () => void;
}

const CategoryCard:FC<CategoryCardProps> = ({ category, isActive, onSelect }) => {
  const { name, imageUrl } = category;

  return (
    <Card
      onClick={onSelect}
      isActive={isActive}
    >
      <CategoryIconWrapper isActive={isActive}>
        {!!imageUrl && <CategoryImage component="img" alt={name} src={imageUrl} isActive={isActive} />}
      </CategoryIconWrapper>
      <Typography fontWeight={700}>
        {name}
      </Typography>
    </Card>
  );
};

export default CategoryCard;
