import { Typography } from '@mui/material';
import { FC } from 'react';

import { KioskRoot } from 'components/_Footer/index.styled';
import ShoppingCart from 'components/ShoppingCart';

const KioskFooter:FC = () => (
  <KioskRoot>
    <Typography variant="body2" fontWeight={700} className="headerBackground">
      CHECKOUT
    </Typography>
    <ShoppingCart />
  </KioskRoot>
);

export default KioskFooter;
