import { Container } from '@mui/material';
import { FC, ReactNode, useContext } from 'react';

import ShoppingCart from 'components/ShoppingCart';
import { Content, HeaderContent, StyledSubHeader } from 'components/SubheaderWrapper/index.styled';
import StoreLocation from 'containers/StoreLocation';
import User from 'containers/User';
import { SystemContext } from 'context/SystemContext';

import { useKiosk } from 'hooks/useKiosk';

import { getShopConfiguration } from 'utils/storeUtils';

const { hideHeader } = getShopConfiguration();

interface SubheaderWrapperProps {
  children?: ReactNode;
}

const SubheaderWrapper:FC<SubheaderWrapperProps> = ({ children }) => {
  const { kioskMode } = useKiosk();
  const { hasStores, isClosed } = useContext(SystemContext);
  return (
    <StyledSubHeader kioskMode={kioskMode} id="subheader">
      <Container>
        {hideHeader ? (
          <Content>
            <HeaderContent>
              {hasStores && <StoreLocation />}
              {!isClosed && <ShoppingCart />}
              {!isClosed && <User />}
            </HeaderContent>
            {children}
          </Content>
        ) : (
          children
        )}
      </Container>
    </StyledSubHeader>
  );
};

export default SubheaderWrapper;
