import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.border.main}`,
  '& .MuiOutlinedInput-root': {
    borderRadius: '16px',
  },
  position: 'relative',
}));
