import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  borderRadius: '4px',
  border: `1px solid ${theme.palette.border.main}`,
  padding: theme.spacing(0.75, 0, 1.2, 4.5),
  width: '100%',
  alignItems: 'center',
}));
