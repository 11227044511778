import { alpha, styled } from '@mui/material/styles';

import CustomInput from 'components/CustomInput';

export const Wrapper = styled('div')(({ theme }) => ({
  '& > *': {
    margin: theme.spacing(0, 0, 2),
  },
}));

export const InputField = styled(CustomInput)(({ theme }) => ({
  '& fieldset': {
    border: `2px solid ${theme.palette.general.lightGrey}`,
    borderRadius: '8px',
  },
  fontSize: '1rem',
}));

export const InfoBox = styled('div')(({ theme }) => ({
  border: `2px solid ${theme.palette.general.lightGrey}`,
  borderRadius: '8px',
  padding: theme.spacing(1.5),
  backgroundColor: alpha(theme.palette.general.lightGrey, 0.6),
}));

export const StoresContainer = styled('div')(({ theme }) => ({
  border: `2px solid ${theme.palette.general.lightGrey}`,
  borderRadius: '8px',
  maxHeight: '18vh',
  overflowY: 'auto',
  '& > *': {
    borderBottom: `2px solid ${theme.palette.general.lightGrey}`,
    margin: theme.spacing(0, 2),
    padding: theme.spacing(1, 0),
    cursor: 'pointer',

    svg: {
      fill: theme.palette.primary.main,
    },
  },
  '& > :last-child': {
    borderBottom: 'none',
  },
}));
