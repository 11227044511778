import { isEmpty } from 'lodash';
import { FC, useEffect, useState } from 'react';

import KioskProductDetails from 'containers/ProductDetailsPage/KioskProductDetails';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { getProductDetails } from 'services/Product';

import { Bounty } from 'types/bounty.interface';
import { handleApiErrors } from 'utils/errorUtils';

interface ProductDetailsPageProps {
  bountyDetails: Bounty;
  onCancel: () => void;
}

const ProductDetailsPage:FC<ProductDetailsPageProps> = ({ bountyDetails, onCancel }) => {
  const [bounty, setBounty] = useState<Bounty>(bountyDetails);

  const { handleTrackViewItem } = useGoogleAnalytics();
  const { product } = bounty;

  useEffect(() => {
    if (bountyDetails?.id) {
      fetchProductDetails(bountyDetails.id);
    }
  }, [bountyDetails.id]);

  useEffect(() => {
    if (!isEmpty(product)) {
      handleTrackViewItem(bounty);
    }
  }, [product]);

  const fetchProductDetails = async (id: string) => {
    try {
      const { data } = await getProductDetails(id);
      setBounty(data);
    } catch (e) {
      handleApiErrors(e);
    }
  };

  return (
    <KioskProductDetails
      onClose={onCancel}
      bounty={bounty}
      isLoading={false}
    />
  );
};

export default ProductDetailsPage;
