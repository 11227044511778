import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { FC } from 'react';

import { StyledButton } from 'components/CarouselPrevArrow/index.styled';

interface CarouselPrevArrowProps {
  className?: string;
  onClick?: () => unknown;
  sx?: object;
}

const CarouselPrevArrow:FC<CarouselPrevArrowProps> = ({ className, onClick, sx }) => (
  <div>
    <StyledButton
      className={className}
      onClick={onClick}
      sx={sx}
    >
      <ArrowBackIosNewIcon />
    </StyledButton>
  </div>
);

export default CarouselPrevArrow;
