import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledImage = styled('img')(({ theme }) => ({
  height: '75px',
  width: '75px',
  objectFit: 'contain',
  [theme.breakpoints.down('sm')]: {
    height: '50px',
    width: '50px',
  },
}));

export const PriceWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  marginLeft: theme.spacing(1.5),

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
  },
}));

export const PriceText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),

  [theme.breakpoints.down('sm')]: {
    marginBottom: 0,
  },
}));

export const QuantityText = styled(Typography)(({ theme }) => ({
  textAlign: 'right',

  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
  },
}));

export const Root = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  width: '100%',
  alignItems: 'center',
  display: 'flex',

  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
}));
