import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'assets/scss/modules/toast.scss';

const CustomToastContainer = () => (
  <ToastContainer
    position="top-right"
    autoClose={2000}
    closeOnClick
    pauseOnHover
    hideProgressBar
    theme="dark"
  />
);
export default CustomToastContainer;
