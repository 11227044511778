import { Box, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { format } from 'date-fns';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomButton from 'components/CustomButton';
import {
  StatusTypography, StyledBox, StyledDivider, StyledInfoWrapper, StyledTypography,
} from 'components/OrderInformation/index.styled';

import { getOrderDetailsRoute } from 'hooks/useRouting';

import { EXTENDED_ORDER_DATE_FORMAT } from 'constants/general';
import { ORDER_STATUS } from 'constants/statuses';
import { Bounty } from 'types/bounty.interface';
import { User } from 'types/user.interface';
import { formatReward } from 'utils/currencyUtils';

interface OrderInformationProps {
  bounty: Bounty;
}

const OrderInformation: FC<OrderInformationProps> = ({ bounty }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matchesScreenSizeSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { order, id } = bounty;
  const merchant = order?.productsInfo?.products
    ? Object.values(order?.productsInfo?.products)?.[0]?.merchant
    : {} as User;

  const handleOrderDetails = () => {
    if (id) {
      navigate(getOrderDetailsRoute(id));
    }
  };

  return (
    <StyledBox>
      <Box display="flex" alignItems={!matchesScreenSizeSm ? 'center' : 'flex-start'} justifyContent="space-between">
        <div>
          <Typography
            variant="body2"
            fontWeight={600}
            mt={matchesScreenSizeSm ? 1 : 0}
          >
            {order?.orderId ? `Order # ${order?.orderId}` : 'Order'}
          </Typography>

          <StyledInfoWrapper display="flex">
            {order?.orderedAt && (
              <>
                <Typography variant="body2" sx={{ opacity: 0.9 }}>
                  {`From: ${format(order?.orderedAt, EXTENDED_ORDER_DATE_FORMAT)}`}
                </Typography>

                <StyledTypography variant="body2">|</StyledTypography>
              </>
            )}

            <Typography variant="body2" sx={{ opacity: 0.9 }}>
              {`TOTAL: ${formatReward(order?.orderTotal, '0', true)}`}
            </Typography>
          </StyledInfoWrapper>
        </div>

        <CustomButton
          id="button-seeOrderDetails"
          variant="outlined"
          onClick={handleOrderDetails}
          className="button-secondary"
        >
          {matchesScreenSizeSm ? 'Details' : 'Order details'}
        </CustomButton>
      </Box>

      <StyledDivider sx={{ my: 2 }} />

      {!!merchant?.name && (
        <Typography variant="body2" fontWeight={600} mb={0.5}>
          {`Sold by ${merchant.name}`}
        </Typography>
      )}

      {
        // TODO: check from where we read the value for subtotal
      }
      {/* <Typography variant="body2" mb={0.5} sx={{ opacity: 0.6 }}> */}
      {/*  {`Subtotal: ${formatReward(order?.negotiatedAmount, '0')}`} */}
      {/* </Typography> */}

      {(order?.status && ORDER_STATUS[order?.status]) && (
        <StatusTypography variant="body2" mb={0.5} status={order?.status}>
          {`[${ORDER_STATUS[order?.status]}]`}
        </StatusTypography>
      )}
    </StyledBox>
  );
};

export default OrderInformation;
