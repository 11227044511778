import { Grid2 as Grid, Typography } from '@mui/material';
import {
  FC, ReactNode, useContext, useEffect, useState,
} from 'react';
import { toast } from 'react-toastify';

import PriceVariant from 'containers/PriceVariantsSelector/PriceVariant';
import { CartContext, CartDispatchContext } from 'context/CartContext';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

import { FlwrQuantityVariant, OnboardingUseType } from 'constants/enums';
import { Bounty } from 'types/bounty.interface';
import { Cart } from 'types/cart.interface';
import { getProductPrice, getTopLevelVariant } from 'utils/priceUtils';
import { getFlwrOptionsByType, getFlwrQuantityByVariant, getFlwrVariantByQuantity } from 'utils/productUtils';

interface PriceVariantSelectorProps {
  bounty: Bounty;
  useType: OnboardingUseType;
  children: (params: { onAddToCart: () => void; isDisabled: boolean; label: string }) => ReactNode;
  kioskMode?: boolean;
  onSuccess?: (cart: Cart) => void;
}

const PriceVariantSelector: FC<PriceVariantSelectorProps> = ({
  bounty,
  useType,
  children,
  kioskMode,
  onSuccess,
}) => {
  const cart = useContext(CartContext);
  const { addItem, setQuantity } = useContext(CartDispatchContext);
  const { handleTrackAddItemToCart } = useGoogleAnalytics();
  const [selectedVariant, setSelectedVariant] = useState<FlwrQuantityVariant>(FlwrQuantityVariant.eightOz);

  const { quantity = 0, maxAllowedQty } = getTopLevelVariant(bounty?.product) || {};
  const allowableQuantity = Math.min(quantity, maxAllowedQty || Number.MAX_VALUE);
  const tierPrices = bounty?.product?.altPrices?.tierPrices;
  const productPrice = getProductPrice(bounty?.product, useType);
  const productFromCart = cart?.products?.[`temp:${bounty?.id}`];
  const flwrOptions = getFlwrOptionsByType(bounty?.product?.sortUnit);

  useEffect(() => {
    const variant = getFlwrVariantByQuantity(productFromCart?.quantity, productFromCart?.sortUnit);

    if (variant) {
      setSelectedVariant(variant);
    }
  }, [productFromCart]);

  const handleAddToCart = () => {
    const selectedQuantity = selectedVariant
      ? getFlwrQuantityByVariant(selectedVariant, bounty?.product?.sortUnit)
      : 0;

    if (cart?.products?.[`temp:${bounty?.id}`]) {
      setQuantity(
        `temp:${bounty?.id}`,
        selectedQuantity,
        (updatedCart) => {
          if (onSuccess) {
            onSuccess(updatedCart);
          }
        },
      );
      toast.success('Product changed successfully!');
      handleTrackAddItemToCart({ bounty, selectedQuantity });
      return;
    }

    addItem({
      bounty,
      quantity: selectedQuantity,
      onSuccess: (updatedCart) => {
        toast.success('Product added to cart!');
        handleTrackAddItemToCart({ bounty, selectedQuantity });
        if (onSuccess) {
          onSuccess(updatedCart);
        }
      },
    });
  };

  if (allowableQuantity <= 0) {
    return (
      <div>
        <Typography variant="body2" color="error">
          Out of stock
        </Typography>
        {children({
          onAddToCart: handleAddToCart,
          isDisabled: true,
          label: 'Add to cart',
        })}
      </div>
    );
  }

  return (
    <>
      <Grid container columnSpacing={3} rowSpacing={3}>
        {flwrOptions?.map(({ key, value }) => {
          const optionsCount = flwrOptions?.length;
          return (
            <Grid
              key={value}
              size={kioskMode ? [12 / optionsCount] : [12, 6, 6, 12 / optionsCount]}
            >
              <PriceVariant
                label={key}
                variant={value}
                useType={useType}
                productPrice={productPrice?.price}
                tierPrices={tierPrices}
                isDisabled={allowableQuantity < getFlwrQuantityByVariant(value, bounty?.product?.sortUnit)}
                isActive={value === selectedVariant}
                onClick={() => setSelectedVariant(value)}
                sortUnit={bounty?.product?.sortUnit}
              />
            </Grid>
          );
        })}
      </Grid>

      {children({
        onAddToCart: handleAddToCart,
        isDisabled: getFlwrVariantByQuantity(productFromCart?.quantity, productFromCart?.sortUnit) === selectedVariant,
        label: productFromCart ? 'Change cart selection' : 'Add to cart',
      })}
    </>
  );
};

export default PriceVariantSelector;
