import { Box, SelectChangeEvent, Typography } from '@mui/material';
import { get } from 'lodash';
import { ChangeEvent, FC } from 'react';

import ChipButton from 'components/ChipButton';
import { StyledCustomDropdown } from 'components/StoreLocationForm/index.styled';

import { STATE_WITH_PLACEHOLDER, STORE_WITH_PLACEHOLDER } from 'constants/fields';
import { USE_TYPE_OPTIONS } from 'constants/options';
import { Option } from 'types/option.interface';

interface StoreLocationFormProps {
  data: any;
  onChange: (name: string, value: string) => void;
  stateOptions: Option[];
  storeOptions: Option[];
}

const StoreLocationForm:FC<StoreLocationFormProps> = ({
  data, onChange, stateOptions, storeOptions,
}) => {
  const handleUseTypeChanges = (value: string) => {
    onChange('useType', value);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement> | SelectChangeEvent) => {
    const { value, name } = e?.target || {};
    onChange(name, value);
  };

  return (
    <Box>
      <ChipButton
        hasCheckmark
        sx={{ mb: 1 }}
        isActive={data.useType === USE_TYPE_OPTIONS[0].key}
        onClick={() => handleUseTypeChanges(USE_TYPE_OPTIONS[0].key)}
      >
        <div>
          <Typography fontWeight={700} className="title">
            {USE_TYPE_OPTIONS[0].value.toUpperCase()}
          </Typography>
          <Typography variant="body2">
            No medical card
          </Typography>
        </div>
      </ChipButton>

      <ChipButton
        hasCheckmark
        isActive={data.useType === USE_TYPE_OPTIONS[1].key}
        onClick={() => handleUseTypeChanges(USE_TYPE_OPTIONS[1].key)}
      >
        <div>
          <Typography fontWeight={700} className="title" variant="body1">
            {USE_TYPE_OPTIONS[1].value.toUpperCase()}
          </Typography>
          <Typography variant="body2">
            Medical card required
          </Typography>
        </div>
      </ChipButton>

      <Box mt={4} sx={{ '& > *': { mb: 1 } }}>
        {stateOptions?.length > 1 && (
          <StyledCustomDropdown
            {...STATE_WITH_PLACEHOLDER}
            variant="outlined"
            value={get(data, STATE_WITH_PLACEHOLDER.name) || ''}
            onChange={(e) => handleChange(e as SelectChangeEvent)}
            options={stateOptions}
          />
        )}
        {storeOptions?.length > 1 && (
          <StyledCustomDropdown
            {...STORE_WITH_PLACEHOLDER}
            variant="outlined"
            value={get(data, STORE_WITH_PLACEHOLDER.name) || ''}
            onChange={(e) => handleChange(e as SelectChangeEvent)}
            options={storeOptions}
          />
        )}
      </Box>
    </Box>
  );
};

export default StoreLocationForm;
