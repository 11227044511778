import { FormControl, FormLabel, Typography } from '@mui/material';
import { TimePicker, TimeStepOptions, TimeView } from '@mui/x-date-pickers';
import { FC } from 'react';

interface TimePickerProps {
  fullWidth?: boolean;
  required?: boolean;
  label?: string;
  onChange: (date: any, name: string) => unknown;
  value: Date | undefined | null;
  size?: 'small' | 'medium' | undefined;
  name: string;
  error?: boolean;
  helperText?: string;
  ampm?: boolean;
  disabled?: boolean;
  minTime?: Date;
  timeSteps?: TimeStepOptions;
  shouldDisableTime?: (value: Date | null, view: TimeView) => boolean;
}

const CustomTimePicker :FC<TimePickerProps> = (props) => {
  const {
    label,
    fullWidth = false,
    required,
    size,
    onChange,
    name,
    shouldDisableTime,
    error,
    helperText,
    ...remainingProps
  } = props;

  const handleChange = (date: any) => {
    const formattedDate = new Date(date).getTime();
    onChange(formattedDate ? date : null, name);
  };

  return (
    <FormControl component="div" fullWidth={fullWidth}>
      {label && (
        <FormLabel required={required}>
          <Typography component="span" color="textPrimary">
            {label}
          </Typography>
        </FormLabel>
      )}
      <TimePicker
        {...remainingProps}
        onChange={handleChange}
        slotProps={{ textField: { size, error, helperText } }}
        shouldDisableTime={shouldDisableTime}
      />
    </FormControl>
  );
};

export default CustomTimePicker;
