import { Box, Grid, Typography } from '@mui/material';
import { FC } from 'react';

import {
  BorderLinearProgress,
  ProgressWrapper,
  AmountWrapper,
  GridHeader,
} from 'containers/MedicalBalance/BalanceInformation/index.styled';

import { BALANCE_PERIOD_LABELS } from 'constants/labels';
import { BalanceCatInfo } from 'types/cart.interface';

interface BalanceInformationProps {
  balances: {
    [key: string]: BalanceCatInfo;
  };
}

const BalanceInformation: FC<BalanceInformationProps> = ({ balances }) => (
  <>
    <GridHeader container>
      <Grid item xs={5.5} md={5} lg={5.5} />

      <Grid item xs={3.5} md={4} lg={3.5} height="100%">
        <Typography variant="body2" fontWeight={700}>
          Intake
        </Typography>
      </Grid>

      <Grid item xs={3} height="100%">
        <Typography variant="body2" fontWeight={700}>
          Limit
        </Typography>
      </Grid>
    </GridHeader>

    <Box display="flex" flexDirection="column" gap={2} mt={6}>
      {Object.values(balances).map((balance) => {
        const {
          limit, amount, name, period, uom,
        } = balance;
        const periodMessage = period?.type ? BALANCE_PERIOD_LABELS[period?.type] : null;
        const value = amount && limit ? Math.min((amount * 100) / limit, 100) : 0;
        const hasReachedLimit = amount && limit ? amount > limit : false;

        return (
          <Box zIndex={1} key={balance.key}>
            <Grid container alignItems="flex-end">
              <Grid item xs={5.5} md={5} lg={5.5} pr={1}>
                <Typography variant="body2">
                  <b>{name}</b>
                  {periodMessage ? ` for ${periodMessage} usage` : ' '}
                </Typography>
              </Grid>

              <Grid item xs={3.5} md={4} lg={3.5} pl={1.25}>
                {amount && (
                  <AmountWrapper hasReachedLimit={hasReachedLimit}>
                    <Typography variant="body2" fontWeight={hasReachedLimit ? 600 : 400}>
                      {amount}
                      {uom ? uom.toLowerCase() : ''}
                    </Typography>
                  </AmountWrapper>
                )}
              </Grid>

              <Grid item xs={3} pl={1.25}>
                {limit && (
                  <Typography variant="body2">
                    {limit}
                    {uom ? uom.toLowerCase() : ''}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <ProgressWrapper amount={amount} limit={limit}>
              <BorderLinearProgress variant="determinate" value={value} />
            </ProgressWrapper>
          </Box>
        );
      })}
    </Box>
  </>
);

export default BalanceInformation;
