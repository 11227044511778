import ImportExportIcon from '@mui/icons-material/ImportExport';
import {
  Drawer, Typography,
} from '@mui/material';
import { FC, useState } from 'react';

import StyledActionButton from 'components/StyledActionButton';

import { useKiosk } from 'hooks/useKiosk';

import { ProductSortOptions } from 'constants/enums';
import { PRODUCT_SORT_OPTIONS } from 'constants/options';

import { Option, OptionsWrapper } from './index.styled';

interface ProductSortFlyoutProps {
  sortOption: ProductSortOptions;
  onChange: (value: ProductSortOptions) => void;
}

const ProductSortFlyout:FC<ProductSortFlyoutProps> = ({
  sortOption,
  onChange,
}) => {
  const { kioskMode } = useKiosk();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => setIsOpen(false);

  const handleSelect = (value: ProductSortOptions) => {
    onChange(value);
    handleClose();
  };

  return (
    <div>
      <StyledActionButton
        icon={<ImportExportIcon />}
        text="Sorting"
        onClick={handleOpen}
        kioskMode={kioskMode}
      />

      {isOpen && (
        <Drawer
          variant="temporary"
          anchor="right"
          open={isOpen}
          onClose={handleClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          PaperProps={{
            sx: { px: 5, py: 7, minWidth: '500px' },
          }}
        >
          <Typography variant="body1" fontWeight={700} mb={7}>
            Sort items by:
          </Typography>

          <OptionsWrapper>
            {PRODUCT_SORT_OPTIONS.map(({ value, key }) => (
              <Option
                key={key}
                isActive={key === sortOption}
                onClick={() => handleSelect(key)}
              >
                {value}
              </Option>
            ))}
          </OptionsWrapper>
        </Drawer>
      )}
    </div>
  );
};

export default ProductSortFlyout;
