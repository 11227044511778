import { toast } from 'react-toastify';

const forceDownload = (url: string, filename: string) => {
  const a = document.createElement('a');
  a.download = filename;
  a.href = url;
  // For Firefox https://stackoverflow.com/a/32226068
  document.body.appendChild(a);
  a.click();
  a.remove();
};

// Current blob size limit is around 500MB for browsers
export const downloadFile = (url: string, filename: string) => fetch(url, {
  headers: new Headers({
    Origin: window.location.origin,
  }),
  mode: 'cors',
})
  .then((response) => response.blob())
  .then((blob) => forceDownload(window.URL.createObjectURL(blob), filename))
  .catch((e) => toast.error(e.message));
