import { Box, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import { FC } from 'react';

import Loader from 'components/Loader';
import { Content, ContentCard } from 'containers/KioskPaymentMethods/index.styled';

import payWithCardAnimation from 'assets/animations/payWithCardOption.json';

import { PayButton } from './index.styled';

interface HowToPayProps {
  onCancel: () => void;
  onPayAtVendor: () => void;
  onPayWithCardHere: () => void;
  isSubmitting: boolean;
  hasPaymentFailed: boolean;
}

const HowToPay: FC<HowToPayProps> = ({
  onCancel,
  onPayWithCardHere,
  onPayAtVendor,
  isSubmitting,
  hasPaymentFailed,
}) => {
  const handlePlayWithCard = () => {
    if (!isSubmitting) {
      onPayWithCardHere();
    }
  };

  return (
    <>
      {hasPaymentFailed && (
        <Typography variant="h3" textAlign="center">
          Payment unsuccessful. Please try again.
        </Typography>
      )}

      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box flexGrow={1} />

        <Content>
          <ContentCard sx={{ cursor: 'pointer' }} onClick={handlePlayWithCard}>
            <Lottie animationData={payWithCardAnimation} loop />
            <PayButton
              id="btn-pay-with-card"
              sx={{ mt: 3 }}
              disabled={isSubmitting}
            >
              Pay with card here
            </PayButton>
          </ContentCard>

          <PayButton
            id="btn-pay-at-vendor"
            onClick={onPayAtVendor}
            variant="outlined"
            disabled={isSubmitting}
          >
            Pay at the counter
          </PayButton>
        </Content>

        <Box display="flex" alignItems="flex-end" justifyContent="center" mb={10} flexGrow={2}>
          {hasPaymentFailed && (
            <PayButton
              id="btn-cancel-order"
              onClick={onCancel}
              variant="outlined"
              disabled={isSubmitting}
            >
              Cancel Order
            </PayButton>
          )}
        </Box>
      </Box>

      {isSubmitting && <Loader />}
    </>
  );
};

export default HowToPay;
