import { styled } from '@mui/material/styles';

export const View = styled('div')(({ theme }) => ({
  height: '80vh',
  width: '25vw',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('lg')]: {
    width: '35vw',
  },
  [theme.breakpoints.down('md')]: {
    width: '50vw',
  },
  [theme.breakpoints.down('sm')]: {
    width: '95vw',
  },
}));

export const ChatArea = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  overflow: 'auto',
  flexGrow: 1,
}));
