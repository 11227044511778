import {
  format,
  getDay,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
  isEqual,
  isFriday,
  isMonday,
  isSameDay,
  isSaturday,
  isThursday,
  isToday,
  isTuesday,
  isWednesday,
  parse,
  setHours,
  setMinutes,
  subMinutes,
} from 'date-fns';
import { isNil } from 'lodash';

import { DeliveryMethod, OperationStatus } from 'constants/enums';
import {
  DEFAULT_DATE_FORMAT, PICKUP_MINUTES_STEP, SCHEDULE_DATE_FORMAT, TIMESTAMP_MINUTE,
} from 'constants/general';
import { HourlyOrder, OrderDateRestrictions } from 'types/checkout.interface';
import {
  DailyHoursOfOperation, ShopOperation,
  SpecialDateRange,
  TimeOfDay,
  WeeklyHoursOfOperation,
} from 'types/shopOperation.interface';
import { isPickupMethod } from 'utils/checkoutUtils';
import { capitalize } from 'utils/formatters';
import { getShopSettingsFromStorage } from 'utils/storageUtils';

const getWeekDaySchedule = (week: WeeklyHoursOfOperation, date: Date) => {
  const formattedDate = new Date(date);

  if (isMonday(formattedDate)) {
    return week?.mon;
  }

  if (isTuesday(formattedDate)) {
    return week?.tue;
  }

  if (isWednesday(formattedDate)) {
    return week?.wed;
  }

  if (isThursday(formattedDate)) {
    return week?.thu;
  }

  if (isFriday(formattedDate)) {
    return week?.fri;
  }

  if (isSaturday(formattedDate)) {
    return week?.sat;
  }

  return week?.sun;
};

export const getShopSpecialSchedule = (date: Date, deliveryMethod: DeliveryMethod) => {
  const shopSettings = getShopSettingsFromStorage() || {};
  const { specialHoursOfOperation } = shopSettings?.effectiveShopOperation || {};

  const shopSpecialHours = isPickupMethod(deliveryMethod)
    ? specialHoursOfOperation?.pickup
    : specialHoursOfOperation?.delivery;

  const { specialDateRanges } = shopSpecialHours || { specialDateRanges: [] };

  return specialDateRanges.find(({ fromDate, hoursOfOperation }) => {
    const formattedDate = parse(fromDate, SCHEDULE_DATE_FORMAT, new Date());

    if (isEqual(date, formattedDate) && hoursOfOperation?.operationStatus === OperationStatus.Closed) {
      return true;
    }

    return false;
  });
};

export const getShopScheduleByDate = (date: Date, deliveryMethod: DeliveryMethod) => {
  const shopSettings = getShopSettingsFromStorage() || {};
  const { hoursOfOperation } = shopSettings?.effectiveShopOperation || {};

  const shopHoursOfOperation = isPickupMethod(deliveryMethod)
    ? hoursOfOperation?.pickup
    : hoursOfOperation?.delivery;

  return shopHoursOfOperation ? getWeekDaySchedule(shopHoursOfOperation, date) : null;
};

export const isShopClosed = (date: Date, deliveryMethod: DeliveryMethod) => {
  const specialDate = getShopSpecialSchedule(date, deliveryMethod);

  if (specialDate && specialDate?.hoursOfOperation?.operationStatus === OperationStatus.Closed) {
    return true;
  }

  const shopOperationSchedule = getShopScheduleByDate(date, deliveryMethod);

  if (shopOperationSchedule && shopOperationSchedule?.operationStatus === OperationStatus.Closed) {
    return true;
  }

  return false;
};

const transformHour = (hour: number, ampm: string) => {
  if (hour === 12) {
    return ampm === 'AM' ? 0 : 12;
  }

  if (ampm === 'AM') {
    return hour;
  }

  return hour + 12;
};

const substractLeadTime = (time: TimeOfDay, leadTime: number) => {
  const [fromH, fromM] = time.hour.split(':');

  let now = new Date();
  now = setHours(now, +fromH);
  now = setMinutes(now, +fromM);
  now = subMinutes(now, leadTime);
  const hour = getHours(now);
  const minutes = getMinutes(now);

  return [hour, minutes];
};

export const getTimeRestrictions = (hoursOfOperation: DailyHoursOfOperation, date: Date) => {
  const {
    operationStatus,
    from,
    to,
    leadTime,
  } = hoursOfOperation;

  if (operationStatus === OperationStatus.Closed) {
    return {
      disabled: true,
    };
  }

  if (from && to) {
    const [fromH, fromM] = from.hour.split(':');
    const [toH, toM] = leadTime ? substractLeadTime(to, leadTime) : to.hour.split(':');

    return {
      minTime: isToday(date)
        ? new Date()
        : new Date(new Date(date).setHours(transformHour(+fromH, from.ampm), +fromM, 0, 0)),
      maxTime: new Date(new Date(date).setHours(transformHour(+toH, to.ampm), +toM, 0, 0)),
    };
  }

  return {};
};

export const isShopCloseByTime = (date: Date, deliveryMethod: DeliveryMethod) => {
  const specialDate = getShopSpecialSchedule(date, deliveryMethod);

  if (specialDate) {
    const restrictions = getTimeRestrictions(specialDate?.hoursOfOperation || {}, date);

    if (Object.keys(restrictions).length) {
      return restrictions;
    }
  }

  const shopOperationSchedule = getShopScheduleByDate(date, deliveryMethod);

  if (shopOperationSchedule) {
    return getTimeRestrictions(shopOperationSchedule, date);
  }

  return {};
};

export const formatDisplayHour = ({ hour, ampm }: TimeOfDay) => `${hour} ${ampm}`;

export const formatAdvanceHours = (from: TimeOfDay, to: TimeOfDay) => {
  if (from?.ampm === to?.ampm) {
    return {
      formattedFrom: from?.hour,
      formattedTo: `${to?.hour} ${to?.ampm}`,
    };
  }

  return {
    formattedFrom: formatDisplayHour(from),
    formattedTo: formatDisplayHour(to),
  };
};

export const formatDailyHours = (dayKey: string, dailyHours: DailyHoursOfOperation) => {
  const { from, to, operationStatus } = dailyHours;

  if (!from || !to) {
    return {};
  }

  const { formattedFrom, formattedTo } = formatAdvanceHours(from, to);
  let formattedStatus = null;

  if (operationStatus !== OperationStatus.Open) {
    formattedStatus = operationStatus;
  }

  return {
    formattedDay: dayKey,
    formattedHours: `${formattedFrom}-${formattedTo}`,
    formattedStatus,
  };
};

export const getGroupedDailyHours = (weeklyHours: WeeklyHoursOfOperation) => {
  const groupedIdenticalDays: any = [];

  if (!weeklyHours) {
    return null;
  }

  Object
    .keys(weeklyHours)
    .forEach((dayKey, dayIndex) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const dailyHours = weeklyHours?.[dayKey];
      const { formattedHours, formattedStatus } = formatDailyHours(dayKey, dailyHours);

      if (!formattedHours && !formattedStatus) {
        return;
      }

      const dayDetails = { label: dayKey };
      const groupKey = formattedStatus || formattedHours;

      const foundIndex = groupedIdenticalDays.findIndex(({ key: itemKey, lastIndex }: any) => (
        itemKey === groupKey && lastIndex + 1 === dayIndex
      ));

      if (groupedIdenticalDays[foundIndex]) {
        groupedIdenticalDays[foundIndex].lastIndex = dayIndex;
        groupedIdenticalDays[foundIndex].days.push(dayDetails);
      } else {
        groupedIdenticalDays.push({
          key: groupKey,
          lastIndex: dayIndex,
          days: [dayDetails],
        });
      }
    });

  const schedule: string[] = [];

  groupedIdenticalDays.forEach((groupedDays: any) => {
    const { key, days } = groupedDays;
    const daysLength = days?.length;

    if (days && daysLength) {
      const from = days[0]?.label;
      const to = days[daysLength - 1]?.label;
      const period = days.length >= 2 ? [[capitalize(from), capitalize(to)].join('-')] : [capitalize(from)];

      schedule.push([period, key].join(': '));
    }
  });

  return schedule.join(', ');
};

export const formatSpecialDateRange = ({ fromDate, toDate, hoursOfOperation }: SpecialDateRange) => {
  const periodParts = [];
  const hoursOfOperationParts = [];
  const formattedFromDate = format(new Date(fromDate), DEFAULT_DATE_FORMAT);
  const formattedToDate = format(new Date(toDate), DEFAULT_DATE_FORMAT);

  if (isSameDay(new Date(fromDate), new Date(toDate))) {
    periodParts.push(`${format(new Date(fromDate), 'EEEE')} ${fromDate}`);
  } else {
    periodParts.push(`${formattedFromDate} - ${formattedToDate}`);
  }

  if (hoursOfOperation?.operationStatus === OperationStatus.Closed) {
    hoursOfOperationParts.push('Closed');
  } else {
    hoursOfOperationParts.push('Open');

    if (hoursOfOperation.from && hoursOfOperation.to) {
      const hours = `${formatDisplayHour(hoursOfOperation.from)}-${formatDisplayHour(hoursOfOperation.to)}`;
      hoursOfOperationParts.push(hours);
    }
  }

  return [periodParts.join(', '), hoursOfOperationParts.join(' ')].join(', ');
};

export const checkSchedule = () => {
  const shopSettings = getShopSettingsFromStorage() || {};
  const { deliveryMethods, effectiveShopOperation } = shopSettings || {};
  const { hoursOfOperation, specialHoursOfOperation } = effectiveShopOperation || {};
  const { pickup, delivery } = hoursOfOperation || {};
  const pickupSchedule = pickup && deliveryMethods && deliveryMethods.indexOf('PICKUP') > -1
    ? getGroupedDailyHours(pickup)
    : null;
  const deliverySchedule = delivery && deliveryMethods && deliveryMethods.indexOf('DELIVERY') > -1
    ? getGroupedDailyHours(delivery)
    : null;

  return {
    hasSchedule: Boolean(
      !!pickupSchedule
      || !!deliverySchedule
      || specialHoursOfOperation?.pickup?.specialDateRanges?.length
      || specialHoursOfOperation?.delivery?.specialDateRanges?.length,
    ),
    pickupSchedule,
    deliverySchedule,
    specialHoursOfOperation,
  };
};

export const getDateFromTime = (time: string, date: Date) => {
  const [hour, minute] = time.split(':');

  return new Date(date).setHours(+hour, +minute, 0, 0);
};

export const isValidHourForPlacingOrder = (date: Date | null, pickupLimit: OrderDateRestrictions | null) => {
  const time = date?.getTime();
  const { hourlyOrders, pickupLimit: orderLimit } = pickupLimit || {};

  if (!pickupLimit || isNil(date)) {
    return true;
  }

  return hourlyOrders?.some((hourlyOrder) => {
    const { from, to, ordersPlaced } = hourlyOrder || {};
    const formattedFrom = getDateFromTime(from, date);
    const formattedTo = getDateFromTime(to, date);

    return orderLimit
      && time
      && !isBefore(time, formattedFrom)
      && !isAfter(time, formattedTo)
      && ordersPlaced < orderLimit;
  });
};

const isCurrentTimeValid = (
  time: number,
  pickupLimit: number,
  hourlyOrders: HourlyOrder[],
  date: Date,
) => !!hourlyOrders.find(({ from, to, ordersPlaced }) => {
  const formattedFrom = getDateFromTime(from, date);
  const formattedTo = getDateFromTime(to, date);

  return !isBefore(time, formattedFrom)
    && !isAfter(time, formattedTo)
    && ordersPlaced < pickupLimit;
});

const getRoundedMinutes = (minutes: number, minutesStep: number) => Math.ceil(minutes / minutesStep) * minutesStep;

export const getFirstValidHour = (date: Date, hourlyOrders: HourlyOrder[], pickupLimit: number) => {
  const { from } = hourlyOrders[0] || {};
  const now = new Date();
  const roundedMinutes = getRoundedMinutes(now.getMinutes(), PICKUP_MINUTES_STEP);

  const min = roundedMinutes % 60;
  const hour = now.getHours() + roundedMinutes / 60;

  let currentTime = isToday(date)
    ? new Date(new Date().setHours(hour, min)).getTime()
    : getDateFromTime(from, date);

  let validTime = isCurrentTimeValid(currentTime, pickupLimit, hourlyOrders, date);

  while (!validTime && getDay(currentTime) === getDay(date)) {
    validTime = isCurrentTimeValid(currentTime, pickupLimit, hourlyOrders, date);
    currentTime += (TIMESTAMP_MINUTE * PICKUP_MINUTES_STEP);
  }

  const isSameDay = getDay(currentTime) === getDay(date);

  return isSameDay ? currentTime : null;
};

export const hasShopDeliveryOpen = (effectiveShopOperation?: ShopOperation) => {
  if (!effectiveShopOperation) {
    return false;
  }

  const { hoursOfOperation, specialHoursOfOperation } = effectiveShopOperation;
  const deliveryHours = hoursOfOperation?.delivery;
  const specialDeliveryHours = specialHoursOfOperation?.delivery;
  let hasDeliveryHours = false;

  if (deliveryHours) {
    hasDeliveryHours = Object.values(deliveryHours).some((deliveryHour) => (
      deliveryHour && deliveryHour.operationStatus !== OperationStatus.Closed
    ));
  }

  if (specialDeliveryHours && !hasDeliveryHours) {
    hasDeliveryHours = specialDeliveryHours.specialDateRanges?.some((deliveryHour) => (
      deliveryHour?.hoursOfOperation?.operationStatus !== OperationStatus.Closed
    ));
  }

  return hasDeliveryHours;
};
