import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledImage = styled('img')(({ theme }) => ({
  height: '70px',
  width: '70px',
  marginRight: theme.spacing(1.5),
  borderRadius: '4px',
  objectFit: 'contain',
}));

export const StyledBox = styled('div')<{hasBottomBorder: boolean}>(({ theme, hasBottomBorder }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.main,
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '4px',
  borderBottomLeftRadius: hasBottomBorder ? '4px' : 0,
  borderBottomRightRadius: hasBottomBorder ? '4px' : 0,
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const StyledPrice = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(10),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: theme.spacing(2),
}));
