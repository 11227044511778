import CardMediaMui from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';

import { getShopConfiguration } from 'utils/storeUtils';

const { asFullPage, hideHeader } = getShopConfiguration();

export const Content = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.subheaderBackground.main,
  marginTop: theme.spacing(asFullPage ? 8 : 0),
  height: `calc(100vh - ${hideHeader ? '0px' : '64px'})`,
}));

export const CardMedia = styled(CardMediaMui)(({ theme }) => ({
  backgroundColor: theme.palette.subheaderBackground.main,
  height: 'calc(100vh - 96px)',
  width: '100%',
  objectFit: 'cover',
  borderRadius: '8px',
  margin: theme.spacing(2, 0),
})) as typeof CardMediaMui;
