import FilterVintageOutlinedIcon from '@mui/icons-material/FilterVintageOutlined';
import ScienceIcon from '@mui/icons-material/Science';
import SpaIcon from '@mui/icons-material/Spa';

export enum ChemicalIconsDef {
  SPA= 'mir:spa',
  SCIENCE = 'mir:science',
  VINTAGE = 'mir:filter_vintage',
}

const CHEMICAL_ICONS = {
  [ChemicalIconsDef.SPA]: SpaIcon,
  [ChemicalIconsDef.SCIENCE]: ScienceIcon,
  [ChemicalIconsDef.VINTAGE]: FilterVintageOutlinedIcon,
};

export const getChemicalIcon = (iconDef: ChemicalIconsDef) => CHEMICAL_ICONS[iconDef] || FilterVintageOutlinedIcon;
