import { toast } from 'react-toastify';

import { Reward } from 'types/money.interface';
import { addMoney, isZero } from 'utils/moneyUtils';
import { addPoints, sumOfAbs } from 'utils/pointsUtils';

export function isRewardEmpty(reward: Reward) {
  return !hasMoney(reward) && !hasPoints(reward);
}

export function hasMoney(reward: Reward, allowZero = false) {
  const amount = reward.money?.amount || 0;
  return reward && reward.money && !Number.isNaN(+amount) && (allowZero || !isZero(reward.money));
}

export function hasPoints(reward: Reward) {
  return reward && reward.points && sumOfAbs(reward.points) > 0;
}

export function add(existingReward: Reward, rewardToAdd: Reward | null) {
  if (!existingReward) {
    return rewardToAdd;
  }

  if (!rewardToAdd || isRewardEmpty(rewardToAdd)) {
    return existingReward;
  }

  if (isRewardEmpty(rewardToAdd)) {
    return rewardToAdd;
  }

  if (hasMoney(existingReward) && hasMoney(rewardToAdd)) {
    if (existingReward?.money?.currency !== rewardToAdd?.money?.currency) {
      toast.error('Cannot have more than one currency in a reward');
      return null;
    }
  }

  return {
    money: addMoney(existingReward.money, rewardToAdd.money) || null,
    points: addPoints(existingReward?.points, rewardToAdd.points) || null,
  };
}
