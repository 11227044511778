import MuiChip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

import { getShopConfiguration } from 'utils/storeUtils';

const { embed } = getShopConfiguration();

export const Chip = styled(MuiChip)(({ theme }) => ({
  padding: theme.spacing(0.5, 0.5),
  borderRadius: '20px',
  backgroundColor: theme.palette.common.white,
  height: '35px',
  width: 'auto',

  '& .MuiChip-icon': {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(0.5),
    color: theme.palette.text.secondary,
  },

  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    paddingRight: 0,
    '& .MuiChip-label': {
      display: embed ? 'inherit' : 'none',
    },
    '& .MuiChip-icon': {
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
    },
  },
  '&.MuiButtonBase-root.MuiChip-root.Mui-disabled': {
    opacity: 1,
  },
  '&.MuiButtonBase-root.MuiChip-root.MuiChip-clickable:hover': {
    backgroundColor: theme.palette.background.secondary,
  },

  [theme.breakpoints.down('sm')]: {
    '& .MuiChip-icon': {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
}));
