import { wrapCreateBrowserRouter } from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';

import ErrorBoundary from 'components/_ErrorBoundary';
import MissingClient from 'components/_MissingClient';
import PreviewCartPage from 'components/PreviewCartPage';
import ShopClosed from 'components/ShopClosed';
import CartListing from 'containers/CartListingPage';
import CheckoutPage from 'containers/CheckoutPage';
import DashboardPage from 'containers/DashboardPage';
import LandingPage from 'containers/LandingPage';
import MainShop from 'containers/MainShop';
import OrderConfirmationPage from 'containers/OrderConfirmationPage';
import OrderDetailsPage from 'containers/OrderDetailsPage';
import OrderDigitalReceiptPage from 'containers/OrderDigitalReceiptPage';
import OrdersPage from 'containers/OrdersPage';
import PaymentWidgetPage from 'containers/PaymentWidgetPage';
import PreviewOnboardingPage from 'containers/PreviewOnboardingPage';
import PreviewProductPage from 'containers/PreviewProductPage';
import PreviewStoreChemicalsPage from 'containers/PreviewStoreChemicalsPage';
import PreviewStorePage from 'containers/PreviewStorePage';
import ProductDetailsPage from 'containers/ProductDetailsPage';
import ProductsProviderPage from 'containers/ProductsProviderPage';
import GlobalProviders from 'context/GlobalProviders';
import { LocationContextProvider } from 'context/LocationContext';
import CheckoutLayout from 'layouts/CheckoutLayout';
import MainLayout from 'layouts/MainLayout';

import { hasClientParams, isCompactMode } from 'hooks/useRouting';

import * as routes from 'constants/clientRoutes';
import { ClientErrorCodes } from 'constants/enums';
import { DetailedClientInfo } from 'types/clientInfo.interface';
import { ensureBasename, ensureClientParams } from 'utils/clientUtils';
import { isProdEnv } from 'utils/utils';

const sentryCreateBrowserRouter = isProdEnv()
  ? wrapCreateBrowserRouter(createBrowserRouter)
  : createBrowserRouter;

const previewRoutes = [
  {
    path: routes.PREVIEW_STORE,
    element: <PreviewStorePage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.PREVIEW_CHEMICALS_IN_STORE,
    element: <PreviewStoreChemicalsPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.PREVIEW_CART,
    element: <PreviewCartPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.PREVIEW_ONBOARDING,
    element: <PreviewOnboardingPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.PREVIEW_PRODUCT,
    element: <PreviewProductPage />,
    errorElement: <ErrorBoundary />,
  },
];

const clientErrorRoutes = [
  ...previewRoutes,
  {
    path: '*',
    element: <MissingClient />,
    errorElement: <ErrorBoundary />,
  },
];

const shopClosedRoutes = [
  ...previewRoutes,
  {
    path: '*',
    element: <ShopClosed />,
    errorElement: <ErrorBoundary />,
  },
];

const shopRoutes = [
  ...previewRoutes,
  {
    element: <GlobalProviders />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <CheckoutLayout />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: routes.CART_ROUTE,
            element: <CartListing />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: routes.CHECKOUT_ROUTE,
            element: <CheckoutPage />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: routes.CHECKOUT_COMPLETE_ROUTE,
            element: <OrderConfirmationPage />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: routes.CHECKOUT_PAYMENT_WIDGET_ROUTE,
            element: <PaymentWidgetPage />,
            errorElement: <ErrorBoundary />,
          },
        ],
      },
      {
        element: <MainLayout />,
        path: routes.HOME_ROUTE,
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: routes.HOME_ROUTE,
            element: <LandingPage />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: routes.DASHBOARD_ROUTE,
            element: <DashboardPage />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: routes.PRODUCTS_ROUTE,
            element: <ProductsProviderPage />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: routes.PRODUCT_DETAILS_ROUTE,
            element: <ProductDetailsPage />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: routes.ORDERS_ROUTE,
            element: <OrdersPage />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: routes.ORDER_DETAILS_ROUTE,
            element: <OrderDetailsPage />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: routes.CART_ROUTE,
            element: <CartListing />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: routes.CHECKOUT_ROUTE,
            element: <CheckoutPage />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: routes.CHECKOUT_COMPLETE_ROUTE,
            element: <OrderConfirmationPage />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: routes.CHECKOUT_PAYMENT_WIDGET_ROUTE,
            element: <PaymentWidgetPage />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: routes.SHOP_CLOSED_ROUTE,
            element: <ShopClosed />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: routes.STORE_ROUTE,
            element: <MainShop />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: '*',
            element: <LandingPage />,
            errorElement: <ErrorBoundary />,
          },
        ],
      },
    ],
  },
  {
    path: routes.ORDER_DIGITAL_RECEIPT_ROUTE,
    element: <LocationContextProvider><OrderDigitalReceiptPage /></LocationContextProvider>,
    errorElement: <ErrorBoundary />,
  },
];

const compactRoutes = [
  {
    element: <GlobalProviders />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <MainLayout />,
        path: routes.HOME_ROUTE,
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: routes.HOME_ROUTE,
            element: <MainShop />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: '*',
            element: <MainShop />,
            errorElement: <ErrorBoundary />,
          },
        ],
      },
    ],
  },
];

const getRouter = (clientError: ClientErrorCodes, isClosed: boolean, detailedClientData?: DetailedClientInfo) => {
  let routes = shopRoutes;
  const isCompact = isCompactMode();
  const { basePath, clientInfo } = detailedClientData || {};

  if (clientError) {
    routes = clientErrorRoutes;
  } else if (isClosed) {
    routes = shopClosedRoutes;
  }

  if (basePath && !isCompact) {
    ensureBasename(basePath);
  } else if (isCompact && hasClientParams() && clientInfo && clientInfo.parentId) {
    ensureClientParams(clientInfo);
  }

  return sentryCreateBrowserRouter(
    isCompact ? compactRoutes : routes,
    { basename: isCompact ? '/' : basePath },
  );
};

export default getRouter;
