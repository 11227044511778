import MuiChip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

export const Chip = styled(MuiChip)(({ theme }) => ({
  paddingLeft: '4px',
  paddingRight: '4px',
  borderRadius: '20px',
  backgroundColor: theme.palette.common.white,

  '&.MuiButtonBase-root.MuiChip-root.MuiChip-clickable:hover': {
    backgroundColor: theme.palette.background.secondary,
  },

  '& .MuiChip-icon': {
    display: 'none',
  },

  [theme.breakpoints.only('xs')]: {
    '& .MuiChip-label': {
      display: 'none',
    },

    '& .MuiChip-icon': {
      display: 'inherit',
      margin: 0,
    },
  },
})) as typeof MuiChip;
