import { CircularProgress } from '@mui/material';
import { FC } from 'react';

import { StyledBox } from 'components/Loader/index.styled';

const Loader:FC = () => (
  <StyledBox>
    <CircularProgress />
  </StyledBox>
);

export default Loader;
