import { getYear } from 'date-fns';
import { isNil } from 'lodash';
import zipState from 'zip-state';

import {
  AgeFilterType, DeliveryMethod, MedicalIdPromptType, PaymentMethod, ProductUseType,
} from 'constants/enums';
import { USE_TYPE_OPTIONS } from 'constants/options';
import { ApiLocation, LocationStores } from 'types/companyLocations.interface';
import { Option } from 'types/option.interface';
import { ShopSettings } from 'types/shopSettings.interface';
import { hasShopDeliveryOpen } from 'utils/shopOperation';
import {
  getClientDetailedInfo,
  getClientInfo,
  getCompanyLocationsData,
  getOnboardingInfo,
  getShopSettingsFromStorage,
} from 'utils/storageUtils';

export const getShopConfiguration = () => {
  const shopConfiguration = window.shopConfiguration || {};
  shopConfiguration.asFullPage = isNil(shopConfiguration.asFullPage) ? true : shopConfiguration.asFullPage;
  return shopConfiguration;
};

export const addStateToUsage = (states: Record<string, Option[]>, usage: string, state: Option) => {
  if (states[usage]) {
    const isStateInUsage = states[usage].find((s) => s.key === state.key);

    if (isStateInUsage) {
      return states;
    }
  }

  return {
    ...states,
    [usage]: [
      ...(states[usage] || []),
      state,
    ],
  };
};

export const addStoreToState = (stores: LocationStores, state: string, usage:string, store: Option) => ({
  ...stores,
  [usage]: {
    ...(stores[usage] || {}),
    [state]: [
      ...(stores?.[usage]?.[state] || []),
      store,
    ],
  },
});

export const getFormattedLocationInfo = (locations: ApiLocation[], usStates: Option[]) => {
  let statesByUsage = {} as Record<string, Option[]>;
  let storesByStates = {} as LocationStores;
  let storesLocations = {} as any;
  const locationsWithStores:Option[] = [];

  locations?.forEach((location: ApiLocation) => {
    const {
      address, id, name, adultUse, medicalUse,
    } = location || {};
    const state = address?.state;
    const store = { key: id, value: name };
    const locationState = usStates?.find((state) => state?.key === address?.state);

    if (locationState && (adultUse || medicalUse)) {
      storesLocations = {
        ...storesLocations,
        [state]: [
          ...(storesLocations[state] || []),
          location,
        ],
      };

      if (!locationsWithStores.find(({ key }) => key === locationState.key)) {
        locationsWithStores.push(locationState);
      }

      if (adultUse) {
        statesByUsage = addStateToUsage(statesByUsage, USE_TYPE_OPTIONS[0].key, locationState);
        storesByStates = addStoreToState(storesByStates, state, USE_TYPE_OPTIONS[0].key, store);
      }

      if (medicalUse) {
        statesByUsage = addStateToUsage(statesByUsage, USE_TYPE_OPTIONS[1].key, locationState);
        storesByStates = addStoreToState(storesByStates, state, USE_TYPE_OPTIONS[1].key, store);
      }
    }
  });

  return {
    statesByUsage, storesByStates, locationsWithStores, storesLocations,
  };
};

export const getUseTypeKey = (useType: string): string => (
  Object
    .keys(ProductUseType)
    .find((key) => ProductUseType[key as keyof typeof ProductUseType] === useType)
  || USE_TYPE_OPTIONS[0].key
);

export const getStoresByZipcode = (zipcode: string) => {
  const { list } = getCompanyLocationsData() || {};
  return list
    ?.filter(({ deliveryZipcodes }: any) => deliveryZipcodes?.split(', ')?.includes(zipcode));
};

export const doesStoreDeliverToZipcode = (zipcode: string) => {
  const { store, state } = getOnboardingInfo() || {};

  return zipState(zipcode) === state
    && (!store?.deliveryZipcodes || !!store?.deliveryZipcodes?.split(', ')?.includes(zipcode));
};

export const getStoreDeliveryMethods = (): DeliveryMethod[] => {
  const shopSettings = getShopSettingsFromStorage() || {};
  const { useType = '' } = getOnboardingInfo() || {};
  const hasDeliveryHours = hasShopDeliveryOpen(shopSettings?.effectiveShopOperation);
  const isCurbsideEnabled = !isNil(shopSettings?.deliveryConfigs)
    ? !!shopSettings?.deliveryConfigs?.[DeliveryMethod.CURBSIDE]?.config?.[useType]?.enabled
    : true;

  if (shopSettings?.deliveryMethods) {
    let deliveryMethods = shopSettings?.deliveryMethods.split(',') as DeliveryMethod[];

    if (deliveryMethods?.includes(DeliveryMethod.CURBSIDE) && !isCurbsideEnabled) {
      deliveryMethods = deliveryMethods.filter((deliveryMethod: DeliveryMethod) => (
        deliveryMethod === DeliveryMethod.CURBSIDE
      ));
    }

    return hasDeliveryHours
      ? deliveryMethods
      : deliveryMethods.filter((deliveryMethod) => deliveryMethod !== DeliveryMethod.DELIVERY);
  }

  return Object.keys(DeliveryMethod) as DeliveryMethod[];
};

export const getAvailablePaymentMethods = (paymentMethods?: Record<PaymentMethod, { enabled: boolean }> | null) => {
  const availablePaymentMethods = [] as PaymentMethod[];

  if (paymentMethods) {
    Object.entries(paymentMethods).forEach(([key, { enabled }]) => {
      const paymentMethod = key as PaymentMethod;

      if (enabled) {
        availablePaymentMethods.push(paymentMethod);
      }
    });
  }

  return availablePaymentMethods;
};

export const getStorePaymentMethods = (
  { storedDeliveryMethod }: { storedDeliveryMethod: DeliveryMethod },
): PaymentMethod[] => {
  const shopSettings = getShopSettingsFromStorage() || {};
  const detailedClientData = getClientDetailedInfo();
  const kioskMode = detailedClientData?.kioskMode || false;
  const deliveryMethodInfos = shopSettings?.deliveryMethodInfos;
  const defaultPaymentMethods = {
    CASH: {
      enabled: true,
    },
    DEBIT: {
      enabled: true,
    },
    BILLMYBANK: {
      enabled: false,
    },
    CHARGEE: {
      enabled: false,
    },
  };

  if (!kioskMode && [DeliveryMethod.PICKUP, DeliveryMethod.CURBSIDE].includes(storedDeliveryMethod)) {
    const paymentMethods = deliveryMethodInfos?.[DeliveryMethod.PICKUP]?.paymentMethods;
    return getAvailablePaymentMethods(paymentMethods);
  }

  if (!kioskMode && storedDeliveryMethod === DeliveryMethod.DELIVERY) {
    const paymentMethods = deliveryMethodInfos?.[DeliveryMethod.DELIVERY]?.paymentMethods;
    return getAvailablePaymentMethods(paymentMethods);
  }

  return (Object.keys(defaultPaymentMethods) as (keyof typeof defaultPaymentMethods)[])
    .filter((key) => defaultPaymentMethods?.[key]?.enabled) as PaymentMethod[];
};

export const isAgeValid = (dob: number) => {
  const shopSettings = getShopSettingsFromStorage() || {};
  const allowAge = shopSettings?.filterByAge || 18;
  const userAge = new Date().getFullYear() - getYear(dob);

  return userAge >= +allowAge;
};

export const getAllowedAge = () => {
  const shopSettings = getShopSettingsFromStorage() || {};
  return shopSettings?.filterByAge || 18;
};

export const isSetupCompleted = (shopSettings: ShopSettings, skipAge = false) => {
  const savedOnboardingInfo = getOnboardingInfo();

  if (!savedOnboardingInfo) {
    return false;
  }

  const { filterByAge, medicalIdPrompt } = shopSettings;
  const { medical, useType } = savedOnboardingInfo;
  const showAgePicker = !skipAge && filterByAge
    && filterByAge !== AgeFilterType.None && (!savedOnboardingInfo.age || savedOnboardingInfo.age < filterByAge);

  if (showAgePicker) {
    return false;
  }

  const showMedicalPicker = medicalIdPrompt === MedicalIdPromptType.AtStart
    && !medical?.medicalId
    && !medical?.medicalIdExp;

  if (useType === USE_TYPE_OPTIONS[1].key && showMedicalPicker) {
    return false;
  }

  return Boolean(savedOnboardingInfo.storeId && savedOnboardingInfo.useType);
};

export const getStoreDetailsFromStorage = (storeId: string) => {
  const { list } = getCompanyLocationsData() || {};
  return list?.find((store: ApiLocation) => store.id === storeId);
};

export const getSlug = () => {
  const clientInfo = getClientInfo();
  return clientInfo?.parentId || clientInfo?.id;
};
