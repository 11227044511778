const config = {
  firebaseConfig: {
    apiKey: 'AIzaSyDL9H96j2PULpu_TbXkKoKtYkUH62EOcrw',
    authDomain: 'stash-prod.firebaseapp.com',
    databaseURL: 'https://stash-prod.firebaseio.com',
    projectId: 'stash-prod',
    storageBucket: 'stash-prod.appspot.com',
    messagingSenderId: '272756466347',
    appId: '1:272756466347:web:e29aff20e0772500de4dee',
    measurementId: 'G-FXB80037Z7',
  },
  siteId: 'stash-shop',
  stripeKey: 'pk_test_Wadgf1aQfWi4h1btcl6Nd5Ox',
  API_BASE_URL: 'https://api.mystash.co',
  UI_BASE_URL: 'https://stash-shop--test-fg1a46io.web.app',
  SENTRY_CONFIG: {
    dsn: 'https://decf7b354e534d03a77b8bd8b5cd2b99@o116454.ingest.sentry.io/4504725751595008',
    tracesSampleRate: 1.0,
  },
};

export default config;
