const config = {
  firebaseConfig: {
    apiKey: 'AIzaSyA6VeZE9T17hCS0cKxVqGsh6wAf0naAH8w', // Your Api key will be here - this is the prod
    authDomain: 'belong-prod.firebaseapp.com', // Your auth domain
    databaseURL: 'https://belong-prod.firebaseio.com', // data base url
    projectId: 'belong-prod', // project id
    storageBucket: 'belong-prod.appspot.com', // storage bucket
    messagingSenderId: '46644680459', // messaging sender id
    appId: '1:46644680459:web:5c37e76b03fd3e9cd7b40e', // app id
    measurementId: 'G-N93NLBBSCY',
  },
  siteId: 'belong-shop',
  stripeKey: 'pk_test_Wadgf1aQfWi4h1btcl6Nd5Ox',
  API_BASE_URL: 'https://api.youkno.ai',
  UI_BASE_URL: 'https://belong-shop--test-x0nndn3a.web.app',
  SENTRY_CONFIG: {
    dsn: 'https://decf7b354e534d03a77b8bd8b5cd2b99@o116454.ingest.sentry.io/4504725751595008',
    tracesSampleRate: 1.0,
  },
};

export default config;
