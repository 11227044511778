import { inMemoryPersistence, setPersistence } from 'firebase/auth';
import { isEqual } from 'lodash';
import {
  FC,
  ReactNode,
  createContext,
  useEffect,
  useState,
} from 'react';
import ReactGA from 'react-ga4';

import Loader from 'components/Loader';

import { getShopId } from 'hooks/useRouting';
import {
  getApiLocation,
  getShopSettings,
  getUsStates,
  guessClient,
} from 'services/Client';

import { auth } from 'config/firebase';
import {
  ClientErrorCodes,
  ClientStatus,
  LayoutType,
  ShopStatusType,
} from 'constants/enums';
import { Branding, ClientInfoInterface, DetailedClientInfo } from 'types/clientInfo.interface';
import { CompanyLocationData } from 'types/companyLocations.interface';
import { ShopSettings } from 'types/shopSettings.interface';
import { generateCustomPalette, generateManifest } from 'utils/clientUtils';
import {
  clearClientInfo,
  clearLocationInformation,
  getClientId,
  getClientInfo,
  getCompanyLocationsData,
  saveCompanyLocationsDataToStorage,
  saveOnboardingInfoToStorage,
  saveShopSettingsToStorage,
  saveUsStatesToStorage,
  setClientInfoToStorage,
} from 'utils/storageUtils';
import { getFormattedLocationInfo } from 'utils/storeUtils';
import { isProdEnv } from 'utils/utils';

import getAppTheme from 'assets/themes/defaultTheme';

const SystemContext = createContext({
  hasStores: false,
  isClosed: false,
  isLoading: true,
  theme: {},
  clientError: '' as ClientErrorCodes,
  locationOptions: {} as CompanyLocationData,
  clientInfo: {} as ClientInfoInterface,
  detailedClientData: {} as DetailedClientInfo,
  shopSettings: {} as ShopSettings,
  showStartNewOrder: false,
  layoutType: LayoutType.Default,
});

interface SystemDispatchContextProps {
  refreshClientInfo: (clientId: string) => Promise<ClientInfoInterface|null>;
  setShopSettings: (shopSettings: ShopSettings) => void;
  setShowStartNewOrder: (showStartNewOrder: boolean) => void;
  setLayoutType: (layoutType: LayoutType) => void;
}

const SystemDispatchContext = createContext({} as SystemDispatchContextProps);

interface SystemProviderProps {
  children: ReactNode;
}

const SystemProvider:FC<SystemProviderProps> = ({ children }) => {
  const [isClientInfoLoading, setIsClientInfoLoading] = useState(true);
  const [isStoresInfoLoading, setIsStoresInfoLoading] = useState(true);
  const [isShopSettingsLoading, setIsShopSettingsLoading] = useState(true);
  const [showStartNewOrder, setShowStartNewOrder] = useState(false);
  const [layoutType, setLayoutType] = useState<LayoutType>(LayoutType.Default);

  const [hasStores, setHasStores] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [clientError, setClientError] = useState<ClientErrorCodes>('' as ClientErrorCodes);

  const [theme, setTheme] = useState(getAppTheme());
  const [clientData, setClientInfo] = useState<DetailedClientInfo>({} as DetailedClientInfo);
  const [shopSettings, setShopSettings] = useState<ShopSettings>({} as ShopSettings);

  const [locationOptions, setStoresInfo] = useState(getCompanyLocationsData() || {} as CompanyLocationData);

  useEffect(() => {
    initializeShop();
  }, []);

  useEffect(() => {
    if (clientData?.kioskMode) {
      setPersistence(auth, inMemoryPersistence);
    }
  }, []);

  const initializeShop = async () => {
    try {
      const shopId = getShopId();
      const { data } = await guessClient({ url: window.location.href, clientId: shopId });

      if (!data.clientInfo) {
        handleMissingClient();
      } else {
        const clientId = data.clientInfo.parentId || data.clientInfo.id;
        const oldClientId = getClientId();

        if (clientId !== oldClientId) {
          saveOnboardingInfoToStorage(null);
        }

        saveClientInfo(data);
        handleTheme(data.clientInfo?.branding, data.kioskMode);
        generateManifest();

        // We mark shop as closed only if `hasLocations` === false
        // If there is no value set for `hasLocations`, we consider the shop opened
        // If the client is locked we need to close the Shop
        if ((data?.clientInfo?.hasLocations === false
          && !data?.clientInfo?.parentId) || data?.clientInfo?.status === ClientStatus.Disabled) {
          setIsClosed(true);
        } else {
          await Promise.all([
            fetchStoreSettings(),
            fetchStoresInfo(),
          ]);
        }
      }
    } catch (error: any) {
      if (error?.status) {
        setClientError(error?.status === 404 ? ClientErrorCodes.wrong : ClientErrorCodes.unknown);
        generateManifest(true);
      }
    } finally {
      setIsClientInfoLoading(false);
      setIsStoresInfoLoading(false);
      setIsShopSettingsLoading(false);
    }
  };

  const handleMissingClient = () => {
    setClientError(ClientErrorCodes.missing);
    clearClientInfo();
    clearLocationInformation();
    setTheme(getAppTheme());
  };

  const handleTheme = (branding?: Branding, kioskMode?: boolean) => {
    const customPalette = generateCustomPalette(branding);
    setTheme(getAppTheme(customPalette, kioskMode));
  };

  const saveClientInfo = (clientInfo: DetailedClientInfo) => {
    setClientInfoToStorage(clientInfo);
    setClientInfo(clientInfo);
  };

  const refreshClientInfo = async (clientId: string): Promise<ClientInfoInterface|null> => {
    try {
      const { data } = await guessClient({ url: window.location.href, clientId });

      saveClientInfo(data);
      const storedClientInfo = getClientInfo();

      if (!isEqual(data?.clientInfo?.branding, storedClientInfo?.branding)) {
        handleTheme(clientData?.clientInfo?.branding);
      }

      return clientData?.clientInfo;
    } catch (error: any) {
      if (error?.status) {
        setClientError(error?.status === 404 ? ClientErrorCodes.wrong : ClientErrorCodes.unknown);
      }

      return null;
    }
  };

  const fetchStoresInfo = async () => {
    try {
      const companyLocationDataFromStorage = getCompanyLocationsData();

      if (companyLocationDataFromStorage?.list?.length) {
        setHasStores(true);
        setIsStoresInfoLoading(false);
      }

      const [{ data: usStates }, { data: locations }] = await Promise.all([
        getUsStates(),
        getApiLocation(),
      ]);

      saveUsStatesToStorage(usStates?.list || []);

      if (locations?.list?.length) {
        const formattedInformation = getFormattedLocationInfo(locations?.list || [], usStates?.list || []);
        const companyLocationsData = {
          ...locations ?? {},
          ...formattedInformation,
        };

        if (!isEqual(companyLocationDataFromStorage, companyLocationsData)) {
          setStoresInfo(companyLocationsData);
          saveCompanyLocationsDataToStorage(companyLocationsData);
        }

        if (!hasStores) {
          setHasStores(true);
        }
      }
    } catch (e) {
      setHasStores(false);
    }
  };

  const fetchStoreSettings = async () => {
    try {
      const { data: settings } = await getShopSettings();
      const measurementId = settings?.analyticsInfo?.dataStreamInfo?.webStreamData?.measurementId;

      if (settings.shopStatus === ShopStatusType.DISABLED) {
        setIsClosed(true);
      }

      if (measurementId && isProdEnv()) {
        ReactGA.initialize(measurementId);
      }

      setShopSettings(settings);
      // refresh shop info saved on local storage
      saveShopSettingsToStorage(settings);
    } catch (e) {
      setShopSettings({});
    }
  };

  const handleShopSettingsChanges = (settings: ShopSettings) => {
    const measurementId = settings?.analyticsInfo?.dataStreamInfo?.webStreamData?.measurementId;

    if (measurementId && isProdEnv()) {
      ReactGA.initialize(measurementId);
    }

    setShopSettings(settings);
    setIsClosed(settings.shopStatus === ShopStatusType.DISABLED);
  };

  if (isClientInfoLoading || isStoresInfoLoading || isShopSettingsLoading) {
    return <Loader />;
  }

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SystemContext.Provider value={{
      hasStores,
      isLoading: isClientInfoLoading || isStoresInfoLoading || isShopSettingsLoading,
      theme,
      clientError,
      locationOptions,
      clientInfo: clientData.clientInfo,
      detailedClientData: clientData,
      shopSettings,
      isClosed,
      layoutType,
      showStartNewOrder,
    }}
    >
      {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
      <SystemDispatchContext.Provider value={{
        refreshClientInfo,
        setShopSettings: handleShopSettingsChanges,
        setShowStartNewOrder,
        setLayoutType,
      }}
      >
        {children}
      </SystemDispatchContext.Provider>
    </SystemContext.Provider>
  );
};

export {
  SystemProvider,
  SystemContext,
  SystemDispatchContext,
};
