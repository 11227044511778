import { Box, Typography } from '@mui/material';
import {
  FC, ReactNode, useContext, useState,
} from 'react';
import { toast } from 'react-toastify';

import QuantitySelector from 'components/QuantitySelector';
import { CartContext, CartDispatchContext } from 'context/CartContext';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

import { Bounty } from 'types/bounty.interface';
import { getTopLevelVariant } from 'utils/priceUtils';
import { getRealQuantity } from 'utils/productUtils';

interface ProductQuantitySelectorProps {
  isInline?: boolean;
  bounty: Bounty;
  children: (params: { onAddToCart: () => void; isDisabled?: boolean }) => ReactNode;
}

const ProductQuantitySelector:FC<ProductQuantitySelectorProps> = ({ bounty, children, isInline }) => {
  const cart = useContext(CartContext);
  const { addItem } = useContext(CartDispatchContext);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const { product } = bounty;
  const { quantity, maxAllowedQty } = getTopLevelVariant(product) || {};
  const productQuantity = Math.max(0, quantity || 0);
  const realQuantity = getRealQuantity(quantity, bounty.id, cart);
  const allowableQuantity = Math.min(realQuantity, maxAllowedQty || Number.MAX_VALUE);
  const showRemainingStock = realQuantity > 0 && realQuantity <= 10;

  const { handleTrackAddItemToCart } = useGoogleAnalytics();

  const handleQuantityChanges = (newQuantity: number) => {
    setSelectedQuantity(newQuantity);
  };

  const handleAddToCart = () => {
    addItem({
      bounty,
      quantity: selectedQuantity,
      onSuccess: () => {
        toast.success('Product added to cart!');
        setSelectedQuantity(1);
        handleTrackAddItemToCart({ bounty, selectedQuantity });
      },
    });
  };

  return (
    <div>
      <Box display="flex" alignItems="center" gap={2}>
        {productQuantity <= 0
          ? (
            <Typography variant="body2" color="error">
              Out of stock
            </Typography>
          ) : (
            <Typography variant="body1">
              QUANTITY
            </Typography>
          )}

        {realQuantity > 0 && (
        <QuantitySelector
          selectedQuantity={selectedQuantity}
          handleQuantity={handleQuantityChanges}
          allowableQuantity={allowableQuantity}
        />
        )}
        {isInline && children({
          onAddToCart: handleAddToCart,
          isDisabled: productQuantity <= 0 || !allowableQuantity,
        })}
      </Box>

      {showRemainingStock && (
      <Typography variant="body2" fontWeight={600} color="error" mt={2}>
        Only
        {' '}
        {realQuantity}
        {' '}
        left in stock!
      </Typography>
      )}

      {!isInline && children({
        onAddToCart: handleAddToCart,
        isDisabled: productQuantity <= 0 || !allowableQuantity,
      })}
    </div>
  );
};

export default ProductQuantitySelector;
