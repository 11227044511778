import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Option = styled(
  Box,
  { shouldForwardProp: (props) => props !== 'isActive' },
)<{ isActive: boolean }>(({ theme, isActive }) => ({
  padding: theme.spacing(2, 2.5),
  backgroundColor: isActive ? theme.palette.border.main : theme.palette.background.main,
  borderBottom: `2px solid ${theme.palette.border.main}`,
  cursor: 'pointer',
  fontWeight: isActive ? 700 : 400,

  '&:hover': {
    fontWeight: 700,
    backgroundColor: theme.palette.border.main,
  },
}));

export const OptionsWrapper = styled(Box)(({ theme }) => ({
  borderTop: `2px solid ${theme.palette.border.main}`,
}));
