import { Box } from '@mui/material';
import CardMediaMui from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';

export const CardMedia = styled(CardMediaMui)(({ theme }) => ({
  height: '300px',
  width: '100%',
  objectFit: 'cover',
  marginBottom: theme.spacing(10),
})) as typeof CardMediaMui;

export const ArrowsWrapper = styled(
  Box,
  { shouldForwardProp: (props) => props !== 'rotationDegrees' },
)<{ rotationDegrees: number }>(({ theme, rotationDegrees }) => ({
  '& svg': {
    height: '200px',
    width: '300px',
    fill: theme.palette.primary.main,
    transform: `rotate(${rotationDegrees}deg)`,
  },

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Root = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  gap: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(4, 0),
  flexGrow: 1,
}));

export const Content = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: theme.spacing(10),
  marginBottom: theme.spacing(10),
}));

export const ContentCard = styled(Box)(({ theme }) => ({
  border: `4px solid ${theme.palette.primary.main}`,
  borderRadius: '40px',
  padding: theme.spacing(5),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(3),
  backgroundColor: theme.palette.background.main,
  maxWidth: '600px',
}));
