import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';

import flavourConfig from 'config';
import setupAxiosInterceptors from 'config/interceptors';

const { firebaseConfig } = flavourConfig;

const firebaseApp = initializeApp(firebaseConfig);

setupAxiosInterceptors();

export const auth = getAuth(firebaseApp);
export const db = getDatabase(firebaseApp);
export const storage = getStorage(firebaseApp);

export default firebaseApp;
