import {
  DeliveryMethod, FlwrQuantityVariant, PaymentMethod, PeriodType, WeightUnit,
} from 'constants/enums';

export const ORDER_DELIVERY_METHOD = {
  [DeliveryMethod.DELIVERY]: 'Delivery',
  [DeliveryMethod.PICKUP]: 'In Store Pickup',
  [DeliveryMethod.RETAIL]: 'Retail',
  [DeliveryMethod.KIOSK]: 'Kiosk',
  [DeliveryMethod.CURBSIDE]: 'Curbside Pickup',
};

export const PAYMENT_METHOD_LABELS = {
  [PaymentMethod.CASH]: 'Cash',
  [`${PaymentMethod.CASH}_SANDBOX`]: 'Cash - Sandbox',
  [PaymentMethod.BILLMYBANK]: 'Bill My Bank',
  [`${PaymentMethod.BILLMYBANK}_SANDBOX`]: 'Bill My Bank - Sandbox',
  [PaymentMethod.DEBIT]: 'Debit',
  [`${PaymentMethod.DEBIT}_SANDBOX`]: 'Debit - Sandbox',
  [PaymentMethod.CHARGEE]: 'Chargee',
  [`${PaymentMethod.CHARGEE}_SANDBOX`]: 'Chargee - Sandbox',
};

export const BALANCE_PERIOD_LABELS = {
  [PeriodType.Daily]: 'daily',
  [PeriodType.Weekly]: 'weekly',
  [PeriodType.Monthly]: 'monthly',
  [PeriodType.Yearly]: 'yearly',
  [PeriodType.Last7d]: 'last 7 days',
  [PeriodType.Last30d]: 'last 30 days',
  [PeriodType.OneTime]: 'one time',
};

export const WEIGHT_UNIT_LABELS = {
  [WeightUnit.G]: 'g',
  [WeightUnit.KG]: 'kg',
  [WeightUnit.OZ]: 'oz',
  [WeightUnit.LB]: 'lb',
  [WeightUnit.EA]: 'EA',
  [WeightUnit.FLWR]: 'FLWR',
  [WeightUnit.FLWR_2]: 'FLWR_2',
  [WeightUnit.FLWR_4]: 'FLWR_4',
};

export const FLWR_QUANTITY_LABELS = {
  [FlwrQuantityVariant.eightOz]: '1/8 oz',
  [FlwrQuantityVariant.quarterOz]: '1/4 oz',
  [FlwrQuantityVariant.halfOz]: '1/2 oz',
  [FlwrQuantityVariant.oneOz]: '1 oz',
};
