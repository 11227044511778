import { FC } from 'react';

import { CertificateLink } from 'components/CertificateOfAuthenticity/index.styled';

interface CertificateOfAuthenticityProps {
  url: string;
}

const CertificateOfAuthenticity:FC<CertificateOfAuthenticityProps> = ({ url }) => (
  <CertificateLink href={url} rel="noreferrer" target="_blank">
    Certificate of Authenticity
  </CertificateLink>
);

export default CertificateOfAuthenticity;
