export const CLIENT_INFORMATION_OPEN_ROUTE = 'api/v1/open/clients/:slug';
export const GUESS_CLIENT_ROUTE = 'api/v1/open/clients/guess';
export const PRODUCT_CATEGORIES_OPEN_ROUTE = 'api/v1/open/categories/PRODUCT';
export const PRODUCTS_LIST_OPEN_ROUTE = 'api/v1/open/products';
export const PRODUCT_DETAILS_OPEN_ROUTE = 'api/v1/open/bounty_fetch/:bountyId';
export const PLACE_ORDER_OPEN_ROUTE = 'api/v1/open/orders';
export const ORDER_DETAILS_OPEN_ROUTE = 'api/v1/open/orders/:orderId';
export const MY_ORDERS_ROUTE = 'api/v1/users/:userId/orders';
export const ORDER_DETAILS_ROUTE = 'api/v1/users/:userId/orders/:orderId';
export const US_STATES_ROUTE = 'api/v1/open/lookups/US_STATE';
export const FILTERS_ROUTE = 'api/v1/open/filters/PRODUCT';
export const PROMOTION_ROUTE = 'api/v1/open/adverts/SHOP/:type';
export const LOCATION_ROUTE = 'api/v1/open/locations';
export const LOCATION_GUESS_ROUTE = 'api/v1/open/locations/guess';
export const SUMMARY_ROUTE = 'api/v1/open/orders/summary';
export const EVENTS_ROUTE = 'api/v1/events/:action';
export const REQUESTS_ROUTE = 'api/v1/requests/:action';
export const SHOP_SETTINGS_ROUTE = 'api/v1/open/settings/shopSettings';
export const PRODUCT_EXTRAS_ROUTE = 'api/v1/open/products/:productId/extras';
export const WRITE_MESSAGE_ROUTE = 'api/v1/chats/:roomId/messages';
export const CHAT_CMD_ROUTE = 'api/v1/chats/:roomId/commands';
export const PSG_CHAT_ROUTE = 'api/v1/chats/support';
export const MARK_MSG_AS_READ_ROUTE = 'api/v1/chats/:roomId/messages/read';
export const CREATE_ALLEAVES_CUSTOMER_ROUTE = 'api/v1/alleaves/customer/create';
export const USER_PAYMENT_ACCOUNT_ROUTE = 'api/v1/users/paymentAccount';
export const PICKUP_ORDERS_ROUTE = 'api/v1/open/pickup_orders/:slug';
export const USER_DATA_ROUTE = 'api/v1/users/:userUid/profile';
export const UPDATE_ORDER_STATUS_ROUTE = 'api/v1/open/orders/:orderId/status/:status';
export const PAY_ORDER_ROUTE = 'api/v1/open/orders/:orderId/pay';
export const OPEN_ORDER_DETAILS_ROUTE = 'api/v1/open/:userId/orders/:orderId';
