import ReceiptIcon from '@mui/icons-material/Receipt';
import { SxProps, Box, useTheme } from '@mui/material';
import { get } from 'lodash';
import { QRCodeCanvas } from 'qrcode.react';
import { FC, ReactNode } from 'react';

import { InnerWrapper } from 'components/QrCodeWrapper/index.styled';

interface QrCodeWrapperProps {
  src: string;
  backgroundColor?: string;
  size?: number;
  sx?: SxProps;
  innerSx?: SxProps;
  children?: ReactNode | ReactNode[];
  isMobileView?: boolean;
  onRedirect?: () => void;
}

const QrCodeWrapper: FC<QrCodeWrapperProps> = ({
  src,
  backgroundColor = 'common.white',
  size = 100,
  sx,
  innerSx,
  children,
  isMobileView = false,
  onRedirect,
}) => {
  const theme = useTheme();

  return (
    <Box sx={{ ...sx, backgroundColor }}>
      <InnerWrapper sx={innerSx} isMobileView={isMobileView} onClick={isMobileView ? onRedirect : undefined}>
        {isMobileView
          ? <ReceiptIcon sx={{ height: size, width: size }} />
          : <QRCodeCanvas size={size} value={src} bgColor={get(theme.palette, backgroundColor)} />}
        {children}
      </InnerWrapper>
    </Box>
  );
};

export default QrCodeWrapper;
