import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledImage = styled('img')(({ theme }) => ({
  height: '75px',
  width: '75px',
  marginRight: theme.spacing(1),
  objectFit: 'contain',
}));

export const ProductWrapper = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '10px',
  padding: theme.spacing(2),
}));

export const MoreSpan = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
}));
