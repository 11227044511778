import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import { FC } from 'react';

import ResetKiosk from 'components/ResetKiosk';
import User from 'containers/User';
import { Actions, StyledHeader, Title } from 'layouts/CheckoutLayout/Header/index.styled';
import { useHeaderProperties } from 'layouts/CheckoutLayout/Header/useHeader';

const Header:FC = () => {
  const { title, onClick, Icon } = useHeaderProperties();

  return (
    <StyledHeader>
      {onClick && (
        <IconButton onClick={onClick} className="headerBackground">
          {Icon ? <Icon /> : <ArrowBackIcon />}
        </IconButton>
      )}

      <Title
        variant="h5"
        className="headerBackground"
      >
        {title}
      </Title>

      <Actions>
        <User />
        <ResetKiosk />
      </Actions>
    </StyledHeader>
  );
};

export default Header;
