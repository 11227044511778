import { HourglassBottomRounded } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

const WarningInactivityMessage = () => {
  const [seconds, setSeconds] = useState(10);

  useEffect(() => {
    let interval: ReturnType<typeof setTimeout> | null = null;

    interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2} color="primary.main" textAlign="center">
      <HourglassBottomRounded sx={{ height: '60px', width: '60px', color: 'primary.main' }} />

      <Typography variant="h4">
        <b>Heads Up!</b>
        <br />
        Due to inactivity, your session will automatically reset in
      </Typography>

      <Typography variant="h3" fontWeight={700} my={3}>
        {seconds}
      </Typography>

      <Typography variant="h4">
        Touch the screen now to keep going.
      </Typography>
    </Box>
  );
};

export default WarningInactivityMessage;
