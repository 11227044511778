import * as Sentry from '@sentry/react';
import { useEffect, StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createRoutesFromChildren, matchRoutes, useLocation, useNavigationType,
} from 'react-router-dom';
import 'assets/index.scss';

import flavourConfig from 'config';
import { getEnv, isProdEnv } from 'utils/utils';

import packageJson from '../package.json';
import App from './App';
import reportWebVitals from './reportWebVitals';

const { SENTRY_CONFIG } = flavourConfig;

if (isProdEnv()) {
  Sentry.init({
    ...SENTRY_CONFIG,
    enabled: true,
    environment: getEnv(),
    release: `${packageJson.name}@${packageJson.version}`,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
  });
}

const node = document.getElementById('shadowHost')?.shadowRoot?.querySelector('#root')
  || document.getElementById('root');
const root = ReactDOM.createRoot(node as HTMLElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);

if (window && isProdEnv()) {
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
