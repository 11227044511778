import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import {
  FC, ReactElement, useEffect, useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import Loader from 'components/Loader';
import AppLayout from 'layouts/AppLayout';

import { ClientInfoInterface } from 'types/clientInfo.interface';
import { generateCustomPalette } from 'utils/clientUtils';
import { formatClientInfoFromSearchParams } from 'utils/formatters';

import getAppTheme from 'assets/themes/defaultTheme';

interface PreviewLayoutProps {
  children: ReactElement;
  hideFooter?: boolean;
  hideNavbarActions?: boolean;
}

const PreviewLayout: FC<PreviewLayoutProps> = ({
  children,
  hideFooter,
  hideNavbarActions,
}) => {
  const [searchParams] = useSearchParams();
  const [theme, setTheme] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [clientInfo, setClientInfo] = useState<ClientInfoInterface | null>(null);

  useEffect(() => {
    const clientInfo = formatClientInfoFromSearchParams(searchParams);
    const customPalette = generateCustomPalette(clientInfo?.branding || {});
    setClientInfo(clientInfo);

    setTheme(getAppTheme(customPalette));
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppLayout clientInfo={clientInfo} hideFooter={hideFooter} hideNavbarActions={hideNavbarActions}>
        {children}
      </AppLayout>
    </ThemeProvider>
  );
};

export default PreviewLayout;
