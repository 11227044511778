import { Typography } from '@mui/material';
import { FC } from 'react';

import { RemoveOption, StyledStockWrapper } from 'components/SummaryStockInfo/index.styled';

interface CartProductProps {
  updatedQuantityLabel: string;
  onRemove: () => void;
  isOutOfStock: boolean;
}

const SummaryStockInfo:FC<CartProductProps> = ({ updatedQuantityLabel, onRemove, isOutOfStock }) => (
  <StyledStockWrapper>
    <Typography variant="body2" fontWeight={600} mr={1}>
      {updatedQuantityLabel}
    </Typography>
    {isOutOfStock && (
      <RemoveOption role="presentation" onClick={onRemove}>
        Remove from cart
      </RemoveOption>
    )}
  </StyledStockWrapper>
);

export default SummaryStockInfo;
