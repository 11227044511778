import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import {
  Badge, IconButton, Typography,
} from '@mui/material';
import {
  FC, SyntheticEvent, useState, KeyboardEvent, MouseEvent,
} from 'react';

import Filters from 'components/Filters';
import { DrawerTitle, FiltersLabel, SwipeableDrawer } from 'components/FiltersMobile/index.styled';

import { FilterType } from 'constants/enums';
import { Filter } from 'types/filter.interface';
import { isIOS } from 'utils/utils';

interface FiltersMobileProps {
  activeFilters: { [key: string]: string[] };
  filters: Filter[];
  onSelectFilter: (filterId: string, filterType: FilterType) => (e: SyntheticEvent, checked: boolean) => void;
  isLoading: boolean;
}

const iOS = isIOS();

const FiltersMobile:FC<FiltersMobileProps> = ({
  filters,
  onSelectFilter,
  activeFilters,
  isLoading,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (event: KeyboardEvent | MouseEvent) => {
    if (
      event?.type === 'keydown'
      && ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setIsOpen((preState) => !preState);
  };

  return (
    <div>
      <FiltersLabel onClick={toggleDrawer}>
        <Badge badgeContent={activeFilters && Object.keys(activeFilters)?.length} color="primary">
          <TuneIcon sx={{ color: 'general.lightGrey1' }} />
        </Badge>
        <Typography>
          Filters
        </Typography>
      </FiltersLabel>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        anchor="left"
        open={isOpen}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        <DrawerTitle>
          <Typography fontWeight={600}>
            Filters
          </Typography>
          <IconButton onClick={toggleDrawer} id="iconButton-closeFilters">
            <CloseIcon />
          </IconButton>
        </DrawerTitle>
        <Filters
          isLoading={isLoading}
          filters={filters}
          activeFilters={activeFilters}
          onSelectFilter={onSelectFilter}
        />
      </SwipeableDrawer>
    </div>
  );
};

export default FiltersMobile;
