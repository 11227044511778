import { DialogTitle as MuiDialogTitle } from '@mui/material';
import { FC, ReactNode } from 'react';

import { Title } from 'containers/Onboarding/index.styled';

interface TitleProps {
  children: ReactNode;
  sx?: object;
}

const DialogTitle:FC<TitleProps> = ({ children, sx = {} }) => (
  <MuiDialogTitle sx={sx} lineHeight={1.2}>
    <Title>
      {children}
    </Title>
  </MuiDialogTitle>
);

export default DialogTitle;
