import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(Box)<{ kioskMode: boolean }>(({ theme, kioskMode }) => ({
  borderRadius: '32px',
  backgroundColor: kioskMode ? theme.palette.common.white : theme.palette.general.lightGrey5,
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  border: `1px solid ${theme.palette.border.main}`,
  cursor: 'pointer',
}));

export const IconWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 0.5, 1, 1),
  backgroundColor: theme.palette.general.lightGrey7,
  display: 'flex',
  alignItems: 'center',
  borderTopLeftRadius: '32px',
  borderBottomLeftRadius: '32px',
  borderRight: `1px solid ${theme.palette.border.main}`,
  fontSize: '1.8rem',
}));
