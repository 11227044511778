import { isNil } from 'lodash';

import { useKiosk } from 'hooks/useKiosk';

import { DASHBOARD_PRODUCT_COVER_SIZE } from 'constants/cover';
import {
  ChemicalKind, ChemicalViewMode, FlwrQuantityVariant, ViewMode, WeightUnit,
} from 'constants/enums';
import { CHEMICALS_FOR_SIMPLIFY_MODE } from 'constants/general';
import {
  FLWR_2_QUANTITY_OPTIONS,
  FLWR_4_QUANTITY_OPTIONS,
  FLWR_QUANTITY_OPTIONS,
} from 'constants/options';
import {
  FLWR_2_QUANTITY_BY_VARIANT, FLWR_2_VARIANT_BY_QUANTITY,
  FLWR_4_QUANTITY_BY_VARIANT, FLWR_4_VARIANT_BY_QUANTITY,
  FLWR_QUANTITY_BY_VARIANT, FLWR_VARIANT_BY_QUANTITY,
} from 'constants/productFlwr';
import { Cart, ProductInfo } from 'types/cart.interface';
import { ChemicalInfo, Product, Tag } from 'types/product.interface';
import { getProductCover } from 'utils/attachmentsUtils';
import { extractNumberFromNumericFormat } from 'utils/numbers';
import { getTopLevelVariant } from 'utils/priceUtils';
import { getShopSettingsFromStorage } from 'utils/storageUtils';
import { reportAlert } from 'utils/utils';

export const generateEmptyArray = (length: number, allowZero = false) => {
  const arrayLength = allowZero ? length + 1 : length;
  return Array.from({ length: arrayLength }, (_, i) => (allowZero ? i : i + 1));
};

export const getRealQuantity = (quantity: number|undefined, bountyId: string, cart: Cart): number => {
  const productQuantity = Math.max(0, quantity || 0);
  const quantityFromCart = Math.max(0, cart?.products?.[`temp:${bountyId}`]?.quantity || 0);

  return Math.max(0, productQuantity - quantityFromCart);
};

export const getManufacturer = (product: Product): string => {
  const variant = getTopLevelVariant(product);
  return variant?.manufacturer || '';
};

export const getChemicalElementPercent = (element: ChemicalInfo) => (
  element.maxValue && element.uom !== '%' ? Math.round((100 * element.value) / element.maxValue) : undefined
);

export const formatChemicalValue = ({ element, summary }: { element: ChemicalInfo; summary: boolean}) => {
  if (!element.value) return '';
  const value = summary && element.uom === '%' ? Math.round(element.value) : element.value;
  return `${value}${element.uom}`;
};

export const formatProductWeight = (weight: number) => weight.toFixed(3);

export const filterChemicals = (chemicals: ChemicalInfo[]) => {
  const shopSettings = getShopSettingsFromStorage() || {};
  const viewMode = shopSettings?.viewConfigs?.PRODUCT?.viewMode || ViewMode.Full;

  if (viewMode === ViewMode.Full) {
    return chemicals;
  }

  return chemicals?.filter(({ name }) => (CHEMICALS_FOR_SIMPLIFY_MODE.includes(name)));
};

export const getProductStrainType = (tags: Record<string, Tag>): Tag | null => {
  const typeKey = Object.keys(tags).find((key) => key.startsWith('strain_type_'));
  return typeKey ? tags[typeKey] : null;
};

export const getSortedChemicals = (chemicals: ChemicalInfo[]): ChemicalInfo[] => {
  const kindPriority = {
    [ChemicalKind.Cannabinoid]: 1,
    [ChemicalKind.Terpene]: 2,
    [ChemicalKind.Other]: 3,
    '': 4,
  };

  return chemicals.sort((a, b) => {
    const priorityA = kindPriority[a.kind] || kindPriority[''];
    const priorityB = kindPriority[b.kind] || kindPriority[''];
    return priorityA - priorityB;
  });
};

export const getPreviewChemicals = (searchParams: URLSearchParams, keys: string[]) => keys?.map((key) => {
  const viewMode = searchParams?.get(`${key}-viewMode`) as ChemicalViewMode;

  return {
    name: searchParams?.get(`${key}-name`) || '',
    kind: searchParams?.get(`${key}-kind`) as ChemicalKind,
    summary: [ChemicalViewMode.TileDetails, ChemicalViewMode.Tile].includes(viewMode),
    secondary: [ChemicalViewMode.TileDetails, ChemicalViewMode.Details].includes(viewMode),
    value: searchParams?.get(`${key}-value`) || 1,
    uom: '%',
  } as ChemicalInfo;
});

export const getProductEquivalentQuantity = (weight: string, productWeight: number) => {
  const { kioskMode } = useKiosk();
  const formattedWeight = extractNumberFromNumericFormat({ value: weight, hasSuffix: kioskMode });

  return +(formattedWeight / productWeight).toFixed(3);
};

export const shouldUseProductWeight = (product: Product | ProductInfo) => (
  product?.sortUnit === WeightUnit.G && !isNil(product?.sortWeight)
);

export const getCoverUrl = (bountyId: string, product: Product) => {
  if (bountyId && !product) {
    reportAlert(`Bounty without product: ${bountyId}`);
    return '';
  }

  const cover = getProductCover(
    product.imageUrl,
    DASHBOARD_PRODUCT_COVER_SIZE.w,
    DASHBOARD_PRODUCT_COVER_SIZE.h,
    product.imageCdn,
  );
  return cover.cdnUrl || cover.url;
};

export const formatChemicalComposition = (product: Product) => {
  if (!product?.chemicalComposition) {
    return '';
  }

  const availableChemicals = filterChemicals(product.chemicalComposition) || [];
  return availableChemicals
    .filter((element) => {
      const hasChemical = isNil(element.summary) || element.summary;
      return element.value > 0 && element.name && hasChemical;
    })
    .map((element) => `${element.name} ${formatChemicalValue({ element, summary: true })}`)
    .join(', ');
};

export const getRealWeight = (product: Product, bountyId: string, cart: Cart): number => {
  const { quantity } = getTopLevelVariant(product) || {};
  const { sortWeight } = product || {};

  if (!sortWeight || !quantity) {
    return 0;
  }

  const productWeight = Math.max(0, sortWeight * quantity);
  const { quantity: cartQuantity } = cart?.products?.[`temp:${bountyId}`] || {};
  const weightFromCart = cartQuantity
    ? Math.max(0, cartQuantity * sortWeight)
    : 0;

  return Math.max(0, productWeight - weightFromCart);
};

export const isFlwr = (sortUnit?: WeightUnit) => (
  sortUnit && [WeightUnit.FLWR, WeightUnit.FLWR_2, WeightUnit.FLWR_4].includes(sortUnit)
);

export const getFlwrQuantityByVariant = (variant: FlwrQuantityVariant, sortUnit?: WeightUnit) => {
  if (!sortUnit) {
    return 0;
  }

  if (sortUnit === WeightUnit.FLWR) {
    return FLWR_QUANTITY_BY_VARIANT[variant];
  }

  if (sortUnit === WeightUnit.FLWR_2) {
    return FLWR_2_QUANTITY_BY_VARIANT[variant];
  }

  if (sortUnit === WeightUnit.FLWR_4) {
    return FLWR_4_QUANTITY_BY_VARIANT[variant];
  }

  return 0;
};

export const getFlwrVariantByQuantity = (quantity: number, sortUnit?: WeightUnit) => {
  if (!sortUnit) {
    return null;
  }

  if (sortUnit === WeightUnit.FLWR) {
    return FLWR_VARIANT_BY_QUANTITY[quantity];
  }

  if (sortUnit === WeightUnit.FLWR_2) {
    return FLWR_2_VARIANT_BY_QUANTITY[quantity];
  }

  if (sortUnit === WeightUnit.FLWR_4) {
    return FLWR_4_VARIANT_BY_QUANTITY[quantity];
  }

  return null;
};

export const getFlwrOptionsByType = (sortUnit?: WeightUnit) => {
  if (!sortUnit) {
    return [];
  }

  if (sortUnit === WeightUnit.FLWR) {
    return FLWR_QUANTITY_OPTIONS;
  }

  if (sortUnit === WeightUnit.FLWR_2) {
    return FLWR_2_QUANTITY_OPTIONS;
  }

  if (sortUnit === WeightUnit.FLWR_4) {
    return FLWR_4_QUANTITY_OPTIONS;
  }

  return [];
};
