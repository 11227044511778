import { Box } from '@mui/material';
import { FC, ReactElement } from 'react';

import Navbar from 'components/_Navbar/index.kiosk';
import { Root } from 'layouts/AppLayout/Kiosk/index.styled';

import { ClientInfoInterface } from 'types/clientInfo.interface';

interface KioskProps {
  children: ReactElement;
  clientInfo?: ClientInfoInterface | null;
}

const Kiosk:FC<KioskProps> = ({
  clientInfo = null,
  children,
}) => (
  <Box display="flex" flexDirection="column" minHeight="100vh">
    <Navbar clientInfo={clientInfo} />
    <Root>
      {children}
    </Root>
  </Box>
);

export default Kiosk;
