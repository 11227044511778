import { Box, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';
import Slider from 'react-slick';

import CarouselNextArrow from 'components/CarouselNextArrow';
import CarouselPrevArrow from 'components/CarouselPrevArrow';
import {
  StyledBox, StyledCardMedia, StyledImg, StyledImgBox,
} from 'components/ProductPictures/index.styled';

import { PRODUCT_DETAILS_COVER_SIZE, PRODUCT_DETAILS_SMALL_COVER_SIZE } from 'constants/cover';
import { Attachment } from 'types/attachment.interface';
import { Product } from 'types/product.interface';
import { getCover } from 'utils/attachmentsUtils';
import { getProductCarouselSettings } from 'utils/carousel';
import { getPublicUrlForImage } from 'utils/publicUrl';

interface ProductPicturesProps {
  product: Product;
  onClick(index: number): void;
  visiblePictureIndex: number;
  setVisiblePictureIndex(index: number): void;
  attachments?: {
    attachments: {
      [key: string]: Attachment;
    };
  };
}

const ProductPictures: FC<ProductPicturesProps> = (props) => {
  const {
    product,
    onClick,
    attachments,
    visiblePictureIndex,
    setVisiblePictureIndex,
  } = props;
  const theme = useTheme();
  const hasPictures = Boolean(attachments?.attachments && Object.values(attachments.attachments));
  const arrowCSS = { height: '40px', top: '45%' };
  const largeImages = useMemo(() => {
    const attachs = attachments?.attachments ? Object.values(attachments.attachments) : [];

    return attachs.map((attach) => {
      const cover = getCover(
        attach,
        PRODUCT_DETAILS_COVER_SIZE.w,
        PRODUCT_DETAILS_COVER_SIZE.h,
      );
      return cover.cdnUrl || cover.url;
    });
  }, []);

  const smallImages = useMemo(() => {
    const attachs = attachments?.attachments ? Object.values(attachments?.attachments) : [];

    return attachs.map((attach) => {
      const cover = getCover(
        attach,
        PRODUCT_DETAILS_SMALL_COVER_SIZE.w,
        PRODUCT_DETAILS_SMALL_COVER_SIZE.h,
      );
      return cover.cdnUrl || cover.url;
    });
  }, []);

  const settings = {
    ...getProductCarouselSettings(theme),
    nextArrow: <CarouselNextArrow sx={arrowCSS} />,
    prevArrow: <CarouselPrevArrow sx={arrowCSS} />,
  };

  return (
    <>
      <StyledCardMedia
        component="img"
        src={largeImages?.[visiblePictureIndex] || getPublicUrlForImage('images/placeholder_image.jpg')}
        alt={product?.name}
        onClick={() => onClick(0)}
        sx={{ cursor: hasPictures ? 'pointer' : 'initial' }}
      />
      {smallImages?.length > 1 && (
        <Box width="100%" display="flex" justifyContent="center" my={3}>
          <StyledBox>
            <Slider {...settings}>
              {smallImages.map((picture, index) => (
                <StyledImgBox
                  key={index}
                  onMouseEnter={() => setVisiblePictureIndex(index)}
                  onClick={() => onClick(index)}
                >
                  <StyledImg src={picture} alt="productPicture" />
                </StyledImgBox>
              ))}
            </Slider>
          </StyledBox>
        </Box>
      )}
    </>
  );
};

export default ProductPictures;
