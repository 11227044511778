import flavourConfig from 'config/index';

export const isIOS = () => typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

export const getEnv = () => {
  const { env } = flavourConfig;

  const { hostname } = window.location;

  if (hostname.includes('localhost')) {
    return 'local';
  }
  if (hostname.includes('--test-')) {
    return 'test';
  }
  return env;
};

export const isProdEnv = () => process.env.NODE_ENV === 'production' && getEnv() === 'prod';

export const isTestChannel = () => {
  const env = getEnv();
  return env === 'local' || env === 'test';
};

// eslint-disable-next-line no-console
export const reportAlert = (message: string) => console.error(`ALERT: ${message}`);

export const loadGoogleMaps = (): Promise<boolean> => new Promise((resolve) => {
  const initFunction = 'initGoogleApi';
  let script = null; // eslint-disable-line no-var

  // if we have been already initialized, bail out
  if ((window as { [key: string]: any })[initFunction]) {
    resolve(true);
    return;
  }

  // Create the script tag, set the appropriate attributes
  const { googleMapsKey } = flavourConfig;
  script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&libraries=places&callback=${initFunction}`;
  script.async = true;

  // Attach your callback function to the `window` object
  // eslint-disable-next-line func-names
  (window as { [key: string]: any })[initFunction] = function () {
    // JS API is loaded and available
  };

  // Append the 'script' element to 'head'
  document.head.appendChild(script);

  resolve(true);
});

export const getShadowDomRoot = () => (
  document.getElementById('shadowHost')?.shadowRoot?.querySelector('#root')
);
export const getShadowDomHost = () => document.getElementById('shadowHost')?.shadowRoot;
