import { Box, Typography } from '@mui/material';
import { FC, useMemo } from 'react';

import Brand from 'components/Brand';
import CertificateOfAuthenticity from 'components/CertificateOfAuthenticity';
import ChemicalInformation from 'components/ChemicalInformation';
import ProductRating from 'components/ProductRating';
import StrainType from 'containers/ProductDetailsPage/StrainType';

import { Bounty } from 'types/bounty.interface';
import { Product } from 'types/product.interface';
import { Rating } from 'types/rating.interface';
import {
  getManufacturer, getProductStrainType, getSortedChemicals,
} from 'utils/productUtils';
import { getRatingScore } from 'utils/ratingUtils';
import { isTestChannel } from 'utils/utils';

interface ProductInformationProps {
  bounty: Bounty;
}

const ProductInformation:FC<ProductInformationProps> = ({ bounty }) => {
  const { product = {} as Product, rating = {} as Rating, tags } = bounty;
  const { chemicalComposition, certificateOfAuthenticityUrl } = product;

  const manufacturer = getManufacturer(product);
  const strainType = tags?.tags ? getProductStrainType(tags?.tags) : null;

  const sortedChemicals = useMemo(() => (
    chemicalComposition
      ? getSortedChemicals(chemicalComposition)
      : []
  ), [chemicalComposition]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <div>
        <Typography variant="h5" fontWeight="500" gutterBottom>
          {product?.name}
        </Typography>
        {manufacturer && <Brand brand={manufacturer} />}
      </div>

      <Typography color="textSecondary">
        {product?.description}
      </Typography>

      {isTestChannel() && <ProductRating ratingStats={getRatingScore(rating)} />}
      {certificateOfAuthenticityUrl && <CertificateOfAuthenticity url={certificateOfAuthenticityUrl} />}
      {strainType && <StrainType strainType={strainType} />}
      {sortedChemicals && sortedChemicals.length > 0 && (
        <ChemicalInformation chemicalComposition={sortedChemicals} />
      )}
    </Box>
  );
};

export default ProductInformation;
