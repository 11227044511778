import { Typography } from '@mui/material';
import { FC } from 'react';

import { BalanceWrapper, Box, IntakeWrapper } from 'components/ProductMedicalBalanceInfo/index.styled';

import { BALANCE_PERIOD_LABELS } from 'constants/labels';
import { BalanceCatInfo } from 'types/cart.interface';

interface ProductMedicalBalanceInfoProps {
  balance: BalanceCatInfo;
}

const ProductMedicalBalanceInfo:FC<ProductMedicalBalanceInfoProps> = (props) => {
  const { balance } = props;
  const {
    amount, limit, name, uom = '', period,
  } = balance;
  const intakeMessage = amount ? `${amount}${uom} intake` : '';
  const limitMessage = limit ? `${limit}${uom} limit` : '';
  const periodMessage = period?.type ? BALANCE_PERIOD_LABELS[period?.type] : null;
  const hasReachedLimit = amount && limit ? amount > limit : false;

  return (
    <Box>
      <Typography variant="body2" color="general.lightGrey4">
        {`Contribution to ${name} balance${periodMessage ? ` from ${periodMessage}` : ' '}`}
        &nbsp;
        <BalanceWrapper>
          {intakeMessage && (
            <IntakeWrapper hasReachedLimit={hasReachedLimit}>
              {intakeMessage}
            </IntakeWrapper>
          )}
          {(intakeMessage && limitMessage) ? ' / ' : ''}
          {limitMessage && (
            <span>
              {limitMessage}
            </span>
          )}
        </BalanceWrapper>
      </Typography>
    </Box>
  );
};

export default ProductMedicalBalanceInfo;
