import {
  Container, Grid, Box, useTheme, Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Suspense, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import BreadcrumbsNavigation from 'components/BreadcrumbsNavigation';
import CustomSelector from 'components/CustomSelector';
import Filters from 'components/Filters';
import FiltersMobile from 'components/FiltersMobile';
import ProductCard from 'components/ProductCard';
import ProductCardSkeleton from 'components/ProductCard/index.skeleton';
import SubheaderWrapper from 'components/SubheaderWrapper';
import UnstyledLink from 'components/UnstyledLink';
import PreviewLayout from 'layouts/PreviewLayout';

import { PREVIEW_PRODUCT } from 'constants/clientRoutes';
import {
  MOCK_BREADCRUMB,
  MOCK_PRODUCT,
  MOCK_PRODUCT_FILTER_1,
} from 'constants/mockData';
import { formatCategoryOptions } from 'utils/formatters';
import { getPreviewChemicals } from 'utils/productUtils';

const PreviewStoreChemicalsPage = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const matchesScreenSizeMd = useMediaQuery(theme.breakpoints.down('md'));
  const options = formatCategoryOptions([]);
  const chemicalKeys = searchParams?.get('keys') || '';
  const [bounty, setBounty] = useState(MOCK_PRODUCT);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleSelectFilter = () => (): void => {};

  useEffect(() => {
    if (chemicalKeys) {
      const keys = chemicalKeys?.split(',');
      const chemicals = getPreviewChemicals(searchParams, keys);

      setBounty({ ...MOCK_PRODUCT, product: { ...MOCK_PRODUCT.product, chemicalComposition: chemicals } });
    }
  }, [chemicalKeys]);

  const generateUrlWithParams = () => {
    const params = new URLSearchParams(searchParams);
    return `${PREVIEW_PRODUCT}?${params.toString()}`;
  };

  return (
    <PreviewLayout hideFooter hideNavbarActions>
      <div>
        <SubheaderWrapper>
          <Box display="flex" gap={1} alignItems="center">
            <BreadcrumbsNavigation breadcrumbs={[MOCK_BREADCRUMB]} />
            <Typography variant="subheader" fontWeight={600}>
              /
            </Typography>
            <CustomSelector
              variant="outlined"
              value="all"
              name="category"
              options={options}
              displayEmpty
            />
          </Box>
        </SubheaderWrapper>

        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              {matchesScreenSizeMd ? (
                <FiltersMobile
                  isLoading={false}
                  filters={MOCK_PRODUCT_FILTER_1}
                  activeFilters={{}}
                  onSelectFilter={handleSelectFilter}
                />
              ) : (
                <Filters
                  isLoading={false}
                  filters={MOCK_PRODUCT_FILTER_1}
                  activeFilters={{}}
                  onSelectFilter={handleSelectFilter}
                />
              )}
            </Grid>
            <Grid item container xs={12} md={12} spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Suspense fallback={<ProductCardSkeleton />}>
                  <UnstyledLink
                    to={generateUrlWithParams()}
                    height="100%"
                    width="100%"
                  >
                    <ProductCard
                      bounty={bounty}
                      hasAddButton={false}
                      isBrandDisabled
                      isDescriptionVisible
                    />
                  </UnstyledLink>
                </Suspense>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </PreviewLayout>
  );
};

export default PreviewStoreChemicalsPage;
