import { styled } from '@mui/material/styles';

export const Root = styled('div')<{ isMine: boolean }>(({ theme, isMine }) => ({
  width: '90%',
  display: 'inline-block',
  float: isMine ? 'right' : 'left',
  marginBottom: theme.spacing(1.5),
}));

export const MessageBubble = styled('div')<{ isMine: boolean }>(({ theme, isMine }) => ({
  borderRadius: isMine ? '8px 0px 8px 8px' : '0px 8px 8px 8px',
  backgroundColor: isMine ? 'rgb(0, 120, 242)' : theme.palette.general.messageBubble,
  padding: theme.spacing(0.5, 1.5),
  marginBottom: theme.spacing(0.5),
  color: isMine ? theme.palette.common.white : theme.palette.text.primary,
  '& .attachmentDetails > .MuiTypography-root': {
    color: isMine ? theme.palette.common.white : theme.palette.text.primary,
  },
  '& .downloadButton > .MuiButtonBase-root': {
    color: isMine ? theme.palette.common.white : theme.palette.text.primary,
  },
}));

export const MessageBox = styled('div')(() => ({
  flexGrow: 1,
  width: 'calc(90% - 30px)',
}));
