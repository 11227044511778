import { Avatar } from '@mui/material';
import { User } from 'firebase/auth';
import { FC, useState, MouseEvent } from 'react';

import UserMenu from 'containers/User/UserMenu';
import { NameTypography, UserInfo } from 'containers/User/Username/index.styled';

import { textTruncate } from 'utils/formatters';

interface UsernameProps {
  onLogout: () => void;
  user: User;
}

const Username:FC<UsernameProps> = ({ onLogout, user }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const username = user?.displayName || user.email || '';

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <UserInfo onClick={handleMenu}>
        <NameTypography fontSize="16px" noWrap>
          {textTruncate(username, 15)}
        </NameTypography>
        <Avatar
          alt={user?.displayName || user.email || ''}
          src={user?.photoURL || '/'}
          sx={{ width: 34, height: 34 }}
        />
      </UserInfo>
      <UserMenu
        anchorEl={anchorEl}
        onClose={handleClose}
        onLogout={onLogout}
      />
    </div>
  );
};

export default Username;
