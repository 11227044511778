import CloseIcon from '@mui/icons-material/Close';
import {
  Box, Dialog, IconButton, Typography,
} from '@mui/material';
import { FC, MouseEvent } from 'react';

import CustomButton from 'components/CustomButton';
import ProductShortPreview from 'components/ProductShortPreview';
import ProductQuantitySelector from 'containers/ProductDetailsPage/ProductQuantitySelector';
import ProductWeightSelector from 'containers/ProductDetailsPage/ProductWeightSelector';

import { Bounty } from 'types/bounty.interface';
import { shouldUseProductWeight } from 'utils/productUtils';

interface ProductQuantityDialogProps {
  bounty: Bounty;
  isOpen: boolean;
  onClose: (e: MouseEvent<HTMLElement>) => void;
}

const ProductQuantityDialog: FC<ProductQuantityDialogProps> = ({
  bounty,
  isOpen,
  onClose,
}) => {
  const handleClick = (e: MouseEvent<HTMLElement>) => e.preventDefault();

  return (
    <Dialog
      open={isOpen}
      fullWidth
      onClose={onClose}
      aria-describedby="select-tier-price"
      PaperProps={{ sx: { maxWidth: 560, borderRadius: '16px', p: 2.5 } }}
    >
      <Box onClick={handleClick}>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="h5" fontWeight={700}>
            Select quantity
          </Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <ProductShortPreview bounty={bounty} sx={{ mb: 2 }} />

        {shouldUseProductWeight(bounty?.product)
          ? (
            <ProductWeightSelector bounty={bounty}>
              {({ onAddToCart, isDisabled = false }) => (
                <Box mt={2}>
                  <Box display="flex" gap={2} mt={2} justifyContent="flex-end">
                    <CustomButton
                      id="btn-closeQuantityDialog"
                      onClick={onClose}
                      variant="outlined"
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      disabled={isDisabled}
                      id="btn-addToCard-quantityDialog"
                      onClick={(e) => {
                        onAddToCart();
                        onClose(e);
                      }}
                    >
                      Add to cart
                    </CustomButton>
                  </Box>
                </Box>
              )}
            </ProductWeightSelector>
          )
          : (
            <ProductQuantitySelector bounty={bounty}>
              {({ onAddToCart, isDisabled = false }) => (
                <Box mt={2}>
                  <Box display="flex" gap={2} mt={2} justifyContent="flex-end">
                    <CustomButton
                      id="btn-closeQuantityDialog"
                      onClick={onClose}
                      variant="outlined"
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      disabled={isDisabled}
                      id="btn-addToCard-quantityDialog"
                      onClick={(e) => {
                        onAddToCart();
                        onClose(e);
                      }}
                    >
                      Add to cart
                    </CustomButton>
                  </Box>
                </Box>
              )}
            </ProductQuantitySelector>
          )}
      </Box>
    </Dialog>
  );
};

export default ProductQuantityDialog;
