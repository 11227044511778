import { Box, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { LayoutType } from 'constants/enums';

export const getLayoutStyle = (theme: Theme) => ({
  [LayoutType.Default]: {
    backgroundColor: theme.palette.background.main,
  },
  [LayoutType.Warning]: {
    backgroundColor: theme.palette.general.lightYellow1,
  },
});

export const ContentWrapper = styled(
  Box,
  { shouldForwardProp: (props) => props !== 'layoutType' },
)<{ layoutType?: LayoutType }>(({ theme, layoutType = LayoutType.Default }) => ({
  ...getLayoutStyle(theme)[layoutType],
}));
