import {
  FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Typography,
} from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { ChangeEvent, FC } from 'react';

import { StyledWrapper } from 'components/CustomRadioButton/index.styled';

import { Option } from 'types/option.interface';

interface CustomRadioButtonProps {
  fullWidth?: boolean;
  required?: boolean;
  label?: string;
  onChange: (value: any, name: string) => unknown;
  value?: string;
  name: string;
  isRow?: boolean;
  options: Array<Option>;
  disabled?: boolean;
  sx?: object;
  size?: 'small' | 'medium';
  fontWeight?: number;
  error?: boolean;
  helperText?: string;
  variant?: Variant;
}

const CustomRadioButton :FC<CustomRadioButtonProps> = (props) => {
  const {
    label, fullWidth = false, required, options, disabled, size,
    sx, name, variant = 'body1', fontWeight, error, helperText, onChange,
    ...remainingProps
  } = props;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e, name);
  };

  return (
    <StyledWrapper hasError={error}>
      <FormControl component="div" fullWidth={fullWidth}>
        {label && (
          <FormLabel required={required} sx={{ mb: 1.5 }}>
            <Typography component="span" color="textPrimary" variant={variant} fontWeight={fontWeight}>
              {label}
            </Typography>
          </FormLabel>
        )}
        <RadioGroup {...remainingProps} onChange={handleChange}>
          {options.map(({ value, key, disabled: isOptionDisabled }, index: number) => (
            <FormControlLabel
              name={name}
              key={`${index}-${key}`}
              value={key}
              label={value}
              sx={sx}
              control={<Radio id={`radio-${name}`} disabled={disabled || isOptionDisabled} size={size} />}
            />
          ))}
        </RadioGroup>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </StyledWrapper>
  );
};

export default CustomRadioButton;
