import MuiCancelIcon from '@mui/icons-material/Cancel';
import MuiSearchIcon from '@mui/icons-material/Search';
import { alpha, styled } from '@mui/material/styles';

import CustomInput from 'components/CustomInput';

export const CancelIcon = styled(MuiCancelIcon)<{ kioskMode: boolean }>(({ kioskMode, theme }) => ({
  cursor: 'pointer',
  fill: kioskMode
    ? theme.palette.getContrastText(theme.palette.headerBackground.main)
    : theme.palette.general.lightGrey2,
}));

export const SearchIcon = styled(MuiSearchIcon)<{ kioskMode: boolean }>(({ kioskMode, theme }) => ({
  fill: kioskMode
    ? theme.palette.getContrastText(theme.palette.headerBackground.main)
    : theme.palette.general.lightGrey2,
  cursor: 'pointer',
}));

export const SearchInput = styled(CustomInput)<{ kioskMode: boolean }>(({ kioskMode, theme }) => ({
  '&.searchInput': {
    ...(kioskMode ? {
      marginRight: theme.spacing(1),

      '& input': {
        padding: theme.spacing(0.25, 3, 0.25, 1),
        color: theme.palette.getContrastText(theme.palette.headerBackground.main),

        '&::placeholder': {
          color: theme.palette.getContrastText(theme.palette.headerBackground.main),
          opacity: 0.6,
        },
      },

      '& .MuiInputBase-root': {
        paddingLeft: theme.spacing(1.75),
        borderRadius: '32px',
        backgroundColor: alpha(theme.palette.getContrastText(theme.palette.headerBackground.main), 0.3),
      },

      '& .MuiOutlinedInput-root:hover fieldset': {
        borderColor: theme.palette.getContrastText(theme.palette.headerBackground.main),
      },

      '& .Mui-focused fieldset, fieldset': {
        borderColor: theme.palette.getContrastText(theme.palette.headerBackground.main),
        borderWidth: '2px',
      },
    } : {
      '& input': {
        padding: theme.spacing('1.75px', 3, '1.75px', 1),
      },

      '& .MuiInputBase-root': {
        paddingLeft: theme.spacing('13px'),
        borderRadius: '32px',
      },

      '& .MuiOutlinedInput-root:hover fieldset': {
        borderColor: theme.palette.border.main,
      },

      '& .Mui-focused fieldset, fieldset': {
        borderColor: theme.palette.border.main,
        borderWidth: '1px',
      },
    }),
  },
}));
