import { Box, Skeleton, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const ProductFiltersSkeleton = () => {
  const theme = useTheme();
  const matchesScreenSizeMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    matchesScreenSizeMd
      ? (
        <div>
          <Skeleton animation="wave" variant="rounded" height={60} width="100%" />
        </div>
      ) : (
        <div>
          <Box display="flex" alignItems="center" mt={1} mb={1.5}>
            <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
            <Skeleton animation="wave" variant="rounded" height={34} width={150} />
          </Box>
          <Box ml={3.5}>
            <Box display="flex" alignItems="center" my={1}>
              <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
              <Skeleton animation="wave" variant="rounded" height={34} width={121} />
            </Box>
            <Box display="flex" alignItems="center" my={1}>
              <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
              <Skeleton animation="wave" variant="rounded" height={34} width={121} />
            </Box>
            <Box display="flex" alignItems="center" my={1}>
              <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
              <Skeleton animation="wave" variant="rounded" height={34} width={121} />
            </Box>
            <Box display="flex" alignItems="center" my={1}>
              <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
              <Skeleton animation="wave" variant="rounded" height={34} width={121} />
            </Box>
          </Box>

          <Box display="flex" alignItems="center" mb={1.5}>
            <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
            <Skeleton animation="wave" variant="rounded" height={34} width={150} />
          </Box>
          <Box ml={3.5}>
            <Box display="flex" alignItems="center" my={1}>
              <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
              <Skeleton animation="wave" variant="rounded" height={34} width={121} />
            </Box>
            <Box display="flex" alignItems="center" my={1}>
              <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
              <Skeleton animation="wave" variant="rounded" height={34} width={121} />
            </Box>
            <Box display="flex" alignItems="center" my={1}>
              <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
              <Skeleton animation="wave" variant="rounded" height={34} width={121} />
            </Box>
            <Box display="flex" alignItems="center" my={1}>
              <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
              <Skeleton animation="wave" variant="rounded" height={34} width={121} />
            </Box>
          </Box>

          <Box display="flex" alignItems="center" mb={1.5}>
            <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
            <Skeleton animation="wave" variant="rounded" height={34} width={150} />
          </Box>
          <Box ml={3.5}>
            <Box display="flex" alignItems="center" my={1}>
              <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
              <Skeleton animation="wave" variant="rounded" height={34} width={121} />
            </Box>
            <Box display="flex" alignItems="center" my={1}>
              <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
              <Skeleton animation="wave" variant="rounded" height={34} width={121} />
            </Box>
            <Box display="flex" alignItems="center" my={1}>
              <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
              <Skeleton animation="wave" variant="rounded" height={34} width={121} />
            </Box>
            <Box display="flex" alignItems="center" my={1}>
              <Skeleton animation="wave" variant="rounded" height={20} width={20} sx={{ mr: 1 }} />
              <Skeleton animation="wave" variant="rounded" height={34} width={121} />
            </Box>
          </Box>
        </div>
      )
  );
};

export default ProductFiltersSkeleton;
