import {
  ref, push, set,
} from 'firebase/database';

import { runPostApiRequest } from 'services/Api';

import { db } from 'config/firebase';
import { EVENTS_ROUTE } from 'constants/apiRoutes';
import { QueueAlias } from 'constants/enums';
import { BountyStats } from 'types/bounty.interface';
import { Comment } from 'types/comment.interface';
import { formatRoute } from 'utils/formatters';

export const getBountyCommentsRef = (bountyId: string) => ref(db, `userComments/bounties/${bountyId}`);

export const addBountyComment = async (bountyId: string, comment: Partial<Comment>) => {
  const commentRef = push(getBountyCommentsRef(bountyId));
  const endpoint = formatRoute(EVENTS_ROUTE, { action: QueueAlias.AddComment });
  const payload = {
    comment: { ...comment, id: commentRef.key },
    commentId: commentRef.key,
  };

  return runPostApiRequest(endpoint, payload);
};

export const increaseBountyComments = async (
  bountyStats: BountyStats,
  bountyId: string,
  userId: string,
  adjustment = 1,
) => {
  const commentCount = bountyStats && bountyStats.commentCount
    ? bountyStats.commentCount
    : 0;
  const fbPath = `userStreams/${userId}/bounty/stats/commentCount/${bountyId}`;

  return set(ref(db, fbPath), commentCount + adjustment);
};
