import { Box } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';

export const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  borderRadius: '4px',
  height: '300px',
  objectFit: 'contain',
  [theme.breakpoints.down('md')]: {
    height: '200px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '150px',
  },
})) as typeof CardMedia;

export const StyledImg = styled('img')(() => ({
  height: '100%',
  width: '100%',
}));

export const StyledImgBox = styled('div')(({ theme }) => ({
  height: '80px',
  width: '80px !important',
  padding: theme.spacing(0.5),
  border: '1px solid transparent',
  cursor: 'pointer',
  '&:hover': {
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: '4px',
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  width: '450px',
  [theme.breakpoints.down('lg')]: {
    width: '350px',
  },
  [theme.breakpoints.down('md')]: {
    width: '500px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '300px',
  },
}));
