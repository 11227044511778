import { Box, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isNil } from 'lodash';
import { FC } from 'react';

import Brand from 'components/Brand';
import {
  PriceText, PriceWrapper, QuantityText, Root, StyledImage,
} from 'components/OrderProductPreview/index.styled';

import { WeightUnit } from 'constants/enums';
import { FLWR_QUANTITY_LABELS, WEIGHT_UNIT_LABELS } from 'constants/labels';
import { ProductInfo } from 'types/cart.interface';
import { formatReward, multiply } from 'utils/currencyUtils';
import { textTruncate } from 'utils/formatters';
import { getFlwrVariantByQuantity, isFlwr } from 'utils/productUtils';
import { getPublicUrlForImage } from 'utils/publicUrl';

interface OrderProductPreviewProps {
  product: ProductInfo;
}

const OrderProductPreview: FC<OrderProductPreviewProps> = ({ product }) => {
  const {
    price, quantity, manufacturer, sortUnit,
  } = product;
  const theme = useTheme();
  const formattedName = textTruncate(product?.name, 30);
  const productPrice = isFlwr(sortUnit)
    ? multiply(price, quantity)
    : price;
  const formattedPrice = productPrice ? formatReward(productPrice, '0', true) : '0';
  const matchesScreenSizeSm = useMediaQuery(theme.breakpoints.down('sm'));
  const weightLabel = WEIGHT_UNIT_LABELS[product?.weightUnit || WeightUnit.G];
  const flwrVariant = getFlwrVariantByQuantity(quantity, sortUnit);

  return (
    <Root>
      <StyledImage
        src={product?.imageUrl || getPublicUrlForImage('images/placeholder_image.jpg')}
        alt={product?.name}
      />

      <PriceWrapper>
        <Box ml={matchesScreenSizeSm ? 0 : 1}>
          <Typography variant="body2" fontWeight={700} noWrap mb={0.5}>
            {matchesScreenSizeSm ? formattedName : product?.name}
          </Typography>

          {!!manufacturer && (
            <Brand brand={manufacturer} />
          )}
        </Box>

        <Box>
          {isNil(product?.weight)
            ? (
              <>
                <PriceText variant="body1" fontWeight={700}>
                  {formattedPrice}
                </PriceText>

                <QuantityText variant="body2">
                  {`X ${isFlwr(sortUnit) && flwrVariant
                    ? FLWR_QUANTITY_LABELS[flwrVariant]
                    : quantity}`}
                </QuantityText>
              </>
            ) : (
              <>
                <PriceText variant="body1" fontWeight={700}>
                  {`${formattedPrice} /${product?.sortWeight} ${weightLabel}`}
                </PriceText>

                <QuantityText variant="body2">
                  {`${product?.weight} ${weightLabel}`}
                </QuantityText>
              </>
            )}
        </Box>
      </PriceWrapper>
    </Root>
  );
};

export default OrderProductPreview;
