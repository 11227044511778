import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import { Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC } from 'react';

import { Chip } from 'components/StoreOneLocation/index.styled';

import { USE_TYPE_OPTIONS } from 'constants/options';

interface StoreOneLocationProps {
  useTypeLabel: string;
  useType: string;
  disabled?: boolean;
  onClick?: () => void;
}

const StoreOneLocation:FC<StoreOneLocationProps> = ({
  useTypeLabel,
  useType,
  onClick,
  disabled = false,
}) => {
  const theme = useTheme();
  const matchesScreenSizeMd = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Chip
      disabled={disabled}
      icon={useType === USE_TYPE_OPTIONS[1].key ? <MedicalServicesOutlinedIcon /> : <SelfImprovementIcon />}
      label={(
        <Typography variant="body1">
          {matchesScreenSizeMd ? useTypeLabel?.substring(0, 3) : useTypeLabel}
        </Typography>
      )}
      variant="outlined"
      onClick={onClick}
    />
  );
};

export default StoreOneLocation;
