import { Box, Typography } from '@mui/material';
import {
  ChangeEvent, FC, useContext, useState,
} from 'react';

import IncrementWrapper from 'components/IncrementWrapper';
import WeightSelector from 'components/WeightSelector';
import KioskProductActions from 'containers/ProductDetailsPage/KioskProductActions';
import { CartContext, CartDispatchContext } from 'context/CartContext';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

import { WeightUnit } from 'constants/enums';
import { WEIGHT_UNIT_LABELS } from 'constants/labels';
import { Bounty } from 'types/bounty.interface';
import { Cart } from 'types/cart.interface';
import { extractNumberFromNumericFormat } from 'utils/numbers';
import { getTopLevelVariant } from 'utils/priceUtils';
import { formatProductWeight, getRealWeight } from 'utils/productUtils';

interface KioskWeightSelectorProps {
  bounty: Bounty;
  setShowConfirmation: (cart: Cart) => void;
  submitLabel?: string;
  cancelLabel?: string;
  onCancel: () => void;
  isLoading?: boolean;
  isSmall?: boolean;
}

const KioskWeightSelector: FC<KioskWeightSelectorProps> = ({
  bounty,
  setShowConfirmation,
  submitLabel = 'Add to Cart',
  cancelLabel = 'Cancel',
  onCancel,
  isLoading = false,
  isSmall = false,
}) => {
  const cart = useContext(CartContext);
  const { addItemWithWeight } = useContext(CartDispatchContext);
  const { handleTrackAddItemToCart } = useGoogleAnalytics();
  const { weightUnit } = getTopLevelVariant(bounty?.product) || {};
  const realWeight = getRealWeight(bounty?.product, bounty.id, cart);
  const showRemainingStock = realWeight > 0 && realWeight <= 10;
  const [currentWeight, setCurrentWeight] = useState<string>(bounty?.product?.sortWeight?.toString() || '');

  const decreaseWeight = () => {
    if (isLoading) {
      return;
    }

    setCurrentWeight((prevWeight) => {
      const formattedWeight = extractNumberFromNumericFormat({ value: prevWeight, hasSuffix: true });
      const newWeight = formattedWeight > 0.5 ? formattedWeight - 0.5 : 0.5;

      return newWeight.toString();
    });
  };

  const increaseWeight = () => {
    if (isLoading) {
      return;
    }

    setCurrentWeight((prevWeight) => {
      const formattedWeight = extractNumberFromNumericFormat({ value: prevWeight, hasSuffix: true });
      const newWeight = formattedWeight === realWeight ? prevWeight : formattedWeight + 0.5;

      return newWeight.toString();
    });
  };

  const handleInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setCurrentWeight(value);
  };

  const handleAddToCart = () => {
    addItemWithWeight({
      bounty,
      weight: currentWeight,
      onSuccess: (updatedCart) => {
        handleTrackAddItemToCart({ bounty, weight: currentWeight });
        setShowConfirmation(updatedCart);
      },
    });
  };

  return (
    <Box width="100%">
      <IncrementWrapper
        onDecrease={decreaseWeight}
        onIncrease={increaseWeight}
        isSmall={isSmall}
      >
        <WeightSelector
          selectedWeight={currentWeight}
          weightUnit={weightUnit}
          onChange={handleInputChange}
          kioskMode
          sx={{ width: '100%' }}
        />
      </IncrementWrapper>

      {showRemainingStock && (
        <Typography variant="body2" fontWeight={600} color="error" mt={2}>
          Only
          {' '}
          {`${formatProductWeight(realWeight)} ${WEIGHT_UNIT_LABELS[weightUnit || WeightUnit.G]}`}
          {' '}
          left in stock!
        </Typography>
      )}

      <KioskProductActions
        onCancel={onCancel}
        onSubmit={handleAddToCart}
        disabled={isLoading || +currentWeight === 0 || !currentWeight || !realWeight}
        cancelLabel={cancelLabel}
        submitLabel={submitLabel}
      />
    </Box>
  );
};

export default KioskWeightSelector;
