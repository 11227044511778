import { styled } from '@mui/material/styles';

import CustomDatePicker from 'components/CustomDatePicker';
import CustomInput from 'components/CustomInput';

export const Wrapper = styled('div')(({ theme }) => ({
  '& > *': {
    margin: theme.spacing(2, 0),
  },
}));

export const InputField = styled(CustomInput)(({ theme }) => ({
  '& fieldset': {
    border: `2px solid ${theme.palette.general.lightGrey}`,
    borderRadius: '8px',
  },
  fontSize: '1rem',
}));

export const DatePickerField = styled(CustomDatePicker)(({ theme }) => ({
  '& fieldset': {
    border: `2px solid ${theme.palette.general.lightGrey}`,
    borderRadius: '8px',
  },
  fontSize: '1rem',
}));
