import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, SxProps, Typography } from '@mui/material';
import { FC } from 'react';

import { Root } from './index.styled';

interface BackCardProps {
  role?: string;
  onClick: () => void;
  text: string;
  sx?: SxProps;
}

const BackCard: FC<BackCardProps> = ({
  role,
  onClick,
  text,
  sx,
}) => (
  <Root role={role} sx={sx} onClick={onClick}>
    <IconButton>
      <ArrowBackIcon />
    </IconButton>
    <Typography>
      {text}
    </Typography>
  </Root>
);

export default BackCard;
