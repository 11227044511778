import flavourConfig from 'config';
import { LocalStorageKeys } from 'constants/enums';
import { StorageCart } from 'types/cart.interface';
import { PlaceOrderInfo } from 'types/checkout.interface';
import { ClientInfoInterface, DetailedClientInfo } from 'types/clientInfo.interface';
import { CompanyLocationData } from 'types/companyLocations.interface';
import { Filter } from 'types/filter.interface';
import { OnboardingInfo } from 'types/onboarding.interface';
import { Option } from 'types/option.interface';
import { ShopSettings } from 'types/shopSettings.interface';

export const getClientId = (): string|null => {
  try {
    const clientInfo = getClientInfo();
    return clientInfo?.parentId || clientInfo?.id || null;
  } catch (error) {
    return null;
  }
};

export const getClientInfo = (): ClientInfoInterface|null => {
  try {
    const clientData = localStorage.getItem(LocalStorageKeys.ClientInfo) || null;
    return clientData ? JSON.parse(clientData)?.clientInfo : null;
  } catch (error) {
    return null;
  }
};

export const getClientDetailedInfo = (): DetailedClientInfo|null => {
  try {
    const clientData = localStorage.getItem(LocalStorageKeys.ClientInfo) || null;
    return clientData ? JSON.parse(clientData) : null;
  } catch (error) {
    return null;
  }
};

export const setClientInfoToStorage = (clientData: DetailedClientInfo): void => {
  localStorage.setItem(LocalStorageKeys.ClientInfo, JSON.stringify(clientData));
};

export const saveCartToStorage = (cart: StorageCart): void => {
  localStorage.setItem(LocalStorageKeys.Cart, JSON.stringify(cart));
};

export const deleteCartFromStorage = () => {
  localStorage.removeItem(LocalStorageKeys.Cart);
};

export const getCartFromStorage = (): StorageCart|null => {
  try {
    const cart = localStorage.getItem(LocalStorageKeys.Cart) || null;
    return cart ? JSON.parse(cart) : null;
  } catch (error) {
    return null;
  }
};

export const saveShopSettingsToStorage = (shopSettings: ShopSettings) => {
  localStorage.setItem(LocalStorageKeys.ShopSettings, JSON.stringify(shopSettings));
};

export const getShopSettingsFromStorage = (): ShopSettings|null => {
  try {
    const shopSettings = localStorage.getItem(LocalStorageKeys.ShopSettings) || null;
    return shopSettings ? JSON.parse(shopSettings) : null;
  } catch (error) {
    return null;
  }
};

export const saveOnboardingInfoToStorage = (onboardingInfo: OnboardingInfo|null) => {
  localStorage.setItem(LocalStorageKeys.Onboarding, onboardingInfo ? JSON.stringify(onboardingInfo) : '');
};

export const getOnboardingInfo = (): OnboardingInfo|null => {
  try {
    const onboardingInfo = localStorage.getItem(LocalStorageKeys.Onboarding) || null;
    return onboardingInfo ? JSON.parse(onboardingInfo) : null;
  } catch (error) {
    return null;
  }
};

export const saveUsStatesToStorage = (usStates: Option[]) => {
  localStorage.setItem(LocalStorageKeys.USstates, JSON.stringify(usStates));
};

export const getLocalUsStates = (): Option[]|null => {
  try {
    const location = localStorage.getItem(LocalStorageKeys.USstates) || null;
    return location ? JSON.parse(location) : null;
  } catch (error) {
    return null;
  }
};

export const saveCompanyLocationsDataToStorage = (locationOptions: CompanyLocationData) => {
  localStorage.setItem(LocalStorageKeys.CompanyLocations, JSON.stringify(locationOptions));
};

export const getCompanyLocationsData = (): CompanyLocationData|null => {
  try {
    const data = localStorage.getItem(LocalStorageKeys.CompanyLocations) || null;
    return data ? JSON.parse(data) : null;
  } catch (error) {
    return null;
  }
};

export const getStoreId = (): string|null => {
  try {
    const onboardingInfo = getOnboardingInfo();
    return onboardingInfo?.storeId || null;
  } catch (error) {
    return null;
  }
};

export const getUseType = (): string|null => {
  try {
    const onboardingInfo = getOnboardingInfo();
    return onboardingInfo?.useType || null;
  } catch (error) {
    return null;
  }
};

export const setFiltersByStore = (storeId: string, filters: Filter[]): void => {
  try {
    const existingFilters = sessionStorage.getItem(`${flavourConfig.id}_filters`);
    const parsedFilters = existingFilters ? JSON.parse(existingFilters) : {};
    const value = {
      ...parsedFilters,
      [storeId]: filters,
    };
    sessionStorage.setItem(`${flavourConfig.id}_filters`, JSON.stringify(value));
  } catch (e) {
    const value = {
      [storeId]: filters,
    };
    sessionStorage.setItem(`${flavourConfig.id}_filters`, JSON.stringify(value));
  }
};

export const getFiltersByStore = (storeId: string): Filter[]|null => {
  try {
    const filters = sessionStorage.getItem(`${flavourConfig.id}_filters`);
    const parsedFilters = filters ? JSON.parse(filters) : {};

    return parsedFilters[storeId] || null;
  } catch (error) {
    return null;
  }
};

export const saveCheckoutDetailsToStorage = (checkoutDetails?: Partial<PlaceOrderInfo>) => {
  localStorage.setItem(LocalStorageKeys.CheckoutDetails, checkoutDetails ? JSON.stringify(checkoutDetails) : '');
};

export const getCheckoutDetails = (): PlaceOrderInfo|null => {
  try {
    const checkoutDetails = localStorage.getItem(LocalStorageKeys.CheckoutDetails) || null;
    return checkoutDetails ? JSON.parse(checkoutDetails) : null;
  } catch (error) {
    return null;
  }
};

export const clearLocationInformation = () => {
  localStorage.removeItem(LocalStorageKeys.CompanyLocations);
  localStorage.removeItem(LocalStorageKeys.ShopSettings);
  localStorage.removeItem(LocalStorageKeys.Onboarding);
};

export const clearClientInfo = () => {
  localStorage.removeItem(LocalStorageKeys.ClientInfo);
};

export const saveUIDToStorage = (uid = '') => {
  localStorage.setItem(LocalStorageKeys.Uid, uid);
};

export const getSavedUid = (): string => {
  try {
    return localStorage.getItem(LocalStorageKeys.Uid) || '';
  } catch (error) {
    return '';
  }
};
