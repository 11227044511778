import { runGetApiRequest } from 'services/Api';

import {
  FILTERS_ROUTE,
  PRODUCT_CATEGORIES_OPEN_ROUTE,
  PRODUCT_DETAILS_OPEN_ROUTE,
  PRODUCT_EXTRAS_ROUTE,
  PRODUCTS_LIST_OPEN_ROUTE,
} from 'constants/apiRoutes';
import { formatRoute } from 'utils/formatters';

export const getProducts = async (params: URLSearchParams) => (
  runGetApiRequest(PRODUCTS_LIST_OPEN_ROUTE, params));

export const getProductDetails = async (bountyId: string) => (
  runGetApiRequest(formatRoute(PRODUCT_DETAILS_OPEN_ROUTE, { bountyId })));

export const getCategories = async (params: URLSearchParams) => (
  runGetApiRequest(PRODUCT_CATEGORIES_OPEN_ROUTE, params)
);

export const getFilters = async (params: URLSearchParams) => (
  runGetApiRequest(FILTERS_ROUTE, params));

export const getProductExtras = async (productId: string) => (
  runGetApiRequest(formatRoute(PRODUCT_EXTRAS_ROUTE, { productId }))
);
