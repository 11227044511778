import {
  Box,
  Fade, Grid2 as Grid, SelectChangeEvent,
} from '@mui/material';
import { get } from 'lodash';
import { ChangeEvent, FC } from 'react';

import CustomInput from 'components/CustomInput';
import CustomPhoneInput from 'components/CustomPhoneInput';
import CustomRadioButton from 'components/CustomRadioButton';
import DeliveryAddressForm from 'components/DeliveryAddressForm';
import DriverLicenseForm from 'components/DriverLicenseForm';
import MedicalInfoForm from 'components/MedicalInfoForm';
import PickupInformationForm from 'components/PickupInformationForm';
import Schedule from 'components/Schedule';
import SectionTitle from 'components/SectionTitle';
import UserInfoForm from 'components/UserInfoForm';
import useCheckoutConfiguration from 'containers/CheckoutPage/CheckoutForm/useCheckoutConfiguration';

import { FieldRuleType, DeliveryMethod } from 'constants/enums';
import {
  DELIVER_NOTE,
  DELIVER_PHONE_NUMBER,
  DELIVERY_METHOD,
  PAYMENT_METHOD,
} from 'constants/fields';
import { ORDER_DELIVERY_METHOD } from 'constants/labels';
import { USE_TYPE_OPTIONS } from 'constants/options';
import { OrderDateRestrictions, PlaceOrderInfo } from 'types/checkout.interface';
import { Option } from 'types/option.interface';
import { hasOrderDateRestrictions, showCheckoutForms } from 'utils/checkoutUtils';
import { getPhoneFromAddress } from 'utils/formatters';
import { checkSchedule } from 'utils/shopOperation';

interface CheckoutFormProps {
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => void;
  onValueChange: (value: any, name: string) => void;
  checkoutForm: PlaceOrderInfo;
  fieldErrors: Record<string, string>;
  usStates: Option[];
  useType: string;
  orderDateRestrictions: OrderDateRestrictions | null;
  checkoutFields?: Record<string, FieldRuleType>;
  isLoadingDateRestrictions: boolean;
  kioskMode?: boolean;
}

const CheckoutForm :FC<CheckoutFormProps> = ({
  onChange,
  onValueChange,
  checkoutForm,
  fieldErrors,
  usStates,
  useType,
  orderDateRestrictions,
  checkoutFields = {},
  isLoadingDateRestrictions,
  kioskMode = false,
}) => {
  const {
    customer, delivery, medical, payment, address, driverLicense,
  } = checkoutForm || {};
  const { paymentMethods, deliveryMethods } = useCheckoutConfiguration({ deliveryMethod: delivery.method });
  const showDeliveryMethodsSection = deliveryMethods?.length > 1;
  const { showUserInfoForm, showDriverLicenseForm } = showCheckoutForms(checkoutFields);
  const showMedicalForm = useType === USE_TYPE_OPTIONS[1].key;
  const orderTypeTitle = showDeliveryMethodsSection
    ? 'Order Type'
    : `Order Type: ${ORDER_DELIVERY_METHOD?.[delivery?.method] || ''}`;
  const { hasSchedule, ...scheduleInfo } = checkSchedule();

  return (
    <Box width="100%">
      {showUserInfoForm && (
        <>
          <SectionTitle title="Contact Info" sx={{ mb: 2 }} />
          <UserInfoForm
            onChange={onChange}
            onValueChange={onValueChange}
            customer={customer}
            fieldErrors={fieldErrors}
            usStates={usStates}
            fieldsRules={checkoutFields}
            kioskMode={kioskMode}
          />
        </>
      )}

      {showDriverLicenseForm && (
        <DriverLicenseForm
          onChange={onChange}
          onValueChange={onValueChange}
          driverLicense={driverLicense}
          fieldErrors={fieldErrors}
          usStates={usStates}
          sx={{ mt: 4 }}
          fieldsRules={checkoutFields}
        />
      )}

      {showMedicalForm && (
        <Fade
          in={showMedicalForm}
          timeout={{ enter: 1000, exit: 1000 }}
        >
          <div>
            <SectionTitle
              title="Medical Info"
              sx={{ mt: showUserInfoForm || showDriverLicenseForm ? 5 : 0, mb: 2 }}
            />
            <MedicalInfoForm
              onChange={onChange}
              onValueChange={onValueChange}
              medical={medical}
              fieldErrors={fieldErrors}
            />
          </div>
        </Fade>
      )}

      {!kioskMode && (
        <>
          <SectionTitle
            title={orderTypeTitle}
            sx={{ mb: 1.5, mt: showMedicalForm || showUserInfoForm || showDriverLicenseForm ? 5 : 0 }}
          />

          <Grid container spacing={4}>
            {showDeliveryMethodsSection && (
              <Grid size={12}>
                <CustomRadioButton
                  {...DELIVERY_METHOD}
                  size="medium"
                  onChange={onChange}
                  value={delivery?.method}
                  options={deliveryMethods}
                  error={!!get(fieldErrors, DELIVERY_METHOD.name)}
                  helperText={get(fieldErrors, DELIVERY_METHOD.name)}
                />
              </Grid>
            )}

            {delivery?.method === DeliveryMethod.CURBSIDE && (
              <Grid size={12}>
                <CustomPhoneInput
                  {...DELIVER_PHONE_NUMBER}
                  onChange={onValueChange}
                  phoneNumber={getPhoneFromAddress(address)}
                  error={!!get(fieldErrors, DELIVER_PHONE_NUMBER.name)}
                  helperText={get(fieldErrors, DELIVER_PHONE_NUMBER.name)}
                />
              </Grid>
            )}

            {hasOrderDateRestrictions(delivery?.method) && (
              <Box width="100%">
                <PickupInformationForm
                  delivery={delivery}
                  onValueChange={onValueChange}
                  fieldErrors={fieldErrors}
                  orderDateRestrictions={orderDateRestrictions}
                  fieldsRules={checkoutFields}
                  isLoadingDateRestrictions={isLoadingDateRestrictions}
                />
              </Box>
            )}
          </Grid>

          {hasSchedule && <Schedule scheduleInfo={scheduleInfo} />}

          {delivery?.method === DeliveryMethod.DELIVERY && address && (
            <>
              <SectionTitle title="Delivery Address" sx={{ mb: 2, mt: 4 }} />
              <DeliveryAddressForm
                onChange={onChange}
                onValueChange={onValueChange}
                address={address}
                fieldErrors={fieldErrors}
              />

              <SectionTitle title="Delivery Notes" sx={{ mb: 2, mt: 4 }} />
              <CustomInput {...DELIVER_NOTE} onChange={onChange} />
            </>
          )}

          <Grid container spacing={4} mt={1}>
            <Grid size={12}>
              <CustomRadioButton
                {...PAYMENT_METHOD}
                variant="body2"
                size="medium"
                onChange={onChange}
                options={paymentMethods}
                value={payment?.paymentMethod}
                error={!!get(fieldErrors, PAYMENT_METHOD.name)}
                helperText={get(fieldErrors, PAYMENT_METHOD.name)}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default CheckoutForm;
