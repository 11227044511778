import { styled } from '@mui/material/styles';

export const StyledBox = styled('div')(({ theme }) => ({
  width: '100%',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 0, 1, 4),
  color: theme.palette.primary.main,
}));
