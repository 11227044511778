import { FormControl, FormLabel, Typography } from '@mui/material';
import { DatePicker, DesktopDatePicker } from '@mui/x-date-pickers';
import { FC } from 'react';

import { DEFAULT_DATE_FORMAT } from 'constants/general';

export interface DatePickerProps {
  fullWidth?: boolean;
  required?: boolean;
  label?: string;
  format?: string;
  onChange: (date: any, name: string) => unknown;
  value?: Date | null;
  size?: 'small' | 'medium';
  name: string;
  error?: boolean;
  disableOpenPicker?: boolean;
  open?: boolean;
  helperText?: string;
  shouldDisableDate?: (date: Date) => boolean;
}

const CustomDatePicker :FC<DatePickerProps> = (props) => {
  const {
    label, fullWidth = false, required, format = DEFAULT_DATE_FORMAT, disableOpenPicker,
    size, onChange, name, error, helperText, value = undefined, ...remainingProps
  } = props;
  const PickerComponent = disableOpenPicker ? DesktopDatePicker : DatePicker;

  const handleChange = (date: any) => {
    const formattedDate = new Date(date).getTime();

    onChange(formattedDate || null, name);
  };

  return (
    <FormControl component="div" fullWidth={fullWidth}>
      {label && (
        <FormLabel required={required}>
          <Typography component="span" color="textPrimary">
            {label}
          </Typography>
        </FormLabel>
      )}
      <PickerComponent
        {...remainingProps}
        value={value ? new Date(value) : null}
        disableOpenPicker={disableOpenPicker}
        format={format}
        onChange={handleChange}
        slotProps={{ textField: { size, error, helperText } }}
      />
    </FormControl>
  );
};

export default CustomDatePicker;
