import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ActionsWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 0),
  borderRadius: '4px',
  marginBottom: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

export const Content = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  flexGrow: 1,
}));
