import { Typography, CardMedia, Box } from '@mui/material';
import { FC, useEffect } from 'react';

import ProductAdded from 'assets/images/added_to_cart.gif';

interface CartConfirmationProps {
  cartTotal: string;
  onClose: () => void;
}

const CartConfirmation:FC<CartConfirmationProps> = ({ cartTotal, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => onClose(), 2400);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
      <CardMedia
        sx={{ width: '200px', height: '200px' }}
        component="img"
        src={ProductAdded}
        alt="product added to cart"
      />
      <Typography variant="h5" fontWeight="500" textAlign="center">
        Item added to cart
      </Typography>

      {cartTotal && (
      <>
        <Typography variant="body2" color="textSecondary" textAlign="center">
          Order total updated
        </Typography>
        <Typography variant="h5" fontWeight="500" textAlign="center">
          {cartTotal}
        </Typography>
      </>
      )}
    </Box>
  );
};

export default CartConfirmation;
