import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Box, IconButton, SelectChangeEvent, Typography,
} from '@mui/material';
import {
  FC, useState, KeyboardEvent, MouseEvent, useMemo,
} from 'react';

import CustomSelector from 'components/CustomSelector';

import { Category } from 'types/category.interface';
import { formatCategoryOptions } from 'utils/formatters';
import { isIOS } from 'utils/utils';

import {
  CategoriesLabel, SwipeableDrawer, DrawerTitle, FiltersWrapper,
} from './index.styled';

const iOS = isIOS();

interface CategoriesMobileProps {
  selectors: Category[][];
  selectedCategories: string[];
  onCategorySelect: ({ target: { value } }: SelectChangeEvent<unknown>, index: number) => void;
}

const CategoriesMobile:FC<CategoriesMobileProps> = ({
  selectors,
  selectedCategories,
  onCategorySelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (event: KeyboardEvent | MouseEvent) => {
    if (
      event?.type === 'keydown'
      && ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setIsOpen((preState) => !preState);
  };

  const findName = (key: string, index: number) => {
    const list = selectors[index];

    if (!list) {
      return '';
    }

    const details = list.find(({ code }) => code === key);
    return details?.name || key;
  };

  const selectedCategoryName = useMemo(() => {
    const name = findName(selectedCategories?.[selectedCategories.length - 1], selectedCategories.length - 1);

    if (!name || name === 'all') {
      return 'All Categories';
    }

    return name;
  }, [selectedCategories, selectors]);

  return (
    <Box sx={{ height: '100%' }}>
      <CategoriesLabel onClick={toggleDrawer}>
        <FilterListIcon sx={{ color: 'general.lightGrey1' }} />
        <Typography>
          {selectedCategoryName}
        </Typography>
      </CategoriesLabel>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        anchor="left"
        open={isOpen}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        <DrawerTitle>
          <Typography fontWeight={600}>
            Categories
          </Typography>
          <IconButton onClick={toggleDrawer} id="iconButton-closeCategories">
            <CloseIcon />
          </IconButton>
        </DrawerTitle>

        <FiltersWrapper>
          {selectors?.map((arr, index) => (
            <CustomSelector
              variant="outlined"
              key={index}
              displayEmpty
              value={selectedCategories[index] || ''}
              onChange={(e) => onCategorySelect(e, index)}
              name="category"
              options={formatCategoryOptions(arr)}
            />
          ))}
        </FiltersWrapper>
      </SwipeableDrawer>
    </Box>
  );
};

export default CategoriesMobile;
