import { Box, Tooltip, Typography } from '@mui/material';
import {
  FC, MouseEvent, useMemo, useState,
} from 'react';

import Brand from 'components/Brand';
import CustomButton from 'components/CustomButton';
import Dot from 'components/Dot';
import FreeTag from 'components/FreeTag';
import {
  StyledSection, StyledImage, StyledPriceBox,
} from 'components/ProductCard/index.styled';
import ProductCardOverlay from 'components/ProductCard/ProductCardOverlay';
import ProductPrice from 'components/ProductPrice';
import ProductWeight from 'components/ProductWeight';
import ProductFlwrVariantsDialog from 'containers/ProductFlwrVariantsDialog';
import ProductQuantityDialog from 'containers/ProductQuantityDialog';
import ProductTierPriceDialog from 'containers/ProductTierPriceDialog';

import { useKiosk } from 'hooks/useKiosk';

import { OnboardingUseType } from 'constants/enums';
import { Bounty } from 'types/bounty.interface';
import { textTruncate } from 'utils/formatters';
import {
  getProductPrice, getTierPrice, getTopLevelVariant, isFree,
} from 'utils/priceUtils';
import {
  formatChemicalComposition,
  formatProductWeight,
  getCoverUrl,
  getManufacturer,
  getProductStrainType,
  isFlwr,
} from 'utils/productUtils';
import { getPublicUrlForImage } from 'utils/publicUrl';

interface ProductCardProps {
  bounty: Bounty;
  useType?: OnboardingUseType;
  onAdd?: (bounty: Bounty) => unknown;
  hasAddButton?: boolean;
  isBrandDisabled?: boolean;
  isDescriptionVisible?: boolean;
}

const ProductCard:FC<ProductCardProps> = ({
  bounty,
  onAdd,
  useType,
  hasAddButton = false,
  isBrandDisabled = false,
  isDescriptionVisible = false,
}) => {
  const { kioskMode } = useKiosk();
  const [isOpen, setIsOpen] = useState(false);
  const { product, tags } = bounty;
  const { price, msrp } = getProductPrice(product, useType);
  const isProductFree = isFree(price);
  const manufacturer = getManufacturer(product);
  const { quantity, weightUnit } = getTopLevelVariant(product) || {};
  const productQuantity = Math.max(0, quantity || 0);
  const isOutOfStock = productQuantity <= 0;
  const strainType = tags?.tags ? getProductStrainType(tags?.tags) : null;
  const tierPrices = bounty?.product?.altPrices?.tierPrices;
  const showWeightModal = !tierPrices && !isFlwr(bounty?.product?.sortUnit);

  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  const coverUrl = useMemo(() => (
    getCoverUrl(bounty?.id, product)
  ), [bounty.id]);

  const chemicalCompositionString = useMemo(() => (
    formatChemicalComposition(product)
  ), [product?.chemicalComposition]);

  const handleShowOverlay = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setShowOverlay(true);
  };

  const handleCloseOverlay = () => setShowOverlay(false);

  const handleOpenQuantitySelectorDialog = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const handleCloseDialog = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsOpen(false);
  };

  const handleAddToCard = (e: MouseEvent<HTMLElement>) => {
    if (!tierPrices?.length && kioskMode) {
      handleShowOverlay(e);
    } else {
      handleOpenQuantitySelectorDialog(e);
    }
  };

  const renderPrice = (useLowestTierPrice: boolean = false) => {
    const tierPricesCount = tierPrices?.length;
    const productPrice = (useLowestTierPrice && tierPricesCount > 0
      ? getTierPrice(useType, tierPrices[tierPricesCount - 1]) : null) || price;

    if (isOutOfStock) {
      return <Typography fontWeight={700} variant="body2" color="error">Out of stock</Typography>;
    }

    if (isProductFree) {
      return <FreeTag fontWeight={700} variant="body2" />;
    }

    return <ProductPrice price={productPrice} msrp={msrp} fontWeight={700} variant="body1" />;
  };

  const renderQuantityModalSelector = () => {
    // eslint-disable-next-line no-nested-ternary
    const QuantityModal = showWeightModal
      ? ProductQuantityDialog
      : isFlwr(bounty?.product?.sortUnit) ? ProductFlwrVariantsDialog : ProductTierPriceDialog;

    return (
      <QuantityModal
        bounty={bounty}
        isOpen={isOpen}
        onClose={handleCloseDialog}
      />
    );
  };

  return (
    <Box position="relative" height="100%">
      <StyledSection kioskMode={kioskMode}>
        <Box margin="0 auto" height={150}>
          <StyledImage
            component="img"
            src={coverUrl || getPublicUrlForImage('images/placeholder_image.jpg')}
            alt={product?.name}
          />
        </Box>

        <Box my={1}>
          {!!product?.name && (
            <Typography fontWeight="700" gutterBottom>
              {product?.name}
            </Typography>
          )}
          {!!manufacturer && (
            <Brand brand={manufacturer} disabled={isBrandDisabled} />
          )}
          {product?.description && isDescriptionVisible && (
            <Typography color="textSecondary" variant="body2" my={1}>
              {textTruncate(product?.description, 50)}
            </Typography>
          )}
          {(strainType || chemicalCompositionString) && (
            <Typography component="div" color="textSecondary" variant="body2" my={1}>
              {!!strainType && strainType.displayName}
              {!!strainType && !!chemicalCompositionString && <Dot display="inline-block" mb={0.5} mx={1} />}
              {!!chemicalCompositionString && chemicalCompositionString}
            </Typography>
          )}
        </Box>

        <StyledPriceBox>
          <Box display="flex" alignItems="center" gap={1}>
            {renderPrice()}
            {product?.sortWeight && (
            <>
              <Dot />
              <ProductWeight
                fontWeight={700}
                variant="body1"
                weight={formatProductWeight(product.sortWeight)}
                weightUnit={weightUnit}
              />
            </>
            )}
          </Box>
          {hasAddButton && (
            <Tooltip title={isOutOfStock ? 'Out of stock' : 'Add to cart'}>
              <span>
                <CustomButton
                  id="button-selectWeight"
                  variant="contained"
                  onClick={handleAddToCard}
                  disabled={isOutOfStock}
                  sx={{ borderRadius: '32px' }}
                >
                  Select
                </CustomButton>
              </span>
            </Tooltip>
          )}
        </StyledPriceBox>

      </StyledSection>

      {showOverlay && onAdd && hasAddButton && (
        <Box onClick={(e) => e.preventDefault()}>
          <ProductCardOverlay onBack={handleCloseOverlay} bounty={bounty} />
        </Box>
      )}

      {isOpen && renderQuantityModalSelector()}
    </Box>
  );
};

export default ProductCard;
