import { styled } from '@mui/material/styles';

export const StyledButton = styled('div')(({ theme }) => ({
  '&, &.slick-next': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: '4px',
    fontSize: '14px',
    height: '60px',
    width: '30px',
    right: '-45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.background.secondary,
      color: theme.palette.primary.main,
    },
  },
  '&.slick-disabled': {
    display: 'none',
  },
  '& svg': {
    fill: theme.palette.primary.main,
  },
}));
