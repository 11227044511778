import { Box, Skeleton } from '@mui/material';
import { FC } from 'react';

import { Content, Root } from 'containers/Categories/KioskCategories/index.styled';

const KioskCategoriesSkeleton:FC = () => (
  <Root level={0}>
    <Content level={0} className="hidden-scroll">
      <Box pt={4.5} pb={4} display="flex" flexDirection="column" alignItems="center">
        <Skeleton
          variant="rectangular"
          width="100%"
          height="54px"
          sx={{ borderRadius: '25px' }}
        />
        <Skeleton variant="text" sx={{ fontSize: '1.3rem', width: '100%' }} />
      </Box>

      <Box my={4} display="flex" alignItems="center" flexDirection="column">
        <Skeleton variant="circular" width="80px" height="80px" />
        <Skeleton variant="text" sx={{ fontSize: '1.3rem', width: '70%' }} />
      </Box>

      <Box my={4} display="flex" alignItems="center" flexDirection="column">
        <Skeleton variant="circular" width="80px" height="80px" />
        <Skeleton variant="text" sx={{ fontSize: '1.3rem', width: '70%' }} />
      </Box>

      <Box my={4} display="flex" alignItems="center" flexDirection="column">
        <Skeleton variant="circular" width="80px" height="80px" />
        <Skeleton variant="text" sx={{ fontSize: '1.3rem', width: '70%' }} />
      </Box>

      <Box my={4} display="flex" alignItems="center" flexDirection="column">
        <Skeleton variant="circular" width="80px" height="80px" />
        <Skeleton variant="text" sx={{ fontSize: '1.3rem', width: '70%' }} />
      </Box>

      <Box my={4} display="flex" alignItems="center" flexDirection="column">
        <Skeleton variant="circular" width="80px" height="80px" />
        <Skeleton variant="text" sx={{ fontSize: '1.3rem', width: '70%' }} />
      </Box>

      <Box my={4} display="flex" alignItems="center" flexDirection="column">
        <Skeleton variant="circular" width="80px" height="80px" />
        <Skeleton variant="text" sx={{ fontSize: '1.3rem', width: '70%' }} />
      </Box>
    </Content>
  </Root>
);

export default KioskCategoriesSkeleton;
