import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  Box, Grid2 as Grid, IconButton, Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import {
  FC, useContext, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';

import Brand from 'components/Brand';
import FreeTag from 'components/FreeTag';
import Price from 'components/Price';
import RemoveProductConfirmation from 'components/RemoveProductConfirmation';
import SummaryStockInfo from 'components/SummaryStockInfo';
import UnstyledLink from 'components/UnstyledLink';
import {
  StyledBox, StyledDescription, StyledImage, StyledPrice,
} from 'containers/CartProduct/index.styled';
import ProductQuantitySelector from 'containers/CartProduct/ProductQuantitySelector';
import PriceVariant from 'containers/PriceVariantsSelector/PriceVariant';
import { CartDispatchContext } from 'context/CartContext';
import { LocationContext } from 'context/LocationContext';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useKiosk } from 'hooks/useKiosk';
import { getProductDetailsRoute } from 'hooks/useRouting';

import { CART_PRODUCT_COVER_SIZE } from 'constants/cover';
import { FlwrQuantityVariant } from 'constants/enums';
import { ProductInfo } from 'types/cart.interface';
import { getProductCover } from 'utils/attachmentsUtils';
import {
  formatPrice, getPrice, isFree,
} from 'utils/priceUtils';
import {
  getFlwrOptionsByType, getFlwrQuantityByVariant, getFlwrVariantByQuantity, isFlwr, shouldUseProductWeight,
} from 'utils/productUtils';
import { getPublicUrlForImage } from 'utils/publicUrl';

interface CartProductProps {
  product: ProductInfo;
  isProductUpdated?: boolean;
  wasAdjusted?: boolean;
  updatedQuantity?: number;
  handleChange?: (productId: string) => void;
  isDisabled?: boolean;
  showErrorMessage?: boolean;
}

const CartProduct:FC<CartProductProps> = (props) => {
  const {
    product,
    updatedQuantity = 0,
    isProductUpdated,
    handleChange,
    wasAdjusted,
    isDisabled = false,
    showErrorMessage = false,
  } = props;
  const { kioskMode } = useKiosk();
  const [isOpen, setIsOpen] = useState(false);
  const { removeItem, setQuantity } = useContext(CartDispatchContext);
  const { onboardingInfo } = useContext(LocationContext);
  const { handleTrackRemoveFromCart } = useGoogleAnalytics();
  const {
    weight = '', sortWeight = 0, quantity = 0, sortUnit,
  } = product;
  const productWeight = weight ? +weight : null;
  const flwrOptions = getFlwrOptionsByType(sortUnit);

  const { price } = getPrice({
    useType: onboardingInfo?.useType,
    altPrices: product?.altPrices,
    productPrice: { mainPrice: product?.price || {} },
    msrp: product?.msrp,
    weight: shouldUseProductWeight(product)
      ? productWeight
      : sortWeight * quantity,
  });

  const isOutOfStock = Boolean(isProductUpdated && updatedQuantity <= 0);
  const updatedQuantityLabel = isOutOfStock
    ? 'Sorry, the item is no longer available due to stock.'
    : `The quantity has changed, there are only ${updatedQuantity} left.`;
  const {
    availableQuantity = 0, maxAllowedQty, manufacturer, balances = {},
  } = product;
  const allowableQuantity = Math.min(availableQuantity, maxAllowedQty || Number.MAX_VALUE);
  const coverUrl = useMemo(() => {
    const cover = getProductCover(
      product.imageUrl,
      CART_PRODUCT_COVER_SIZE.w,
      CART_PRODUCT_COVER_SIZE.h,
      product.imageCdn,
    );
    return cover.cdnUrl || cover.url;
  }, [product.id]);

  const handleClose = () => setIsOpen(false);

  const handleRemove = () => {
    removeItem(product.id);
    handleTrackRemoveFromCart(product);
    toast.success('Product was removed from cart!');
  };

  const handleSubmit = () => {
    handleRemove();
    handleClose();
  };

  const handleSelectPriceVariant = (variant: FlwrQuantityVariant) => {
    setQuantity(product?.id, getFlwrQuantityByVariant(variant, sortUnit));
  };

  return (
    <StyledBox hasBottomBorder={!(balances && Object.keys(balances).length > 0)}>
      <Box sx={{ opacity: isOutOfStock ? 0.5 : 1 }}>
        <Box display="flex">
          <StyledImage
            src={coverUrl || getPublicUrlForImage('images/placeholder_image.jpg')}
            alt={product?.name}
          />
          <Box pb={1} maxWidth="500px" width="100%">
            {isOutOfStock || isDisabled
              ? (
                <Typography variant="body1" fontWeight={600}>
                  {product?.name}
                </Typography>
              ) : (
                <UnstyledLink to={getProductDetailsRoute(product.bountyId)}>
                  <Typography variant="body1" fontWeight={600}>
                    {product?.name}
                  </Typography>
                </UnstyledLink>
              )}
            {!!manufacturer && (
              <Brand brand={manufacturer} sx={{ mb: 1 }} disabled={isOutOfStock || isDisabled} />
            )}
            <StyledDescription variant="body1" color="textSecondary">
              {product?.description}
            </StyledDescription>
          </Box>
        </Box>

        {sortUnit && isFlwr(sortUnit)
          ? (
            <Box display="flex" alignItems="center" gap={2} width="100%" mt={2}>
              <Grid container columnSpacing={2} rowSpacing={2} maxWidth="500px" width="100%">
                {flwrOptions?.map(({ key, value }) => {
                  const optionsCount = flwrOptions?.length;
                  return (
                    <Grid size={[6, (12 / optionsCount)]} key={value}>
                      <PriceVariant
                        label={key}
                        onClick={() => handleSelectPriceVariant(value)}
                        variant={value}
                        useType={onboardingInfo?.useType}
                        productPrice={price}
                        tierPrices={product?.altPrices?.tierPrices}
                        isActive={value === getFlwrVariantByQuantity(quantity, sortUnit)}
                        sortUnit={sortUnit}
                        isDisabled={allowableQuantity < getFlwrQuantityByVariant(value, sortUnit)}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <IconButton
                disabled={isOutOfStock || isDisabled}
                sx={{ p: 0.5 }}
                onClick={() => setIsOpen(true)}
                id="iconButton-removeItem"
              >
                <CancelOutlinedIcon sx={{ color: 'general.lightGrey1' }} />
              </IconButton>
            </Box>
          ) : (
            <StyledPrice>
              <Box display="flex" alignItems="center">
                {allowableQuantity > 0
                  ? (
                    <ProductQuantitySelector
                      product={product}
                      kioskMode={kioskMode}
                      isProductUpdated={isProductUpdated}
                      onOpen={setIsOpen}
                      isDisabled={isDisabled}
                      onRemove={handleRemove}
                      onChange={handleChange}
                      updatedQuantity={updatedQuantity}
                    />
                  ) : (
                    <Typography variant="body2" color="error">
                      Out of stock
                    </Typography>
                  )}
              </Box>
              <div>
                {!isFree(price)
                  ? (
                    <Price
                      fontWeight={700}
                      variant="body1"
                      price={formatPrice(price)}
                      quantity={product?.quantity}
                      weight={!isNil(product?.weight) ? product?.sortWeight : null}
                    />
                  ) : <FreeTag />}
              </div>
            </StyledPrice>
          )}
      </Box>

      {(showErrorMessage || (isProductUpdated && !wasAdjusted)) && (
        <SummaryStockInfo
          updatedQuantityLabel={showErrorMessage
            ? 'Sorry, this item is unavailable right now.'
            : updatedQuantityLabel}
          onRemove={handleRemove}
          isOutOfStock={isOutOfStock}
        />
      )}

      {isOpen && (
        <RemoveProductConfirmation
          isOpen={isOpen}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
    </StyledBox>
  );
};

export default CartProduct;
